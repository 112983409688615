import { useAssetInventoryDetailsRangeQuery } from "./useAssetInventoryDetailsRangeQuery";
import { useCallback, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMonitorCompanyFilterContext } from "./useMonitorCompanyFilterContext";
import { priorityToString } from "../util/priorityToString";
import { Asset } from "../types/assetInventoryApi";
import { useScannersQuery } from "./useScannersQuery";
import { useCompaniesQuery } from "./useCompaniesQuery";
import { getVulnImpact } from "../util/getVulnImpact";
import { getVulnLikelihood } from "../util/getVulnLikelihood";

export function useMonitorCompany() {
  const { companyId, scannerId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const start_timestamp = useMemo(
    () => searchParams.get("start_timestamp"),
    [searchParams]
  );
  const end_timestamp = useMemo(
    () =>
      searchParams.get("end_timestamp") ??
      new Date().toISOString().replace("T", " ").replace("Z", "").split(".")[0],
    [searchParams]
  );

  const setTimeRangeDays = useCallback(
    (days: number) => {
      const end_timestamp_string = end_timestamp?.replace(" ", "T") + "Z";
      const end_timestampMilliseconds = new Date(
        end_timestamp_string
      ).getTime();
      const start_timestampMilliseconds =
        end_timestampMilliseconds - 1000 * 60 * 60 * 24 * days;
      setSearchParams({
        start_timestamp: new Date(start_timestampMilliseconds)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
          .split(".")[0],
        end_timestamp: end_timestamp as string,
      });
    },
    [searchParams]
  );

  const { companies, isLoading: isLoadingCompanies } = useCompaniesQuery(1);

  const company = useMemo(() => {
    return companies?.find(
      (company) => company.CUID === parseInt(companyId ?? "")
    );
  }, [companyId, companies]);

  const { scanners: allScanners, isLoading: isLoadingScanners } =
    useScannersQuery();

  const scanners = useMemo(() => {
    return allScanners?.filter((scanner) => scanner.CUID === company?.CUID);
  }, [allScanners, company]);


  const currentScanner = useMemo(() => {
    return scanners?.find(
      (scanner) => scanner.ScannerID === parseInt(scannerId ?? "")
    );
  }, [scanners, scannerId]);


  const { assets, nvts, ...assetsQuery } = useAssetInventoryDetailsRangeQuery(
    parseInt(companyId ?? ""),
    scannerId,
    start_timestamp,
    end_timestamp
  );

  const isLoading = useMemo(
    () => isLoadingCompanies || isLoadingScanners || assetsQuery.isLoading,
    [isLoadingCompanies, isLoadingScanners, assetsQuery.isLoading]
  );

  const { filter } = useMonitorCompanyFilterContext();

  const filteredNvts = useMemo(() => {
    return nvts?.filter((nvt) => {
      return (
        filter.priority[priorityToString(nvt.priority)] &&
        filter.impact[getVulnImpact(nvt)] &&
        filter.likelihood[getVulnLikelihood(nvt)] &&
        filter.status[nvt.status ?? "To Do"]
      );
    });
  }, [assets, filter]);

  const filteredAssets: Asset[] | undefined = useMemo(() => {
    // change the nvts object for each asset to only include the filtered nvts
    const assetsWithFilteredNvts = assets?.map((asset) => {
      return {
        ...asset,
        nvts: asset.nvts.filter((nvt) => {
          return (
            filter.priority[priorityToString(nvt.priority)] &&
            filter.impact[getVulnImpact(nvt)] &&
            filter.likelihood[getVulnLikelihood(nvt)] &&
            filter.status[nvt.status ?? "To Do"]
          );
        }),
      };
    });
    return assetsWithFilteredNvts?.filter((asset) => {
      if (!filter.assets["No Risks"] && asset.nvts.length === 0) return false;
      else return true;
    });
  }, [assets, filter]);

  return {
    scanners,
    currentScanner,
    companies: companies,
    scannerId,
    companyId: parseInt(companyId ?? ""),
    company,
    assets: filteredAssets,
    nvts: filteredNvts,
    start_timestamp,
    end_timestamp,
    setTimeRangeDays,
    assetsQuery,
    isLoading,
    setSearchParams,
    assetsLoading: assetsQuery.isLoading,
    scannersLoading: isLoadingScanners,
    companiesLoading: isLoadingCompanies,
  };
}
