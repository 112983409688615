import {
  Divider,
  Flex,
  Link,
  ScrollView,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
} from "@aws-amplify/ui-react";
import React from "react";
import { CyberScoreCombined } from "../../../types/CyberScoreCombined";

type Props = {
  cyberScore?: CyberScoreCombined | undefined;
};

export function ESSDetails({ cyberScore }: Props) {
  return (
    <>
      <Flex direction="column">
        <Flex direction="column" gap="xs">
          <Flex gap={"xxs"}>
            <Divider
              width={"6px"}
              margin={"3px 0 3px 0"}
              borderRadius={"4px"}
              opacity={"100"}
              borderColor={"#42cefb"}
              backgroundColor="#42cefb"
              orientation="vertical"
            />
            <Text fontSize={"smaller"} fontWeight="bold">
              What is it?
            </Text>
          </Flex>

          <Text fontSize={"smaller"}>
            SPF, DKIM, and DMARC make sure emails are really from who they say
            they're from. This stops criminals from pretending to be someone
            else in emails. Detailed instructions in our{" "}
            <Link
              isExternal
              textDecoration={"underline"}
              fontWeight={"bold"}
              href="https://www.icebergcyber.com/help-center-category/website-security"
            >
              Help Center
            </Link>{" "}
            to set up them correctly.
          </Text>
        </Flex>
        <Flex direction="column" gap="xs">
          <Flex gap={"xxs"}>
            <Divider
              width={"6px"}
              margin={"3px 0 3px 0"}
              borderRadius={"4px"}
              opacity={"100"}
              borderColor={"#42cefb"}
              backgroundColor="#42cefb"
              orientation="vertical"
            />
            <Text fontSize={"smaller"} fontWeight="bold">
              Your Results
            </Text>
          </Flex>
          <Flex padding={"small xl xs xl"}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell fontSize={"small"} as="th">
                    SPF
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-all",
                    }}
                    fontSize={"smaller"}
                  >
                    {cyberScore?.SPF}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontSize={"small"} as="th">
                    DKIM
                  </TableCell>
                  <TableCell
                    maxWidth={"80px"}
                    fontSize={"smaller"}
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    <ScrollView maxHeight={"30px"}>
                      {cyberScore?.DKIM}
                    </ScrollView>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontSize={"small"} as="th">
                    DMARC
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-all",
                    }}
                    fontSize={"smaller"}
                  >
                    {cyberScore?.DMARC}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
