import { NVT } from "../types/assetInventoryApi";

export function getVulnLikelihood(nvt: NVT): "High" | "Medium" | "Low" {
  switch (nvt.likelihood) {
    case 1:
      return "High";
    case 2:
      return "Medium";
    case 3:
      return "Low";
    default:
      return "Low";
  }
}
