import { ResponsiveStyle, StyleToken, Text } from "@aws-amplify/ui-react";
import { ColorKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import React, { ReactNode } from "react";
import { Property } from "csstype";

export default function TooltipHoverView({
  TooltipContent,
  color = "white",
  children,
}: {
  color?: ResponsiveStyle<ColorKeys<StyleToken<Property.Color>>>;
  TooltipContent: ReactNode;
  children?: ReactNode;
}) {
  return (
    <Text color={color} className="tooltipInfo">
      {children}
      <Text className="tooltiptext">{TooltipContent}</Text>
    </Text>
  );
}
