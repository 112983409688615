import { useQuery } from "@tanstack/react-query";
import { CompanyGroup } from "../types/companies";
import { listCompanies } from "../services/companies/listCompanies";
import { useUserContext } from "../hooks/useUserContext";
import { useMemo } from "react";

export function useCompaniesQuery(groupId: CompanyGroup, campaignId?: string) {
  const { user } = useUserContext();

  const memberId = useMemo(() => user?.memberId ?? undefined, [user]);

  const { data: companies, ...query } = useQuery({
    queryKey: ["companies", groupId, campaignId],
    queryFn: async () => {
      const response = await listCompanies(
        memberId as string,
        groupId,
        campaignId
      );
      return response.Businesses;
    },
    enabled: memberId !== null && memberId !== undefined,
  });

  return {
    companies,
    ...query,
  };
}
