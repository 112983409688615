import { Autocomplete, Button, Flex, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import CompanyDetailsForm from "../portfolio/CompanyDetailsForm";
import { Modal } from "../Modal";
import { useCompaniesMutation } from "../../hooks/useCompaniesMutation";

import "../../style/cyber-audit.css";

type CompanySelectProps = {
  companyId: number | undefined;
  setCompanyId: (id: number | undefined) => void;
};

export default function CompanySelect({ setCompanyId }: CompanySelectProps) {
  const { companies, isLoading: loadingCompanies } = useCompaniesQuery(1);

  const { updateCompaniesMutate } = useCompaniesMutation();
  const addCompanyModal = React.useRef<HTMLDialogElement>(null);

  const [value, setValue] = React.useState("");

  const options = useMemo(() => {
    return (
      companies?.map((c) => ({
        id: c.CUID.toString(),
        label: c.CompanyName ?? "",
      })) ?? []
    );
  }, [companies]);

  return (
    <>
      <Modal ref={addCompanyModal}>
        <CompanyDetailsForm
          groupId={1}
          Operation="insert"
          onSave={async (insertCompanyRequest) => {
            await updateCompaniesMutate({
              body: { Businesses: [insertCompanyRequest] },
            });
          }}
          onClose={() => addCompanyModal.current?.close()}
        />
      </Modal>
      <Flex width={"100%"}>
        <Autocomplete
          width={"100%"}
          label=""
          // size="small"
          labelHidden={true}
          isLoading={loadingCompanies}
          options={options}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            const selectedOption = options.find(
              (o) => o.label === e.target.value
            );
            setCompanyId(
              selectedOption ? Number(selectedOption.id) : undefined
            );
          }}
          onSelect={(option) => {
            setValue(option.label);
            setCompanyId(Number(option.id));
          }}
          onClear={() => setValue("")}
          optionFilter={(option, value) => {
            return option.label.toLowerCase().includes(value.toLowerCase());
          }}
          renderOption={(option) => <Text>{option.label}</Text>}
        ></Autocomplete>
        <Button
          size="small"
          data-attr="add-company"
          variation="primary"
          flex={1}
          onClick={() => {
            addCompanyModal.current?.showModal();
          }}
        >
          + Add a Company
        </Button>
      </Flex>
    </>
  );
}
