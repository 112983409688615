import { getCredentials } from "../auth/getCurrentSession";
import { getEnvironment } from "../getEnvironment";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";

export const invokeLambda = async (funcName: string, payload: any) => {
  try {
    const env = await getEnvironment();

    const credentials = await getCredentials();

    const client = new LambdaClient({
      region: "us-east-2",
      credentials,
    });
    const command = new InvokeCommand({
      FunctionName: funcName + "-" + env,
      Payload: JSON.stringify(payload),
    });

    const { Payload } = await client.send(command);
    //convert Payload from UInt8ArrayBlobAdapter to string
    const decoder = new TextDecoder("utf-8");
    const decodedPayload = decoder.decode(Payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
