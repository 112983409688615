import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "../hooks/useUserContext";
import { VulnStatus, updateNvtStatus } from "../services/updateNvtStatus";
import { useParams, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { Asset } from "../types/assetInventoryApi";
import posthog from "posthog-js";

const vulnStatusOptions = [
  "To Do",
  "Ignored",
  "In Progress",
  "Resolved",
  "Accepted",
];

export const useUpdateVulnStatusMutation = (
  companyId: number,
  assetId: string,
  vulnId: string
) => {
  const { user } = useUserContext();

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const { scannerId } = useParams();

  const { start_timestamp, end_timestamp } = useMemo(() => {
    const start_timestamp = searchParams.get("start_timestamp");
    const end_timestamp = searchParams.get("end_timestamp");
    return { start_timestamp, end_timestamp };
  }, [searchParams]);

  const { mutateAsync } = useMutation({
    mutationFn: async (status: string) => {
      if (!vulnStatusOptions.includes(status)) {
        throw new Error("Invalid status");
      }
      const response = await updateNvtStatus(status as VulnStatus, {
        companyId,
        assetId,
        vulnId,
        memberId: user?.memberId || "",
      });
      if (response.Success !== 1) {
        throw new Error("Update failed");
      }
      return response;
    },
    onMutate: async (newStatus) => {
      await queryClient.cancelQueries({
        queryKey: [
          "asset-inventory",
          "details",
          companyId,
          scannerId,
          start_timestamp,
          end_timestamp,
        ],
      });

      // Snapshot the previous value
      const previousInventory: Asset[] | undefined = queryClient.getQueryData([
        "asset-inventory",
        "details",
        companyId,
        scannerId,
        start_timestamp,
        end_timestamp,
      ]);

      // determine the new inventory value based on the previous value with the new vuln status change
      const prevAssetIndex = previousInventory?.findIndex(
        (a) => a.DEVICE_ID === assetId
      );
      if (prevAssetIndex === undefined) return;
      const prevAsset = previousInventory?.[prevAssetIndex];
      const prevVulnIndex = prevAsset?.nvts.findIndex(
        (v) => v.nvt_oid === vulnId
      );
      if (prevVulnIndex === undefined) return;
      const prevVuln = prevAsset?.nvts[prevVulnIndex];
      const newVuln = prevVuln ? { ...prevVuln, status: newStatus } : undefined;

      const newAsset = prevAsset
        ? {
            ...prevAsset,
            nvts: prevAsset.nvts.map((v, i) =>
              i === prevVulnIndex ? newVuln : v
            ),
          }
        : undefined;

      const newInventory = prevAsset
        ? previousInventory?.map((a, i) =>
            i === prevAssetIndex ? newAsset : a
          )
        : undefined;

      // Optimistically update to the new value
      queryClient.setQueryData(
        [
          "asset-inventory",
          "details",
          companyId,
          scannerId,
          start_timestamp,
          end_timestamp,
        ],
        newInventory
      );

      // Return a context object with the snapshotted value
      return { previousInventory, newInventory };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, status, context) => {
      console.error(err.message);
      queryClient.setQueryData(
        [
          "asset-inventory",
          "details",
          companyId,
          scannerId,
          start_timestamp,
          end_timestamp,
        ],
        context?.previousInventory
      );
      posthog.capture("error", {
        function: "update-vuln-status",
        args: {
          companyId,
          assetId,
          vulnId,
          status,
        },
        message: err.message,
      });
    },

    // Always refetch after error or success:
    // onSettled: async () => {
    //   // wait 1 second before refetching
    //   await new Promise((resolve) => setTimeout(resolve, 1000));
    //   queryClient.invalidateQueries({
    //     queryKey: [
    //       "asset-inventory",
    //       "details",
    //       companyId,
    //       scannerId,
    //       start_timestamp,
    //       end_timestamp,
    //     ],
    //   });
    // },
  });
  return { updateNvtStatus: mutateAsync };
};
