import { Button, Card, Flex, TextField } from "@aws-amplify/ui-react";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import parser from "tld-extract";

export default function EnterDomainField() {
  const [input, setInput] = React.useState("");

  const navigate = useNavigate();
  return (
    <Card boxShadow={"large"} borderRadius={"large"}>
      <Flex
        as="form"
        id="recon-form"
        data-attr="recon-form-sub"
        data-ph-capture-attribute-domain={input}
        justifyContent={"space-between"}
        padding={"xxs"}
        alignItems={"center"}
        onSubmit={(e) => {
          e.preventDefault();
          let domain;
          try {
            const tld = parser(input);
            domain = tld.domain;
          } catch (err) {
            domain = input.split("/")[0].split("?")[0];
          }
          navigate(`/recon/${domain}`);
        }}
      >
        <TextField
          width={"100%"}
          placeholder="Enter a domain to get a Cyber Score"
          required
          label=""
          size="small"
          fontSize={"smaller"}
          inputStyles={{ fontSize: "var(--amplify-font-sizes-xs)" }}
          type="text"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <Button
          form="recon-form"
          borderRadius={"medium"}
          fontSize={"small"}
          boxShadow={"small"}
          color={"white"}
          backgroundColor={"blue.60"}
          gap={"small"}
          type="submit"
        >
          <FaPlus />
        </Button>
      </Flex>
    </Card>
  );
}
