import React from "react";
import {
  Button,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  ResponsiveStyle,
  Text,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import {
  FaCog,
  FaHeartbeat,
  FaPercentage,
  FaSatelliteDish,
  FaSignOutAlt,
} from "react-icons/fa";
import { Property } from "csstype";
import { useNavigate } from "react-router-dom";
import Media from "react-media";
import {  GiHamburgerMenu } from "react-icons/gi";
import logoSimple from "../../src/assets/images/logo-simple.png";
import TooltipHoverView from "../components/styling/TooltipHoverView";

export default function Navbar({
  area,
}: {
  area?: ResponsiveStyle<Property.GridArea>;
}) {
  // const Navbar: React.FC<NavbarProps> = () => {
  //   const navigate = useNavigate();
  // //   const [firstName, setFirstName] = useState<string | undefined>(undefined);

  // //   useEffect(() => {
  // //     setFirstName(user?.attributes?.given_name); // set the first name directly from the prop
  // //   }, [user]);

  //   // Sign out user and navigate to login
  // const handleSignOut = async () => {
  //   try {
  //   //   await Auth.signOut();
  //     navigate("/login");
  //   } catch (error) {
  //     console.error("Error signing out: ", error);
  //   }
  // };

  const { signOut } = useAuthenticator((context) => {
    return [context.user];
    navigate("/");
  });

  const navigate = useNavigate();

  return (
    <Media queries={{ large: { maxWidth: 992 } }}>
      {(matches) =>
        matches.large ? (
          <>
            <Flex
              backgroundColor={"white"}
              columnStart={"1"}
              columnEnd={"-1"}
              backgroundImage={{
                base: "linear-gradient(to bottom right, var(--amplify-colors-blue-60), var(--amplify-colors-blue-100))",
                large: "",
              }}
              area={area}
              height={"60px"}
              padding={"large"}
              justifyContent={{ base: "space-between", large: "right" }}
              alignItems={"center"}
            >
              <Flex justifyContent={"center"}>
                <Button onClick={() => navigate("/")} variation="link">
                  <Image
                    alt="Cyber Score logo"
                    src={logoSimple}
                    maxHeight={"40px"}
                  />
                </Button>
              </Flex>

              <Flex>
                <Flex backgroundColor={"blue.60"} borderRadius={"small"}>
                  <Menu
                    trigger={
                      <Flex alignContent={"center"}>
                        <MenuButton
                          variation="primary"
                          size="small"
                          backgroundColor={"white"}
                        >
                          <Text>
                            {" "}
                            <GiHamburgerMenu />
                          </Text>
                        </MenuButton>
                      </Flex>
                    }
                  >
                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("/score-card")}
                    >
                      <Text color={"neutral.80"}>
                        <FaPercentage />
                      </Text>
                      <TooltipHoverView TooltipContent={"New Features!"}>
                        <Text color={"neutral.80"} fontSize={"small"} >
                          Score Card
                        </Text>
                      </TooltipHoverView>
                    </MenuItem>

                    {/* <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("/cyber-audit")}
                    >
                      <Text color={"neutral.80"}>
                        <FaGaugeHigh />
                      </Text>
                      <TooltipHoverView TooltipContent={"New Features!"}>
                        <Text color={"neutral.80"} fontSize={"small"}>
                          Cyber Audit
                        </Text>
                      </TooltipHoverView>
                    </MenuItem> */}

                    {/* <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("/recon")}
                    >
                      <Text color={"neutral.80"}>
                        <GiFedora />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Recon{" "}
                        <TooltipHoverView
                          TooltipContent={"New Feature!"}
                          color={"red.60"}
                          children={<FaStarOfLife fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem> */}
                    {/* 
                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <FaFolderOpen />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Directory{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"neutral.80"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem> */}

                    {/* <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <FaChartLine />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Performance{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"neutral.80"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem>

                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <GiMining />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Prospecting{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"neutral.80"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem> */}

                    <Divider
                      style={{ borderColor: "var(--amplify-colors-neutral-40" }}
                    />

                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("/monitor")}
                    >
                      <Text color={"neutral.80"}>
                        <FaHeartbeat />
                      </Text>
                      <TooltipHoverView TooltipContent={"New Features!"}>
                        <Text color={"neutral.80"} fontSize={"small"}>
                          Monitor
                        </Text>
                      </TooltipHoverView>
                    </MenuItem>

                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("/portfolio")}
                    >
                      <Text color={"neutral.80"}>
                        <FaSatelliteDish />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Portfolio
                      </Text>
                    </MenuItem>

                    {/* <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <BsFillRouterFill />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Assets{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"neutral.80"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem>

                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <LuPackageSearch />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Vulnerabilities{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"neutral.80"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem>

                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <BsMailbox2Flag />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Email Addresses{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"neutral.80"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </MenuItem> */}

                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => navigate("")}
                    >
                      <Text color={"neutral.80"}>
                        <FaCog />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Settings
                      </Text>
                    </MenuItem>

                    <Divider
                      style={{ borderColor: "var(--amplify-colors-neutral-40" }}
                    />
                    <MenuItem
                      gap={"xxs"}
                      size="small"
                      onClick={() => signOut()}
                    >
                      <Text color={"neutral.80"}>
                        <FaSignOutAlt />
                      </Text>
                      <Text color={"neutral.80"} fontSize={"small"}>
                        Sign Out
                      </Text>
                    </MenuItem>
                  </Menu>
                </Flex>
              </Flex>
            </Flex>
          </>
        ) : (
          <></>
        )
      }
    </Media>
  );
}
