import { DarkWebTableData } from "..";

export function DarkWebSortFunction(
  sortColumn: keyof DarkWebTableData | undefined,
  a: DarkWebTableData,
  b: DarkWebTableData
) {
  if (!sortColumn) return 0;

  if (a[sortColumn] === "") return -1;
  if (b[sortColumn] === "") return 1;
  if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) {
    return -1;
  }
  if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) {
    return 1;
  }
  return 0;
}
