import React, { useMemo } from "react";
import { EChartsOption, EChartsType } from "echarts";
import { useECharts } from "../../hooks/useECharts";
import { View } from "@aws-amplify/ui-react";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";

const categories = ["0-4", "4-10", "10+", "N/A"];

type AssetCount = {
  ageGroup: (typeof categories)[number];
  nSecure: number;
  nVulnerable: number;
};

export const useAssetAgeChart = (): [
  React.FC,
  EChartsType | null,
  () => string | undefined
] => {
  const { assets } = useMonitorCompanyContext();

  const assetCountsByAgeGroup: AssetCount[] = useMemo(() => {
    const counts: Record<string, AssetCount> = {};

    assets?.forEach((asset) => {
      let ageGroup = "N/A";
      if (asset.MAC_DATE) {
        const ageMilliseconds = Date.now() - new Date(asset.MAC_DATE).getTime();
        const ageYears = ageMilliseconds / 1000 / 60 / 60 / 24 / 365;
        if (ageYears <= 4) {
          ageGroup = "0-4";
        } else if (ageYears <= 10) {
          ageGroup = "4-10";
        } else {
          ageGroup = "10+";
        }
      }

      if (!counts[ageGroup]) {
        counts[ageGroup] = {
          ageGroup,
          nSecure: 0,
          nVulnerable: 0,
        };
      }

      if (asset.nvts.length > 0) {
        counts[ageGroup].nVulnerable += 1;
      } else {
        counts[ageGroup].nSecure += 1;
      }
    });

    return Object.values(counts);
  }, [assets]);

  var series = [
    {
      data: categories.map((type) => {
        const assetCount = assetCountsByAgeGroup.find(
          (ac) => ac.ageGroup === type
        );
        return assetCount ? assetCount.nSecure : 0;
      }),
      type: "bar",
      stack: "Secure",
      name: "Secure",
      showBackground: true,
      backgroundStyle: {
        color: "rgba(180, 180, 180, 0.2)",
        borderRadius: 40,
      },
      emphasis: {
        focus: "self",
      },
      color: "#000080",
      itemStyle: {
        borderRadius: 10,
      },
      grid: {
        right: "50%",
      },
    },
    {
      data: categories.map((type) => {
        const assetCount = assetCountsByAgeGroup.find(
          (ac) => ac.ageGroup === type
        );
        return assetCount ? assetCount.nVulnerable : 0;
      }),
      type: "bar",
      stack: "Secure",
      name: "Vulnerable",
      emphasis: {
        focus: "self",
      },
      color: "#DC2B2B",
      itemStyle: {
        borderRadius: 10,
      },
    },
  ];

  const options: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },

    grid: {
      left: "32px",
      right: "0",
      top: "0",
      bottom: "0",
    },

    legend: {
      show: false,
      top: "auto",
    },

    yAxis: {
      type: "category",
      data: categories,
      name: "",
      nameLocation: "start",
      nameTextStyle: {
        fontSize: "11px",
        color: "#000",
      },
      axisTick: "none",
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: "#000",
        fontSize: "11px",
      },
      inverse: true,
    },
    xAxis: {
      type: "value",
      show: false,
    },
    series: series as any,
  };

  const { ref, chartInstance, getImageData } = useECharts(options);

  const Chart = useMemo(
    () => () => <View ref={ref} width={"300px"} height={"150px"} />,

    []
  );

  return [Chart, chartInstance, getImageData];
};
