import React from "react";
import ScoreCardCard from "./ScoreCardCard";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";
import { Card, Flex, Grid } from "@aws-amplify/ui-react";
import {
  ESSDetails,
  ISHDetails,
  SEGDetails,
  WAFDetails,
  WIDetails,
} from "../../features/cyber-score";

export function ScoreCard() {
  const { company } = useMonitorCompanyContext();

  const { cyberScore } = useCyberScoreCombinedQuery(company?.EmailDomain);

  const { ESSScore, SEGScore, WIScore, ISHScore, WAFScore } = cyberScore ?? {};

  const [menu, setMenu] = React.useState<
    | "email-impersonation"
    | "secure-email-gateway"
    | "web-security-settings"
    | "website-encryption"
    | "website-firewall"
    | undefined
  >(undefined);

  return (
    <Grid templateColumns={"1fr 1fr"} gap={"large"}>
      <Flex direction={"column"}>
        <ScoreCardCard
          title={"Email Impersonation Protection"}
          score={ESSScore}
          subtitle={undefined}
          onClick={() => {
            setMenu("email-impersonation");
          }}
        />
        <ScoreCardCard
          title={"Secure Email Gateway"}
          score={SEGScore}
          subtitle={undefined}
          onClick={() => {
            setMenu("secure-email-gateway");
          }}
        />
        <ScoreCardCard
          title={"Web Security Settings"}
          score={ISHScore}
          subtitle={undefined}
          onClick={() => {
            setMenu("web-security-settings");
          }}
        />
        <ScoreCardCard
          title={"Website Encryption"}
          score={WIScore}
          subtitle={undefined}
          onClick={() => {
            setMenu("website-encryption");
          }}
        />
        <ScoreCardCard
          title={"Website Firewall"}
          score={WAFScore}
          subtitle={undefined}
          onClick={() => {
            setMenu("website-firewall");
          }}
        />
      </Flex>
      <Card variation="elevated" borderRadius={"large"}>
        {menu === "email-impersonation" ? (
          <ESSDetails cyberScore={cyberScore} />
        ) : menu === "secure-email-gateway" ? (
          <SEGDetails cyberScore={cyberScore} />
        ) : menu === "web-security-settings" ? (
          <ISHDetails cyberScore={cyberScore} />
        ) : menu === "website-encryption" ? (
          <WIDetails cyberScore={cyberScore} />
        ) : menu === "website-firewall" ? (
          <WAFDetails cyberScore={cyberScore} />
        ) : null}
      </Card>
    </Grid>
  );
}
