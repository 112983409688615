import { Flex, Loader } from "@aws-amplify/ui-react";
import * as React from "react";

export interface ICenteredLoaderProps {}

export default function CenteredLoader(props: ICenteredLoaderProps) {
  const {} = props;

  return (
    <Flex
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Loader size={"large"} />
    </Flex>
  );
}
