import { useCallback, useMemo } from "react";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import { useQuery } from "@tanstack/react-query";

import { imageToBase64QueryOptions } from "../util/imageToBase64QueryOptions";
import { useCyberScoreCombinedQuery } from "./useCyberScoreCombinedQuery";
import { useMonitorCompanyChartsContext } from "../components/monitorcompany/MonitorCompanyChartsContextProvider";
import { useKeyTakeawaysQuery } from "./useKeyTakeawaysQuery";
import { useFaviconGoogle } from "./useFaviconGoogle";
import { useBrandingContext } from "./useBrandingContext";
import { Company } from "../types/companies";
import { Asset, NVT } from "../types/assetInventoryApi";
import PDFFullCyberReport from "../components/reports/score-card/PDFFullCyberReport";
import { toast } from "react-toastify";
import { useActionPlanQuery } from "./useActionPlanQuery";

type UseCyberReportOptions = {
  prospectLogo: string;
  profilePics: string[];
  company: Company | undefined;
  nvts: NVT[];
  assets: Asset[];
};

export default function useCyberReport({
  prospectLogo,
  profilePics,
  company,
  nvts,
  assets,
}: UseCyberReportOptions) {
  const { branding } = useBrandingContext();

  const { cyberScore, isLoading: isLoadingCyberScore } =
    useCyberScoreCombinedQuery(company?.EmailDomain);

  // const { data: topCyberRisks, isLoading: isLoadingTopCyberRisks } = useQuery(
  //   getTopCyberRiskQueryOptions(companyId, assets)
  // );

  const { keyTakeaways, isLoading: ktLoading } = useKeyTakeawaysQuery(
    assets,
    company?.CUID
  );

  const { actionPlan, isLoading: apisLoading } = useActionPlanQuery(
    assets,
    company?.CUID
  );

  const { url } = useFaviconGoogle(company?.EmailDomain);

  const { data: logo } = useQuery(
    imageToBase64QueryOptions(
      url,
      "https://assets-global.website-files.com/658c7dbe6f7007cb9233ad26/658c7dbe6f7007cb9233af00_icon-1-perks-dataplus-template.svg"
    )
  );

  const { getAssetAgeChartImageData, getITLandscapeChartImageData } =
    useMonitorCompanyChartsContext();

  const isLoading = useMemo(
    () =>
      isLoadingCyberScore || isLoadingCyberScore || apisLoading || ktLoading,
    [isLoadingCyberScore, isLoadingCyberScore, apisLoading, ktLoading]
  );

  const ITLanscapeChartImage = useMemo(
    () => getITLandscapeChartImageData(),
    [getITLandscapeChartImageData]
  );
  const assetAgeChartImage = useMemo(
    () => getAssetAgeChartImageData(),
    [getAssetAgeChartImageData]
  );

  const Document = useMemo(
    () =>
      company && cyberScore && actionPlan && keyTakeaways ? (
        <PDFFullCyberReport
          clientLogo={prospectLogo}
          branding={branding}
          company={company}
          cyberScore={cyberScore}
          actionPlan={actionPlan}
          keyTakeaways={keyTakeaways}
          profilePics={profilePics}
          nvts={nvts}
          assets={assets}
          ITLanscapeChartImage={ITLanscapeChartImage ?? ""}
          assetAgeChartImage={assetAgeChartImage ?? ""}
        />
      ) : null,
    [
      company,
      cyberScore,
      actionPlan,
      keyTakeaways,
      profilePics,
      nvts,
      ITLanscapeChartImage,
      assetAgeChartImage,
      branding,
    ]
  );

  const download = useCallback(async () => {
    if (!company) {
      toast.error("Company undefined, cannot generate report.");
      return;
    }
    if (!cyberScore) {
      toast.error("Cyber Score undefined, cannot generate report.");
      return;
    }
    if (!actionPlan) {
      toast.error("Action Plan undefined, cannot generate report.");
      return;
    }
    if (!keyTakeaways) {
      toast.error("Key Takeaways undefined, cannot generate report.");
      return;
    }

    const asPdf = pdf(
      <PDFFullCyberReport
        clientLogo={prospectLogo}
        branding={branding}
        company={company}
        cyberScore={cyberScore}
        actionPlan={actionPlan}
        keyTakeaways={keyTakeaways}
        profilePics={profilePics}
        nvts={nvts}
        assets={assets}
        ITLanscapeChartImage={ITLanscapeChartImage ?? ""}
        assetAgeChartImage={assetAgeChartImage ?? ""}
      />
    );
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} ${branding.companyName ?? "Iceberg Cyber"} - Cyber Report - ${
      company?.CompanyName
    }.pdf`;
    link.click();
  }, [
    keyTakeaways,
    actionPlan,
    company,
    cyberScore,
    logo,
    ITLanscapeChartImage,
    assetAgeChartImage,
    branding,
    nvts,
    cyberScore,
    profilePics,
    branding,
  ]);

  return {
    download,
    isLoading,
    Document,
  };
}
