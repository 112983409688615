import { Button, Card, Flex, Grid, Text } from "@aws-amplify/ui-react";
import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import TableTitle from "../components/styling/TableTitle";
import { FaCog, FaEnvelope, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaRocket, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { useUserContext } from "../hooks/useUserContext";

// import { useUserQuery } from "../hooks/useUser";

export default function Settings() {
  // check if user exists to throw error
  const { userAttributes } = useUserContext();

  return (
    <>
      <DashboardLayout>
        <Flex direction={"column"} gap="0" padding={"large"}>
          <Flex
            minHeight={"40px"}
            padding={"small large 0 0"}
            justifyContent={"right"}
          ></Flex>

          <Flex direction="column" gap="large">
            <Grid
              templateColumns={{ base: "1fr", medium: "1fr 1fr" }}
              templateRows={"minmax(0,1fr)"}
              gap="medium"
            >
              <Card boxShadow={"medium"} borderRadius={"large"}>
                <TableTitle title={"Account Information"} />

                <Grid
                  templateColumns={"1fr 1fr"}
                  padding={"medium 0 xl 0"}
                  gap={"xs"}
                >
                  {/* Josh - These fields do not need to be editable like Gibbly */}

                  <Text fontSize={"small"}>First Name:</Text>
                  <Text fontSize={"small"}>{userAttributes?.given_name}</Text>
                  <Text fontSize={"small"}>Last Name:</Text>
                  <Text fontSize={"small"}>{userAttributes?.family_name}</Text>
                  <Text fontSize={"small"}>Email:</Text>
                  <Text fontSize={"small"}>{userAttributes?.email}</Text>
                  {/* <Text fontSize={"small"}>Joined:</Text>
                  <Text fontSize={"small"}>{user.createdAt}</Text> */}
                </Grid>

                <Flex padding="0 0 0 medium">
                  <a
                    href="https://buy.stripe.com/cN23dS0317Ie6ac3cu"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Button
                      size="small"
                      variation="primary"
                      gap="xs"
                      backgroundColor={"#144ee3"}
                    >
                      <FaRocket /> Start a Subscription
                    </Button>
                  </a>
                  <a
                    href="https://billing.stripe.com/p/login/eVa6p43ypcIo3fi288"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Button size="small" variation="primary" gap="xs">
                      <FaCog /> Manage Your Subscription
                    </Button>
                  </a>
                </Flex>
                <ul>
                  <li>
                    <Text fontSize={"small"}>Manage Billing</Text>
                  </li>
                  <li>
                    <Text fontSize={"small"}>Update Payment Information</Text>
                  </li>
                  <li>
                    <Text fontSize={"small"}>Download Invoices</Text>
                  </li>
                  <li>
                    <Text fontSize={"small"}>Cancel Plan</Text>
                  </li>
                </ul>
              </Card>

              <Flex direction={"column"} height={"100%"}>
                <Flex borderRadius={"large"} flex={1} grow={1}>
                  <Card
                    boxShadow={"medium"}
                    borderRadius={"large"}
                    width={"100%"}
                  >
                    <TableTitle title={"Privacy & Terms"} />
                    <Flex>
                      <a
                        href="https://www.icebergcyber.com/privacy"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Button size="small" variation="link" gap="xs">
                          Privacy Policy
                        </Button>
                      </a>
                    </Flex>
                    <Flex>
                      <a
                        href="https://www.icebergcyber.com/terms"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Button size="small" variation="link" gap="xs">
                          Terms & Conditions
                        </Button>
                      </a>
                    </Flex>
                  </Card>
                </Flex>

                <Flex flex={1} grow={1}>
                  <Card
                    boxShadow={"medium"}
                    borderRadius={"large"}
                    width={"100%"}
                  >
                    <TableTitle title={"Support"} />

                    <a
                      href="mailto:support@icebergcyber.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Button size="small" variation="link" gap="xs">
                        <FaEnvelope /> Contact Us
                      </Button>
                    </a>
                  </Card>
                </Flex>

                <Flex flex={1} grow={1}>
                  <Card
                    boxShadow={"medium"}
                    borderRadius={"large"}
                    width={"100%"}
                  >
                    <TableTitle title={"Socials"} />
                    <a
                      href="https://www.linkedin.com/company/iceberg-cyber/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Button
                        fontSize="larger"
                        padding={"xxs"}
                        variation="link"
                      >
                        <FaLinkedinIn />
                      </Button>
                    </a>

                    <a
                      href="https://twitter.com/iceberg_cyber"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Button
                        fontSize="larger"
                        padding={"xxs"}
                        variation="link"
                      >
                        <FaXTwitter />
                      </Button>
                    </a>

                    {/* <a
                    href="https://www.youtube.com/@icebergcyber"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Button
                      fontSize="larger"
                      padding={"xxs"}
                      variation="link"
                    >
                     <FaFacebook/>
                    </Button>
                  </a> */}

                    <a
                      href="https://www.instagram.com/icebergcyber/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Button
                        fontSize="larger"
                        padding={"xxs"}
                        variation="link"
                      >
                        <FaInstagram />
                      </Button>
                    </a>

                    <a
                      href="https://www.youtube.com/@icebergcyber"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Button
                        fontSize="larger"
                        padding={"xxs"}
                        variation="link"
                      >
                        <FaYoutube />
                      </Button>
                    </a>
                  </Card>
                </Flex>
              </Flex>
            </Grid>
          </Flex>
        </Flex>
      </DashboardLayout>
    </>
  );
}
