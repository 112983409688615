import { Flex, Text } from "@aws-amplify/ui-react";
import * as echarts from "echarts";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./cyberscorecard.css";
import transformSnakeInput from "./helperFunctions/transformSnakeInput";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";

export interface CyberScoreGraphsInput {
  cyberScore: CyberScoreCombined | undefined;
}

export default function CyberScoreGraphs({
  cyberScore,
}: CyberScoreGraphsInput) {
  /* *********************** useRef/UseState Hooks ************************* */

  // echarts/apache charts requires direct DOM manipulation, hence the chartRef
  const chartRef = useRef(null);

  // state to update the gauge image based off the useEffect/if statement logic below
  const [gauge, setGauge] = useState<string>("");

  /* *********************** useEffect Hooks ************************* */

  // updates the gauge image when props of the SimpleAPI comes in. images saved within css file
  useEffect(() => {
    let gaugeValue: any;
    if (!cyberScore) return;
    // dynamically render the gauge value
    if (cyberScore.Score >= 0 && cyberScore.Score <= 100) {
      // Calculate the gauge value based on the score
      gaugeValue = "gauge-" + Math.ceil(cyberScore.Score / 10) * 10;
    } else {
      // Handle cases where the score is out of the expected range
      console.error("Score is out of range (0-100)");
    }
    setGauge(gaugeValue);
  }, [cyberScore]);

  // on render the graph is created
  useEffect(() => {
    // Check if the chartRef is currently pointing to a DOM element, and ensure that the 'output' array is defined and not empty
    if (chartRef.current && output && output.length > 0) {
      let myChart = echarts.init(chartRef.current, null, {
        renderer: "svg",
      });

      // apache chart data
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: false, readOnly: true },
            restore: { show: false },
            saveAsImage: { show: false }, //turned to false, took up space in the field
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: [15, 90],
            center: ["60%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              show: false,
            },
            data: [
              {
                value: 14,
                name: `${output[0].title} - ${output[0].status}`,
                itemStyle: {
                  color: `rgb(${output[0].color},.9)`,
                },
              },
              {
                value: 13,
                name: `${output[1].title} - ${output[1].status}`,
                itemStyle: {
                  color: `rgb(${output[1].color},.8)`,
                },
              },
              {
                value: 12,
                name: `${output[2].title} - ${output[2].status}`,
                itemStyle: {
                  color: `rgb(${output[2].color},.7)`,
                },
              },
              {
                value: 11,
                name: `${output[3].title} - ${output[3].status}`,
                itemStyle: {
                  color: `rgb(${output[3].color},.6)`,
                },
              },
              {
                value: 10,
                name: `${output[4].title} - ${output[4].status}`,
                itemStyle: {
                  color: `rgb(${output[4].color},.5)`,
                },
              },
              {
                value: 9,
                name: `${output[5].title} - ${output[5].status}`,
                itemStyle: {
                  color: `rgb(${output[5].color},.4)`,
                },
              },
              {
                value: 8,
                name: `${output[6].title} - ${output[6].status}`,
                itemStyle: {
                  color: `rgb(${output[6].color},.3)`,
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    }
  }, [cyberScore]);

  // invoke function and give result a variable used within the chartRef in the useEffect
  // in helperFunctions folder
  let output = useMemo(() => {
    if (cyberScore) return transformSnakeInput(cyberScore);
    else
      return [0, 1, 2, 3, 4, 5, 6].map(() => ({
        key: "",
        value: 0,
        status: "",
        color: "",
        title: "",
      }));
  }, [cyberScore]);

  // attempt at a responsive design below based off the amplify breakpoints
  // ICEAPP-676 shows the resolutions used
  return (
    <>
      {/* <Card boxShadow={"medium"} borderRadius={"large"}> */}
      <Flex
        id="fuel-gauge"
        direction={"column"}
        className={gauge}
        alignItems={"center"}
        justifyContent={"end"}
        gap={"0"}
        height={{
          small: "",
          medium: "70px",
        }}
        style={{
          aspectRatio: "30/17",
        }}
      >
        <Text fontSize={"large"} fontWeight={"bold"} textAlign={"end"}>
          {cyberScore?.Score}%
        </Text>
        <Text
          fontSize={"xs"}
          textAlign={"center"}
          fontWeight={"bold"}
          color={"neutral.80"}
        >
          Cyber Score
        </Text>
      </Flex>
      {/* )} */}
      {/* <div style={{ height: "100%", width: "100%" }}>
            {loading ? (
              ""
            ) : (
              <Text
                fontSize={{
                  base: "25px",
                  small: "25px",
                  medium: "30px",
                  large: "15px",
                  xl: "20px",
                  xxl: "25px",
                }}
                textAlign={"left"}
                fontWeight={"bold"}
                color={"neutral.80"}
              >
                Risk Profile
              </Text>
            )}
            <div
              ref={chartRef}
              style={{
                width: "300px",
                height: "200px",
              }}
            />
          </div> */}
      {/* </Grid> */}
      {/* </Card> */}
    </>
  );
}
