import { useQuery } from "@tanstack/react-query";
import { Asset } from "../types/assetInventoryApi";
import { getAssetInventoryDetailsRange } from "../services/asset-inventory/getAssetInventoryDetailsRange";
import { useMemo } from "react";

export function useAssetInventoryDetailsRangeQuery(
  companyId?: number,
  scannerId?: string,
  startTimeStamp?: string | null,
  endTimeStamp?: string | null
) {
  const { data: assets, ...query } = useQuery<Asset[]>({
    queryKey: [
      "asset-inventory",
      "details",
      companyId,
      scannerId,
      startTimeStamp,
      endTimeStamp,
    ],
    queryFn: async () => {
      const result = await getAssetInventoryDetailsRange(
        companyId,
        scannerId,
        startTimeStamp,
        endTimeStamp
      );

      return result;
    },
    enabled: !!companyId && !!scannerId && !!startTimeStamp && !!endTimeStamp,
  });

  const nvts = useMemo(() => {
    // if (!assets) return [];
    return assets?.flatMap((asset) => {
      return asset.nvts;
    });
  }, [assets]);

  return {
    assets,
    nvts,
    ...query,
  };
}
