import {
  Badge,
  Flex,
  ResponsiveStyle,
  Sizes,
  StyleToken,
} from "@aws-amplify/ui-react";
import { ColorKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import React from "react";
import { Property } from "csstype";

export type LevelBadgeProps = {
  level: string;
  size?: Sizes;
  fontSize?: string;
};

export default function LevelBadge({
  level,
  size,
  fontSize = "xs",
}: LevelBadgeProps) {
  const colorMap: {
    [key: string]: {
      backgroundColor: ResponsiveStyle<ColorKeys<StyleToken<Property.Color>>>;
      color: ResponsiveStyle<ColorKeys<StyleToken<Property.Color>>>;
    };
  } = {
    Critical: {
      backgroundColor: "red.80",
      color: "white",
    },
    High: {
      backgroundColor: "red.10",
      color: "red.100",
    },
    Medium: {
      backgroundColor: "orange.40",
      color: "orange.100",
    },
    Low: {
      backgroundColor: "neutral.40",
      color: "neutral.100",
    },
  };

  return (
    <Badge
      color={colorMap[level].color}
      paddingLeft={"small"}
      paddingRight={"small"}
      backgroundColor={colorMap[level].backgroundColor}
      size={size}
      fontSize={fontSize}
    >
      <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
        {level}
      </Flex>
    </Badge>
  );
}
