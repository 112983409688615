import React from "react";
import { Routes, Route } from 'react-router-dom';
import DashboardLayout from "../layouts/DashboardLayout";
import Portfolio from "../pages/Portfolio";

export default function PortfolioRoutes() {

    return (
        <>
            <DashboardLayout>
                <Routes>
                    <Route index element={<Portfolio/>} />
                    {/* <Route path=":cuid" element={} /> */}
                </Routes>
            </DashboardLayout>
        </>
    );
}
