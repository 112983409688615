import React from "react";
import { View, Image, Text } from "@react-pdf/renderer";
// import { CalcResults } from "./pdfsimple";
import scoring from "../../assets/images/pdf/scoring.png";
import ex from "../../assets/images/pdf/cs-red-x.png";
import check from "../../assets/images/pdf/cs-green-check.png";
import na from "../../assets/images/pdf/cs-not-available.png";
export interface IScoringCriteriaProps {
  styles: any;
  userColor: string | undefined;
  scoreSimple: any;
}

type IconType = string;

// Define the interface for the result object
interface ResultItem {
  key: string;
  icon: string;
  title: string;
  info: string;
}

// Update the interface for titleTable with an index signature
interface TitleTableType {
  [key: string]: string | undefined; // Now allows any string as a key
}

export default function ScoringCriteria({
  styles,
  userColor,
  scoreSimple,
}: IScoringCriteriaProps) {

  const titleTable: TitleTableType = {
    ESS: "Email Impersonation Security",
    BDV: "Dark Web Breaches",
    SEG: "Secure Email Gateway",
    ISH: "Website HTTP Security Settings",
    PS: "WordPress Security",
    WI: "Website SSL Encryption",
    WAF: "Web Application Firewall",
  };

  const processCyberData = (
    scoreSimple: any
  ): { [key: string]: ResultItem } => {
    const result: { [key: string]: ResultItem } = {};

    Object.keys(scoreSimple).forEach((key) => {
      if (key.endsWith("Score")) {
        const score = scoreSimple[key];
        let icon: IconType;

        switch (score) {
          case 1:
            icon = check;
            break;
          case -1:
            icon = ex;
            break;
          case 0:
            icon = na;
            break;
          default:
            icon = score;
            break;
        }

        const baseKey = key.slice(0, -5);
        const title = titleTable[baseKey] || "unknown";
        const info = scoreSimple[baseKey];
        result[baseKey] = { key: baseKey, icon, title, info };
      }
    });

    return result;
  };

  const updatedStatus: any = processCyberData(scoreSimple);

  return (
    <View style={[styles.topRisks, { borderColor: userColor }]}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: "0 0 0 12px",
        }}
      >
        <Image
          src={scoring}
          style={{
            objectFit: "contain",
            width: "40px",
            height: "70px",
            marginRight: "10px",
          }}
        />
        <Text
          style={{
            fontSize: "20px",
            textDecoration: "underline",
            textDecorationColor: "#FF343A",
            fontWeight: "medium",
          }}
        >
          Scoring Criteria
        </Text>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.ESS.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.ESS}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.ESS}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.BDV.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.BDV}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.BDV}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.SEG.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.SEG}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.SEG}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.ISH.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.ISH}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.ISH}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.PS.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.PS}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.PS}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.WI.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.WI}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.WI}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginLeft: "4px",
          marginBottom: "12px",
        }}
      >
        <View style={styles.riskItem}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={updatedStatus.WAF.icon}
              style={{
                width: "30px",
                height: "25px",
                objectFit: "contain",
                marginRight: "10px",
              }}
            />
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                {titleTable.WAF}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "85%",
                }}
              >
                {scoreSimple.WAF}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
