import {  Flex } from "@aws-amplify/ui-react";
import React from "react";
import {useUserContext} from "../hooks/useUserContext";
import DashboardLayout from "../layouts/DashboardLayout";

// import { useUserQuery } from "../hooks/useUser";

export default function PageNotFound() {

  // check if user exists to throw error
  useUserContext();

  return (
    <>

      <DashboardLayout>
        <Flex direction={"column"} gap="0" padding={"large"}>
          <Flex
            minHeight={"40px"}
            padding={"small large 0 0"}
            justifyContent={"right"}
          ></Flex>

          <Flex direction="column" gap="large">


            Page Not Found

          </Flex>
        </Flex>
      </DashboardLayout>
    </>
  );
}
