import * as React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import gauge from "../../../assets/images/pdf/speedometer black.png";
import agent from "../../../assets/images/pdf/agent black.png";
import action from "../../../assets/images/pdf/action.png";
import steps from "../../../assets/images/pdf/steps.png";
import { Company } from "../../../types/companies";
// import { ReportStyles } from "../../monitorcompany/ReportBuilderButton";
// import { CyberScoreCombined } from "../../../types/CyberScoreCombined";
// import { Asset, NVT } from "../../../types/assetInventoryApi";
// import { KeyTakeaways } from "../../../services/gpt-generation/genKeyTakeaways";
import { PageLayout } from "../PageLayout";
import { Title } from "../Title";
import { CompanyDetails } from "../CompanyDetails";
import { KeyTakeaway } from "../KeyTakeaway";
import { LinkedinProfilePics } from "../LinkedinProfilePics";
import { DarkWebBreaches } from "../DarkWebBreaches";
import { CyberScore } from "../CyberScore";
import { Subtitle } from "../Subtitle";
import { ScoringCriteriaItems } from "../ScoringCriteriaItems";
import { TitledCommentary } from "../WhyItMatters";
import { CyberScoreCombined } from "../../../types/CyberScoreCombined";
import { ActionPlan } from "../../../services/gpt-generation/genReconActionPlan";
import { KeyTakeaways } from "../../../services/gpt-generation/genReconKeyTakeaways";
import { BreachTable } from "../BreachTable";
import { Branding } from "../../monitorcompany/ReportBuilderButton";

export type PDFScoreCardDocumentProps = {
  company: Company | string;
  logo: string;
  branding: Branding;
  cyberScore: CyberScoreCombined;
  actionPlan: ActionPlan;
  keyTakeaways: KeyTakeaways;
  whiteLabelLogo: string;
  profilePics: string[];
};

export const PDFScoreCardDocument = ({
  company,
  logo,
  cyberScore,
  actionPlan,
  keyTakeaways,
  branding,
  whiteLabelLogo,
  profilePics,
}: PDFScoreCardDocumentProps) => {
  return (
    <Document title={"Cyber Score Card"}>
      <Page
        size="LETTER"
        orientation="landscape"
        style={{ padding: "0", margin: "0", fontSize: "13px" }}
      >
        <PageLayout
          reportStyles={branding}
          left={
            <>
              <Title
                title={`${branding.scoreAlias || "Cyber Score"} Card`}
                reportStyles={branding}
              ></Title>

              <View
                style={{
                  padding: "10px 20px 0px 0px",
                  gap: "10px",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ gap: "10px" }}>
                  <CompanyDetails
                    logoUrl={logo}
                    companyName={
                      typeof company === "string"
                        ? company
                        : company.CompanyName
                    }
                    website={
                      typeof company === "string" ? "" : company.CompanyName
                    }
                  />

                  <KeyTakeaway
                    text={keyTakeaways["key-takeaway"]}
                    reportStyles={branding}
                  />

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "15px",
                    }}
                  >
                    <LinkedinProfilePics profilePics={profilePics} />

                    <DarkWebBreaches cyberScore={cyberScore} />
                    <CyberScore cyberScore={cyberScore} branding={branding} />
                  </View>
                </View>

                <View style={{ gap: "15px" }}>
                  <Subtitle
                    icon={gauge}
                    titlepart1={"SCORING"}
                    titlepart2={"CRITERIA"}
                    color={branding.accentColor}
                  />

                  <TitledCommentary
                    reportStyles={branding}
                    header="Why it matters:"
                    body={
                      "It's more cost-effective to prevent breaches by regularly scanning your external risk surface than to pay the steep price of recovering from an attack. Find and fix weaknesses before criminals can turn them into costly problems."
                    }
                  />
                </View>
                <ScoringCriteriaItems cyberScore={cyberScore} />

                <View
                  style={{
                    gap: "2px",
                    fontSize: "10px",
                    paddingBottom: "12px",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {branding.slogan ? `${branding.slogan}:` : ""}{" "}
                    {branding.contactName} | {branding.contactEmail}
                  </Text>
                </View>
              </View>
            </>
          }
          right={
            <>
              <View>
                {whiteLabelLogo !== "" ? (
                  <Image
                    src={whiteLabelLogo}
                    style={{
                      objectFit: "contain",
                      height: "70px",
                      width: "100%",
                    }}
                  ></Image>
                ) : null}

                <View style={{ paddingTop: "15px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingBottom: "20px",
                      gap: "10px",
                      alignItems: "flex-end",
                    }}
                  >
                    <View style={{ height: "40px", width: "40px" }}>
                      <Image
                        src={action}
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    </View>

                    <Text
                      style={{
                        fontSize: "20px",
                        paddingBottom: "2px",
                        fontWeight: "bold",
                      }}
                    >
                      ACTION PLAN
                    </Text>
                  </View>

                  <View style={{ fontSize: "10.5px", gap: "10px" }}>
                    <Text>
                      This is your 3 Step Cyber Action Plan to reduce security
                      risks:
                    </Text>

                    {actionPlan?.actions?.map((a, i) => (
                      <View key={i} style={{ gap: "2px" }}>
                        <Text style={{ fontWeight: "semibold" }}>
                          {a.title}
                        </Text>
                        <Text>{a.detail}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <Text
                style={{ fontSize: "10.5px", paddingBottom: "12px" }}
                render={({ pageNumber, totalPages }) =>
                  `${new Date().toLocaleString("en-us", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })} | Page ${pageNumber}/${totalPages}`
                }
              />
            </>
          }
        />

        {/* Page 2 */}
        <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
          <View
            style={{
              width: "70%",
              height: "100vh",
              padding: "20px 0px 5px 20px",
            }}
          >
            <Title
              title={`${branding.scoreAlias || "Cyber Score"} Card`}
              reportStyles={branding}
            ></Title>

            <View
              style={{
                padding: "10px 20px 0px 0px",
                gap: "10px",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <View style={{ gap: "20px" }}>
                <View style={{ gap: "15px" }}>
                  <Subtitle
                    icon={agent}
                    titlepart1={"DARK WEB"}
                    titlepart2={"REPORT SUMMARY"}
                    color={branding.accentColor}
                  />

                  <TitledCommentary
                    reportStyles={branding}
                    header="Why it matters:"
                    body={
                      "Discovering your employees' work emails and passwords on the dark web spells serious trouble. It's a gold mine for cybercriminals. Armed with your team's emails and passwords from the Dark Web, they can raid your assets in seconds, not days. This isn't just risky; it's a direct threat to your business's survival."
                    }
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "black",
                        fontSize: "32px",
                        color: "#FF343A",
                      }}
                    >
                      {cyberScore.BreachList.length}
                    </Text>
                    <Text style={{ fontSize: "10px" }}>
                      Email accounts for sale on the Dark Web
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "black",
                        fontSize: "32px",
                        color: "#FF343A",
                      }}
                    >
                      {cyberScore.Employees.length}
                    </Text>
                    <Text style={{ fontSize: "10px" }}>
                      Total Accounts Scanned
                    </Text>
                  </View>
                </View>

                <BreachTable cyberScore={cyberScore} reportStyles={branding} />
              </View>

              <View style={{ gap: "25px" }}>
                <TitledCommentary
                  reportStyles={branding}
                  header="How to fix it:"
                  body={
                    "By implementing Multi-Factor Authentication and ongoing Dark Web monitoring, even if passwords are exposed, your business remains sealed. Turn your vulnerabilities into strengths, safeguarding your future and setting you apart from the competition."
                  }
                />

                <View
                  style={{
                    gap: "2px",
                    fontSize: "10px",
                    paddingBottom: "12px",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {branding.slogan ? `${branding.slogan}:` : ""}{" "}
                    {branding.contactName} | {branding.contactEmail}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Right Side Panel   */}
          <View
            style={{
              width: "30%",
              backgroundColor: branding.primaryColor,
              height: "100vh",
              color: branding.fontColor,
              padding: "20px 20px 5px 20px",
              justifyContent: "space-between",
            }}
          >
            <View>
              <View
                style={{
                  height: "75px",
                  width: "90%",
                  alignSelf: "center",
                  borderRadius: "20px",
                }}
              >
                {whiteLabelLogo !== "" ? (
                  <Image
                    src={whiteLabelLogo}
                    style={{
                      objectFit: "contain",
                      height: "70px",
                      width: "100%",
                    }}
                  ></Image>
                ) : null}
              </View>

              <View style={{ paddingTop: "15px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                    gap: "5px",
                  }}
                >
                  <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                    3 STEP ATTACK
                  </Text>
                </View>

                <View
                  style={{
                    alignItems: "center",
                    paddingBottom: "20px",
                    gap: "5px",
                  }}
                >
                  <View style={{ height: "200px", width: "auto" }}>
                    <Image
                      src={steps}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </View>
                </View>

                <View style={{ fontSize: "10.5px", gap: "5px" }}>
                  <Text style={{ fontWeight: "medium" }}>
                    What could go wrong:
                  </Text>
                  <Text>
                    Using off-the-shelf marketing tools, criminals find your
                    employees' details, then match them with credentials from
                    the Dark Web. Armed with emails & passwords, they
                    effortlessly access banking, email, and cloud services. This
                    simple but effective method exposes your business to serious
                    financial risks and data breaches.
                  </Text>
                </View>
              </View>
            </View>

            <Text
              style={{ fontSize: "10.5px", paddingBottom: "12px" }}
              render={({ pageNumber, totalPages }) =>
                `${new Date().toLocaleString("en-us", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })} | Page ${pageNumber}/${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFScoreCardDocument;
