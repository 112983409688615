import * as React from "react";
import { Image, View } from "@react-pdf/renderer";

export const LinkedinProfilePics = ({
  profilePics,
}: {
  profilePics: string[];
}) => (
  <View style={{ flexDirection: "row" }}>
    {profilePics.slice(0, 2).map((pic, index) => (
      <Image
        key={index}
        src={pic}
        style={{
          height: "90px",
          width: "90px",
          marginLeft: index === 0 ? 0 : -8,
          borderRadius: "100%",
        }}
      ></Image>
    ))}
  </View>
);
