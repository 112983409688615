import "../../style/assetDetailsPopOutPanel.css";

import {
  Accordion,
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Image,
  ScrollView,
  Text,
  View,
} from "@aws-amplify/ui-react";
import * as React from "react";
import TableTitle from "../styling/TableTitle";

import { macDateToAge } from "../../util/macDateToAge";
import { Asset, NVT } from "../../types/assetInventoryApi";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import DeviceDetailAcoordianItem from "./DeviceDetailAcoordianItem";
import VulnDetails from "./VulnDetails";
import TooltipHoverView from "../styling/TooltipHoverView";
import CyberCenterContent from "../../pages/CyberCenterContent";
import { Modal } from "../Modal";
import { genVulnRemediation } from "../../services/gpt-generation/genVulnRemediation";
import { useMutation } from "@tanstack/react-query";
import LevelBadge from "./LevelBadge";
import { getVulnPriority } from "../../util/getVulnPriority";

import loadingGif from "../../assets/images/blueprint white.gif";
import InformationPanel from "./InformationPanel";

export interface IDeviceDetailsProps {
  asset: Asset | null | undefined;
  selectedNvt: NVT | null | undefined;
}

const DeviceDetails = React.forwardRef<HTMLDivElement, IDeviceDetailsProps>(
  (props: IDeviceDetailsProps) => {
    const { asset, selectedNvt } = props;

    const defaultAccordianValue = ["device-details"];

    // const priority = React.useMemo(() => {
    //   return Math.min(...NVTs.map((nvt) => nvt.priority));
    // }, [NVTs]);

    const genFixModal = React.useRef<HTMLDialogElement>(null);

    const ipAddress = React.useMemo(() => {
      const ip = asset?.IPs.sort((a, b) => {
        // convert LAST_SEEN values to dates and sort by most recent
        const aDate = new Date(a.LAST_SEEN);
        const bDate = new Date(b.LAST_SEEN);
        return bDate.getTime() - aDate.getTime();
      })[0];

      return ip;
    }, [asset]);

    const HeaderCard = ({
      children,
      backgroundColor,
      color,
    }: {
      children: React.ReactNode;
      backgroundColor?: string;
      color?: string;
    }) => {
      return (
        <TooltipHoverView TooltipContent={children}>
          <Card
            padding={"xxxs medium xxxs medium"}
            backgroundColor={backgroundColor ?? "neutral.20"}
            borderRadius={"medium"}
            maxWidth={"500px"}
          >
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Text
                fontWeight={"bold"}
                fontSize={"xs"}
                isTruncated
                color={color}
              >
                {children}
              </Text>
            </Flex>
          </Card>
        </TooltipHoverView>
      );
    };

    const {
      data: remediation,
      status,
      mutateAsync: genVulnRemediation_mutate,
    } = useMutation({
      mutationKey: ["gen-vuln-rem", asset?.DEVICE_ID, selectedNvt?.nvt_oid],
      mutationFn: async () => {
        if (!asset || !selectedNvt) throw new Error("Asset or NVT not found");
        return await genVulnRemediation(asset, selectedNvt.nvt_oid);
      },
    });

    return (
      <>
        <Accordion.Container
          defaultValue={defaultAccordianValue}
          width={"100%"}
          onClick={undefined}
          allowMultiple
          backgroundColor={"transparent"}
        >
          <Flex direction={"column"} gap={"xs"}>
            {asset ? (
              <DeviceDetailAcoordianItem
                value="device-details"
                trigger={
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Flex alignItems={"center"}>
                      <Accordion.Icon />
                      <TableTitle title={"Device Details"} />
                    </Flex>
                    <Badge
                      size="small"
                      fontSize={"small"}
                      fontWeight={"bold"}
                      textAlign={"center"}
                    >
                      {asset?.IPs[0].IP}
                    </Badge>
                  </Flex>
                }
                content={
                  <Flex width={"100%"} padding={"xs"}>
                    <Grid
                      flex={1}
                      templateColumns={"auto 1fr"}
                      gap={"small"}
                      justifyContent={"start"}
                    >
                      <View>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Last Scanned
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          MAC Vendor
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          MAC Address
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Age (years)
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Vulnerabilities
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          First Seen
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Last Seen
                        </Text>
                      </View>
                      <View>
                        <Text fontSize="xs" columnSpan={3}>
                          {" "}
                          {timeStampToLocaleString(ipAddress?.LAST_SEEN) ?? "-"}
                        </Text>
                        <Text fontSize="xs" columnSpan={3}>
                          {asset?.MAC_VENDOR ?? "-"}
                        </Text>
                        <Text fontSize="xs">{asset?.MAC ?? "-"}</Text>

                        <Text fontSize="xs">
                          {asset?.MAC_DATE
                            ? macDateToAge(asset?.MAC_DATE)
                            : "-"}
                        </Text>

                        <Text fontSize="xs">{asset?.nvts.length ?? "-"}</Text>
                        <Text fontSize="xs">
                          {timeStampToLocaleString(asset?.IPs[0].FIRST_SEEN) ??
                            "-"}
                        </Text>
                        <Text fontSize="xs">
                          {timeStampToLocaleString(asset?.IPs[0].LAST_SEEN) ??
                            "-"}
                        </Text>
                      </View>
                    </Grid>
                    <Grid
                      templateColumns={"auto 1fr"}
                      gap={"small"}
                      justifyContent={"start"}
                      flex={1}
                    >
                      <View>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Category
                        </Text>

                        <Text fontSize="xs" fontWeight={"bold"}>
                          Type
                        </Text>

                        <Text fontSize="xs" fontWeight={"bold"}>
                          Brand
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Model
                        </Text>

                        <Text fontSize="xs" fontWeight={"bold"}>
                          Hostname
                        </Text>
                        <Text fontSize="xs" fontWeight={"bold"}>
                          Operating System
                        </Text>
                      </View>
                      <View>
                        <Text fontSize="xs">
                          {asset?.recog_type_group ?? "-"}
                        </Text>

                        <Text fontSize="xs">{asset?.recog_type ?? "-"}</Text>
                        <Text fontSize="xs">{asset?.recog_brand ?? "-"}</Text>
                        <Text fontSize="xs">{asset?.recog_model ?? "-"}</Text>
                        <Text fontSize="xs">{asset?.HOSTNAME ?? "-"}</Text>

                        <Text fontSize="xs">{asset?.recog_os_name ?? "-"}</Text>
                      </View>
                    </Grid>
                  </Flex>
                }
              ></DeviceDetailAcoordianItem>
            ) : null}
            {selectedNvt ? (
              <>
                <Modal ref={genFixModal} size="large">
                  <InformationPanel
                    onClose={() => genFixModal.current?.close()}
                    header={
                      <>
                        <View>
                          <Heading level={4}>
                            {selectedNvt.ice_insight_headline}
                          </Heading>
                          <Text fontSize={"small"}>{selectedNvt.name}</Text>
                        </View>
                        <LevelBadge level={getVulnPriority(selectedNvt)} />
                      </>
                    }
                    body={
                      status === "pending" ? (
                        <Flex justifyContent={"center"} position={"relative"}>
                          <Image alt="" src={loadingGif} height={"400px"} />
                          <Flex
                            position={"absolute"}
                            backgroundColor={"rgba(255,255,255, 0.75)"}
                            top={"0"}
                            left={"0"}
                            right={"0"}
                            bottom={"0"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            {" "}
                            <Text
                              fontSize={"large"}
                              backgroundColor={"rgba(255,255,255, 0.75)"}
                            >
                              We're drawing up a plan for you...
                            </Text>
                          </Flex>
                        </Flex>
                      ) : (
                        <ScrollView
                          maxHeight={"450px"}
                          backgroundColor={"neutral.10"}
                          padding={"xs"}
                          borderRadius={"small"}
                          dangerouslySetInnerHTML={{
                            __html: remediation ?? "",
                          }}
                        ></ScrollView>
                      )
                    }
                  />
                </Modal>
                <DeviceDetailAcoordianItem
                  value="risk-insights"
                  headerColor="#144ee3"
                  trigger={
                    <Flex
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Flex alignItems={"center"} gap={"medium"}>
                        <Accordion.Icon color={"white"} />
                        <TableTitle
                          color={"white"}
                          title={
                            <>
                              {"Risk Insights"}
                              <TooltipHoverView
                                TooltipContent={
                                  "Select an Asset and a Vulnerability to generate tailored Remediation Instructions."
                                }
                              >
                                <Button
                                  variation="primary"
                                  data-attr="fix-button"
                                  marginLeft={"small"}
                                  size="small"
                                  colorTheme="success"
                                  isDisabled={!selectedNvt || !asset}
                                  onClick={async (e) => {
                                    if (!selectedNvt || !asset) return;
                                    e.stopPropagation();
                                    genFixModal.current?.showModal();
                                    await genVulnRemediation_mutate();
                                  }}
                                >
                                  Fix
                                </Button>
                              </TooltipHoverView>
                            </>
                          }
                        />
                      </Flex>
                      <HeaderCard
                        backgroundColor="white"
                        color="brand.primary.100"
                      >
                        {selectedNvt.ice_insight_headline ?? "-"}
                      </HeaderCard>
                    </Flex>
                  }
                  content={
                    <CyberCenterContent
                      whatIsItContent={
                        selectedNvt.ice_insight_what_short ?? "-"
                      }
                      whyDoesItMatterContent={
                        selectedNvt.ice_insight_why_short ?? "-"
                      }
                      howDoYouFixItContent={
                        selectedNvt.ice_insight_how_short ?? "-"
                      }
                    ></CyberCenterContent>
                  }
                ></DeviceDetailAcoordianItem>
                <DeviceDetailAcoordianItem
                  value="risk-details"
                  trigger={
                    <Flex alignItems={"center"} width={"100%"}>
                      <Flex alignItems={"center"} width={"100%"}>
                        <Accordion.Icon />
                        <TableTitle title={"Vulnerability Details"} />
                      </Flex>
                      <HeaderCard>{selectedNvt.name}</HeaderCard>
                    </Flex>
                  }
                  content={<VulnDetails nvt={selectedNvt} />}
                ></DeviceDetailAcoordianItem>
              </>
            ) : null}
          </Flex>
        </Accordion.Container>
      </>
    );
  }
);

export default DeviceDetails;
