import { useQuery } from "@tanstack/react-query";
import { GetAssetInventorySummaryResponse } from "../types/assetInventoryApi";
import { getAssetInventorySummary } from "../services/asset-inventory/getAssetInventorySummary";

export function useAssetInventorySummaryQuery(companyIds?: number[]) {
  const { data: assetInventorySummary, ...query } =
    useQuery<GetAssetInventorySummaryResponse>({
      queryKey: ["asset-inventory", "summary", companyIds],
      queryFn: async () =>
        await getAssetInventorySummary(companyIds as number[]),
      enabled: !!companyIds && companyIds.length > 0,
       
       
    });

  return {
    assetInventorySummary,
    ...query,
  };
}
