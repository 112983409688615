import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Branding } from "../../../components/monitorcompany/ReportBuilderButton";
import { BreachTableItem } from "../types";

export const BreachedAccountsTable = ({
  items,
  reportStyles,
}: {
  items: BreachTableItem[];
  reportStyles: Branding;
}) => {
  return (
    <View style={{ fontSize: "10px" }}>
      <View
        style={{
          flexDirection: "row",
          borderBottom: "2px",
          borderBottomColor: reportStyles.accentColor,
          gap: "5px",
        }}
      >
        <Text style={{ fontWeight: "bold", width: "75px" }}>Breach Date</Text>
        <Text style={{ fontWeight: "bold", width: "150px" }}>
          Email Address
        </Text>
        <Text style={{ fontWeight: "bold", width: "300px" }}>
          Breached Account
        </Text>
      </View>
      <View
        style={{
          paddingTop: "5px",
        }}
      >
        {items.map((item, i) => (
          <View
            key={i}
            style={{ flexDirection: "row", gap: "5px", height: "18px" }}
          >
            <Text
              style={{
                width: "75px",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {item.date}
            </Text>
            <Text
              style={{
                width: "150px",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {item.email}
            </Text>
            <Text
              style={{
                width: "300px",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {item.account}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};
