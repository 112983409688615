import React from "react";

import {
  Flex,
  Grid,
  Loader,
  Text,
  Divider,
  Accordion,
} from "@aws-amplify/ui-react";

import { useCyberScoreCombinedQuery } from "../hooks/useCyberScoreCombinedQuery";
import { useReconKeyTakeawaysQuery } from "../hooks/useReconKeyTakeawaysQuery";
import posthog from "posthog-js";
import { useReconActionPlanQuery } from "../hooks/useReconActionPlanQuery";
import InsightsCardReconLite from "../components/recon/InsightsCardReconLite";
import {
  BDVDetails,
  ESSDetails,
  ISHDetails,
  SEGDetails,
  WIDetails,
} from "../features/cyber-score";
import { WAFDetails } from "../features/cyber-score/components/WAFDetails";

// tried to add these to the interface folder/file but too many issues
export interface PersonalizedRiskItem {
  Email: string;
  BreachDate: string;
  BreachName: string;
}

export interface CyberScoreSimpleType {
  [key: string]: number;
}

export interface CyberScoreDetailedType {
  [key: string]: any[] | string | number;
}

export interface ThemeAttributes {
  colors: Record<string, string>;
  icons: Record<string, JSX.Element>;
  subtitles: Record<string, string>;
}

export enum Menu {
  EmailImpersonationProtection = "Email Impersonation Protection",
  DarkWebEmailBreaches = "Dark Web Email Breaches",
  SecureEmailGateway = "Secure Email Gateway",
  WebsiteSecuritySettings = "Website Security Settings",
  WordPressPluginSecurity = "WordPress Plugin Security",
  WebsiteEncryption = "Website Encryption",
  WebsiteFirewall = "Website Firewall",
}

type Props = {
  domain: string | undefined;
};

export default function ReconCompanyLite({ domain }: Props) {
  /* *********************** useRef/UseState Hooks ************************* */

  // state for opening dialog popout

  // state for "active" card selected
  // const [activeCategory, setActiveCategory] = useState<string>("");

  /* *********************** API Queries ************************* */

  const { cyberScore, isLoading, isError } = useCyberScoreCombinedQuery(domain);

  const { keyTakeaways, isLoading: ktLoading } = useReconKeyTakeawaysQuery(
    cyberScore,
    domain
  );
  const { actionPlan, isLoading: apLoading } = useReconActionPlanQuery(
    cyberScore,
    domain
  );

  if (isError) {
    return (
      <>
        <Text>Something went wrong. 😓</Text>
      </>
    );
  }

  if (isLoading) {
    return (
      <Flex
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex
          shrink={1}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"medium"}
        >
          <Text>
            We're calculating {domain}'s Cyber Score. Sit tight, this can take
            60 seconds.
          </Text>
          <Loader size="large" variation="linear" filledColor="#144ee3" />
        </Flex>
      </Flex>
    );
  }

  // const [activeTab, setActiveTab] = React.useState("darkwebreport");

  return (
    <>
      <Flex direction={"column"} gap="0" padding={"large xxxs"}>
        <Flex justifyContent={"right"}>
          {isLoading ? "" : <></>}
          {/* <View
            as="dialog"
            open={cyberScoreCardOpen}
            border={"0"}
            position={"absolute"}
            style={{ zIndex: "99" }}
            padding={"0"}
          >
            <CyberScoreCard
              colors={"neutral.80"}
              // colors={themeAttributes.colors}
              score={cyberScore?.Score}
              domain={domain}
              setCyberScoreCard={setCyberScoreCard}
            />
          </View> */}
        </Flex>
        <Grid gap="medium" templateColumns={"1fr"}>
          <Flex direction={"column"} gap="xs">
            <Flex direction={"column"} gap="xs">
              <Accordion.Container
                data-attr="scoring-criteria-cyber-action-plan"
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Accordion.Item value="insight">
                  <Accordion.Trigger>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Flex gap={"xs"} alignItems={"inherit"}>
                        <Divider
                          alignSelf={"stretch"}
                          orientation="vertical"
                          size="large"
                          border={"5px solid #144EE3"}
                          opacity={1}
                          borderRadius={"medium"}
                        />
                        <Flex direction={"column"} gap={"0"}>
                          <Text
                            fontWeight={"bold"}
                            color={"color"}
                            fontSize={"smaller"}
                          >
                            {!ktLoading && keyTakeaways ? (
                              <>{keyTakeaways?.["key-takeaway"]}</>
                            ) : null}
                          </Text>
                          <Text fontSize={"xs"}>Cyber Action Plan</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Accordion.Icon />
                  </Accordion.Trigger>
                  <Accordion.Content>
                    {!apLoading && actionPlan ? (
                      <ul>
                        {actionPlan?.actions?.map((action, index) => (
                          <li key={index}>
                            <Text fontSize={"smaller"}>
                              <b>{action.title}:</b> {action.detail}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Container>

              <InsightsCardReconLite
                title={"Dark Web Email Breaches"}
                data-attr="scoring-criteria-dark-web-email-breaches"
                score={cyberScore?.BDVScore}
                subtitle={cyberScore?.BDV}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <BDVDetails domain={domain} />
              </InsightsCardReconLite>

              <InsightsCardReconLite
                data-attr="scoring-criteria-email-impersonation-protection"
                title={"Email Impersonation Protection"}
                score={cyberScore?.ESSScore}
                subtitle={cyberScore?.ESS}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <ESSDetails cyberScore={cyberScore} />
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Secure Email Gateway"}
                data-attr="scoring-criteria-security-email-gateway"
                score={cyberScore?.SEGScore}
                subtitle={cyberScore?.SEG}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <SEGDetails />
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Website Security Settings"}
                data-attr="scoring-criteria-web-security-settings"
                score={cyberScore?.ISHScore}
                subtitle={cyberScore?.ISH}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <ISHDetails />
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Website Encryption"}
                data-attr="scoring-criteria-website-encryption"
                score={cyberScore?.WIScore}
                subtitle={cyberScore?.WI}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <WIDetails />
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Website Firewall"}
                data-attr="scoring-criteria-website-firewall"
                score={cyberScore?.WAFScore}
                subtitle={cyberScore?.WAF}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <WAFDetails />
              </InsightsCardReconLite>
            </Flex>
          </Flex>
        </Grid>
      </Flex>
    </>
  );
}
