import { ListScannersOutput } from "../../types/scanners";
import { getRequest } from "../restAPI";

export async function listScanners(memberId: string) {
  try {
    const scannersList = await getRequest(`/scanners`, {
      queryParams: {
        member_id: memberId,
      },
    }).then(async (response) => {
      return await response.body.json().then((json) => {
        const data = json as any as ListScannersOutput;

        return data;
      });
    });
    return scannersList as ListScannersOutput;
  } catch (error) {
    console.error(error);
    throw new Error("Error listing scanners.");
  }
}
