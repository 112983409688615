import { CompanyGroup } from "../../types/companies";
import { ListReconOutputsOutput } from "../../types/recon-outputs";
import { icebergGet } from "../iceberg-api";

export async function listReconOutputs({
  memberId,
  groupId,
  campaignId,
}: {
  memberId: string;
  groupId: CompanyGroup;
  campaignId?: string;
}) {
  try {
    const companiesList = await icebergGet(`recon_outputs`, {
      queryParams: campaignId
        ? {
            member_id: memberId,
            group_id: groupId,
            campaign_id: campaignId,
          }
        : {
            member_id: memberId,
            group_id: groupId,
          },
    }).then(async (response) => {
      return await response.body.json().then((json) => {
        const data = json as any as ListReconOutputsOutput;

        return data.Businesses ?? [];
      });
    });
    return companiesList;
  } catch (error) {
    console.error(error);
    throw new Error("Error listing recon outputs.");
  }
}
