import React, { ReactNode } from "react";
import { Card, Text } from "@aws-amplify/ui-react";

export default function ImportantStatInfoCard({ title, stat }: { title: any, stat: ReactNode }) {
    return (
        <Card padding={"medium"} boxShadow={"medium"} borderRadius={"large"}>
            <Text fontWeight={"medium"} fontSize={"small"} whiteSpace={"nowrap"} color="neutral.80">{title}</Text>
            <Text fontWeight={"bold"} fontSize={"xxl"}>{stat}</Text>
        </Card>
    )
}
