import React from "react";
import { Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import { Branding } from "../../components/monitorcompany/ReportBuilderButton";
import ReportHeader2 from "./ReportHeader2";

/* ============================================================= */
/* ************************ STYLESHEET ************************* */

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#000080",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    fontFamily: "Rubik",
  },
  baseCard: {
    width: "100%",
    height: "85%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topSection: {
    height: "95vh",
    flexDirection: "row",
    padding: "10px",
    gap: "20px",
    margin: "10px",
  },
  table: {
    display: "flex",
    width: "100%",
    paddingTop: "5px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColLeft: {
    width: "20%",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#000080",
  },
  tableColRight: {
    width: "80%",
    textAlign: "left",
  },
  tableCell: {
    margin: "auto",
    marginLeft: 5,
    marginTop: 5,
    fontSize: 10,
    justifyContent: "flex-start",
    textAlign: "left",
  },
});
type IReportGlossaryProps = {
  reportStyles: Branding;
  terms: any;
};

/* ============================================================= */
/* ============================================================= */
export const Glossary = ({ reportStyles, terms }: IReportGlossaryProps) => {
  /* ============================================================= */
  /* ********************* RETURN COMPONENT ********************** */
  return (
    <Page
      size={"LETTER"}
      style={[styles.page, { backgroundColor: reportStyles.primaryColor }]}
    >
      <ReportHeader2 heading={"Glossary"} logoUrl={reportStyles.yourLogo} />
      <View style={[styles.baseCard, { height: "90%" }]}>
        <View style={styles.topSection}>
          <View style={{ width: "95%" }}>
            <View
              style={{
                flexDirection: "row",
                gap: "20px",
                marginBottom: 10,
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                borderBottomColor: "#000080",
              }}
            >
              <Text
                style={{
                  flex: ".4",
                  fontSize: "12",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Terms
              </Text>
              <Text
                style={{
                  flex: "1",
                  fontSize: "12",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                What This Means To You
              </Text>
            </View>
            {terms.map((item: any, index: any) => (
              <View
                key={index}
                style={{ flexDirection: "row", gap: "20px", marginBottom: 5 }}
              >
                <Text
                  style={{
                    flex: ".4",
                    fontSize: "10px",
                    textAlign: "right",
                    width: "40px",
                  }}
                >
                  {item.term}
                </Text>
                <Text
                  style={{
                    flex: "1",
                    fontSize: "10px",
                    textAlign: "left",
                    width: "40px",
                  }}
                >
                  {item.meaning}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default Glossary;
