const fallbackUrl =
  "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";

export async function getLogo(domain: string): Promise<string> {
  if (!domain) {
    return fallbackUrl;
  } else {
    let faviconUrl = `https://favicon.twenty.com/${domain}`;

    const isValid = await isValidImageURL(faviconUrl);
    if (isValid) {
      return faviconUrl;
    } else {
      return fallbackUrl;
    }
  }
}

// Function to check if a URL is a valid image
const isValidImageURL = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};
