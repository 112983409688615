import { Asset } from "../types/assetInventoryApi";
import { timeStampToLocaleString } from "./timeStampToLocaleString";
import { getAssetCurrentIPRecord } from "./getAssetCurrentIPRecord";

export function getAssetLastScanned(asset: Asset) {
  
  const ipRecord = getAssetCurrentIPRecord(asset);

  return timeStampToLocaleString(ipRecord?.LAST_SEEN ?? "");
}
