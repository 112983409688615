import * as React from "react";
import { Text, View } from "@react-pdf/renderer";

export const Commentary = ({
  body,
}: {
  body: string;
}) => (
  <View style={{ gap: "2px", fontSize: "10px" }}>
    <Text>
      {body}

    </Text>
  </View>
);
