import { useQuery } from "@tanstack/react-query";

import { useUserContext } from "../hooks/useUserContext";
import { useMemo } from "react";
import { listScanners } from "../services/scanners/listScanners";



export function useScannersQuery() {
  const { user } = useUserContext();

  const memberId = useMemo(() => user?.memberId ?? undefined, [user]);

  const { data: scanners, ...query } = useQuery({
    queryKey: ["scanner", "list"],
    queryFn: async () => {
      const response = await listScanners(memberId as string);
      return response.Scanners;
    },
  
    enabled: !!memberId,
    
  });

  // const {  } = useQueries({
  //   queries: (scanners as Scanner[]).map((scanner) => ({
  //     queryKey: ["scanner", scanner.ScannerID],
  //     queryFn: async () => {
  //       return scanner;
  //     },
  //     enabled: !!scanners,
  //   })),
  // });


  return {
    scanners,
    ...query,
  };
}
