import {
  ListReconOutputsInputParams,
  ListReconOutputsOutput,
} from "../../types/recon-outputs";
import { icebergGet } from "../iceberg-api";

type Params = Pick<
  ListReconOutputsInputParams,
  "member_id" | "group_id" | "campaign_id" | "cuid"
>;

export async function forceScan(params: Params) {
  try {
    const inputParams: ListReconOutputsInputParams = {
      ...params,
      scan_new: 1,
      rerun_scan: 1,
    };

    const companiesList = await icebergGet(`recon_outputs`, {
      queryParams: inputParams,
    }).then(async (response) => {
      return await response.body.json().then((json) => {
        const data = json as any as ListReconOutputsOutput;

        return data.Businesses ?? [];
      });
    });
    return companiesList;
  } catch (error) {
    console.error(error);
    throw new Error("Error forcing scan.");
  }
}
