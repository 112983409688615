import { Button, Divider, Flex, Text } from "@aws-amplify/ui-react";
import React, { useEffect, useRef } from "react";
import { FaPaintBrush, FaTimes } from "react-icons/fa";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";

import { Modal } from "../Modal";
import BrandingOptions from "../getting-started/BrandingOptions";
import { useFavicon } from "../../hooks/useFavicon";
import { useBase64Image } from "../../hooks/useBase64Image";

export type Branding = {
  contactName: string;
  contactEmail: string;
  phoneNumber: string;
  primaryColor: string;
  accentColor: string;
  fontColor: string;
  yourLogo: string;
  clientLogo: string;
  slogan: string;
  companyName: string;
  scoreAlias: string;
};

type Props = {
  prospectLogo?: string;
  setProspectLogo?: React.Dispatch<React.SetStateAction<string>>;
};

export default function BrandingButton({
  prospectLogo,
  setProspectLogo,
}: Props) {
  // const [openReportBuilder, setReportBuilder] = useState(false);

  const reportBuilderModal = useRef<HTMLDialogElement>(null);

  const { company } = useMonitorCompanyContext(); // REMOVE THIS

  const { url } = useFavicon(company?.EmailDomain);

  const { base64Data } = useBase64Image(url);

  useEffect(() => {
    if (base64Data) {
      setProspectLogo?.(base64Data);
    }
  }, [base64Data]);

  return (
    <>
      <Button
        borderRadius={"medium"}
        size="small"
        variation="primary"
        gap={"xs"}
        onClick={() => {
          reportBuilderModal.current?.showModal();
        }}
      >
        <FaPaintBrush />
        Branding
      </Button>

      <Modal ref={reportBuilderModal} size="medium">
        <Flex
          direction={"column"}
          // height={"100%"}
          justifyContent={"space-between"}
        >
          <Flex justifyContent={"space-between"} alignItems={"start"}>
            <Flex gap={"small"}>
              <Divider
                orientation="vertical"
                borderColor={"#174De1"}
                borderWidth={"large"}
                borderRadius={"large"}
              />
              <Text fontWeight={"bold"} fontSize={"larger"} color={"blue.90"}>
                Branding
              </Text>
            </Flex>

            <Button
              color="white"
              backgroundColor={"red.60"}
              borderRadius={"medium"}
              onClick={() => {
                reportBuilderModal.current?.close();
              }}
            >
              <FaTimes />
            </Button>
          </Flex>
          {/* <Grid
            templateColumns={{ base: "1fr", medium: "2fr 1fr" }}
            gap={"medium"}
          > */}

          <BrandingOptions
            prospectLogo={prospectLogo}
            setProspectLogo={setProspectLogo}
          />

          {/* <Flex height={"300px"} width={"100%"} justifyContent={"center"}>
              <View
                // width={"100%"}
                boxShadow={"0 2px 10px -5px rgba(0, 0, 0, 0.4)"}
                style={{ aspectRatio: "8/11" }}
                borderRadius={"small"}
              >
                <Grid
                  width={"100%"}
                  height={"100%"}
                  templateColumns={"1fr 1fr"}
                  templateRows={"1fr 2fr 5fr"}
                  gap={"small"}
                >
                  <View
                    columnSpan={2}
                    backgroundColor={branding.primaryColor}
                    borderRadius={"small small 0 0"}
                  >
                    <Grid templateColumns={"1fr 1fr 1fr"} padding={"xs"}>
                      {branding.logo !== "" ? (
                        <Image
                          column={3}
                          alt={undefined}
                          src={branding.logo}
                          height={"20px"}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </View>
                  <View
                    marginTop={"large"}
                    marginLeft={"small"}
                    borderRadius={"medium"}
                    columnStart={1}
                    columnSpan={1}
                    backgroundColor={branding.accentColor}
                  ></View>
                  <View
                    marginLeft={"small"}
                    marginBottom={"small"}
                    borderRadius={"medium"}
                    columnStart={1}
                    columnSpan={1}
                    boxShadow={"inset 0 0 0 3px " + branding.fontColor}
                    backgroundColor={"transparent"}
                  ></View>
                </Grid>
              </View>
            </Flex>
          </Grid> */}
        </Flex>
      </Modal>
    </>
  );
}
