import { queryOptions } from "@tanstack/react-query";
import { imageToBase64 } from "../services/imageToBase64";

export function imageToBase64QueryOptions(url?: string, fallbackUrl?: string) {
  return queryOptions({
    queryKey: ["Image Base 64 Data", url],
    queryFn: async () => {
      try {
        const data = await imageToBase64(url as string);
        if (data.includes("data:image/svg+xml")) {
          const uri = await svgToDataURI(data, 200, 200);
          return uri;
        }

        return data;
      } catch (err) {
        if (fallbackUrl) {
          const data = await imageToBase64(fallbackUrl as string);
          return data;
        } else throw err;
      }
    },
    enabled: !!url,
  });
}

async function svgToDataURI(
  svgEncodedData: string,
  renderWidth: number,
  renderHeight: number
): Promise<string> {
  const canvas = document.createElement("canvas");
  const id = "temp-canvas";
  canvas.setAttribute("id", id);
  canvas.setAttribute("style", "display: none");
  document.body.appendChild(canvas);

  canvas.width = renderWidth;
  canvas.height = renderHeight;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("no canvas 2d context");
  }

  const img = document.createElement("img");
  img.setAttribute("src", svgEncodedData);

  return new Promise(() => {
    img.onload = function load() {
      ctx.drawImage(img, 0, 0);
      const url = canvas.toDataURL("image/jpeg", 1.0);
      const el = document.getElementById(id);
      if (el) el.remove();

      return url;
    };
  });
}
