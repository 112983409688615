import "../../style/assetDetailsPopOutPanel.css";

import { Accordion, Divider, View } from "@aws-amplify/ui-react";
import * as React from "react";

export type IDeviceDetailsAccordianItemProps = {
  value: string;
  headerColor?: string;
  trigger: React.ReactNode;
  content: React.ReactNode;
};

const DeviceDetailsAccordianItem = React.forwardRef<
  HTMLDivElement,
  IDeviceDetailsAccordianItemProps
>(
  ({
    trigger,
    content,
    value,
    headerColor,
  }: IDeviceDetailsAccordianItemProps) => {
    return (
      <Accordion.Item
      
        value={value}
        borderRadius={"medium"}
        backgroundColor={"white"}
      >
        <Accordion.Trigger borderRadius={"small"} backgroundColor={headerColor}>
          {trigger}
        </Accordion.Trigger>
        <Divider />

        <Accordion.Content padding={"small"} >
          <View>{content}</View>
        </Accordion.Content>
      </Accordion.Item>
    );
  }
);

export default DeviceDetailsAccordianItem;
