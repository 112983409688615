import React from "react";
import { Routes, Route } from "react-router-dom";
import ScoreGroupView from "../pages/ScoreGroupView";
import ScoreGroupListView from "../pages/ScoreGroupListView";

export default function GroupRoutes() {
  return (
    <Routes>
      <Route index element={<ScoreGroupListView />}></Route>
      <Route path=":id" element={<ScoreGroupView />}></Route>
    </Routes>
  );
}
