import * as React from "react";
import { View, Image, Text } from "@react-pdf/renderer";

export const Subtitle = ({ icon, titlepart1, titlepart2, color }: { icon: any, titlepart1: string, titlepart2: string, color:any }) => (
  <View
    style={{
      flexDirection: "row", 
      alignItems: "center",
      gap: "3px",
    }} 
  >


    <View style={{ height: "35px", width: "35px" }}>
      <Image
        src={icon}
        style={{
          width: "100%",
          height: "auto",
        }}
      />
    </View>
    <View style={{ flexDirection: "row" }}>
      <Text
        style={{
          fontWeight: "bold",
          fontSize: "20px",
          color: color,
        }}
      >
        {titlepart1}
      </Text>
      <Text style={{ fontWeight: "thin", fontSize: "20px" }}>
        {" "}
        {titlepart2}
      </Text>
    </View>


  </View>
);
