import { Badge, Flex, Grid, Link, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import TableTitle from "../styling/TableTitle";
import TooltipHoverView from "../styling/TooltipHoverView";
import { NVT } from "../../types/assetInventoryApi";
import LevelBadge from "./LevelBadge";
import { getVulnLikelihood } from "../../util/getVulnLikelihood";
import { getVulnImpact } from "../../util/getVulnImpact";
import { getVulnPriority } from "../../util/getVulnPriority";

type VulnDetailsProps = {
  nvt: NVT;
};

export default function VulnDetails({ nvt }: VulnDetailsProps) {
  const data = React.useMemo(() => {
    const cvss_score = nvt.cvss_score;
    const epss_score = nvt.epss_score;
    const KEV = nvt.CVEs.some((cve) => cve.KEV);
    const name = nvt.name;
    const summary = nvt.summary;
    const solution = nvt.solution;
    const priority = nvt.priority;
    const ports = nvt.ports;
    const solution_type = nvt.solution_type;
    const references = nvt.references;
    const CVEs = nvt.CVEs;
    return {
      cvss_score,
      epss_score,
      KEV,
      name,
      summary,
      solution,
      priority,
      ports,
      solution_type,
      impacted: nvt.impact,
      affected: nvt.affected,
      insight: nvt.insight,
      published: nvt.nvt_published,
      id: nvt.nvt_oid,
      references,
      CVEs,
      first_seen: nvt.nvt_first_seen,
      last_seen: nvt.nvt_last_seen,
    };
  }, [nvt]);

  return (
    <Flex direction={"column"}>
      {/* ---------------- Card 1 ---------------- */}
      <Flex gap={"medium"} direction={"column"}>
        <Grid templateColumns={"1fr 1fr"} gap={"medium"}>
          <Grid templateColumns={"auto 1fr"} gap={"small"}>
            <View>
              <Text fontSize="xs" fontWeight={"bold"}>
                Last Scanned
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                CVSS Score
                <TooltipHoverView
                  TooltipContent={
                    <Text color={"white"}>
                      <u>
                        <Link
                          color={"white"}
                          isExternal
                          href="https://nvd.nist.gov/vuln-metrics/cvss"
                        >
                          Common Vulnerability Scoring System (CVSS)
                        </Link>
                      </u>{" "}
                      rates the impact of vulnerabilities out of 10.
                    </Text>
                  }
                  color={"neutral.90"}
                  children={<HiInformationCircle />}
                />
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                EPSS Score:{" "}
                <TooltipHoverView
                  TooltipContent={
                    <Text color={"white"}>
                      <u>
                        <Link
                          color={"white"}
                          isExternal
                          href="https://www.first.org/epss/"
                        >
                          Exploit Prediction Scoring System (EPSS)
                        </Link>
                      </u>{" "}
                      predicts the likelihood of a vulnerability being
                      exploited.
                    </Text>
                  }
                  color={"neutral.90"}
                  children={<HiInformationCircle />}
                />
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                Exploited (KEV):{" "}
                <TooltipHoverView
                  TooltipContent={
                    <Text color={"white"}>
                      <u>
                        <Link
                          color={"white"}
                          isExternal
                          href="https://www.cisa.gov/known-exploited-vulnerabilities-catalog"
                        >
                          Known Exploited Vulnerabilities (KEV)
                        </Link>
                      </u>{" "}
                      is a list of vulnerabilities that have been exploited in
                      real-world cyber attacks.
                    </Text>
                  }
                  color={"neutral.90"}
                  children={<HiInformationCircle />}
                />
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                Ports:
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                Publish Date:
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                ID:
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                First Seen:
              </Text>
              <Text fontSize="xs" fontWeight={"bold"}>
                Last Seen:
              </Text>
            </View>
            <View>
              <Text fontSize="xs">
                {timeStampToLocaleString(nvt.nvt_last_seen)}
              </Text>

              <Text fontSize="xs">{data?.cvss_score}</Text>

              <Text fontSize="xs">
                {data?.epss_score === -Infinity ||
                data?.epss_score === undefined
                  ? "-"
                  : Math.round(data?.epss_score * 100) + "%"}
              </Text>

              <Text fontSize="xs">{data?.KEV ? "Yes" : "No"}</Text>

              <Text fontSize="xs">{data?.ports.join(", ")}</Text>
              <Text fontSize="xs">{data?.published?.split("T")[0] ?? "-"}</Text>
              <Text fontSize="xs">{data?.id}</Text>
              <Text fontSize="xs">
                {timeStampToLocaleString(data?.first_seen)}
              </Text>
              <Text fontSize="xs">
                {timeStampToLocaleString(data?.last_seen)}
              </Text>
            </View>
          </Grid>
          <Grid templateColumns={"auto 1fr"} gap={"small"} rowGap={"0"}>
            <Text alignSelf={"center"} fontSize="xs" fontWeight={"bold"}>
              Impact
            </Text>
            <Text alignSelf={"center"} fontSize="xs">
              <LevelBadge level={getVulnImpact(nvt)} />
            </Text>
            <Text alignSelf={"center"} fontSize="xs" fontWeight={"bold"}>
              Likelihood
            </Text>

            <Text alignSelf={"center"} fontSize="xs">
              <LevelBadge level={getVulnLikelihood(nvt)} />
            </Text>
            <Text alignSelf={"center"} fontSize="xs" fontWeight={"bold"}>
              Priority
            </Text>
            <Text alignSelf={"center"} fontSize="xs">
              <LevelBadge level={getVulnPriority(nvt)} />
            </Text>
          </Grid>
        </Grid>
      </Flex>

      {/* ---------------- Card 2 ---------------- */}

      {data?.summary ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <TableTitle title={"Vulnerability Summary"} />
          <Text fontSize={"xs"}>{data?.summary}</Text>
        </Flex>
      ) : null}
      {data?.solution_type ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <Flex justifyContent={"space-between"}>
            <TableTitle title={"Solution"} />
            <Badge fontSize={"xs"}>
              <i>Solution Type: {data?.solution_type}</i>
            </Badge>
          </Flex>
          {data?.solution ? <Text fontSize={"xs"}>{data.solution}</Text> : null}
        </Flex>
      ) : null}

      {data?.impacted ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <TableTitle title={"Impacted"} />
          <Text fontSize={"xs"}>{data?.impacted}</Text>
        </Flex>
      ) : null}

      {data?.affected ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <TableTitle title={"Affected"} />
          <Text fontSize={"xs"}>{data?.affected}</Text>
        </Flex>
      ) : null}

      {data?.insight ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <TableTitle title={"Insight"} />
          <Text fontSize={"xs"}>{data?.insight}</Text>
        </Flex>
      ) : null}
      {data?.CVEs.length > 0 ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <TableTitle title={"CVEs"} />
          <Flex direction={"column"} gap={"xxs"}>
            {data.CVEs.map((CVE) => (
              <Link
                fontSize={"xs"}
                href={`https://nvd.nist.gov/vuln/detail/${CVE.cve_id}`}
                isExternal
              >
                {CVE.cve_id}
              </Link>
            ))}
          </Flex>
        </Flex>
      ) : null}
      {data?.references.length > 0 ? (
        <Flex direction={"column"} gap={"xxxs"} padding={"medium 0 small 0"}>
          <TableTitle title={"References"} />
          <Flex direction={"column"} gap={"xxs"}>
            {data.references.map((r) => (
              <Link fontSize={"xs"} href={r} isExternal>
                {r}
              </Link>
            ))}
          </Flex>
        </Flex>
      ) : null}

      {/* <Flex justifyContent={"center"} padding={"large"}>
        <Button
          size="small"
          variation="primary"
          onClick={() => {
            setRemediationInstructions((prev) => !prev);
          }}
        >
          Remediation Instructions
        </Button>
      </Flex> */}
    </Flex>
  );
}
