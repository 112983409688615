import { Button, Card, Flex, Text } from '@aws-amplify/ui-react'
import React from 'react'
import { Modal } from "../../components/Modal";
import Step1Modal from './Step1Modal';
import { FaTimes } from 'react-icons/fa';
import Step2Modal from './Step2Modal';

export default function StepCards({ stepNumber, borderColor, action, icebergAction, children }: { stepNumber: string, borderColor: string, action: string, icebergAction: string, children?:React.ReactNode }) {
    const step1Modal = React.useRef<HTMLDialogElement>(null);
    const step2Modal = React.useRef<HTMLDialogElement>(null);
    return (
        <>
            <Modal ref={step1Modal}>
                <Flex justifyContent={"right"} padding={"0"}>
                    <Button color="white"
                        backgroundColor={"red.60"}
                        borderRadius={"medium"} onClick={() => step1Modal.current?.close()}> <FaTimes /></Button>
                </Flex>
                <Step1Modal />
            </Modal>

            <Modal ref={step2Modal}>
                <Flex justifyContent={"right"} padding={"0"}>
                    <Button color="white"
                        backgroundColor={"red.60"}
                        borderRadius={"medium"} onClick={() => step2Modal.current?.close()}> <FaTimes /></Button>
                </Flex>
                <Step2Modal />
            </Modal>

            <Flex direction={"column"}>
                <Flex justifyContent={"center"}>
                    <Card height={"75px"} padding={"0"} width={"75px"} borderColor={borderColor} borderWidth={"6px"} borderRadius={"100%"}>
                        <Flex width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
                            <Text fontWeight={"semibold"} fontSize={"medium"}>{stepNumber}</Text>
                        </Flex>
                    </Card>

                    <Flex alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                        <Flex gap={"0"} direction={"column"} width={"100%"}>
                            <Text fontSize={"larger"} fontWeight={"bold"}>{action}</Text>
                            <Text color={"neutral.90"}>{icebergAction}</Text>
                        </Flex>
                    </Flex>
                </Flex>


                <Card padding={"0"} borderColor={borderColor} height={"400px"} borderRadius={"large"} borderWidth={"6px"}>
                    {children}
                </Card >


            </Flex >
        </>

    )
}
