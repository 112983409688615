import * as React from "react";
import {
  Flex,
  Card,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Text,
  ScrollView,
  Placeholder,
  Link,
} from "@aws-amplify/ui-react";
import { FaPlus, FaEye, FaCircle } from "react-icons/fa";
import TableTitle from "../styling/TableTitle";
import { Scanner } from "../../types/scanners";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { useScannersQuery } from "../../hooks/useScannersQuery";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import { Modal } from "../Modal";
import ScannerDetailsForm from "./ScannerDetailsForm";
import { useScannersMutation } from "../../hooks/useScannersMutation";
import { useNavigate } from "react-router-dom";

export interface IScannerPortfolioProps {}

export default function ScannerPortfolio({}: IScannerPortfolioProps) {
  // const { setSelectedScanner } = props;

  const navigate = useNavigate();

  const updateScannerModal = React.useRef<HTMLDialogElement>(null);
  const callToActionModal = React.useRef<HTMLDialogElement>(null);

  const [selectedScanner, setSelectedScanner] = React.useState<
    Scanner | undefined
  >();

  const { scanners, isLoading: isLoadingScanners } = useScannersQuery();
  const { companies: businesses, isLoading: isLoadingCompanies } =
    useCompaniesQuery(1);

  const isLoading = isLoadingScanners || isLoadingCompanies;

  const { updateScannersMutate } = useScannersMutation();

  return (
    <>
      <Modal ref={updateScannerModal}>
        <ScannerDetailsForm
          selectedScanner={selectedScanner}
          onClose={() => updateScannerModal.current?.close()}
          onSave={async (updateScannerRequest) => {
            await updateScannersMutate({
              body: { Scanners: [updateScannerRequest] },
            });
            updateScannerModal.current?.close();
          }}
        />
      </Modal>
      <Modal ref={callToActionModal}>
        <Flex direction={"column"} alignItems={"center"} padding={"medium"}>
          <Text fontSize={"large"} textAlign={"center"}>
            To add a new scanner, add a subscription to your account on the{" "}
            <Link onClick={() => navigate("/settings")}>
              <b>Settings</b>
            </Link>{" "}
            page
          </Text>
          <Button
            variation="primary"
            onClick={() => {
              callToActionModal.current?.close();
            }}
          >
            Close
          </Button>
        </Flex>
      </Modal>
      <Card boxShadow={"medium"} borderRadius={"large"}>
        <Flex justifyContent={"space-between"} paddingBottom={"large"}>
          <TableTitle title={"Scanner Portfolio"} />

          <Button
            borderRadius={"medium"}
            fontSize={"small"}
            boxShadow={"small"}
            color={"white"}
            backgroundColor={"blue.60"}
            gap={"small"}
            onClick={() => {
              callToActionModal.current?.showModal();
            }}
          >
            <FaPlus />
          </Button>
        </Flex>
        <ScrollView height={"400px"}>
          <Table highlightOnHover={true} size="small">
            <TableHead>
              <TableRow>
                <TableCell width={"15%"} fontSize={"smaller"} as="th">
                  Name
                </TableCell>
                <TableCell width={"20%"} fontSize={"smaller"} as="th">
                  Serial Number
                </TableCell>
                <TableCell width={"30%"} fontSize={"smaller"} as="th">
                  Company
                </TableCell>
                <TableCell width={"30%"} fontSize={"smaller"} as="th">
                  Last Seen (Status)
                </TableCell>
                <TableCell width={"5%"} fontSize={"smaller"} as="th">
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading
              ? [0, 1, 2, 3, 4, 5].map((i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                  </TableRow>
                ))
              : scanners?.map((scanner, i) => (
                  <TableRow key={i}>
                    <TableCell fontSize={"smaller"}>
                      {scanner.Name || "-"}
                    </TableCell>
                    <TableCell fontSize={"smaller"}>
                      {scanner?.Type === "Sensor"
                        ? scanner?.SerialNumber
                        : scanner?.Type === "Container"
                        ? scanner?.HostID
                        : "-"}
                    </TableCell>
                    <TableCell fontSize={"smaller"} padding="0">
                      {businesses?.find((b) => b.CUID === scanner.CUID)
                        ?.CompanyName || "-"}
                      {/* <Flex>
                        <SelectField
                          label=""
                          descriptiveText=""
                          size="small"
                          variation="quiet"
                          value={scanner.CUID.toString()}
                        >
                          {businesses?.map((business) => (
                            <option value={business.CUID.toString()}>
                              {business.CompanyName}
                            </option>
                          ))}
                        </SelectField>
                      </Flex> */}
                    </TableCell>
                    <TableCell fontSize={"smaller"}>
                      <Flex>
                        <Flex
                          justifyContent={"center"}
                          gap={"xxs"}
                          alignItems={"end"}
                        >
                          <Text
                            color={scanner.LastScan ? "green.60" : "grey"}
                            fontSize={"smaller"}
                          >
                            <FaCircle />
                          </Text>
                          <Text>
                            {timeStampToLocaleString(scanner.LastSeen)}
                          </Text>
                        </Flex>
                      </Flex>
                    </TableCell>

                    <TableCell fontSize={"small"}>
                      <Button
                        onClick={() => {
                          setSelectedScanner(scanner);
                          updateScannerModal.current?.showModal();
                        }}
                      >
                        <FaEye />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </Table>
        </ScrollView>
      </Card>
    </>
  );
}
