import { Divider, Flex, Link, Text } from "@aws-amplify/ui-react";
import React from "react";
import { CyberScoreCombined } from "../../../types/CyberScoreCombined";
type Props = {
  cyberScore?: CyberScoreCombined | undefined;
};
export function ISHDetails({ cyberScore }: Props) {
  return (
    <Flex direction="column">
      <Flex direction="column" gap="xs">
        <Flex gap={"xxs"}>
          <Divider
            width={"6px"}
            margin={"3px 0 3px 0"}
            borderRadius={"4px"}
            opacity={"100"}
            borderColor={"#42cefb"}
            backgroundColor="#42cefb"
            orientation="vertical"
          />
          <Text fontSize={"smaller"} fontWeight="bold">
            What is it?
          </Text>
        </Flex>

        <Text fontSize={"smaller"}>
          Website security settings, like HTTP secure headers, help protect your
          site and visitors. Content Security Policy (CSP) controls what
          resources the site can load, reducing risk of malicious content.
          X-Frame-Options prevent your site from being displayed in a frame,
          averting clickjacking attacks. For any business, their website is the
          public face of the company. Website security maintains client trust by
          protecting their data and ensuring a safe browsing experience.
        </Text>
        <Text fontSize={"smaller"}>
          Find the exact steps in our{" "}
          <Link
            isExternal
            textDecoration={"underline"}
            fontWeight={"bold"}
            href="https://www.icebergcyber.com/help-center-category/website-security"
          >
            Help Center
          </Link>
          .
        </Text>
      </Flex>
      {cyberScore ? (
        <Flex direction="column" gap="xs">
          <Flex gap={"xxs"}>
            <Divider
              width={"6px"}
              margin={"3px 0 3px 0"}
              borderRadius={"4px"}
              opacity={"100"}
              borderColor={"#42cefb"}
              backgroundColor="#42cefb"
              orientation="vertical"
            />
            <Text fontSize={"smaller"} fontWeight="bold">
              Your Results
            </Text>
          </Flex>

          <Text fontSize={"smaller"}>{cyberScore?.ISH}</Text>
        </Flex>
      ) : null}
    </Flex>
  );
}
