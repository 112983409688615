import {
  Grid,
  Placeholder,
  ScrollView,
  View,
  Text,
  Accordion,
  Flex,
  Badge,
  Button,
} from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import DeviceDetailsAccordianItem from "./DeviceDetailAcoordianItem";
import { RelatedAssetsTable } from "./RelatedAssetsTable";
import LevelBadge from "./LevelBadge";

import { VulnTableData } from "../../features/assets";
import {
  priorityToString,
  vulnSortFunction,
} from "../../features/assets/utils";

export default function VulnerabilitiesTable({
  setNvtId: setNvtId,
  selectedNvtId,
  selectedAssetId,
  setSelectedAssetId,
}: {
  setNvtId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedNvtId: string | null;
  selectedAssetId: string | null;
  setSelectedAssetId: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const { nvts, isLoading, assets } = useMonitorCompanyContext();

  const [sortColumn, setSortColumn] = useState<keyof VulnTableData | undefined>(
    "priority"
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const uniqueNvts = useMemo(() => {
    const seen = new Set();
    return nvts
      ?.filter((nvt) => {
        const duplicate = seen.has(nvt.nvt_oid);
        seen.add(nvt.nvt_oid);
        return !duplicate;
      })
      .sort((a, b) => {
        return a.priority - b.priority;
      });
  }, [nvts]);

  const tableData: VulnTableData[] = useMemo(() => {
    const sorted =
      uniqueNvts
        ?.map((nvt) => ({
          id: nvt.nvt_oid,
          assets:
            assets?.filter((asset) =>
              asset.nvts.some((v) => nvt.nvt_oid === v.nvt_oid)
            ).length ?? 0,
          priority: nvt.priority,
          family: nvt.family,
          solution: nvt.solution_type ?? "",
          name: nvt.name ?? "",
        }))
        .sort((a, b) => vulnSortFunction(sortColumn, a, b)) ?? [];

    return sortOrder === "asc" ? sorted : sorted.reverse();
  }, [uniqueNvts, assets, sortColumn, sortOrder]);

  const [increment, setIncrement] = React.useState(10);

  const incrementChunks = React.useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIncrement((prev) => prev + 100);
  }, []);

  React.useEffect(() => {
    setIncrement(10);
  }, [nvts]);

  React.useEffect(() => {
    if (increment < (tableData?.length ?? 0)) incrementChunks();
  }, [increment, tableData]);

  const Trigger = ({ data }: { data: VulnTableData }) => {
    return (
      <Grid
        templateColumns={"15px 65px 80px 80px 90px 20fr"}
        width={"100%"}
        padding={"0"}
        gap={"xs"}
        alignItems={"center"}
      >
        <Accordion.Icon />
        <Flex width={"100%"} padding={'0 xs'}>
          <Badge
            width={"100%"}
            size="small"
            fontSize={"xs"}
            variation={data.assets === 0 ? "success" : "warning"}
          >
            <Flex justifyContent={"center"} width={"100%"}>
              {data.assets}
            </Flex>
          </Badge>
        </Flex>
        <View>
          <LevelBadge
            level={priorityToString(data.priority)}
            size="small"
            fontSize="xs"
          />
        </View>
        <Text fontSize={"xs"}>{data.family}</Text>
        <Text fontSize={"xs"}>{data.solution ?? "-"}</Text>
        <Text fontSize={"xs"}>{data.name}</Text>
      </Grid>
    );
  };

  return (
    <ScrollView maxHeight={"400px"}>
      <Grid
        position={"sticky"}
        top={"0"}
        templateColumns={"15px 65px 80px 80px 90px 20fr"}
        width={"100%"}
        padding={"small"}
        gap={"xs"}
        style={{ zIndex: 999 }}
        backgroundColor={"white"}
      >
        <Text> </Text>
        <Button
          paddingLeft={"0"}
          boxShadow={"none"}
          backgroundColor={"transparent"}
          padding={"xxs"}
          justifyContent={"start"}
          variation="link"
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          textAlign={"center"}
          onClick={() => {
            if (sortColumn !== "assets") {
              setSortColumn("assets");
              setSortOrder("desc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Assets{" "}
          {sortColumn === "assets" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>
        <Button
          paddingLeft={"0"}
          boxShadow={"none"}
          backgroundColor={"transparent"}
          padding={"xxs"}
          justifyContent={"start"}
          variation="link"
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          textAlign={"center"}
          onClick={() => {
            if (sortColumn !== "priority") {
              setSortColumn("priority");
              setSortOrder("desc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Priority{" "}
          {sortColumn === "priority" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>
        <Button
          paddingLeft={"0"}
          boxShadow={"none"}
          backgroundColor={"transparent"}
          padding={"xxs"}
          justifyContent={"start"}
          variation="link"
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          textAlign={"center"}
          onClick={() => {
            if (sortColumn !== "family") {
              setSortColumn("family");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Family{" "}
          {sortColumn === "family" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>
        <Button
          paddingLeft={"0"}
          boxShadow={"none"}
          backgroundColor={"transparent"}
          padding={"xxs"}
          justifyContent={"start"}
          variation="link"
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          textAlign={"center"}
          onClick={() => {
            if (sortColumn !== "solution") {
              setSortColumn("solution");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Solution{" "}
          {sortColumn === "solution" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>
        <Button
          paddingLeft={"0"}
          boxShadow={"none"}
          backgroundColor={"transparent"}
          padding={"xxs"}
          justifyContent={"start"}
          variation="link"
          fontSize={"smaller"}
          fontWeight={"bold"}
          onClick={() => {
            if (sortColumn !== "name") {
              setSortColumn("name");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Name {sortColumn === "name" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>
      </Grid>
      <Accordion.Container
        allowMultiple={false}
        width={"100%"}
        value={selectedNvtId ? [selectedNvtId] : []}
        onValueChange={(value) => {
          if (value === undefined || value.length === 0) {
            setNvtId(null);
          } else if (value !== undefined) setNvtId(value[0]);
          setSelectedAssetId(null);
        }}
        // backgroundColor={"#EAF4FF"}
      >
        <Flex direction={"column"} gap={"xs"}>
          {isLoading && nvts === undefined
            ? [0, 1, 2, 3, 4].map(() => (
                <>
                  <Text fontSize={"smaller"} textAlign={"center"}>
                    <Placeholder />
                  </Text>
                  <Text fontSize={"smaller"} textAlign={"center"}>
                    <Placeholder />{" "}
                  </Text>
                  <Text fontSize={"smaller"}>
                    {" "}
                    <Placeholder />
                  </Text>
                  <Text fontSize={"smaller"}>
                    {" "}
                    <Placeholder />
                  </Text>
                  <Text fontSize={"smaller"}>
                    <Placeholder />
                  </Text>
                </>
              ))
            : tableData?.slice(0, increment)?.map((data) => (
                <DeviceDetailsAccordianItem
                  value={data.id}
                  trigger={<Trigger data={data} />}
                  content={
                    selectedNvtId === data.id ? (
                      <View>
                        <RelatedAssetsTable
                          vulnId={data.id}
                          selectedAssetId={selectedAssetId}
                          setSelectedAssetId={setSelectedAssetId}
                        />
                      </View>
                    ) : null
                  }
                ></DeviceDetailsAccordianItem>
              ))}
        </Flex>
      </Accordion.Container>
    </ScrollView>
  );
}
