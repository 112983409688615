import { useQueries } from "@tanstack/react-query";
import { CyberScoreCombined } from "../types/CyberScoreCombined";
import { useMemo } from "react";
import { imageToBase64 } from "../services/imageToBase64";

export function useLinkedinProfilePics(
  cyberScore: CyberScoreCombined | undefined
) {
  const profilePicUrls = useMemo(() => {
    return [cyberScore?.Employee1, cyberScore?.Employee2].filter(
      (e) => !!e
    ) as string[];
  }, [cyberScore]);

  const queries = useQueries({
    queries: profilePicUrls.map((url) => ({
      queryKey: ["li-profile-pic", url],
      queryFn: async () => imageToBase64(url),
      enabled: !!url,
    })),
  });

  const profilePics = queries
    .map((query) => query.data)
    .filter(Boolean) as string[];

  const isLoading = queries.some((query) => query.isLoading);

  return {
    profilePics,
    isLoading,
  };
}
