import { postRequest } from "./restAPI";

export type VulnStatus = "To Do" | "Ignored" | "In Progress" | "Resolved";

type UpdateNVTStatusOptions = {
  memberId: string;
  companyId: number;
  assetId: string;
  vulnId: string;
};

export async function updateNvtStatus(
  status: VulnStatus,
  options: UpdateNVTStatusOptions
) {
  const updateObject = [
    {
      Engine: "LAI",
      CUID: options.companyId,
      Asset: options.assetId,
      VulnID: options.vulnId,
      Status: status,
    },
  ];

  const response = await postRequest("/update-vulnerability-status", {
    body: {
      memberId: options.memberId,
      updateObject,
    },
  });

  const text = await response.body.text();
  return JSON.parse(text);
}
