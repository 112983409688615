import {
  Button,
  CheckboxField,
  Flex,
  Placeholder,
  ScrollView,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { FaCircle, FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import { useReconOutputsQuery } from "../../hooks/useReconOutputsQuery";
import { Company } from "../../types/companies";

type Props = {
  onClick?: (company: Company) => void;
};

export default function CompaniesTable({ onClick }: Props) {
  const { id } = useParams();

  const { companies, isLoading: loadingCompanies } = useCompaniesQuery(2, id);
  const { recons: recon, isLoading: loadingReconOutputs } =
    useReconOutputsQuery({
      groupId: 2,
      campaignId: id,
    });

  const scores = useMemo(() => {
    return (
      companies?.map((c) => {
        const r = recon?.find((r) => r.CUID === c.CUID);
        if (!r) return undefined;
        if (r.Outputs) {
          return r.CyberScore.Score ?? undefined;
        } else return undefined;
      }) ?? []
    );
  }, [recon, companies]);

  const isLoading = loadingCompanies || loadingReconOutputs;

  return (
    <ScrollView maxHeight={"400px"}>
      <View>
        <Table highlightOnHover={true} size="small">
          <TableHead>
            <TableRow>
              <TableCell width={"20%"} fontSize={"smaller"} as="th">
                Name
              </TableCell>
              <TableCell
                textAlign={"center"}
                width={"15%"}
                fontSize={"smaller"}
                as="th"
              >
                Breaches
              </TableCell>
              <TableCell
                textAlign={"center"}
                width={"15%"}
                fontSize={"smaller"}
                as="th"
              >
                Cyber Score
              </TableCell>
              <TableCell textAlign={"center"} width={"5%"} as="th"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? [0, 1, 2, 3, 4, 5].map(() => (
                  <TableRow>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                    <TableCell>
                      <Placeholder />
                    </TableCell>
                  </TableRow>
                ))
              : recon &&
                companies &&
                companies.map((c, i) => (
                  <TableRow
                    key={i}
                    onClick={() => {
                      onClick?.(c);
                    }}
                  >
                    <TableCell fontSize={"smaller"}>
                      <Flex alignItems={"center"}>
                        <CheckboxField label="" name="" value="yes" />
                        <Text>{c.CompanyName}</Text>
                      </Flex>
                    </TableCell>
                    <TableCell fontSize={"smaller"} textAlign={"center"}>
                      {c.Employees?.length ?? "-"}
                    </TableCell>
                    <TableCell fontSize={"smaller"} textAlign={"center"}>
                      <Flex
                        justifyContent={"center"}
                        gap={"xxs"}
                        alignItems={"end"}
                      >
                        <Text color={"yellow.60"} fontSize={"smaller"}>
                          <FaCircle />
                        </Text>
                        <Text>{scores[i] ? `${scores[i]}%` : "-"}</Text>
                      </Flex>
                    </TableCell>
                    <TableCell>
                      <Button
                        gap={"xs"}
                        borderRadius={"large"}
                        fontSize={"small"}
                        // onClick={() => navigate("/recon/:cuid")}
                      >
                        <FaEye />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </View>
    </ScrollView>
  );
}
