import React from "react";
import CyberCenterContent from "./CyberCenterContent";
import { Link, Text } from "@aws-amplify/ui-react";

export default function WebsiteSecuritySettingsContent() {
  return (
    <CyberCenterContent
      whatIsItContent={
        <Text>
          Website security settings, like HTTP secure headers, help protect your
          site and visitors. Content Security Policy (CSP) controls what
          resources the site can load, reducing risk of malicious content.
          X-Frame-Options prevent your site from being displayed in a frame,
          averting clickjacking attacks. For any business, their website is the
          public face of the company. Website security maintains client trust by
          protecting their data and ensuring a safe browsing experience.
        </Text>
      }
      whyDoesItMatterContent={
        <Text>
          Caring about website security settings is crucial to prevent hacking,
          data theft, and malicious attacks, ensuring your website remains a
          safe, trustworthy platform. Without proper HTTP secure headers, your
          website could be vulnerable to attacks like cross-site scripting or
          data theft, potentially leading to compromised client data and loss of
          trust.
        </Text>
      }
      howDoYouFixItContent={
        <Text>
          To implement HTTP secure headers on WordPress or similar platforms,
          either use security plugins that automatically add these headers or
          manually add them by modifying your website's .htaccess file or server
          configuration. Professional assistance is recommended for accuracy.
          Find the exact steps in our{" "}
          <Link
            href="https://www.icebergcyber.com/help-center-category/website-security"
            isExternal
            color={"teal.80"}
            fontWeight={"semibold"}
          >
            Help Center
          </Link>

          .{" "}
        </Text>
      }
    />
  );
}
