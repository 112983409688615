export function timeStampToLocaleString(
  timeStamp: string | null | undefined,
  options?: Intl.DateTimeFormatOptions
) {
  if (!timeStamp) return "";
  if (timeStamp.includes("T") && timeStamp.includes("Z"))
    return new Date(timeStamp).toLocaleString("en-CA", {
      timeZoneName: "short",      
      ...options,
    });
  else
    return new Date(timeStamp.replace(" ", "T") + "Z").toLocaleString("en-CA", {
      timeZoneName: "short",
      ...options,
    });
}
