import React from "react";
import CyberCenterContent from "./CyberCenterContent";
import { Link, Text } from "@aws-amplify/ui-react";

export default function WebsiteEncryptionContent() {
  return (
    <CyberCenterContent
      whatIsItContent={
        <Text>
          An SSL certificate encrypts data exchanged between a user's browser
          and your website. This ensures information like login details and
          transactions remain private and secure. SSL certificates and website
          encryption protect client data during transmission, crucial for
          maintaining confidentiality, especially in sensitive sectors like
          accounting, legal, and healthcare.
        </Text>
      }
      whyDoesItMatterContent={
        <Text>
          Without an SSL certificate, your website's data transmission is
          unencrypted. This means sensitive information like client financial
          details, passwords, and personal data can be intercepted and read by
          hackers. The worst-case scenario is a significant data breach leading
          to financial fraud, identity theft of clients, and possibly legal
          consequences for your business. This can be particularly damaging for
          small businesses, where their reputation with client confidentiality is
          paramount.
        </Text>
      }
      howDoYouFixItContent={
        <Text>
          To get an SSL certificate, choose a reputable certificate provider.
          Many web hosting services offer SSL certificates, sometimes for free.
          Follow their process to install and activate the certificate on your
          website. Find the exact steps in our{" "}
          <Link
            href=" https://www.icebergcyber.com/help-center-category/website-security"
            isExternal
            color={"teal.80"}
            fontWeight={"semibold"}
          >
            Help Center
          </Link>
          .
        </Text>
      }
    />
  );
}
