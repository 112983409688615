import { Grid, Loader, Tabs, View } from "@aws-amplify/ui-react";
import React, { Suspense } from "react";
import AssetsPanel from "./AssetsPanel";
import CyberReportDetailsTable from "./CyberScoreDetailsTables";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import VulnerabilitiesPanel from "./VulnerabilitiesPanel";
import CyberAutdit from "./CyberAudit";
import { ScoreCard } from "../cyber audit/CyberAudit.ScoreCard";

type MonitorCompanyTabsContainerProps = {
  assetId: string | null;
  setAssetId: React.Dispatch<React.SetStateAction<string | null>>;
  nvtId: string | null;
  setNvtId: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function TabsContainer({
  assetId,
  setAssetId,
  nvtId,
  setNvtId,
}: MonitorCompanyTabsContainerProps) {
  const { company } = useMonitorCompanyContext();

  return (
    <Tabs.Container
      defaultValue="cyber-audit"
      onValueChange={() => {
        if (nvtId === null || assetId === null) {
          setNvtId(null);
          setAssetId(null);
        }
      }}
    >
      <Grid height={"100%"} templateRows={"auto minmax(0, 1fr)"} gap={"0"}>
        <Tabs.List>
          <Tabs.Item fontSize={"small"} value="cyber-audit">
            Cyber Audit
          </Tabs.Item>
          <Tabs.Item fontSize={"small"} value="Assets">
            Assets
          </Tabs.Item>
          <Tabs.Item fontSize={"small"} value="Vulnerabilities">
            Vulnerabilities
          </Tabs.Item>
          <Tabs.Item fontSize={"small"} value="Dark Web">
            Dark Web Report
          </Tabs.Item>
          <Tabs.Item fontSize={"small"} value="Score Card">
            Score Card
          </Tabs.Item>
        </Tabs.List>
        <View>
          <AssetsPanel
            assetId={assetId}
            setAssetId={setAssetId}
            nvtId={nvtId}
            setNvtId={setNvtId}
          />

          <Tabs.Panel value="Vulnerabilities">
            <VulnerabilitiesPanel
              assetId={assetId}
              setAssetId={setAssetId}
              nvtId={nvtId}
              setNvtId={setNvtId}
            />
          </Tabs.Panel>
          <Tabs.Panel value="Dark Web">
            <View columnStart={"0"} columnSpan={"2"}>
              <Suspense fallback={<Loader>Loading Cyber Score Details</Loader>}>
                <CyberReportDetailsTable
                  domain={company?.EmailDomain as string | undefined}
                />
              </Suspense>
            </View>
          </Tabs.Panel>
          <Tabs.Panel value="cyber-audit">
            <View columnStart={"0"} columnSpan={"2"}>
              <CyberAutdit />
            </View>
          </Tabs.Panel>
          <Tabs.Panel value="Score Card">
            <View columnStart={"0"} columnSpan={"2"}>
              <ScoreCard />
            </View>
          </Tabs.Panel>
        </View>
      </Grid>
    </Tabs.Container>
  );
}
