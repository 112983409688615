import React from "react";
import { Button, Flex, Grid, Heading, Text } from "@aws-amplify/ui-react";
import StepCards from "../components/prospecting/StepCards";
import { Modal } from "../components/Modal";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


export default function ScoreManagement() {

    const tutorialModal = React.useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();


    return (
        <>

            <Modal ref={tutorialModal} size="medium">
                <Flex justifyContent={"right"} padding={"0"}>
                    <Button color="white"
                        backgroundColor={"red.60"}
                        borderRadius={"medium"} onClick={() => tutorialModal.current?.close()}> <FaTimes /></Button>
                </Flex>

                <iframe src="https://scribehow.com/embed/Create_your_1st_Cyber_Score_Card___1tx4SlumQW-rFGT99YQv8g" style={{ WebkitTransform: 'scale(0.9)', border: "0" }} width="100%" height="560"></iframe>
            </Modal>

            <Flex direction={"column"} gap="0" padding={"medium"} >
                <Flex height={"52px"} padding={"small large 0 large"} justifyContent={"right"}>
                    <Flex gap={"xxs"}>
                    </Flex>
                </Flex>

        <Flex
          width={"100%"}
          gap="large"
          direction={"column"}
          padding={"0 large large large"}
        >
          <Heading
            textAlign={"center"}
            fontSize={"2.5em"}
            lineHeight={"0.75em"}
          >
            CYBER POWERED PROSPECTING
          </Heading>

                    <Grid gap={"small"} padding={"medium"} templateColumns={{ base: "1fr", medium: "1fr 1fr 1fr" }} alignItems={"center"} alignContent={"center"}>

                        <StepCards stepNumber={"STEP 1"} borderColor={"#42cefb"} action={"OUTREACH"} icebergAction={"Score Card"}>
                            <Flex direction={"column"} justifyContent={"space-between"} padding={"large"} height={"100%"}>
                                <Flex direction={"column"} textAlign={"center"} justifyContent={"center"} >
                                    <Button onClick={() => {
                                        tutorialModal.current?.showModal();
                                    }}
                                        variation="primary"
                                        borderRadius={"medium"}
                                        backgroundColor={"white"}
                                        isFullWidth
                                        color={"neutral.90"}
                                        boxShadow={"medium"}
                                        fontWeight={"medium"}
                                        borderColor={"#42cefb"}
                                    >
                                        What is a Score Card
                                    </Button>
                                    <Button onClick={() => {
                                        tutorialModal.current?.showModal();
                                    }}
                                        variation="primary"
                                        borderRadius={"medium"}
                                        backgroundColor={"white"}
                                        isFullWidth
                                        color={"neutral.90"}
                                        fontWeight={"medium"}
                                        boxShadow={"medium"}
                                        borderColor={"#42cefb"}
                                    >
                                        How do you create a Score Card
                                    </Button>
                                    <Button onClick={() => {
                                        tutorialModal.current?.showModal();
                                    }}
                                        variation="primary"
                                        borderRadius={"medium"}
                                        backgroundColor={"white"}
                                        isFullWidth
                                        color={"neutral.90"}
                                        fontWeight={"medium"}
                                        boxShadow={"medium"}
                                        borderColor={"#42cefb"}
                                    >
                                        How to use a Score Card to break the ice with a prospect
                                    </Button>
                                </Flex>

                                <Flex direction={"column"}>
                                    <Text textAlign={"center"}>Ready to get started 👇</Text>
                                    <Button onClick={() => navigate("/score-card")} variation="primary">Create a Score Card</Button>
                                </Flex>

                            </Flex>
                        </StepCards>
                        <StepCards stepNumber={"STEP 2"} borderColor={"#1b6eff"} action={"NUTURE"} icebergAction={"Cyber Audit"}>

                            <Flex direction={"column"} justifyContent={"space-between"} padding={"large"} height={"100%"}>
                                <Flex direction={"column"} textAlign={"center"} justifyContent={"center"} >
                                    <Button onClick={() => {
                                        tutorialModal.current?.showModal();
                                    }}
                                        variation="primary"
                                        borderRadius={"medium"}
                                        backgroundColor={"white"}
                                        isFullWidth
                                        color={"neutral.90"}
                                        fontWeight={"medium"}
                                        boxShadow={"medium"}
                                        borderColor={"#1b6eff"}
                                    >
                                        What is a Cyber Audit
                                    </Button>
                                    <Button onClick={() => {
                                        tutorialModal.current?.showModal();
                                    }}
                                        variation="primary"
                                        borderRadius={"medium"}
                                        backgroundColor={"white"}
                                        isFullWidth
                                        color={"neutral.90"}
                                        fontWeight={"medium"}
                                        boxShadow={"medium"}
                                        borderColor={"#1b6eff"}
                                    >
                                        How do you create Cyber Audit
                                    </Button>
                                    <Button onClick={() => {
                                        tutorialModal.current?.showModal();
                                    }}
                                        variation="primary"
                                        borderRadius={"medium"}
                                        backgroundColor={"white"}
                                        isFullWidth
                                        color={"neutral.90"}
                                        fontWeight={"medium"}
                                        boxShadow={"medium"}
                                        borderColor={"#1b6eff"}
                                    >
                                        How to use a Cyber Audit to nurture a prospect
                                    </Button>
                                </Flex>

                                <Flex direction={"column"}>
                                    <Text textAlign={"center"}>Ready to get started 👇</Text>
                                    <Button onClick={() => navigate("/cyber-audit")} variation="primary">Create a Cyber Audit</Button>
                                </Flex>
                            </Flex>

                        </StepCards>
                        <StepCards stepNumber={"STEP 3"} borderColor={"#144ee3"} action={"PITCH"} icebergAction={"Your Services"}>
                            {" "}
                        </StepCards>

                    </Grid>
                </Flex>
            </Flex >
        </>
    )
}
