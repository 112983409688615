import { Button, Card, Flex, Grid, Text } from '@aws-amplify/ui-react'
import React from 'react'
import { FaCog, FaRocket, FaTimes } from 'react-icons/fa'


type SensorModalProps = {
    onClose?: () => void;
};

export default function SensorModal({
    onClose,
}: SensorModalProps) {
    return (


        <>
            <Flex justifyContent={"right"} padding={"0"}>
                <Button color="white"
                    backgroundColor={"red.60"}
                    borderRadius={"medium"} onClick={onClose}> <FaTimes /></Button>
            </Flex>


            <Grid
                gap="large"
                justifyContent={"center"}
                templateColumns={"1fr auto 1fr"}
                alignItems={"center"}
                padding={"small"}
            >
                <Card borderRadius={"medium"} boxShadow={"large"}>
                    <Flex height={"100%"} width={"200px"} direction={"column"} gap="medium">
                        <Text fontSize={"small"} textAlign={"center"}>Order your first Sensor by starting a subscription</Text>
                        <a
                            href="https://buy.stripe.com/cN23dS0317Ie6ac3cu"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <Button
                                size="small"
                                borderRadius={"medium"}
                                variation="primary"
                                gap="xs"
                                whiteSpace={"nowrap"}
                                backgroundColor={"#144ee3"}
                            >
                                <FaRocket /> Start a Subscription
                            </Button>
                        </a>



                    </Flex>
                </Card>

                <Text fontWeight={"bold"} textAlign={"center"}>OR</Text>
                <Card borderRadius={"medium"} boxShadow={"large"}>
                    <Flex height={"100%"} width={"200px"} direction={"column"} gap="medium">
                        <Text fontSize={"small"} textAlign={"center"}>Add another Sensor to your subscription</Text>

                        <a
                            href="https://billing.stripe.com/p/login/eVa6p43ypcIo3fi288"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <Button whiteSpace={"nowrap"} borderRadius={"medium"} size="small" variation="primary" gap="xs">
                                <FaCog /> Manage Subscription
                            </Button>
                        </a>
                    </Flex>
                </Card>






            </Grid>
        </>
    )
}
