import React from "react";
import logo from "../../src/assets/images/logo.png";
import {
  Flex,
  Grid,
  ResponsiveStyle,
  Text,
  Image,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { FaCog, FaPercentage, FaSignOutAlt } from "react-icons/fa";
import { Property } from "csstype";
import Media from "react-media";
import { Link, useNavigate } from "react-router-dom";
import { FaGaugeHigh } from "react-icons/fa6";

export default function Sidebar({
  area,
}: {
  area?: ResponsiveStyle<Property.GridArea>;
}) {
  const navigate = useNavigate();

  const { signOut } = useAuthenticator((context) => {
    return [context.user];
    navigate("/");
  });

  return (
    <>
      {/* Sidebar */}
      <Media queries={{ large: { maxWidth: 992 } }}>
        {(matches) =>
          matches.large ? (
            <></>
          ) : (
            <>
              <Flex
                width={"250px"}
                height={"100dvh"}
                area={area}
                direction="column"
                backgroundImage={
                  "linear-gradient(to bottom right, var(--amplify-colors-blue-60), var(--amplify-colors-blue-100))"
                }
                style={{
                  borderRight: "3px solid var(--amplify-colors-neutral-40",
                }}
              >
                <Flex
                  padding={"large large 0 large"}
                  onClick={() => navigate("/getting-started")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Image alt="Cyber Score logo" src={logo} maxHeight={"60px"} />
                </Flex>

                <Flex direction="column" gap="small" padding="large">
                  <Link
                    color="white"
                    to="/score-card"
                    style={{ textDecoration: "none" }}
                  >
                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <FaPercentage />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Score Card{" "}
                      </Text>
                    </Grid>
                  </Link>
                  <Link
                    color="white"
                    to="/cyber-audit"
                    style={{ textDecoration: "none" }}
                  >
                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <FaGaugeHigh />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Cyber Audit{" "}
                      </Text>
                    </Grid>
                  </Link>

                  {/* <Grid
                    templateColumns={"20px auto"}
                    gap={"xxs"}
                    alignItems={"flex-start"}
                    onClick={() => navigate("/cyber-audit")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Text color={"white"}>
                      <FaGaugeHigh />
                    </Text>
                    <Text color={"white"} fontSize={"small"}>
                      Cyber Audit{" "}
                      <TooltipHoverView
                        TooltipContent={"New Feature!"}
                        color={"red.60"}
                        children={<FaStarOfLife fontSize={"smaller"} />}
                      />
                    </Text>
                  </Grid> */}

                  {/* <Link color="white" to="/recon">
                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <GiFedora />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Recon{" "}
                        <TooltipHoverView
                          TooltipContent={"New Feature!"}
                          color={"red.60"}
                        />
                      </Text>
                    </Grid>
                  </Link> */}
                  {/* <Grid
                    templateColumns={"20px auto"}
                    gap={"xxs"}
                    alignItems={"flex-start"}
                    onClick={() => navigate("/monitor")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Text color={"white"}>
                      <FaFolderOpen />
                    </Text>
                    <Text color={"white"} fontSize={"small"}>
                      Directory{" "}
                      <TooltipHoverView
                        TooltipContent={"Coming Soon!"}
                        color={"white"}
                        children={<FaClock fontSize={"smaller"} />}
                      />
                    </Text>
                  </Grid> */}

                  {/* <Grid
                    templateColumns={"20px auto"}
                    gap={"xxs"}
                    alignItems={"flex-start"}
                    onClick={() => navigate("/monitor")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Text color={"white"}>
                      <FaChartLine />
                    </Text>
                    <Text color={"white"} fontSize={"small"}>
                      Performance{" "}
                      <TooltipHoverView
                        TooltipContent={"Coming Soon!"}
                        color={"white"}
                        children={<FaClock fontSize={"smaller"} />}
                      />
                    </Text>
                  </Grid> */}

                  {/* <Grid
                    templateColumns={"20px auto"}
                    gap={"xxs"}
                    alignItems={"flex-start"}
                    onClick={() => navigate("/monitor")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Text color={"white"}>
                      <GiMining />
                    </Text>
                    <Text color={"white"} fontSize={"small"}>
                      Prospecting{" "}
                      <TooltipHoverView
                        TooltipContent={"Coming Soon!"}
                        color={"white"}
                        children={<FaClock fontSize={"smaller"} />}
                      />
                    </Text>
                  </Grid> */}
                </Flex>

                {/* <Divider
                  style={{ borderColor: "var(--amplify-colors-neutral-40" }}
                /> */}

                <Flex
                  height={"100%"}
                  direction="column"
                  gap="small"
                  padding="xxxs large large large"
                  justifyContent={"end"}
                >
                  {/* <Flex direction={"column"}>
                    <Link
                      color="white"
                      to="/monitor"
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        templateColumns={"20px auto"}
                        gap={"xxs"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Text color={"white"}>
                          <FaHeartbeat />
                        </Text>
                        <Text color={"white"} fontSize={"small"}>
                          {"Monitor "}
                          <TooltipHoverView
                            TooltipContent={"New Features!"}
                            color={"red.60"}
                          />
                        </Text>
                      </Grid>
                    </Link>

                    <Link
                      color="white"
                      to="/portfolio"
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        templateColumns={"20px auto"}
                        gap={"xxs"}
                        alignItems={"flex-start"}
                        onClick={() => navigate("/portfolio")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Text color={"white"}>
                          <FaSatelliteDish />
                        </Text>
                        <Text color={"white"} fontSize={"small"}>
                          Portfolio
                        </Text>
                      </Grid>
                    </Link>
                  </Flex>
                  <Flex direction="column">
                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      onClick={() => navigate("/monitor")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <BsFillRouterFill />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Assets{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"white"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </Grid>

                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      onClick={() => navigate("/monitor")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <LuPackageSearch />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Vulnerabilities{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"white"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </Grid>

                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      onClick={() => navigate("/monitor")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <BsMailbox2Flag />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Email Addresses{" "}
                        <TooltipHoverView
                          TooltipContent={"Coming Soon!"}
                          color={"white"}
                          children={<FaClock fontSize={"smaller"} />}
                        />
                      </Text>
                    </Grid>
                  </Flex> */}

                  <Flex direction={"column"}>
                    <Link
                      color="white"
                      to="/settings"
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        templateColumns={"20px auto"}
                        gap={"xxs"}
                        alignItems={"flex-start"}
                        onClick={() => navigate("/settings")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Text color={"white"}>
                          <FaCog />
                        </Text>
                        <Text color={"white"} fontSize={"small"}>
                          Settings
                        </Text>
                      </Grid>
                    </Link>
                    <Grid
                      templateColumns={"20px auto"}
                      gap={"xxs"}
                      alignItems={"flex-start"}
                      onClick={() => signOut()}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text color={"white"}>
                        <FaSignOutAlt />
                      </Text>
                      <Text color={"white"} fontSize={"small"}>
                        Sign Out
                      </Text>
                    </Grid>
                  </Flex>
                </Flex>
              </Flex>
            </>
          )
        }
      </Media>
    </>
  );
}
