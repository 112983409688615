import { Asset } from "../types/assetInventoryApi";

export function getAssetAge(asset: Asset): number {
  if (!asset.MAC_DATE) return 0;
  const assetBirthDate = new Date(asset.MAC_DATE).getTime();
  const today = new Date().getTime();
  const ageMilliseconds = today - assetBirthDate;
  const ageYears = ageMilliseconds / 1000 / 60 / 60 / 24 / 365;
  return ageYears;
}
