import { NVT } from "../types/assetInventoryApi";

export function getVulnImpact(nvt: NVT) {
  switch (nvt.severity) {
    case 1:
      return "High";
    case 2:
      return "Medium";
    case 3:
      return "Low";
    default:
      return "Low";
  }
}
