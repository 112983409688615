import { Button, Card, Flex, Tabs } from "@aws-amplify/ui-react";
import React from "react";
import CompaniesTable from "../monitor/CompaniesTable";
import SensorsTable from "./SensorsTable";
import BrandingOptions from "../getting-started/BrandingOptions";

type CyberAuditTableProps = {
  showModal?: () => void;
};

export default function CyberAuditCard({ showModal }: CyberAuditTableProps) {
  return (
    <Card borderRadius={"large"} boxShadow={"large"}>
      <Flex direction="column" gap="2rem">
        <Tabs.Container defaultValue="cyber-audits">
          <Tabs.List>
            <Tabs.Item fontSize={"smaller"} value="cyber-audits">
              Cyber Audits
            </Tabs.Item>
            <Tabs.Item fontSize={"smaller"} value="sensors">
              Sensors
            </Tabs.Item>
            <Tabs.Item fontSize={"smaller"} value="branding">
              Branding
            </Tabs.Item>

            <Flex flex={1} justifyContent={"right"} alignItems={"center"}>
              <Button
                onClick={showModal}
                padding={"xs"}
                variation="primary"
                fontSize={"xs"}
                borderRadius={"medium"}
                size="small"
              >
                Add Sensor
              </Button>
            </Flex>
          </Tabs.List>
          <Tabs.Panel fontSize={"smaller"} value="cyber-audits">
            <>
              {/* <CyberAuditTable /> */}
              <CompaniesTable></CompaniesTable>
            </>
          </Tabs.Panel>
          <Tabs.Panel fontSize={"smaller"} value="sensors">
            <SensorsTable />
          </Tabs.Panel>
          <Tabs.Panel fontSize={"smaller"} value="branding">
            <BrandingOptions />
          </Tabs.Panel>
        </Tabs.Container>
      </Flex>
    </Card>
  );
}
