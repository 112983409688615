import React from "react";
import MonitorCompanyContent from "../components/monitorcompany/MonitorCompanyContent";
import { MonitorCompanyChartsContextProvider } from "../components/monitorcompany/MonitorCompanyChartsContextProvider";
import { MonitorCompanyFilterContextProvider } from "../hooks/useMonitorCompanyFilterContext";
import { MonitorCompanyContextProvider } from "../hooks/useMonitorCompanyContext";
// import TopCyberRisks from '../components/monitorcompany/TopCyberRisks'

export default function MonitorCompany() {
  return (
    <MonitorCompanyFilterContextProvider>
      <MonitorCompanyContextProvider>
        <MonitorCompanyChartsContextProvider>
          <MonitorCompanyContent />
        </MonitorCompanyChartsContextProvider>
      </MonitorCompanyContextProvider>
    </MonitorCompanyFilterContextProvider>
  );
}
