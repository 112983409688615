import { Card, Grid, Flex, View } from "@aws-amplify/ui-react";
import React from "react";
import TableTitle from "../styling/TableTitle";
import TopRisksandPrioritiesCharts from "./TopRisksandPrioritiesCharts";
import { useMonitorCompanyChartsContext } from "./MonitorCompanyChartsContextProvider";

export default function Charts() {
  const { ITLandscapeChart, AssetAgeChart } = useMonitorCompanyChartsContext();

  return (
    <Card variation="elevated" padding={"medium"} borderRadius={"large"}>
      <Grid
        alignItems={"center"}
        justifyContent={"space-around"}
        templateColumns={{ base: "1fr", large: "auto auto auto" }}
        gap={"large"}
      >
        <Flex
          direction={"column"}
          gap={"xs"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <View alignSelf={"start"}>
            <TableTitle title={"Risk Landscape"} />
          </View>
          <TopRisksandPrioritiesCharts />
        </Flex>
        <Flex
          direction={"column"}
          gap={"xs"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <View alignSelf={"start"}>
            <TableTitle title={"IT Landscape"} />
          </View>
          <ITLandscapeChart />
        </Flex>
        {/* <View><AssetAgeGraph assets={allAssets} /></View> */}
        <Flex
          direction={"column"}
          gap={"xs"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <View alignSelf={"start"}>
            <TableTitle title={"Asset Age (Years Old)"} />
          </View>
          <AssetAgeChart />
        </Flex>
      </Grid>
    </Card>
  );
}
