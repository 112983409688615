import { Autocomplete, Button, Flex, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { useScannersQuery } from "../../hooks/useScannersQuery";

import "../../style/cyber-audit.css";

type SensorModalProps = {
  setScannerId: (id: number | undefined) => void;
  showModal?: () => void;
};

export default function SensorSelect({
  showModal,
  setScannerId,
}: SensorModalProps) {
  const { scanners, isLoading } = useScannersQuery();

  const [value, setValue] = React.useState("");

  const options = useMemo(() => {
    return (
      scanners?.map((s) => ({
        id: s.ScannerID.toString(),
        label:
          s.Type === "Sensor"
            ? s.SerialNumber ?? "No Serial Number"
            : s.HostID ?? "No Host ID",
      })) ?? []
    );
  }, [scanners]);

  return (
    <>
      <Flex width={"100%"}>
        <Autocomplete
          label=""
          width={"100%"}
          labelHidden={true}
          isLoading={isLoading}
          options={options}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            const selectedOption = options.find(
              (o) => o.label === e.target.value
            );
            setScannerId(
              selectedOption ? Number(selectedOption.id) : undefined
            );
          }}
          onSelect={(option) => {
            setValue(option.label);
            setScannerId(Number(option.id));
          }}
          onClear={() => setValue("")}
          optionFilter={(option, value) => {
            return option.label.toLowerCase().includes(value.toLowerCase());
          }}
          renderOption={(option) => <Text>{option.label}</Text>}
        ></Autocomplete>
        {/* <SelectField
          flex={3}
          width={"100%"}
          label=""
          size="small"
          labelHidden={true}
          value={scannerId?.toString()}
          onChange={(e) => setScannerId(Number(e.target.value))}
        >
          <option selected disabled>
            Choose a Sensor
          </option>
          {scanners?.map((s) => (
            <option value={s.ScannerID}>
              <Text>{s.Type === "Sensor" ? s.SerialNumber : s.HostID}</Text>
            </option>
          ))}
        </SelectField> */}
        <Button size="small" onClick={showModal} variation="primary" flex={1}>
          + Add a Sensor
        </Button>
      </Flex>
    </>
  );
}
