import { Button } from "@aws-amplify/ui-react";
import React from "react";
import { FaTimes } from "react-icons/fa";

type Props = {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  hasCloseButton?: boolean;
} & React.DetailedHTMLProps<
  React.DialogHTMLAttributes<HTMLDialogElement>,
  HTMLDialogElement
>;

export const Modal = React.forwardRef<HTMLDialogElement, Props>(
  ({ children, size, hasCloseButton = false }, ref) => {
    const width =
      size === "small" ? "600px" : size === "medium" ? "800px" : "1000px";

    return (
      <dialog
        ref={ref}
        className="modal"
        style={{ maxWidth: width, padding: "1.5em", position: "relative" }}
      >
        {ref && hasCloseButton ? (
          <Button
            borderRadius={"medium"}
            position={"absolute"}
            variation="destructive"
            padding={"xs"}
            top={"xs"}
            right={"xs"}
            style={{ aspectRatio: 1 }}
            onClick={() => {
              (ref as React.RefObject<HTMLDialogElement>).current?.close();
            }}
          >
            <FaTimes />
          </Button>
        ) : null}
        {children}
      </dialog>
    );
  }
);
