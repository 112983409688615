import * as React from "react";
import { Flex, Button, TextAreaField, TextField } from "@aws-amplify/ui-react";
import { FaSave, FaTimes } from "react-icons/fa";
import TableTitle from "../styling/TableTitle";
import {
  Company,
  InsertCompanyRequest,
  UpdateCompanyRequest,
} from "../../types/companies";
import { useScannersQuery } from "../../hooks/useScannersQuery";

export type CompanyPortfolioProps = {
  groupId: 1 | 2;
  onClose: () => void;
} & (
  | {
      Operation: "update";
      onSave: (
        updateCompanyRequest: UpdateCompanyRequest
      ) => void | Promise<void>;
      selectedCompany: Company | undefined;
    }
  | {
      Operation: "insert";
      onSave: (
        insertCompanyRequest: InsertCompanyRequest
      ) => void | Promise<void>;
    }
);

export default function CompanyPortfolio(props: CompanyPortfolioProps) {
  const { Operation, onSave, onClose, groupId } = props;

  const [isSaving, setIsSaving] = React.useState(false);

  const selectedCompany = React.useMemo(() => {
    if (Operation === "update") {
      return props.selectedCompany;
    }
    return;
  }, [props, Operation]);

  React.useEffect(() => {
    if (selectedCompany) setCompany(selectedCompany);
  }, [selectedCompany]);

  const { scanners } = useScannersQuery();

  const companyScanners = scanners?.filter(
    (scanner) => scanner.CUID === selectedCompany?.CUID
  );

  const createUpdateCompanyRequest = (
    oldCompany: Company,
    newCompany: Company
  ): UpdateCompanyRequest => {
    const request: UpdateCompanyRequest = {
      Operation: "update",
      GroupID: 1,
      CUID: oldCompany.CUID,
    };

    // go over rest of attributes and if different add them to the request object
    if (oldCompany.CompanyName !== newCompany.CompanyName) {
      request.CompanyName = newCompany.CompanyName;
    }
    if (oldCompany.EmailDomain !== newCompany.EmailDomain) {
      request.EmailDomain = newCompany.EmailDomain;
    }
    if (oldCompany.Website !== newCompany.Website) {
      request.Website = newCompany.Website;
    }
    if (oldCompany.Address !== newCompany.Address) {
      request.Address = newCompany.Address;
    }
    if (oldCompany.City !== newCompany.City) {
      request.City = newCompany.City;
    }
    if (oldCompany.Region !== newCompany.Region) {
      request.Region = newCompany.Region;
    }
    if (oldCompany.Country !== newCompany.Country) {
      request.Country = newCompany.Country;
    }
    if (oldCompany.PostalCode !== newCompany.PostalCode) {
      request.PostalCode = newCompany.PostalCode;
    }
    if (
      JSON.stringify(oldCompany.PublicIPs ?? []) !==
      JSON.stringify(newCompany.PublicIPs ?? [])
    ) {
      request.PublicIPs = newCompany.PublicIPs;
    }
    // still need to handle email requests

    return request;
  };

  const createInsertCompanyRequest = (
    company: Company,
    groupId: 1 | 2
  ): InsertCompanyRequest => {
    const request: InsertCompanyRequest = {
      Operation: "insert",
      GroupID: groupId,
      CompanyName: company.CompanyName,
      EmailDomain: company.EmailDomain,
      Website: company.Website,
      Address: company.Address,
      City: company.City,
      Region: company.Region,
      Country: company.Country,
      PostalCode: company.PostalCode,
      Industry: company.Industry,
      PublicIPs: company.PublicIPs,
    };

    return request;
  };

  const [company, setCompany] = React.useState<Company | Omit<Company, "CUID">>(
    Operation === "insert"
      ? {
          CompanyName: "",
          EmailDomain: "",
          Website: "",
          Address: "",
          City: "",
          Region: "",
          Country: "",
          PostalCode: "",
          Industry: "",
          Location: "",
          Employees: [],
          PublicIPs: [],
        }
      : {
          CompanyName: "",
          EmailDomain: "",
          Website: "",
          Address: "",
          City: "",
          Region: "",
          Country: "",
          PostalCode: "",
          Industry: "",
          Location: "",
          Employees: [],
          PublicIPs: [],
        }
  );

  return (
    <Flex direction={"column"} width={"100%"}>
      <Flex justifyContent={"space-between"}>
        <TableTitle title={"Company Details"} />
        <Flex gap={"xs"}>
          <Button
            color={"white"}
            data-attr="save-company-button"
            backgroundColor={"blue.60"}
            borderRadius={"medium"}
            isLoading={isSaving}
            onClick={async () => {
              setIsSaving(true);
              if (Operation === "update" && selectedCompany !== undefined) {
                await onSave(
                  createUpdateCompanyRequest(
                    selectedCompany,
                    company as Company
                  )
                );
              } else if (Operation === "insert") {
                await onSave(
                  createInsertCompanyRequest(company as Company, groupId)
                );
              }
              setIsSaving(false);
              onClose();
            }}
          >
            <FaSave />
          </Button>
          <Button
            color="white"
            backgroundColor={"red.60"}
            borderRadius={"medium"}
            onClick={() => {
              onClose();
            }}
          >
            <FaTimes />
          </Button>
        </Flex>
      </Flex>

      <Flex direction={"column"} gap="xxs">
        <TextField
          label="Company Name"
          size="small"
          fontSize={"smaller"}
          gap={"xxxs"}
          name="last_name"
          placeholder="ABC Corp."
          inputStyles={{ fontSize: "small" }}
          value={company.CompanyName ?? ""}
          onChange={(event) => {
            setCompany((prev) => ({
              ...prev,
              CompanyName: event.target.value,
            }));
          }}
        />

        <TextField
          label="Domain"
          size="small"
          fontSize={"smaller"}
          gap={"xxxs"}
          name="last_name"
          placeholder="admin@abccorp.com"
          inputStyles={{ fontSize: "small" }}
          value={company.EmailDomain ?? ""}
          onChange={(event) => {
            setCompany((prev) => ({
              ...prev,
              EmailDomain: event.target.value,
            }));
          }}
        />
        <Flex direction={"column"}>
          <Flex>
            <TextField
              flex={1}
              label="Address"
              size="small"
              fontSize={"smaller"}
              gap={"xxxs"}
              name="address"
              placeholder=""
              inputStyles={{ fontSize: "small" }}
              value={company.Address ?? ""}
              onChange={(event) => {
                setCompany((prev) => ({
                  ...prev,
                  Address: event.target.value,
                }));
              }}
            />
            <TextField
              label="City"
              size="small"
              fontSize={"smaller"}
              gap={"xxxs"}
              name="city"
              placeholder=""
              inputStyles={{ fontSize: "small" }}
              value={company.City ?? ""}
              onChange={(event) => {
                setCompany((prev) => ({
                  ...prev,
                  City: event.target.value,
                }));
              }}
            />
            <TextField
              label="Region"
              size="small"
              fontSize={"smaller"}
              gap={"xxxs"}
              name="region"
              placeholder=""
              inputStyles={{ fontSize: "small" }}
              value={company.Region ?? ""}
              onChange={(event) => {
                setCompany((prev) => ({
                  ...prev,
                  Region: event.target.value,
                }));
              }}
            />
          </Flex>
          <Flex>
            <TextField
              label="Country"
              size="small"
              fontSize={"smaller"}
              gap={"xxxs"}
              name="country"
              placeholder=""
              inputStyles={{ fontSize: "small" }}
              value={company.Country ?? ""}
              onChange={(event) => {
                setCompany((prev) => ({
                  ...prev,
                  Country: event.target.value,
                }));
              }}
            />
            <TextField
              label="Postal Code"
              size="small"
              fontSize={"smaller"}
              gap={"xxxs"}
              name="postal-code"
              placeholder=""
              inputStyles={{ fontSize: "small" }}
              value={company.PostalCode ?? ""}
              onChange={(event) => {
                setCompany((prev) => ({
                  ...prev,
                  PostalCode: event.target.value,
                }));
              }}
            />
          </Flex>
          <TextAreaField
            disabled
            label="List of Public IPs"
            size="small"
            fontSize={"smaller"}
            gap={"xxxs"}
            name="last_name"
            placeholder="Please enter IPs separated by commas"
            rows={2}
            inputStyles={{ fontSize: "small" }}
            value={company.PublicIPs?.join(",")}
            onChange={(event) => {
              setCompany((prev) => ({
                ...prev,
                PublicIPs: event.target.value.split(","),
              }));
            }}
          />
        </Flex>
        <TextAreaField
          disabled
          label="List of Email Addresses"
          size="small"
          fontSize={"smaller"}
          gap={"xxxs"}
          name="last_name"
          placeholder="Please enter email accounts separated by commas"
          rows={2}
          inputStyles={{ fontSize: "small" }}
          value={company.Employees?.map((employee) => employee.Email)}
          // onChange={(event) => {
          //   setCompanyUpdate({
          //     ...companyUpdate,
          //     Details: {
          //       ...companyUpdate.Details,
          //       Emails: event.target.value.split(","),
          //     },
          //   });
          // }} //TODO: Fix the edit emails functionality in scanner table
        />

        <TextField
          label="Serial Number"
          fontSize={"smaller"}
          gap={"xxxs"}
          inputStyles={{ fontSize: "small" }}
          disabled={true}
          value={
            companyScanners !== undefined && companyScanners.length > 0
              ? companyScanners
                  .map((scanner) =>
                    scanner.Type === "Sensor"
                      ? scanner.SerialNumber
                      : scanner.Type === "Container"
                      ? scanner.HostID
                      : ""
                  )
                  .join(", ")
              : "-"
          }
        />
      </Flex>
    </Flex>
  );
}
