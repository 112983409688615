export function getOffsetTimestamp(
  timestamp: string,
  offsetHours: number
): string {
  const formattedTimestamp = timestamp.includes("Z")
    ? timestamp
    : timestamp?.replace(" ", "T") + "Z";

  const timestampMS = new Date(formattedTimestamp).getTime();

  const offsetTimestampFormatted = new Date(
    timestampMS + offsetHours * 1000 * 60 * 60
  ).toISOString();

  const offsetTimestamp = offsetTimestampFormatted
    .replace("T", " ")
    .replace("Z", "")
    .split(".")[0];

  return offsetTimestamp;
}
