import { Asset } from "../types/assetInventoryApi";

export function getAssetCurrentIPRecord(asset: Asset) {
  const sortedIpRecords = asset?.IPs.sort((a, b) => {
    // convert LAST_SEEN values to dates and sort by most recent
    const aDate = new Date(a.LAST_SEEN);
    const bDate = new Date(b.LAST_SEEN);
    return bDate.getTime() - aDate.getTime();
  });

  if (!sortedIpRecords?.length) return null;

  const ipRecord = sortedIpRecords[0];

  return ipRecord;
}
