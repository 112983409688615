import { Card, Flex } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import TableTitle from "../styling/TableTitle";
import { Menu } from "../../pages/ReconCompany";
import EmailSpoofindProtectionInfo from "../../pages/EmailSpoofindProtectionInfo";
import DarkWebEmailBreachesContent from "../../pages/DarkWebEmailBreachesContent";
import SecureEmailGatewayContent from "../../pages/SecureEmailGatewayContent";
import WebsiteSecuritySettingsContent from "../../pages/WebsiteSecuritySettingsContent";
import WordPressPluginSecurityContent from "../../pages/WordPressPluginSecurityContent";
import WebsiteEncryptionContent from "../../pages/WebsiteEncryptionContent";
import WebsiteFirewall from "../../pages/WebsiteFirewall";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";

// redundant (create a folder for all of these I guess)/output of cutEmail function
interface CyberCenterCardProps {
  menu: Menu;
  cyberScoreData?: CyberScoreCombined | undefined;
}

export default function CyberCenterCard({
  menu,
  cyberScoreData,
}: CyberCenterCardProps) {
  // allows for a change to the grid layout on different sizes, needs more use cases and testing
  // const [responsive, setResponsive] = useState({
  //   small: "0fr",
  //   large: "0fr 1fr 2fr 1fr",
  //   xl: "0fr 1fr 2fr 1fr",
  //   xxl: "0fr 1fr 2fr 1fr",
  // });

  // function to pass key to child components (ie, "ESS")
  // function descriptionCut(input: any) {
  //   let spaceIndex = input.indexOf(" ");
  //   let update = input.slice(0, spaceIndex);
  //   return update;
  // }

  // let insightsOnClick = descriptionCut(description);

  const Content = useMemo(
    () =>
      ({ menu }: { menu: Menu }) => {
        switch (menu) {
          case Menu.EmailImpersonationProtection:
            return (
              <EmailSpoofindProtectionInfo cyberScoreData={cyberScoreData} />
            );
          case Menu.DarkWebEmailBreaches:
            return <DarkWebEmailBreachesContent />;
          case Menu.SecureEmailGateway:
            return <SecureEmailGatewayContent />;
          case Menu.WebsiteSecuritySettings:
            return <WebsiteSecuritySettingsContent />;
          case Menu.WordPressPluginSecurity:
            return <WordPressPluginSecurityContent />;
          case Menu.WebsiteEncryption:
            return <WebsiteEncryptionContent />;
          case Menu.WebsiteFirewall:
            return <WebsiteFirewall />;
          default:
            return null;
        }
      },
    []
  );

  return (
    <>
      <Card
        boxShadow={"large"}
        borderRadius={"large"}
        padding={"medium"}
        height={"100%"}
      >
        <Flex justifyContent={"space-between"} paddingBottom={"small"}>
          <TableTitle title={"Cyber Center"} />
        </Flex>
        <Flex direction={"column"} padding={"xs"}>
          {menu === undefined ? (
            <TableTitle title={"Please Select An Insights Card"} />
          ) : (
            <Content menu={menu} />
          )}
        </Flex>
      </Card>
    </>
  );
}
