import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";

import ScoreCardForm from "../components/getting-started/ScoreCardForm";
import ScoreCardBuilder from "../components/getting-started/ScoreCardBuilder";
import ScoreCardBatcher from "../components/getting-started/ScoreCardBatcher";

export default function ScoreCardRoutes() {
  return (
    <>
      <DashboardLayout>
        <Routes>
          <Route index element={<ScoreCardForm />} />
          <Route path="batch" element={<ScoreCardBatcher />} />
          <Route path=":domain" element={<ScoreCardBuilder />} />
        </Routes>
      </DashboardLayout>
    </>
  );
}
