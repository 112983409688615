import * as React from "react";
import { Image, Text, View } from "@react-pdf/renderer";

export const CompanyDetails = ({
  logoUrl,
  companyName,
  website,
}: {
  logoUrl: string | undefined;
  companyName: string | null;
  website: string | null;
}) => (
  <View style={{ gap: "10px" }}>
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {logoUrl !== "" ? (
        <Image
          src={logoUrl}
          style={{
            height: "65px",
            width: "65px",
            objectFit: "contain",
          }}
        ></Image>
      ) : null}
      <View>
        <Text style={{ fontWeight: "bold" }}>{companyName}</Text>
        <Text>{website}</Text>
      </View>
    </View>
  </View>
);
