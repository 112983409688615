import { View, Text, Image } from "@react-pdf/renderer";
import React from "react";

export interface IReportHeader2Props {
  heading: string;
  subheading?: string;
  logoUrl: string;
}

export default function ReportHeader2(props: IReportHeader2Props) {
  const { logoUrl, heading } = props;

  return (
    <View
      style={{
        width: "85%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <Text
        style={{
          fontSize: "24px",
          color: "#FFF",
          fontWeight: "bold",
          textDecoration: "underline",
          textDecorationColor: "#FF343A",
          marginBottom: "15px",
        }}
      >
        {heading}
      </Text>
      {logoUrl ? (
        <Image
          src={logoUrl}
          style={{
            width: "180px",
            height: "50px",
            objectFit: "contain",
            marginBottom: "15px",
          }}
        />
      ) : null}
    </View>
  );
}
