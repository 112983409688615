import { View, Text, Image } from "@react-pdf/renderer";
import React, { useMemo } from "react";
import { Title } from "../../../components/reports/Title";
import { Subtitle } from "../../../components/reports/Subtitle";
import steps from "../../../assets/images/pdf/steps.png";
import agent from "../../../assets/images/pdf/agent black.png";
import { BreachedAccountsTable } from ".";
import { getBreachTableItems } from "../util";
import { PageLayout } from "../../../components/reports/PageLayout";
import { Branding } from "../../../components/monitorcompany/ReportBuilderButton";

type Props = {
  branding: Branding;
  cyberScore: any;
};

export default function MostRecentBreaches({ branding, cyberScore }: Props) {
  const InfoPanel = () => (
    <View
      style={{
        width: "100%%",
        height: "100vh",
        backgroundColor: branding.primaryColor,
        color: branding.fontColor,
        justifyContent: "space-between",
      }}
    >
      <View>
        {branding.yourLogo !== "" ? (
          <Image
            src={branding.yourLogo}
            style={{
              objectFit: "contain",
              height: "70px",
              width: "100%",
            }}
          ></Image>
        ) : null}

        <View style={{ paddingTop: "15px" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
              gap: "5px",
            }}
          >
            <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
              3 STEP ATTACK
            </Text>
          </View>
          <View style={{ paddingTop: "15px" }}>
            <View
              style={{
                alignItems: "center",
                paddingBottom: "20px",
                gap: "5px",
              }}
            >
              <View style={{ height: "200px", width: "auto" }}>
                <Image
                  src={steps}
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                />
              </View>
            </View>

            <View style={{ fontSize: "10.5px", gap: "5px" }}>
              <Text style={{ fontWeight: "medium", color: "red" }}>
                What could go wrong:
              </Text>
              <Text>
                Using off-the-shelf marketing tools, criminals find your
                employees' details, then match them with credentials from the
                Dark Web. Armed with emails & passwords, they effortlessly
                access banking, email, and cloud services. This simple but
                effective method exposes your business to serious financial
                risks and data breaches.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Text
        style={{ fontSize: "10.5px", paddingBottom: "12px" }}
        render={({ pageNumber, totalPages }) =>
          `${new Date().toLocaleString("en-us", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })} | Page ${pageNumber}/${totalPages}`
        }
      />
    </View>
  );

  const tableItems = useMemo(
    () => getBreachTableItems(cyberScore).slice(0, 20),
    [cyberScore]
  );

  return (
    <PageLayout
      reportStyles={branding}
      left={
        <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
          <View
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Title title={"Cyber Report"} reportStyles={branding}></Title>

            <View
              style={{
                padding: "10px 20px 0px 0px",
                gap: "10px",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  gap: "15px",
                }}
              >
                <View
                  style={{
                    gap: "10px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Subtitle
                    icon={agent}
                    titlepart1={"Dark Web"}
                    titlepart2={"Full Report"}
                    color={branding.accentColor}
                  />
                  <Text
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Most Recent{" "}
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: branding.accentColor,
                      }}
                    >
                      Breaches
                    </Text>
                  </Text>
                </View>
                <View style={{ gap: "20px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                        gap: "10px",
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "black",
                          fontSize: "15px",
                          color: "#FF343A",
                        }}
                      >
                        {cyberScore.BreachList.length}
                      </Text>
                      <Text style={{ fontSize: "10px" }}>
                        Email accounts for sale on the Dark Web
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                        gap: "10px",
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "black",
                          fontSize: "15px",
                          color: "#FF343A",
                        }}
                      >
                        {cyberScore.Employees.length}
                      </Text>
                      <Text style={{ fontSize: "10px" }}>
                        Total accounts scanned
                      </Text>
                    </View>
                  </View>

                  <BreachedAccountsTable
                    items={tableItems}
                    reportStyles={branding}
                  />
                </View>
              </View>

              <View
                style={{
                  gap: "2px",
                  fontSize: "10px",
                  paddingBottom: "12px",
                }}
              >
                <Text style={{ textAlign: "right" }}>
                  {branding.slogan ? `${branding.slogan}:` : ""}{" "}
                  {branding.contactName} | {branding.contactEmail}
                </Text>
              </View>
            </View>
          </View>
        </View>
      }
      right={<InfoPanel />}
    ></PageLayout>
  );
}
