import { useCallback, useEffect, useRef } from "react";
import * as echarts from "echarts";

export function useECharts(options: echarts.EChartsOption) {
  const chartRef = useRef<HTMLDivElement | null>(null);
  //   const chartInstanceRef = useRef<echarts.ECharts | null>(null);
  // const [chartInstance, setChartInstance] = useState<echarts.ECharts | null>(
  //   null
  // );
  const chartInstanceRef = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);
      chartInstance.setOption(options);
      // setChartInstance(chartInstance);
      chartInstanceRef.current = chartInstance;
    }
  }, [options]);


  const getImageData = useCallback(() => {
    if (!chartRef.current) {
      return undefined;
    }
    const chartInstance = echarts.init(chartRef.current);
    // chartInstance.setOption({ ...options, animation: false });
    return chartInstance.getDataURL({ pixelRatio: 4 });
  }, [options]);

  return {
    ref: chartRef,
    chartInstance: chartInstanceRef.current,
    getImageData,
  };
}
