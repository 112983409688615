import * as React from "react";
import { Text, View } from "@react-pdf/renderer";

export const ImportantStat = ({
  stat,
  context,
}: {
  stat: string | number;
  context: string;
}) => (
  <View
    style={{
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      gap: "10px",
    }}
  >
    <Text
      style={{
        fontWeight: "black",
        fontSize: "15px",
        color: "#FF343A",
      }}
    >
      {stat}
    </Text>
    <Text style={{ fontSize: "10px" }}>{context}</Text>
  </View>
);
