import { Card, Flex, Tabs } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import AssetsTable from "./AssetsTable";
import DeviceDetails from "./DeviceDetails";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";

type AssetsPanelProps = {
  assetId: string | null;
  setAssetId: React.Dispatch<React.SetStateAction<string | null>>;
  nvtId: string | null;
  setNvtId: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function AssetsPanel({
  nvtId,
  setNvtId,
  assetId,
  setAssetId,
}: AssetsPanelProps) {
  const { assets, nvts } = useMonitorCompanyContext();

  const selectedNvt = useMemo(() => {
    if (!nvtId) return null;
    return nvts?.find((nvt) => nvt.nvt_oid === nvtId);
  }, [nvtId, nvts]);

  const selectedAsset = useMemo(() => {
    if (!assets || assetId === null) return null;
    return assets.find((a) => a.DEVICE_ID === assetId);
  }, [assetId, assets]);



  return (
    <Tabs.Panel flex={1} value="Assets">
      <Flex gap={"large"} height={"100%"}>
        <Card variation="elevated" borderRadius={"large"} flex={1}>
          <AssetsTable
            setAssetId={setAssetId}
            setNVTId={(value) => {
              setNvtId(value);
            }}
            nvt={selectedNvt}
            assetId={assetId}
          ></AssetsTable>
        </Card>

        <Flex direction={"column"} flex={1}>
          <DeviceDetails asset={selectedAsset} selectedNvt={selectedNvt} />
        </Flex>
      </Flex>
    </Tabs.Panel>
  );
}
