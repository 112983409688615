import { Divider, Flex, Text } from "@aws-amplify/ui-react";
import React from "react";

type CyberCenterContentProps = {
  whatIsItContent: React.ReactNode;
  whyDoesItMatterContent: React.ReactNode;
  howDoYouFixItContent: React.ReactNode;
};
export default function CyberCenterContent(props: CyberCenterContentProps) {
  const { whatIsItContent, whyDoesItMatterContent, howDoYouFixItContent } =
    props;

  return (
    <Flex direction="column">
      <Flex direction="column" gap="xs">
        <Flex gap={"xxs"}>
          <Divider width={"6px"} margin={"3px 0 3px 0"} borderRadius={"4px"} opacity={"100"} borderColor={"#42cefb"} backgroundColor="#42cefb" orientation="vertical" />
          <Text fontSize={"smaller"} fontWeight="bold">What is it?</Text>
        </Flex>

        <Text fontSize={"smaller"}> {whatIsItContent}</Text>

      </Flex>

      <Divider />

      <Flex direction="column" gap="xs">
        <Flex gap={"xxs"}>
          <Divider width={"6px"} margin={"3px 0 3px 0"} borderRadius={"4px"} opacity={"100"} borderColor={"#144ee3"} backgroundColor="#144ee3" orientation="vertical" />
          <Text fontSize={"smaller"} fontWeight="bold">Why does it matter?</Text>
        </Flex>

        <Text fontSize={"smaller"}> {whyDoesItMatterContent}</Text>

      </Flex>

      <Divider />

      <Flex direction="column" gap="xs">

        <Flex gap={"xxs"}>
          <Divider width={"6px"} margin={"3px 0 3px 0"} borderRadius={"4px"} opacity={"100"} borderColor={"blue.60"} backgroundColor="blue.60" orientation="vertical" />
          <Text fontSize={"smaller"} fontWeight="bold">How do you fix it?</Text>
        </Flex>

        <Text fontSize={"smaller"}>  {howDoYouFixItContent}</Text>

      </Flex>
    </Flex>
  );
}
