import { Divider, Flex, Heading, Text } from "@aws-amplify/ui-react";
import React from "react";

export default function HeadlineVerticalDivider({
  heading,
  subheading,
  borderColor,
}: {
  heading?: string;
  subheading?: string;
  borderColor?: string;
}) {
  return (
    <Flex gap={"small"}>
      <Divider
        orientation="vertical"
        borderWidth={"large"}
        borderRadius={"large"}
        borderColor={borderColor ?? "primary"}
        opacity={1}
      />

      <Text fontSize={"large"}>
        {heading ? (
          <Heading level={1} fontSize={"medium"} fontWeight={"bold"}>
            {heading}
          </Heading>
        ) : null}
        {subheading ? <Text fontSize={"small"}>{subheading}</Text> : null}
      </Text>
    </Flex>
  );
}
