type CyberCenterDescriptionsType = {
  [key: string]: any;
};

const cyberCenterDescriptions: CyberCenterDescriptionsType = {
  ESS: {
    title: "Email Spoofing Protection",
    "-1": "ESS message Fail ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
    "1": "ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
  },
  BDV: {
    title: "Email Breaches",
    "-1": "BDV message Fail ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
    "1": "BDV message Pass ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
  },
  SEG: {
    title: "Secure Email Gateway",
    "-1": "SEG message Fail ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
    "1": "SEG message Pass",
  },
  ISH: {
    title: "Website Security Settings",
    "-1": "ISH message Fail",
    "1": "ISH message Pass ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
  },
  PS: {
    title: "WordPress Plugin Security",
    "-1": "PS message Fail ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
    "1": "PS message Pass ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
  },
  WI: {
    title: "Website Encryption",
    "-1": "WI message Fail ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
    "1": "WI message Pass ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
  },
  WAF: {
    title: "Website Firewall",
    "-1": "WAF message Fail ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
    "1": "WAF message Pass ESS message Pass 50 Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis vulputate urna, sed ornare orci. Nunc quis nunc vel erat vulputate tincidunt quis tincidunt tortor. Aliquam tempus, diam a fermentum bibendum, felis enim venenatis tortor, vitae tincidunt leo erat eget elit. Vestibulum non porttitor mauris.",
  },
};

export default cyberCenterDescriptions;
