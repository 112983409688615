import { View, Image, Text } from "@react-pdf/renderer";
import * as React from "react";
import agent from "../../assets/images/pdf/agent.png";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";

export interface IReportDarkWebReportProps {
  styles: any;
  cyberScore: CyberScoreCombined | undefined;
  source: string;
}

export default function ReportDarkWebReport(props: IReportDarkWebReportProps) {
  const { styles, cyberScore, source } = props;

  // slice based off pdfSimple or ScoreCard
  const tableRows = React.useMemo(() => {
    const emailList = cyberScore?.EmailList;

    const breachEmailCount = emailList?.map(
      (email) => ({
        email,
        count:
          cyberScore?.BreachList.filter((b) => b.Email === email).length ?? 0,
      }),
      []
    );

    const sortedTable = breachEmailCount?.sort((a, b) => b.count - a.count);
    return source === "ScoreCard"
      ? sortedTable?.slice(0, 20)
      : sortedTable?.slice(0, 5);
  }, [cyberScore, source]);

  const truncateText = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "..."; // Truncate the string and add ellipsis
    }
    return str;
  };

  return (
    <View style={styles.darkWeb}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          src={agent}
          style={{ objectFit: "contain", width: "40px", height: "70px" }}
        />
        <Text
          style={{
            fontSize: "20px",
            textDecoration: "underline",
            textDecorationColor: "#FF343A",
            fontWeight: "medium",
          }}
        >
          Dark Web Report
        </Text>
      </View>

      <View style={styles.darkWebSum}>
        <View
          style={{
            flexDirection: "row",
            // padding: "5px 0 5px 0",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              margin: "0 10px 0 5px",
              color: "#FF343A",
              fontWeight: "bold",
              width: "15%",
              textAlign: "center",
            }}
          >
            {/* {calcResults.BDV?.[0]} */}
            {cyberScore?.BreachList.length}
          </Text>
          <View>
            <Text style={styles.textSmall}>
              Accounts for sale on the Dark Web
            </Text>
            <Text style={styles.textSmall}>
              out of {cyberScore?.EmailList.length} total email addresses
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeaderLeft}>
            <Text style={styles.tableCellHeader}>Breaches</Text>
          </View>
          <View style={styles.tableColHeaderRight}>
            {source === "ScoreCard" ? (
              <Text style={styles.tableCellHeader}>Top 20 Accounts</Text>
            ) : (
              <Text style={[styles.tableCellHeader, { textAlign: "left" }]}>
                Top 5 Accounts
              </Text>
            )}
          </View>
        </View>
        {tableRows?.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableColLeft}>
              <Text style={styles.tableCellNum}>{item.count}</Text>
            </View>
            <View style={styles.tableColRight}>
              <Text style={styles.tableCell}>
                {truncateText(item.email, 30)}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}
