import {
  Button,
  Flex,
  Text,
  DropZone,
  Image,
  VisuallyHidden,
  View,
  Placeholder,
} from "@aws-amplify/ui-react";
import React, { useEffect, useRef, useState } from "react";
import fileToBase64 from "../../util/fileToBase64";
import { FaTrash } from "react-icons/fa";
import { FaUpload } from "react-icons/fa6";

type ImageUploaderProps = {
  dataBase64?: string;
  onChangeBase64?: (data: string) => void;
  isLoading?: boolean;
  text?: any;
};

export default function ImageUploader({
  dataBase64,
  onChangeBase64,
  isLoading,
  text,
}: ImageUploaderProps) {
  const [file, setFile] = useState<File | undefined>();
  // const [base64EncodedData, setBase64EncodedData] = useState<
  //   string | undefined
  // >();
  const hiddenInput = useRef<HTMLInputElement>(null);

  const onFilePickerChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }
    setFile(files[0]);
  };

  const handleFileChange = async (file: File) => {
    const base64EncodedData = await fileToBase64(file);
    // console.log(base64EncodedData);
    // setBase64EncodedData(base64EncodedData);
    onChangeBase64?.(base64EncodedData);
  };

  useEffect(() => {
    if (file !== undefined) handleFileChange(file);
  }, [file]);

  return (
    <DropZone
      height={"100px"}
      padding={"small"}
      acceptedFileTypes={["image/*"]}
      isDisabled={isLoading}
      onDropComplete={({ acceptedFiles }) => {
        if (acceptedFiles.length === 0) {
          return;
        }
        setFile(acceptedFiles[0]);
      }}
    >
      <Flex
        direction="column"
        alignItems="center"
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        position={"relative"}
      >
        {isLoading ? (
          <Placeholder
            width={"100%"}
            height={"100%"}
            style={{
              aspectRatio: "1/1",
            }}
          />
        ) : dataBase64 ? (
          <View width={"100%"} height={"100%"}>
            <Image
              alt=""
              src={dataBase64}
              width={"100%"}
              height={"100%"}
              objectFit={"contain"}
            ></Image>
            <Flex
              position={"absolute"}
              bottom={"0"}
              left={"0"}
              width={"100%"}
              opacity={0.8}
              justifyContent={"space-between"}
              gap={"xs"}
              padding={"xxs"}
            >
              <Button
                size="small"
                variation="primary"
                onClick={() => hiddenInput.current?.click()}
              >
                <FaUpload />
              </Button>
              <Button
                size="small"
                variation="primary"
                onClick={() => onChangeBase64?.("")}
              >
                <FaTrash />
              </Button>
            </Flex>
          </View>
        ) : (
          <Flex
            width={"100%"}
            height={"100%"}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            // style={{ aspectRatio: "1/1" }}
          >
            <Text>{text}</Text>
            <Button
              size="small"
              gap={"xs"}
              onClick={() => hiddenInput.current?.click()}
            >
              <FaUpload /> Upload
            </Button>
          </Flex>
        )}
      </Flex>
      <VisuallyHidden>
        <input
          type="file"
          tabIndex={-1}
          ref={hiddenInput}
          max={1}
          onChange={onFilePickerChange}
          multiple={true}
          accept={"image/*"}
        />
      </VisuallyHidden>
    </DropZone>
  );
}
