import { Divider, Flex, Text } from "@aws-amplify/ui-react";
import React from "react";
import { CyberScoreCombined } from "../../../types/CyberScoreCombined";
type Props = {
  cyberScore?: CyberScoreCombined | undefined;
};
export function SEGDetails({ cyberScore }: Props) {
  return (
    <Flex direction="column">
      <Flex direction="column" gap="xs">
        <Flex gap={"xxs"}>
          <Divider
            width={"6px"}
            margin={"3px 0 3px 0"}
            borderRadius={"4px"}
            opacity={"100"}
            borderColor={"#42cefb"}
            backgroundColor="#42cefb"
            orientation="vertical"
          />
          <Text fontSize={"smaller"} fontWeight="bold">
            What is it?
          </Text>
        </Flex>

        <Text fontSize={"smaller"}>
          Secure Email Gateways filter out harmful emails like spam and
          phishing, safeguarding business data and client trust. Since 90% of
          all attacks come through email, they are a smart investment, reducing
          the risk of financial loss.
        </Text>
      </Flex>
      {cyberScore ? (
        <Flex direction="column" gap="xs">
          <Flex gap={"xxs"}>
            <Divider
              width={"6px"}
              margin={"3px 0 3px 0"}
              borderRadius={"4px"}
              opacity={"100"}
              borderColor={"#42cefb"}
              backgroundColor="#42cefb"
              orientation="vertical"
            />
            <Text fontSize={"smaller"} fontWeight="bold">
              Your Results
            </Text>
          </Flex>

          <Text fontSize={"smaller"}>{cyberScore?.SEG}</Text>
        </Flex>
      ) : null}
    </Flex>
  );
}
