import { useQuery } from "@tanstack/react-query";

import { Asset } from "../types/assetInventoryApi";
import { genKeyTakeaways } from "../services/gpt-generation/genKeyTakeaways";

export function useKeyTakeawaysQuery(
  assets: Asset[] | undefined,
  companyId: number | undefined
) {
  const { data: keyTakeaways, ...query } = useQuery({
    queryKey: ["key-takeaways", companyId],
    queryFn: async () => await genKeyTakeaways(assets as Asset[]),
    enabled: !!assets && !!companyId,
  });

  return {
    keyTakeaways,
    ...query,
  };
}
