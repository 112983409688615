import { useQueryClient } from "@tanstack/react-query";
import { fetchUserAttributes } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import posthog from "posthog-js";
import { useEffect } from "react";

export default function useAuthEventListener() {
  const queryClient = useQueryClient();

  useEffect(() => {
    const stopListening = Hub.listen("auth", ({ payload }) => {
      console.log("AUTH EVENT: ", payload.event);
      switch (payload.event) {
        case "signedIn":
          onSignedIn();
          break;
        case "signedOut":
          posthog.reset();
          break;
      }
    });

    return () => stopListening();
  }, []);

  const onSignedIn = async () => {
    const attributes = await fetchUserAttributes();
    posthog.identify(attributes.email, {
      email: attributes.email,
      name: `${attributes.given_name} ${attributes.family_name}`,
    });
    posthog.capture("Sign In", {
      attributes,
    });
    queryClient.invalidateQueries({ queryKey: ["user"] });
  };
}
