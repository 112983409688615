import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Loader,
  Tabs,
  View,
} from "@aws-amplify/ui-react";

import CompaniesTable from "../components/portfolio/CompaniesTable";
import PaddedContainer from "../layouts/PaddedContainer";
import InformationPanel from "../components/monitorcompany/InformationPanel";
import { useParams } from "react-router-dom";
import { useCampaignsQuery } from "../hooks/useCampaignsQuery";
import { useCompaniesMutation } from "../hooks/useCompaniesMutation";
import { FaPlus, FaSignal } from "react-icons/fa6";
import { Modal } from "../components/Modal";
import CompanyDetailsForm from "../components/portfolio/CompanyDetailsForm";
import { insertCompany } from "../services/campaigns/insertCompany";
import { useUserContext } from "../hooks/useUserContext";
import { useCompaniesQuery } from "../hooks/useCompaniesQuery";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { scanNewCompanies } from "../services/recon-outputs/scanNewCompanies";
import { useReconOutputsQuery } from "../hooks/useReconOutputsQuery";
import ReconOutputView from "./ReconOutputView";

export default function ScoreGroupView() {
  const { id } = useParams();

  const { groups, isLoading } = useCampaignsQuery();

  const group = groups?.find((group) => group.CampaignID === id);

  const { updateCompaniesMutate } = useCompaniesMutation();
  const { companies } = useCompaniesQuery(2, id);

  const addCompanyModal = React.useRef<HTMLDialogElement>(null);

  const { user } = useUserContext();

  const queryClient = useQueryClient();

  const [currentCompanyId, setCurrentCompanyId] = useState<number | undefined>(
    undefined
  );

  const { recons } = useReconOutputsQuery({ groupId: 2, campaignId: id });

  const currentCompany = useMemo(() => {
    return companies?.find((c) => c.CUID === currentCompanyId);
  }, [companies, currentCompanyId]);

  const currentRecon = useMemo(() => {
    return recons?.find((r) => r.CUID === currentCompanyId);
  }, [recons, currentCompanyId]);

  const { mutateAsync: scanNewCompanies_mutateAsync, isPending: pendingScan } =
    useMutation({
      mutationKey: ["scan-new-companies"],
      mutationFn: async () =>
        scanNewCompanies({
          member_id: user?.memberId ?? "",
          group_id: 2,
          campaign_id: id,
        }),
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: ["recon-outputs", 2, id] });
      },
    });

  return (
    <>
      <Modal ref={addCompanyModal}>
        <CompanyDetailsForm
          groupId={2}
          Operation="insert"
          onSave={async (insertCompanyRequest) => {
            if (!user?.memberId) throw new Error("Member ID is not defined.");
            if (!id) throw new Error("Campaign ID is not defined.");
            if (!companies) throw new Error("Companies are not defined.");
            const result = await updateCompaniesMutate({
              body: { Businesses: [insertCompanyRequest] },
            });
            const newProspect = result[0].CUID;

            await insertCompany({
              memberId: user?.memberId,
              campaignId: id,
              currentProspects: companies.map((company) => company.CUID),
              newProspect,
            });

            queryClient.invalidateQueries({
              queryKey: ["recon-outputs", 2, id],
            });
            queryClient.invalidateQueries({ queryKey: ["companies", 2, id] });
          }}
          onClose={() => addCompanyModal.current?.close()}
        />
      </Modal>
      <PaddedContainer>
        {isLoading || !group ? (
          <Loader />
        ) : (
          <Grid
            gap={"xl large"}
            templateColumns={{ base: "1fr", large: "1fr 1fr" }}
          >
            <Flex
              direction="column"
              height={"100%"}
              justifyContent={"space-between"}
            >
              <InformationPanel
                header={
                  <Heading level={3} fontWeight={"bold"}>
                    {group.Name}
                  </Heading>
                }
              >
                We are going to write some sort of intro description paragraph
                here to describe that Score Management allows the user to save
                Score Cards for companies and group together these save Score
                Cards. This way you can stay up to date on all the publicly
                available cyber risk data of companies you are interested in.
                Maybe we call them Packs of Score Cards?
              </InformationPanel>
            </Flex>
            <Card borderRadius={"large"} boxShadow={"medium"}></Card>
            <Tabs.Container defaultValue="companies">
              <Tabs.List>
                <Tabs.Item value="companies">Companies</Tabs.Item>
              </Tabs.List>
              <Card
                marginTop={"small"}
                borderRadius={"large"}
                boxShadow={"medium"}
              >
                <Tabs.Panel value="companies">
                  <Flex direction={"column"}>
                    <Flex justifyContent={"end"} gap={"xs"}>
                      <Button
                        borderRadius={"xxxl"}
                        isLoading={pendingScan}
                        gap={"small"}
                        onClick={() => {
                          scanNewCompanies_mutateAsync();
                        }}
                      >
                        <FaSignal />
                        Scan
                      </Button>
                      <Button
                        borderRadius={"xxxl"}
                        variation="primary"
                        onClick={() => {
                          addCompanyModal.current?.showModal();
                          // updateCompaniesMutate({
                          //   body: {
                          //     Businesses: [
                          //       { Operation: "insert", GroupID: 2, EmailDomain:  },
                          //     ],
                          //   },
                          // });
                        }}
                      >
                        <FaPlus />
                      </Button>
                    </Flex>
                    <CompaniesTable
                      onClick={(c) => setCurrentCompanyId(c.CUID)}
                    />
                  </Flex>
                </Tabs.Panel>
              </Card>
            </Tabs.Container>
            <View padding={"medium"}>
              <ReconOutputView company={currentCompany} recon={currentRecon} />
            </View>
          </Grid>
        )}
      </PaddedContainer>
    </>
  );
}
