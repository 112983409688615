import { Asset } from "../../types/assetInventoryApi";
import { getRequest } from "../restAPI";

export async function getAssetInventoryDetailsRange(
  companyId?: number,
  scannerId?: string,
  startTimeStamp?: string | null,
  endTimeStamp?: string | null
): Promise<Asset[]> {
  try {
    if (!companyId || !scannerId || !startTimeStamp || !endTimeStamp) {
      throw new Error("Missing required parameters");
    }
    const assets = await getRequest(
      `/asset-inventory/details/${companyId}/${scannerId}/${startTimeStamp}/${endTimeStamp}`
    ).then(async (response) => {
      return await response.body.json().then((json) => {
        const assets = json as any as Asset[];

        return assets;
      });
    });
    return assets as unknown as Asset[];
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching details. Please change time range.");
  }
}
