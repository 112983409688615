import { Card, Flex, Grid, Loader, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import DarkWebReportTable from "./DarkWebReportTable";
import CompromisedAccountsTable from "./CompromisedAccountsTable";
import TableTitle from "../styling/TableTitle";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";

export interface ICyberReportDetailsTableProps {
  domain: string | undefined;
}

export default function CyberReportDetailsTable(
  props: ICyberReportDetailsTableProps
) {
  const { domain } = props;

  const { cyberScore, isLoading } = useCyberScoreCombinedQuery(domain);


  return (
    <Card variation="elevated" borderRadius={"large"}>
      <Grid templateColumns={{ base: "1fr", xl: "1fr 1fr" }} gap={"xl"}>
        <Flex justifyContent={"flex-start"} direction={"column"}>
          <TableTitle
            title={
              <Flex justifyContent={"space-between"}>
                <Text>Dark Web Report</Text>
                <Text fontWeight={"normal"}>
                  Total Breaches:{" "}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Text
                      as="span"
                      color={"#DC2B2B"}
                      fontSize={"medium"}
                      fontWeight={"bold"}
                    >
                      {cyberScore?.BreachList.length}
                    </Text>
                  )}
                </Text>
              </Flex>
            }
          />
          <DarkWebReportTable maxHeight={"490px"} domain={domain} />
        </Flex>
        <Flex justifyContent={"flex-start"} direction={"column"}>
          <TableTitle
            title={
              <Flex justifyContent={"space-between"}>
                <Text>Compromised Accounts</Text>
                <Text fontWeight={"normal"}>
                  Total Accounts:{" "}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Text
                      as="span"
                      color={"#DC2B2B"}
                      fontSize={"medium"}
                      fontWeight={"bold"}
                    >
                      {cyberScore?.EmailList.length}
                    </Text>
                  )}
                </Text>
              </Flex>
            }
          />
          <CompromisedAccountsTable maxHeight={"490px"} domain={domain} />
        </Flex>
      </Grid>
    </Card>
  );
}
