import { useMemo } from "react";
import { useMonitorCompanyContext } from "./useMonitorCompanyContext";
import { EChartsOption } from "echarts-for-react";
import { Asset } from "../types/assetInventoryApi";

const categories = [
  "Mobile",
  "Smart Home & AV",
  "Home & Office",
  "Network & Server",
  "Eng & Industry",
  // "Industry",
  "Other",
];

type ChartData = {
  type: (typeof categories)[number];
  nSecure: number;
  nVulnerable: number;
};

export default function useAssetTypeOptions() {
  const { assets } = useMonitorCompanyContext();

  const isVulnerable = (asset: Asset) => {
    return asset.nvts.length > 0;
  };

  const chartData = useMemo(() => {
    const countData: Record<string, ChartData> = {};

    assets?.forEach((asset) => {
      const type = asset.recog_type_group || "Other";

      if (categories.includes(type)) {
        if (!countData[type]) {
          countData[type] = {
            type,
            nSecure: 0,
            nVulnerable: 0,
          };
        }

        if (isVulnerable(asset)) {
          countData[type].nVulnerable += 1;
        } else {
          countData[type].nSecure += 1;
        }
      } else if (type === "Audio & Video" || type === "Smart Home") {
        if (!countData["Smart Home & AV"]) {
          countData["Smart Home & AV"] = {
            type: "Smart Home & AV",
            nSecure: 0,
            nVulnerable: 0,
          };
        }

        if (isVulnerable(asset)) {
          countData["Smart Home & AV"].nVulnerable += 1;
        } else {
          countData["Smart Home & AV"].nSecure += 1;
        }
      } else if (type === "Network" || type === "Server") {
        if (!countData["Network & Server"]) {
          countData["Network & Server"] = {
            type: "Network & Server",
            nSecure: 0,
            nVulnerable: 0,
          };
        }

        if (isVulnerable(asset)) {
          countData["Network & Server"].nVulnerable += 1;
        } else {
          countData["Network & Server"].nSecure += 1;
        }
      } else if (type === "Engineering" || type === "Industry") {
        if (!countData["Eng & Industry"]) {
          countData["Eng & Industry"] = {
            type: "Eng & Industry",
            nSecure: 0,
            nVulnerable: 0,
          };
        }

        if (isVulnerable(asset)) {
          countData["Eng & Industry"].nVulnerable += 1;
        } else {
          countData["Eng & Industry"].nSecure += 1;
        }
      }
    });

    return Object.values(countData);
  }, [assets]);

  const series = useMemo(
    () => [
      {
        data: categories.map((type) => {
          const assetCount = chartData.find((ac) => ac.type === type);
          return assetCount ? assetCount.nSecure : 0;
        }),
        type: "bar",
        stack: "Secure",
        name: "Secure",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
          borderRadius: 40,
        },
        emphasis: {
          focus: "self",
        },
        color: "#000080",
        itemStyle: {
          borderRadius: 10,
        },
        // center: ["0%", "50%"],
      },
      {
        data: categories.map((type) => {
          const assetCount = chartData.find((ac) => ac.type === type);
          return assetCount ? assetCount.nVulnerable : 0;
        }),
        type: "bar",
        stack: "Secure",
        name: "Vulnerable",
        emphasis: {
          focus: "self",
        },
        color: "#DC2B2B",
        itemStyle: {
          borderRadius: 10,
        },
      },
    ],
    [chartData]
  );

  const options: EChartsOption = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      grid: {
        left: "105px",
        right: "0",
        top: "0",
        bottom: "0",
      },

      yAxis: {
        type: "category",
        data: categories,
        axisTick: "none",
        axisLine: {
          show: false,
        },
        axisLabel: {
          color: "#000",
          fontSize: "11px",
        },
      },
      xAxis: {
        type: "value",
        show: false,
      },
      series: series as any,
    }),
    [series]
  );

  return options;
}
