import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import ScoreManagement from "../pages/ScoreManagement";
import GroupRoutes from "./CampaignRoutes";

export default function ScoreManagementRoutes() {
  return (
    <DashboardLayout>
      <Routes>
        <Route index element={<ScoreManagement />} />
        <Route path="groups/*" element={<GroupRoutes />}></Route>
      </Routes>
    </DashboardLayout>
  );
}
