import { Flex, TableCell, TableRow, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import { useUpdateVulnStatusMutation } from "../../hooks/useUpdateVulnStatusMutation";
import { NVT } from "../../types/assetInventoryApi";
import { Company } from "../../types/companies";

import { priorityToString } from "../../util/priorityToString";
import StatusSelectField from "./StatusSelectField";
import LevelBadge from "./LevelBadge";

export interface NvtTableRowProps {
  assetId: string;
  nvt: NVT;
  key: React.Key | null | undefined;
  company: Company | undefined;
  selectedNvt: NVT | null | undefined;
  setSelectedNvtId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const NvtTableRow = (props: NvtTableRowProps) => {
  const {
    nvt,
    assetId,
    key,
    company,
    setSelectedNvtId: setSelectedNvt,
    selectedNvt,
  } = props;

  const { updateNvtStatus } = useUpdateVulnStatusMutation(
    company?.CUID as number,
    assetId,
    nvt.nvt_oid
  );

  const isSelected = React.useMemo(
    () => selectedNvt?.nvt_oid === nvt.nvt_oid,
    [nvt, selectedNvt]
  );

  // const impact = React.useMemo(() => getVulnImpact(nvt), [nvt]);

  // const likelihood = React.useMemo(() => getVulnLikelihood(nvt), [nvt]);

  const priority = React.useMemo(() => priorityToString(nvt.priority), [nvt]);

  return (
    <TableRow
      key={key}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedNvt((prev) => (prev === nvt.nvt_oid ? null : nvt.nvt_oid));
      }}
      backgroundColor={isSelected ? "#EAF4FF" : undefined}
    >
      <TableCell>
        <Flex justifyContent={"center"}>
          <LevelBadge level={priority} fontSize="xs" size="small"></LevelBadge>
        </Flex>
      </TableCell>
      <TableCell>
        <Text fontSize={"xs"} padding={"0 15px 0 0"} textAlign={"start"}>
          {nvt.family}
        </Text>
      </TableCell>

      <TableCell>
        <Text fontSize={"xs"} padding={"0 15px 0 0"}>
          {nvt.name}
        </Text>
      </TableCell>
      <TableCell>
        <StatusSelectField
          status={nvt.status ?? "To Do"}
          onChange={async (value) => {
            await updateNvtStatus(value);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default NvtTableRow;
