import React from "react";
import { Button, Flex, Grid, Heading, Text } from "@aws-amplify/ui-react";
import HeadlineVerticalDivider from "../components/styling/HeadlineVerticalDivider";
import { Modal } from "../components/Modal";
import SensorModal from "../components/cyber audit/SensorModal";
import CompanySelect from "../components/cyber audit/CompanySelect";
import SensorSelect from "../components/cyber audit/SensorSelect";
import CyberAuditTable from "../components/cyber audit/CyberAuditCard";
import { useScannersMutation } from "../hooks/useScannersMutation";
import ConfirmScannerUpdate from "../components/portfolio/ConfirmScannerUpdate";
import { toast } from "react-toastify";

export default function CyberAudit() {
  const addSensor = React.useRef<HTMLDialogElement>(null);
  const createCyberAuditModalRef = React.useRef<HTMLDialogElement>(null);

  const [companyId, setCompanyId] = React.useState<number | undefined>(
    undefined
  );

  const [scannerId, setScannerId] = React.useState<number | undefined>(
    undefined
  );

  const { updateScannersMutate, isPending } = useScannersMutation();

  return (
    <>
      <Modal ref={addSensor} size="small">
        <SensorModal onClose={() => addSensor.current?.close()} />
      </Modal>
      <Modal ref={createCyberAuditModalRef} size="medium">
        <ConfirmScannerUpdate
          sensorId={scannerId ?? -1}
          companyId={companyId ?? -1}
          onBack={() => createCyberAuditModalRef.current?.close()}
          onSave={async () => {
            if (companyId === undefined || scannerId === undefined) {
              toast.error("Please select a Company and a Sensor.");
              return;
            }
            await updateScannersMutate({
              body: {
                Scanners: [
                  {
                    Operation: "update",
                    ScannerID: scannerId,
                    CUID: companyId,
                  },
                ],
              },
            });
            createCyberAuditModalRef.current?.close();
          }}
          isSaving={isPending}
        ></ConfirmScannerUpdate>
      </Modal>

      <Flex direction={"column"} gap="0" padding={"medium"}>
        <Flex
          height={"52px"}
          padding={"small large 0 large"}
          justifyContent={"right"}
        >
          <Flex gap={"xxs"}></Flex>
        </Flex>

        <Flex
          width={"100%"}
          gap="large"
          direction={"column"}
          padding={"0 large large large"}
        >
          <Grid
            gap={"xxl"}
            templateColumns={{ base: "1fr", medium: "2fr 3fr" }}
          >
            <Flex gap={"xxl"} direction={"column"}>
              <Heading fontSize={"2.5em"} lineHeight={"0.75em"}>
                CYBER AUDIT
              </Heading>
              <Text fontSize={"small"}>
                Cyber Audits use Iceberg Sensors to create Cyber Reports that
                assess the current security posture of a Company. Create your
                Cyber Audit and see the Cyber Report within 24 hours. Click on
                the Company row to see their Cyber Report.
              </Text>

              <Flex gap={"small"} direction={"column"}>
                <Text
                  fontWeight={"bold"}
                  fontSize={"larger"}
                  paddingBottom={"small"}
                >
                  Create a new Cyber Audit
                </Text>
                <HeadlineVerticalDivider
                  heading={"Choose a Company"}
                  subheading={"Step 1"}
                  borderColor={"#174DE1"}
                />
                <CompanySelect
                  companyId={companyId}
                  setCompanyId={setCompanyId}
                />
              </Flex>

              <Flex gap={"small"} direction={"column"}>
                <HeadlineVerticalDivider
                  heading={"Choose a Sensor"}
                  subheading={"Step 2"}
                  borderColor={"#174DE1"}
                />
                <SensorSelect
                  setScannerId={setScannerId}
                  showModal={() => addSensor.current?.showModal()}
                />
                <Flex paddingTop={"xl"}>
                  <Button
                    variation="primary"
                    size="small"
                    isLoading={isPending}
                    isDisabled={
                      companyId === undefined || scannerId === undefined
                    }
                    onClick={() => {
                      createCyberAuditModalRef.current?.showModal();

                      // if (companyId === undefined || scannerId === undefined) {
                      //   return;
                      // }
                      // updateScannersMutate({
                      //   body: {
                      //     Scanners: [
                      //       {
                      //         Operation: "update",
                      //         ScannerID: scannerId,
                      //         CUID: companyId,
                      //       },
                      //     ],
                      //   },
                      // });
                    }}
                  >
                    Create a Cyber Audit
                  </Button>
                </Flex>
              </Flex>
            </Flex>

            <CyberAuditTable showModal={() => addSensor.current?.showModal()} />
          </Grid>
        </Flex>
      </Flex>
    </>
  );
}
