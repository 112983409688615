import {
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Link,
  Placeholder,
  ScrollView,
  Tabs,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useScoreCardPDF from "../../hooks/useScoreCardPDF";
import { useFavicon } from "../../hooks/useFavicon";
import { useBase64Image } from "../../hooks/useBase64Image";
import { useLinkedinProfilePics } from "../../hooks/useLinkedinProfilePics";
import parser from "tld-extract";
import HeadlineVerticalDivider from "../styling/HeadlineVerticalDivider";
import ReconCompanyLite from "../../pages/ReconCompanyLite";
import BrandingOptions from "./BrandingOptions";
import { useBrandingContext } from "../../hooks/useBrandingContext";

export default function ScoreCardBuilder() {
  const { domain } = useParams();

  const navigate = useNavigate();

  const [prospectLogo, setProspectLogo] = useState<string>("");
  const [nextDomain, setNextDomain] = useState<string>("");
  const { branding } = useBrandingContext();

  const {
    cyberScore,
    isLoading: cyberScoreIsLoading,
    isError,
  } = useCyberScoreCombinedQuery(domain);

  const { url, isLoading: favUrlIsLoading } = useFavicon(domain);

  const { base64Data: faviconData } = useBase64Image(url);

  useEffect(() => {
    setProspectLogo(faviconData ?? "");
  }, [faviconData]);

  const { profilePics } = useLinkedinProfilePics(cyberScore);

  const {
    savePDFScoreCardV2: downloadPDF,
    isLoading: pdfIsLoading,
    isDownloading,
  } = useScoreCardPDF(
    branding,
    domain,
    prospectLogo ?? faviconData,
    profilePics
  );

  if (isError) return <Navigate to="/score-card" />;

  return (
    <>
      <Flex
        minHeight={"40px"}
        padding={"small large 0 0"}
        justifyContent={"right"}
      ></Flex>

      <Grid
        templateColumns={{ base: "1fr", medium: "3fr 4fr" }}
        gap="xxl"
        padding={"large large xs large"}
      >
        <Flex direction={"column"}>
          <Card borderRadius={"large"} boxShadow={"large"}>
            <Flex justifyContent={"space-between"} gap={"medium"}>
              <Flex flex={1}>
                <Flex alignItems={"center"}>
                  {!favUrlIsLoading || url ? (
                    <Image
                      alt="company logo"
                      src={url}
                      objectFit="initial"
                      objectPosition="50% 50%"
                      height="65px"
                      width="65px"
                    />
                  ) : (
                    <Placeholder width="65px" height="65px"></Placeholder>
                  )}
                </Flex>

                <Flex direction={"column"} gap={"2px"}>
                  {!cyberScoreIsLoading ? (
                    <>
                      <Link
                        href={"https://www." + domain}
                        isExternal
                        fontWeight={"bold"}
                        color={"blue.90"}
                      >
                        {cyberScore?.CompanyName || domain}
                      </Link>
                      {cyberScore?.CompanyName ? (
                        <Link
                          href={"https://www." + domain}
                          isExternal
                          fontSize={"smaller"}
                          color={"blue.90"}
                        >
                          {domain}
                        </Link>
                      ) : null}

                      <Text color={"neutral.80"} fontSize={"xs"}>
                        Latest Report:{" "}
                        {new Date().toLocaleDateString("en-CA").split("/")}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Placeholder
                        width={"200px"}
                        height={"24px"}
                      ></Placeholder>
                      <Placeholder
                        width={"225px"}
                        height={"21px"}
                      ></Placeholder>
                      <Placeholder
                        width={"150px"}
                        height={"18px"}
                      ></Placeholder>
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex justifyContent={"center"} height={"65px"}>
                <View position={"relative"} height={"100%"}>
                  <Image
                    height={"100%"}
                    alt=""
                    objectFit="contain"
                    src={"/images/score-card/score-guage.png"}
                  />
                  <Text
                    position={"absolute"}
                    bottom={"0px"}
                    left={"0"}
                    right={"0"}
                    textAlign={"center"}
                    fontWeight={"extrabold"}
                    fontSize={"large"}
                    lineHeight={"tight"}
                  >
                    {cyberScore?.Score}%<Text fontSize={"xs"}>Cyber Score</Text>
                  </Text>
                </View>
              </Flex>
            </Flex>
          </Card>

          <Card borderRadius={"large"} padding={"large"} boxShadow={"large"}>
            <Flex direction={"column"} gap={"xl"}>
              <Flex direction={"column"}>
                <HeadlineVerticalDivider
                  heading={"Setup your branding"}
                  subheading={"Step 2"}
                  borderColor={"#174DE1"}
                />
                <BrandingOptions
                  prospectLogo={prospectLogo}
                  setProspectLogo={setProspectLogo}
                />

                {/* <Flex alignItems={"end"}>
                  {linkedInUrls?.length === 0 ? (
                    <i>
                      {" "}
                      <Text color={"gray"} fontSize={"smaller"}>
                        No LinkedIn Profiles Found
                      </Text>
                    </i>
                  ) : (
                    <>
                      <Button
                        variation="primary"
                        colorTheme="info"
                        data-attr="load-profile-pics"
                        size="small"
                        fontSize={"smaller"}
                        borderRadius={"medium"}
                        backgroundColor={"#144ee3"}
                        gap={"xs"}
                        isDisabled={
                          isLoadingProfilePics ||
                          (linkedInUrls
                            ? nProfilePics >= linkedInUrls.length
                            : false)
                        }
                        loadingText="Loading Profiles, this may 5 minutes..."
                        isLoading={isLoadingProfilePics}
                        onClick={() => {
                          if (linkedInUrls)
                            setNProfilePics((prev) =>
                              Math.min(prev + 2, linkedInUrls?.length)
                            );
                        }}
                      >
                        <FaLinkedin /> Find Profile Pictures
                      </Button>

                      <Text fontSize={"small"} color={"neutral.80"}>
                        Searched{" "}
                        {Math.min(nProfilePics, linkedInUrls?.length ?? 0)}/
                        {linkedInUrls?.length} Profiles
                      </Text>
                    </>
                  )}
                </Flex> */}
              </Flex>

              <Flex direction={"column"}>
                <HeadlineVerticalDivider
                  heading={"Send it to your prospect"}
                  subheading={"Step 3"}
                  borderColor={"#174DE1"}
                />

                <Flex direction={"row"} gap={"small"}>
                  <Button
                    data-attr="download-score-card"
                    fontWeight={"medium"}
                    size="small"
                    variation="primary"
                    color={"#174DE1"}
                    backgroundColor={"white"}
                    borderWidth={"medium"}
                    borderColor={"#174DE1"}
                    borderRadius={"medium"}
                    loadingText={
                      isDownloading ? "Downloading..." : "Download the Report"
                    }
                    isLoading={pdfIsLoading || isDownloading}
                    onClick={() => downloadPDF()}
                  >
                    Download the Report
                  </Button>
                  <Button
                    as={Link}
                    data-attr="share-score-card"
                    color={"white"}
                    size="small"
                    fontWeight={"medium"}
                    variation="primary"
                    backgroundColor={"#174DE1"}
                    borderRadius={"medium"}
                    loadingText="Send it to your prospect"
                    isLoading={pdfIsLoading}
                    isDisabled={pdfIsLoading}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    // }}
                    isExternal
                    href={
                      "mailto:" +
                      branding.contactEmail +
                      "?bcc=gettingstarted@icebergcyber.com&subject=Checkout%20Your%20Cyber%20Score%20Card&body=Hi%20" +
                      branding.contactName.split(" ")[0] +
                      "%2C%0A%20%20%20%20Thought%20you'd%20be%20interested%20in%20your%20new%20Cyber%20Score%20Card%20because%20it%20simplifies%20identifying%20cyber%20risks%2C%20ensuring%20your%20business%20stays%20ahead%20and%20secure.%20Let%E2%80%99s%20chat%20about%20tailoring%20it%20to%20fortify%20your%20defense%20strategy.%20How%E2%80%99s%20your%20calendar%20for%20a%20quick%20call%3F%0A%0A%0A-----%3E%3E%3E%3E%20Make%20sure%20the%20Score%20Card%20PDF%20is%20attached.%0A%0A" +
                      branding.contactName
                    }
                  >
                    Send it to your prospect
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Card>
          <View
            padding={"medium"}
            as="form"
            data-attr="score-card-form"
            data-ph-capture-attribute-domain={nextDomain}
            borderRadius={"large"}
            onSubmit={(e) => {
              e.preventDefault();
              let domain;
              try {
                const tld = parser(nextDomain);
                domain = tld.domain;
              } catch (err) {
                domain = nextDomain.split("/")[0].split("?")[0];
              }
              setNextDomain("");
              navigate(`/score-card/${domain}`);
            }}
          >
            <Flex>
              <HeadlineVerticalDivider
                heading={"Create another Score Card"}
                subheading={"Next steps..."}
                borderColor={"#42CEFB"}
              />
              <Flex direction={"row"} gap={"small"} alignItems={"center"}>
                <TextField
                  label={"domain"}
                  labelHidden
                  size="small"
                  placeholder="Enter website"
                  value={nextDomain}
                  onChange={(e) => setNextDomain(e.target.value)}
                ></TextField>
                <Button
                  fontWeight={"medium"}
                  variation="primary"
                  size="small"
                  backgroundColor={"#174DE1"}
                  borderRadius={"medium"}
                  type="submit"
                >
                  Create
                </Button>
              </Flex>
            </Flex>
          </View>
        </Flex>
        <View>
          <Tabs.Container defaultValue="preview">
            <Tabs.List justifyContent={"right"}>
              <Tabs.Item
                padding={"xxxs small xxxs small"}
                fontSize={"smaller"}
                value="preview"
              >
                Preview
              </Tabs.Item>
              <Tabs.Item
                padding={"xxxs small xxxs small"}
                fontSize={"smaller"}
                value="details"
              >
                Details
              </Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value="preview">
              <Flex justifyContent={"center"} alignItems={"center"}>
                <View flex={1} position={"relative"}>
                  <Flex justifyContent={"left"}>
                    <Image
                      data-attr="score-card-image"
                      width={"100%"}
                      objectFit={"contain"}
                      borderRadius="large"
                      src="/images/score-card/score-card-blue-p1.png"
                      alt=""
                      boxShadow={"large"}
                      style={{
                        aspectRatio: "10/7.5",
                        cursor: pdfIsLoading ? "not-allowed" : "pointer",
                      }}
                      onClick={() => (pdfIsLoading ? undefined : downloadPDF())}
                    ></Image>
                  </Flex>
                  <Flex
                    // display={pdfIsLoading ? undefined : "none"}
                    borderRadius="large"
                    position={"absolute"} /* Sit on top of the page content */
                    width={"100%"} /* Full width (cover the whole page) */
                    height={"100%"} /* Full height (cover the whole page) */
                    top={"0"}
                    left={"0"}
                    backgroundColor={
                      pdfIsLoading ? "rgba(255,255,255,0.5)" : "transparent"
                    }
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    {pdfIsLoading ? (
                      <Image
                        opacity={0.8}
                        height={"60%"}
                        alt="loading-gif"
                        objectFit={"contain"}
                        borderRadius={"xxl"}
                        src="/images/score-card/pdf-loading.gif"
                      ></Image>
                    ) : (
                      <Button
                        fontWeight={"medium"}
                        variation="primary"
                        size="large"
                        color={"#174DE1"}
                        backgroundColor={"white"}
                        border={"none"}
                        borderRadius={"large"}
                        boxShadow={"large"}
                        isLoading={pdfIsLoading || isDownloading}
                      >
                        <Image
                          alt={"download-gif"}
                          height={"100px"}
                          src="/images/score-card/download.gif"
                        ></Image>
                      </Button>
                    )}
                  </Flex>
                </View>
              </Flex>
            </Tabs.Panel>
            <Tabs.Panel value="details" padding={"0"}>
              <ScrollView height={"80vh"}>
                <ReconCompanyLite domain={domain} />
              </ScrollView>
            </Tabs.Panel>
          </Tabs.Container>
        </View>
      </Grid>
    </>
  );
}
