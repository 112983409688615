import * as React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import gauge from "../../../assets/images/pdf/speedometer black.png";
import mailbox from "../../../assets/images/pdf/mailbox.png";
import sensor from "../../../assets/images/pdf/sensor.png";
import action from "../../../assets/images/pdf/action.png";
import stepsEmail from "../../../assets/images/pdf/steps-email.png";
import stepsAssets from "../../../assets/images/pdf/steps-assets.png";
import { PageLayout } from "../PageLayout";
import { Title } from "../Title";
import { CompanyDetails } from "../CompanyDetails";
import { KeyTakeaway } from "../KeyTakeaway";
import { LinkedinProfilePics } from "../LinkedinProfilePics";
import { DarkWebBreaches } from "../DarkWebBreaches";
import { CyberScore } from "../CyberScore";
import { Subtitle } from "../Subtitle";
import { Commentary } from "../Commentary";
import { TitledCommentary } from "../WhyItMatters";
import { CyberScoreCombined } from "../../../types/CyberScoreCombined";
import { ImportantStat } from "../ImportantStat";
import { Company } from "../../../types/companies";
import { ActionPlan } from "../../../services/gpt-generation/genActionPlan";
import { KeyTakeaways } from "../../../services/gpt-generation/genKeyTakeaways";
import { Asset, NVT } from "../../../types/assetInventoryApi";
import { Branding } from "../../monitorcompany/ReportBuilderButton";
import { getVulnPriority } from "../../../util/getVulnPriority";
import { getVulnImpact } from "../../../util/getVulnImpact";
import { getVulnLikelihood } from "../../../util/getVulnLikelihood";
import { getAssetAge } from "../../../util/getAssetAge";
import DarkWebFullReport from "../../../features/report/components/DarkWebFullReport";
import MostRecentBreaches from "../../../features/report/components/MostRecentBreaches";

type PDFFullCyberReportProps = {
  clientLogo: string;
  company: Company | string;
  cyberScore: CyberScoreCombined;
  actionPlan: ActionPlan;
  keyTakeaways: KeyTakeaways;
  profilePics: string[];
  nvts: NVT[];
  ITLanscapeChartImage: string;
  assetAgeChartImage: string;
  branding: Branding;
  assets: Asset[];
};

export const PDFFullCyberReport = ({
  clientLogo,
  cyberScore,
  company,
  actionPlan,
  keyTakeaways,
  profilePics,
  branding,
  ITLanscapeChartImage,
  assetAgeChartImage,
  nvts,
  assets,
}: PDFFullCyberReportProps) => {
  const DMARCPass: boolean =
    cyberScore.ESSScore === 1 ||
    (cyberScore.ESSScore === -1 && !cyberScore.ESS.includes("DMARC"));

  const uniqueNvts = (nvts: NVT[]) => {
    const seen = new Set();
    return nvts
      ?.filter((nvt) => {
        const duplicate = seen.has(nvt.nvt_oid);
        seen.add(nvt.nvt_oid);
        return !duplicate;
      })
      .sort((a, b) => {
        return a.priority - b.priority;
      });
  };

  const getSortedNvts = (nvts: NVT[]) => {
    const likelihoodSorted = uniqueNvts(nvts).sort((a: NVT, b: NVT) => {
      const likelihoodA =
        getVulnLikelihood(a) === "High"
          ? 1
          : getVulnLikelihood(a) === "Medium"
          ? 2
          : 3;
      const likelihoodB =
        getVulnLikelihood(b) === "High"
          ? 1
          : getVulnLikelihood(b) === "Medium"
          ? 2
          : 3;

      if (likelihoodA < likelihoodB) {
        return -1;
      }
      if (likelihoodA > likelihoodB) {
        return 1;
      }
      return 0;
    });

    const impactSorted = likelihoodSorted?.sort((a: NVT, b: NVT) => {
      const impactA =
        getVulnImpact(a) === "High" ? 1 : getVulnImpact(a) === "Medium" ? 2 : 3;
      const impactB =
        getVulnImpact(b) === "High" ? 1 : getVulnImpact(b) === "Medium" ? 2 : 3;

      if (impactA < impactB) {
        return -1;
      }
      if (impactA > impactB) {
        return 1;
      }
      return 0;
    });

    const prioritySorted = impactSorted?.sort((a: NVT, b: NVT) => {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    });

    return prioritySorted;
  };

  return (
    // <PDFViewer width="100%" height={"100%"} showToolbar={true}>
    <Document title="pdf">
      <Page
        size="LETTER"
        orientation="landscape"
        style={{ padding: "0", margin: "0", fontSize: "13px" }}
      >
        <PageLayout
          reportStyles={branding}
          left={
            <>
              <Title title={"Cyber Report"} reportStyles={branding}></Title>

              <View
                style={{
                  padding: "10px 20px 0px 0px",
                  gap: "10px",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ gap: "10px" }}>
                  <CompanyDetails
                    logoUrl={clientLogo}
                    companyName={
                      typeof company === "string"
                        ? company
                        : company.CompanyName
                    }
                    website={
                      typeof company === "string" ? "" : company.EmailDomain
                    }
                  />

                  <KeyTakeaway
                    reportStyles={branding}
                    text={keyTakeaways["key-takeaway"]}
                  />

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "15px",
                    }}
                  >
                    <LinkedinProfilePics profilePics={profilePics} />
                    <DarkWebBreaches cyberScore={cyberScore} />
                    <CyberScore cyberScore={cyberScore} branding={branding} />
                  </View>
                </View>

                <View style={{ gap: "15px" }}>
                  <Subtitle
                    icon={gauge}
                    titlepart1={"RISK"}
                    titlepart2={"OVERVIEW"}
                    color={branding.accentColor}
                  />

                  <Commentary
                    body={
                      "Cyber threats are a reality, swift and silent. Your business is your single largest investment. Regular cyber audits act as your early warning system, pinpointing risks before they strike. It's about being proactive, not reactive. Strengthen your defenses, ensure your business's resilience and prosperity in the face of digital dangers."
                    }
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                    padding: "6px",
                  }}
                >
                  <ImportantStat
                    stat={nvts.length.toString()}
                    context={"Exploitable software vulnerabilities found"}
                  />

                  <ImportantStat
                    stat={assets
                      .filter((a) => {
                        const age = getAssetAge(a);
                        return age >= 4;
                      })
                      .length.toString()}
                    context={"Aging assets more than 4 years old"}
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  {ITLanscapeChartImage !== "" ? (
                    <Image
                      src={ITLanscapeChartImage}
                      style={{
                        height: "100px",
                        width: "100%",
                      }}
                    ></Image>
                  ) : null}
                  {assetAgeChartImage !== "" ? (
                    <Image
                      src={assetAgeChartImage}
                      style={{
                        height: "100px",
                        width: "100%",
                      }}
                    ></Image>
                  ) : null}
                </View>

                <View
                  style={{
                    gap: "2px",
                    fontSize: "10px",
                    paddingBottom: "12px",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {branding.slogan ? `${branding.slogan}:` : ""}{" "}
                    {branding.contactName} | {branding.contactEmail}
                  </Text>
                </View>
              </View>
            </>
          }
          right={
            <>
              <View>
                {branding.yourLogo !== "" ? (
                  <Image
                    src={branding.yourLogo}
                    style={{
                      objectFit: "contain",
                      height: "70px",
                      width: "100%",
                    }}
                  ></Image>
                ) : null}

                <View style={{ paddingTop: "15px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-end",
                      paddingBottom: "20px",
                      gap: "10px",
                    }}
                  >
                    <View style={{ paddingTop: "15px" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-end",
                          paddingBottom: "20px",
                          gap: "10px",
                        }}
                      >
                        <View style={{ height: "40px", width: "40px" }}>
                          <Image
                            src={action}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </View>

                        <Text
                          style={{
                            fontSize: "20px",
                            paddingBottom: "2px",
                            fontWeight: "bold",
                          }}
                        >
                          ACTION PLAN
                        </Text>
                      </View>

                      <View style={{ fontSize: "10.5px", gap: "10px" }}>
                        <Text>
                          This is your 3 Step Cyber Action Plan to reduce
                          security risks:
                        </Text>
                        {actionPlan?.actions?.map((a, i) => (
                          <View key={i} style={{ gap: "2px" }}>
                            <Text style={{ fontWeight: "semibold" }}>
                              {a.title}
                            </Text>
                            <Text>{a.detail}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <Text
                style={{ fontSize: "10.5px", paddingBottom: "12px" }}
                render={({ pageNumber, totalPages }) =>
                  `${new Date().toLocaleString("en-us", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })} | Page ${pageNumber}/${totalPages}`
                }
              />
            </>
          }
        />

        <DarkWebFullReport branding={branding} cyberScore={cyberScore} />
        <MostRecentBreaches branding={branding} cyberScore={cyberScore} />
        {/* Page 3 - EMAIL IMPERSONATION */}
        <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
          <View
            style={{
              width: "70%",
              height: "100vh",
              padding: "20px 0px 5px 20px",
            }}
          >
            <Title title={"Cyber Report"} reportStyles={branding}></Title>

            <View
              style={{
                padding: "10px 20px 0px 0px",
                gap: "10px",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <View style={{ gap: "20px" }}>
                <View style={{ gap: "15px" }}>
                  <Subtitle
                    icon={mailbox}
                    titlepart1={"EMAIL IMPERSONATION"}
                    titlepart2={"REPORT"}
                    color={branding.accentColor}
                  />

                  <TitledCommentary
                    reportStyles={branding}
                    header={"Why it matters:"}
                    body={
                      "Email fraud can devastate a small business where criminals can forge your identity to trick clients or employees, risking finances, and reputation. Without SPF, DKIM, and DMARC, your email domain is an open door to such impersonation and deceit."
                    }
                  />
                </View>

                {/* Pass/Fail Table */}
                <View
                  style={{
                    fontSize: "10px",
                    gap: "3px",
                    padding: "0px 25px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: "2px",
                      borderBottomColor: branding.accentColor,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", width: "100px" }}>
                      Status
                    </Text>
                    <Text style={{ fontWeight: "bold", width: "370px" }}>
                      Record
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "100px", justifyContent: "center" }}>
                      <View
                        style={{
                          width: "50px",
                          backgroundColor: cyberScore.SPF
                            ? "#00B050"
                            : "#FF343A",
                          color: "white",
                          padding: "2px 0px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        <Text>{cyberScore.SPF ? "Pass" : "Fail"}</Text>
                      </View>
                    </View>
                    <Text style={{ width: "370px" }}>
                      {cyberScore.SPF ?? "No SPF records found."}
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "100px", justifyContent: "center" }}>
                      <View
                        style={{
                          width: "50px",
                          backgroundColor: cyberScore.DKIM
                            ? "#00B050"
                            : "#FF343A",
                          color: "white",
                          padding: "2px 0px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        <Text>{cyberScore.DKIM ? "Pass" : "Fail"}</Text>
                      </View>
                    </View>
                    <Text style={{ width: "370px" }}>
                      {cyberScore.DKIM
                        ? cyberScore.DKIM?.slice(0, 55) + "…"
                        : "No DKIM records found."}
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "100px", justifyContent: "center" }}>
                      <View
                        style={{
                          width: "50px",
                          backgroundColor: DMARCPass ? "#00B050" : "#FF343A",
                          color: "white",
                          padding: "2px 0px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        <Text>{DMARCPass ? "Pass" : "Fail"}</Text>
                      </View>
                    </View>
                    <Text style={{ width: "370px" }}>
                      {cyberScore.DMARC === null
                        ? "No DMARC records found. We recommend you use: v=DMARC1; p=reject;"
                        : !DMARCPass
                        ? cyberScore.DMARC +
                          " We recommend you use: v=DMARC1; p=reject;"
                        : cyberScore.DMARC}
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "100px", justifyContent: "center" }}>
                      <View
                        style={{
                          width: "50px",
                          backgroundColor:
                            cyberScore.SEGScore === 1 ? "#00B050" : "#FF343A",
                          color: "white",
                          padding: "2px 0px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        <Text>
                          {cyberScore.SEGScore === 1 ? "Pass" : "Fail"}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ width: "370px" }}>
                      {cyberScore.SEGScore === 1
                        ? cyberScore.SEG
                        : cyberScore.SEGScore === -1
                        ? `No email service detected that provides spam/phishing protection.`
                        : "No email service associated with this domain."}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ gap: "25px" }}>
                <TitledCommentary
                  reportStyles={branding}
                  header={"How to fix it:"}
                  body={
                    "Implementing SPF, DKIM, and DMARC seals your email domain against imposters, safeguarding your business's communications. This trio verifies emails' authenticity, blocking fraudsters, protecting your finances, and maintaining your reputation. It's a clear step towards securing your business's integrity and trustworthiness."
                  }
                />

                <View
                  style={{
                    gap: "2px",
                    fontSize: "10px",
                    paddingBottom: "12px",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {branding.slogan ? `${branding.slogan}:` : ""}{" "}
                    {branding.contactName} | {branding.contactEmail}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Right Side Panel   */}
          <View
            style={{
              width: "30%",
              backgroundColor: branding.primaryColor,
              height: "100vh",
              color: branding.fontColor,
              padding: "20px 20px 5px 20px",
              justifyContent: "space-between",
            }}
          >
            <View>
              {branding.yourLogo !== "" ? (
                <Image
                  src={branding.yourLogo}
                  style={{
                    objectFit: "contain",
                    height: "70px",
                    width: "100%",
                  }}
                ></Image>
              ) : null}

              <View style={{ paddingTop: "15px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                    gap: "5px",
                  }}
                >
                  <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                    3 STEP ATTACK
                  </Text>
                </View>

                <View
                  style={{
                    alignItems: "center",
                    paddingBottom: "20px",
                    gap: "5px",
                  }}
                >
                  <View style={{ height: "200px", width: "auto" }}>
                    <Image
                      src={stepsEmail}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </View>
                </View>

                <View style={{ fontSize: "10.5px", gap: "5px" }}>
                  <Text style={{ fontWeight: "medium" }}>
                    What could go wrong:
                  </Text>
                  <Text>
                    DNS records are the internet's phone book. Criminals can
                    easily spot your email system's weaknesses, as
                    straightforward as reading the phone book. They can then
                    automatically send emails to your employees, masquerading as
                    you, the CEO, or HR. Who wouldn't open an email titled
                    'Vacation Policy Updates' from HR?
                  </Text>
                </View>
              </View>
            </View>

            <Text
              style={{ fontSize: "10.5px", paddingBottom: "12px" }}
              render={({ pageNumber, totalPages }) =>
                `${new Date().toLocaleString("en-us", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })} | Page ${pageNumber}/${totalPages}`
              }
            />
          </View>
        </View>

        {/* Page 4 - ASSET RISK REPORT */}
        <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
          <View
            style={{
              width: "70%",
              height: "100vh",
              padding: "20px 0px 5px 20px",
            }}
          >
            <Title title={"Cyber Report"} reportStyles={branding}></Title>

            <View
              style={{
                padding: "10px 20px 0px 0px",
                gap: "10px",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <View>
                <View style={{ gap: "15px" }}>
                  <Subtitle
                    icon={sensor}
                    titlepart1={"Asset Risk"}
                    titlepart2={"Report"}
                    color={branding.accentColor}
                  />

                  <TitledCommentary
                    reportStyles={branding}
                    header={"Why it matters:"}
                    body={
                      "Outdated software and vulnerable devices are open invitations for cyber thieves, jeopardizing your revenue and customer trust. With weekly vulnerabilities emerging, ignoring updates transforms your business into a prime target for data theft and ransomware attacks."
                    }
                  />
                </View>

                <View style={{ gap: "20px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                      padding: "6px",
                    }}
                  >
                    <ImportantStat
                      stat={nvts.length}
                      context={"Exploitable software vulnerabilities found"}
                    />

                    <ImportantStat
                      stat={assets.length}
                      context={"Total assets scanned"}
                    />
                  </View>

                  <View style={{ fontSize: "10px", gap: "3px" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "2px",
                        borderBottomColor: branding.accentColor,
                        gap: "5px",
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          width: "55px",
                          textAlign: "center",
                        }}
                      >
                        Priority
                      </Text>

                      <Text
                        style={{
                          fontWeight: "bold",
                          width: "300px",
                        }}
                      >
                        Vulnerability
                      </Text>
                    </View>

                    {getSortedNvts(nvts)
                      .slice(0, 10)
                      .map((v) => (
                        <View style={{ flexDirection: "row", gap: "5px" }}>
                          <View
                            style={{ width: "55px", justifyContent: "center" }}
                          >
                            <View
                              style={{
                                width: "50px",
                                backgroundColor:
                                  getVulnPriority(v) === "Critical"
                                    ? "#FF343A"
                                    : getVulnPriority(v) === "High"
                                    ? "#C00000"
                                    : getVulnPriority(v) === "Medium"
                                    ? "#FF9E2C"
                                    : "#7F7F7F",
                                color: "white",
                                padding: "2px 0px",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              <Text style={{ fontWeight: "semibold" }}>
                                {getVulnPriority(v)}
                              </Text>
                            </View>
                          </View>

                          <Text
                            style={{
                              width: "350px",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {v.ice_insight_headline}
                          </Text>
                        </View>
                      ))}
                  </View>
                </View>
              </View>

              <View style={{ gap: "25px" }}>
                <TitledCommentary
                  header={"How to fix it:"}
                  body={
                    "By deploying regular software updates, rigorous network configurations, and advanced protection protocols, you not only close the door on potential attackers but also ensure it stays closed. Proactive maintenance and monitoring services mean that your network remains resilient against the ever-evolving threat landscape."
                  }
                  reportStyles={branding}
                />

                <View
                  style={{
                    gap: "2px",
                    fontSize: "10px",
                    paddingBottom: "12px",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {branding.slogan ? `${branding.slogan}:` : ""}{" "}
                    {branding.contactName} | {branding.contactEmail}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Right Side Panel   */}
          <View
            style={{
              width: "30%",
              backgroundColor: branding.primaryColor,
              height: "100vh",
              color: branding.fontColor,
              padding: "20px 20px 5px 20px",
              justifyContent: "space-between",
            }}
          >
            <View>
              {branding.yourLogo !== "" ? (
                <Image
                  src={branding.yourLogo}
                  style={{
                    objectFit: "contain",
                    height: "70px",
                    width: "100%",
                  }}
                ></Image>
              ) : null}

              <View style={{ paddingTop: "15px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                    gap: "5px",
                  }}
                >
                  <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                    3 STEP ATTACK
                  </Text>
                </View>

                <View
                  style={{
                    alignItems: "center",
                    paddingBottom: "20px",
                    gap: "5px",
                  }}
                >
                  <View style={{ height: "200px", width: "auto" }}>
                    <Image
                      src={stepsAssets}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </View>
                </View>

                <View style={{ fontSize: "10.5px", gap: "5px" }}>
                  <Text style={{ fontWeight: "medium" }}>
                    What could go wrong:
                  </Text>
                  <Text>
                    Neglected and outdated devices are significant cyber risks.
                    They're ideal for attackers to spread malware. One phishing
                    email can trigger a domino effect paralyzing your whole
                    company. With new software exploits daily, such oversights
                    quickly escalate from minor issues to major security
                    breaches.{" "}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{ fontSize: "10.5px", paddingBottom: "12px" }}
              render={({ pageNumber, totalPages }) =>
                `${new Date().toLocaleString("en-us", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })} | Page ${pageNumber}/${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default PDFFullCyberReport;
