import {
  Authenticator,
  Grid,
  ScrollView,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import React from "react";
import Navbar from "./NavBar";
import Sidebar from "./SideBar";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorFallback from "../components/ErrorFallback";

export default function DashboardLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  const Nav = useBreakpointValue({
    base: () => <Navbar />,
    large: () => <Sidebar />,
  }) as () => JSX.Element;

  return (
    <Authenticator>
      <Grid
        height={"100dvh"}
        minHeight={"100vh"}
        gap="0"
        templateColumns={{ base: "1fr", large: "auto 1fr" }}
        templateRows={{ base: "auto minmax(0,1fr)", large: "minmax(0,1fr)" }}
        backgroundColor={"#f7f9fc"}
      >
        <Nav />

        <ScrollView
          backgroundColor={"#f7faff"} //#f7faff
          paddingLeft={{ base: "small", small: "xl" }}
          paddingRight={{ base: "small", small: "xl" }}
        >
          <ErrorBoundary fallback={(error) => <ErrorFallback error={error} />}>
            {children}
          </ErrorBoundary>
        </ScrollView>
      </Grid>
    </Authenticator>
  );
}
