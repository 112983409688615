import {
  Flex,
  Heading,
  ButtonGroup,
  Button,
  Text,
} from "@aws-amplify/ui-react";
import React from "react";
import { FaArrowLeft, FaSave } from "react-icons/fa";

type Props = {
  onBack: () => void;
  onSave: () => void;
  sensorId: number;
  companyId: number;
  isSaving: boolean;
};

export default function ConfirmScannerUpdate({
  onBack,
  onSave,
  isSaving,
  sensorId,
  companyId,
}: Props) {
  return (
    <Flex direction={"column"} alignItems={"center"} padding={"medium"}>
      <Heading level={5}>Double Check 👀</Heading>
      <Text>
        Remember, the Sensor will automatically start streaming data to the new
        Company. Best practice would be to{" "}
        <b>turn the Sensor off before you create a Cyber Audit</b> to make sure
        old data does not flow into the new Company’s records.
      </Text>
      <ButtonGroup>
        <Button variation="menu" gap={"small"} onClick={onBack}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          variation="primary"
          backgroundColor={"green.80"}
          data-attr={"create-cyber-audit"}
          data-ph-capture-attribute-scannerid={sensorId}
          data-ph-capture-attribute-companyid={companyId}
          gap={"small"}
          isLoading={isSaving}
          onClick={onSave}
        >
          <FaSave />
          Continue
        </Button>
      </ButtonGroup>
    </Flex>
  );
}
