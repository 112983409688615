import { useQuery } from "@tanstack/react-query";

import { genReconActionPlan } from "../services/gpt-generation/genReconActionPlan";
import { CyberScoreCombined } from "../types/CyberScoreCombined";

export function useReconActionPlanQuery(
  cyberScore: CyberScoreCombined | undefined,
  domain: string | undefined | null
) {
  const { data: actionPlan, ...query } = useQuery({
    queryKey: ["recon-action-plan", domain],
    queryFn: async () =>
      await genReconActionPlan(cyberScore as CyberScoreCombined),
    enabled: !!cyberScore && !!domain,
  });

  return {
    actionPlan,
    ...query,
  };
}
