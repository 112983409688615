import * as React from "react";
import {
  Flex,
  Card,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  ScrollView,
  Placeholder,
} from "@aws-amplify/ui-react";
import { FaPlus, FaEye } from "react-icons/fa";
import TableTitle from "../styling/TableTitle";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import { Company } from "../../types/companies";
import { Modal } from "../Modal";
import { useCompaniesMutation } from "../../hooks/useCompaniesMutation";
import CompanyDetailsForm from "./CompanyDetailsForm";

export interface ICompanyPortfolioProps {}

export default function CompanyPortfolio({}: ICompanyPortfolioProps) {
  // const { selectedCompany, setSelectedCompany } = props;

  const [selectedCompany, setSelectedCompany] = React.useState<
    Company | undefined
  >();

  const { companies, isLoading } = useCompaniesQuery(1);

  const addCompanyModal = React.useRef<HTMLDialogElement>(null);
  const updateCompanyModal = React.useRef<HTMLDialogElement>(null);

  const { updateCompaniesMutate } = useCompaniesMutation();

  return (
    <>
      <Modal ref={addCompanyModal}>
        <CompanyDetailsForm
          groupId={1}
          Operation="insert"
          onSave={async (insertCompanyRequest) => {
            await updateCompaniesMutate({
              body: { Businesses: [insertCompanyRequest] },
            });
          }}
          onClose={() => addCompanyModal.current?.close()}
        />
      </Modal>
      <Modal ref={updateCompanyModal}>
        <CompanyDetailsForm
          groupId={1}
          Operation="update"
          selectedCompany={selectedCompany}
          onSave={async (updateCompanyRequest) => {
            await updateCompaniesMutate({
              body: { Businesses: [updateCompanyRequest] },
            });
          }}
          onClose={() => updateCompanyModal.current?.close()}
        />
      </Modal>
      <Card boxShadow={"medium"} borderRadius={"large"}>
        <Flex justifyContent={"space-between"} paddingBottom={"large"}>
          <TableTitle title={"Company Portfolio"} />
          <Button
            borderRadius={"medium"}
            data-attr="add-company-button"
            fontSize={"small"}
            boxShadow={"small"}
            color={"white"}
            backgroundColor={"blue.60"}
            gap={"small"}
            onClick={() => {
              addCompanyModal.current?.showModal();
            }}
          >
            <FaPlus />
          </Button>
        </Flex>

        <ScrollView height={"400px"}>
          <Table highlightOnHover={true} size="small">
            <TableHead>
              <TableRow>
                <TableCell width={"20%"} fontSize={"smaller"} as="th">
                  Name
                </TableCell>
                <TableCell width={"20%"} fontSize={"smaller"} as="th">
                  Domain
                </TableCell>
                <TableCell width={"20%"} fontSize={"smaller"} as="th">
                  Location
                </TableCell>
                <TableCell width={"20%"} fontSize={"smaller"} as="th">
                  Last Cyber Report
                </TableCell>
                <TableCell width={"10%"} fontSize={"smaller"} as="th">
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? [0, 1, 2, 3, 4, 5].map((i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                    </TableRow>
                  ))
                : companies?.map((business, i) => (
                    <TableRow key={i}>
                      <TableCell fontSize={"smaller"}>
                        {business.CompanyName || "-"}
                      </TableCell>
                      <TableCell fontSize={"smaller"}>
                        {business.EmailDomain || "-"}
                      </TableCell>
                      <TableCell fontSize={"smaller"}>
                        {business.Location || "-"}
                      </TableCell>
                      <TableCell fontSize={"smaller"}>
                        {timeStampToLocaleString(business.LastScan) || "-"}
                      </TableCell>
                      <TableCell fontSize={"smaller"}>
                        <Button
                          onClick={() => {
                            setSelectedCompany(business);
                            updateCompanyModal.current?.showModal();
                          }}
                        >
                          <FaEye />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </ScrollView>
      </Card>
    </>
  );
}
