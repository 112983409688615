import {
  Accordion,
  Badge,
  Button,
  Flex,
  Grid,
  Placeholder,
  ScrollView,
  Table,
  TableRow,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
// import useClickAway from "../../hooks/useClickAway";

import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import { RelatedVulnsTable } from "./RelatedVulnsTable";
import { NVT } from "../../types/assetInventoryApi";
import DeviceDetailsAccordianItem from "./DeviceDetailAcoordianItem";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { AssetTableData } from "../../features/assets";
import { assetSortFunction } from "../../features/assets/utils";

export default function AssetsTable({
  setAssetId,
  assetId,
  setNVTId: setNVTId,
  nvt,
}: {
  setAssetId: React.Dispatch<React.SetStateAction<string | null>>;
  setNVTId: React.Dispatch<React.SetStateAction<string | null>>;
  assetId: string | null;
  nvt: NVT | null | undefined;
}) {
  const { assets, isLoading } = useMonitorCompanyContext();

  const [sortColumn, setSortColumn] = useState<
    keyof AssetTableData | undefined
  >("risks");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const tableData: AssetTableData[] = useMemo(() => {
    const sorted =
      assets
        ?.map((asset) => {
          const oldestPublished = asset.nvts
            .sort((a, b) => {
              const dateA = new Date(a.nvt_published ?? 0).getTime();
              const dateB = new Date(b.nvt_published ?? 0).getTime();
              if (dateA > dateB) {
                return 1;
              } else if (dateA < dateB) {
                return -1;
              }
              return 0;
            })
            .map((nvt) => nvt.nvt_published)[0];

          return {
            id: asset.DEVICE_ID,
            risks: asset.nvts.length,
            ipAddress: asset.IPs[0].IP,
            macVendor: asset.MAC_VENDOR ?? "",
            brand: asset.recog_brand ?? "",
            type: asset.recog_type ?? "",
            nvts: asset.nvts,
            lastUpdated:
              asset.nvts.length > 0
                ? timeStampToLocaleString(oldestPublished, {
                    timeZoneName: undefined,
                    dateStyle: "short",
                  })
                : "",
          };
        })
        .sort((a, b) => assetSortFunction(sortColumn, a, b)) ?? [];

    return sortOrder === "asc" ? sorted : sorted.reverse();
  }, [assets, sortColumn, sortOrder]);

  return (
    <ScrollView position={"relative"} flex={1} height={"520px"}>
      <Grid
        position={"sticky"}
        top={"0"}
        templateColumns={"15px 60px 100px 1fr 100px 100px 120px"}
        width={"100%"}
        padding={"small"}
        gap={"xs"}
        style={{ zIndex: 999 }}
        backgroundColor={"white"}
      >
        <Text> </Text>
        <Button
          boxShadow={"none"}
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          backgroundColor={"transparent"}
          padding={"xxs xxs xxs 0"}
          justifyContent={"start"}
          variation="link"
          onClick={() => {
            if (sortColumn !== "risks") {
              setSortColumn("risks");
              setSortOrder("desc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Risks
          {sortColumn === "risks" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>
        <Button
          boxShadow={"none"}
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          backgroundColor={"transparent"}
          padding={"xxs xxs xxs 0"}
          justifyContent={"start"}
          variation="link"
          onClick={() => {
            if (sortColumn !== "ipAddress") {
              setSortColumn("ipAddress");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          IP Address
          {sortColumn === "ipAddress" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>

        <Button
          boxShadow={"none"}
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          fontSize={"smaller"}
          backgroundColor={"transparent"}
          padding={"xxs xxs xxs 0"}
          justifyContent={"start"}
          variation="link"
          onClick={() => {
            if (sortColumn !== "macVendor") {
              setSortColumn("macVendor");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          MAC Vendor
          {sortColumn === "macVendor" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>

        <Button
          boxShadow={"none"}
          fontSize={"smaller"}
          fontWeight={"bold"}
          backgroundColor={"transparent"}
          padding={"xxs xxs xxs 0"}
          justifyContent={"start"}
          variation="link"
          onClick={() => {
            if (sortColumn !== "brand") {
              setSortColumn("brand");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Brand
          {sortColumn === "brand" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>

        <Button
          boxShadow={"none"}
          fontSize={"smaller"}
          fontWeight={"bold"}
          backgroundColor={"transparent"}
          padding={"xxs xxs xxs 0"}
          justifyContent={"start"}
          variation="link"
          onClick={() => {
            if (sortColumn !== "type") {
              setSortColumn("type");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Type
          {sortColumn === "type" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
        </Button>

        <Button
          boxShadow={"none"}
          fontSize={"smaller"}
          fontWeight={"bold"}
          backgroundColor={"transparent"}
          padding={"xxs xxs xxs 0"}
          justifyContent={"start"}
          variation="link"
          onClick={() => {
            if (sortColumn !== "lastUpdated") {
              setSortColumn("lastUpdated");
              setSortOrder("asc");
            } else {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            }
          }}
        >
          Last Updated
          {sortColumn === "lastUpdated"
            ? sortOrder === "asc"
              ? "▲"
              : "▼"
            : ""}
        </Button>
      </Grid>
      <Accordion.Container
        allowMultiple={false}
        width={"100%"}
        value={assetId ? [assetId] : []}
        onValueChange={(value) => {
          if (value === undefined || value.length === 0) {
            setAssetId(null);
          } else if (value !== undefined) setAssetId(value[0]);
          setNVTId(null);
        }}
        // backgroundColor={"#EAF4FF"}
      >
        <Flex direction={"column"} gap={"xs"}>
          {isLoading && assets === undefined
            ? [0, 1, 2, 3, 4].map(() => (
                <>
                  <Text fontSize={"smaller"}>
                    <Placeholder />
                  </Text>
                  <Text fontSize={"smaller"}>
                    <Placeholder />{" "}
                  </Text>
                  <Text fontSize={"smaller"}>
                    {" "}
                    <Placeholder />
                  </Text>
                  <Text fontSize={"smaller"}>
                    {" "}
                    <Placeholder />
                  </Text>
                  <Text fontSize={"smaller"}>
                    <Placeholder />
                  </Text>
                </>
              ))
            : tableData?.map((data) => (
                <DeviceDetailsAccordianItem
                  value={data.id}
                  trigger={
                    <Table>
                      <TableRow padding={"0"}>
                        <Grid
                          templateColumns={
                            "15px 60px 100px 1fr 100px 100px 120px"
                          }
                          width={"100%"}
                          padding={"0"}
                          gap={"xs"}
                          alignItems={"center"}
                        >
                          <Accordion.Icon />
                          <Flex width={"100%"} padding={"0 xs"}>
                            <Badge
                              width={"100%"}
                              size="small"
                              fontSize={"xs"}
                              variation={
                                data.risks === 0 ? "success" : "warning"
                              }
                            >
                              <Flex width={"100%"} justifyContent={"center"}>
                                {data.risks}
                              </Flex>
                            </Badge>
                          </Flex>
                          <Text fontSize={"xs"}>
                            {data.ipAddress /* TODO: get latest IP*/}
                          </Text>
                          <Text fontSize={"xs"} isTruncated>
                            {data.macVendor}
                          </Text>
                          <Text fontSize={"xs"}>{data.brand}</Text>
                          <Text fontSize={"xs"}>{data.type}</Text>
                          <Text fontSize={"xs"}>{data.lastUpdated}</Text>
                        </Grid>
                      </TableRow>
                    </Table>
                  }
                  content={
                    assetId === data.id ? (
                      <View>
                        <RelatedVulnsTable
                          assetId={data.id}
                          nvts={data.nvts}
                          nvt={nvt}
                          setNVTId={(value) => {
                            setNVTId(value);
                          }}
                        />
                      </View>
                    ) : null
                  }
                ></DeviceDetailsAccordianItem>
              ))}
        </Flex>
      </Accordion.Container>
    </ScrollView>
  );
}
