import {
  Button,
  Card,
  Flex,
  Text,
  View,
  TextField,
  Grid,
  Image,
  ScrollView,
} from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { FaHammer, FaStarOfLife, FaTimes } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import useCyberReportScoreDownloader from "../../hooks/useCyberReportScoreDownloader";
import { useMutation } from "@tanstack/react-query";
import { imageToBase64 } from "../../services/imageToBase64";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Branding } from "../monitorcompany/ReportBuilderButton";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";
import { useLinkedinProfilePics } from "../../hooks/useLinkedinProfilePics";
import { FaLinkedin } from "react-icons/fa6";
import ImageUploader from "../monitorcompany/ImageUploader";
import { useBase64Image } from "../../hooks/useBase64Image";
import { useFavicon } from "../../hooks/useFavicon";

type ReportBuilderButtonProps = {
  domain: string | undefined | null;
};

export default function ReportBuilderButton(props: ReportBuilderButtonProps) {
  const { domain } = props;

  const [openReportBuilder, setReportBuilder] = useState(false);
  const [reportStyles, setReportStyles] = useLocalStorage<Branding>(
    "report-styling",
    {
      contactName: "",
      contactEmail: "",
      phoneNumber: "",
      primaryColor: "#000080",
      accentColor: "#000080",
      fontColor: "#000080",
      yourLogo: "",
      clientLogo: "",
      slogan: "",
      companyName: "",
      scoreAlias: "Cyber Score",
    }
  );

  const [logoInput, setLogoInput] = useState(reportStyles.yourLogo);

  const { mutateAsync: imageToBase64Mutate } = useMutation({
    mutationFn: ({
      url,
    }: // maxWidth,
    // maxHeight,
    {
      url: string;
      // maxWidth: number;
      // maxHeight: number;
    }) => imageToBase64(url),
  });

  const [nProfilePics, setNProfilePics] = useState<number>(2);

  const { cyberScore } = useCyberScoreCombinedQuery(domain);

  const linkedInUrls = cyberScore?.Employees.map((e) => e.SourcePage).filter(
    (e) => e !== null
  );

  const { profilePics, isLoading: isLoadingProfilePics } =
    useLinkedinProfilePics(cyberScore);

  const {
    savePDFScore,
    savePDFScoreCardV2,
    isLoading: isCyberReportDowloaderLoading,
  } = useCyberReportScoreDownloader(reportStyles, domain, profilePics);

  const { url } = useFavicon(domain);

  const { base64Data: prospectLogo, isLoading: prospectLogoLoading } =
    useBase64Image(
      url,
      "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png"
    );

  const [overwriteProspectLogo, setOverwriteProspectLogo] = useState<
    string | undefined
  >();

  return (
    <>
      <Button
        borderRadius={"medium"}
        size="small"
        variation="primary"
        gap={"xs"}
        onClick={() => {
          setReportBuilder((prev) => !prev);
        }}
      >
        <FaHammer />
        Report Builder
      </Button>

      <View
        position={"absolute"}
        style={{ zIndex: "99" }}
        top={"0"}
        as="dialog"
        width={"100%"}
        open={openReportBuilder}
        height={"100%"}
        padding={"0"}
        backgroundColor={"overlay.20"}
        border={"0"}
      >
        <Flex
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Card
            backgroundColor={"white"}
            width={"100%"}
            maxWidth={"800px"}
            padding="large"
            borderRadius={"large"}
            boxShadow={"medium"}
          >
            <ScrollView>
              <Flex
                direction={"column"}
                // height={"100%"}
                justifyContent={"space-between"}
              >
                <Flex justifyContent={"space-between"} alignItems={"start"}>
                  <Flex direction={"column"} gap={"0"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={"larger"}
                      color={"blue.90"}
                    >
                      Report Builder
                    </Text>
                  </Flex>

                  <Flex gap={"xs"}>
                    <Button
                      color="white"
                      backgroundColor={"red.60"}
                      borderRadius={"medium"}
                      onClick={() => {
                        setReportBuilder((prev) => !prev);
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </Flex>
                </Flex>
                <Grid
                  templateColumns={{ base: "1fr", medium: "2fr 1fr" }}
                  gap={"medium"}
                >
                  <Flex direction={"column"} gap={"0"}>
                    <Button
                      alignSelf={"flex-start"}
                      variation="link"
                      size="small"
                      padding={"xs"}
                      fontSize={"smaller"}
                      onClick={() => {
                        setReportStyles({
                          contactName: "",
                          contactEmail: "",
                          phoneNumber: "",
                          primaryColor: "#000080",
                          accentColor: "#000080",
                          fontColor: "#000080",
                          yourLogo: "",
                          clientLogo: "",
                          slogan: "",
                          companyName: "",
                          scoreAlias: "Cyber Score",
                        });
                      }}
                    >
                      <u> Reset to Default</u>
                    </Button>

                    <Grid
                      templateColumns={"auto auto"}
                      gap={"xs"}
                      padding={"xs"}
                    >
                      <Text fontSize={"small"}>Header Background</Text>
                      <input
                        type="color"
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "0px",
                          borderColor: "transparent",
                          outline: "0px",
                          outlineColor: "transparent",
                          background: "none",
                          padding: "0",
                        }}
                        value={reportStyles.primaryColor}
                        onChange={(e) =>
                          setReportStyles({
                            ...reportStyles,
                            primaryColor: e.target.value,
                          })
                        }
                      />

                      <Text fontSize={"small"}>Card Background</Text>

                      <input
                        type="color"
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "0px",
                          borderColor: "transparent",
                          outline: "0px",
                          outlineColor: "transparent",
                          background: "none",
                          padding: "0",
                        }}
                        value={reportStyles.accentColor}
                        onChange={(e) =>
                          setReportStyles({
                            ...reportStyles,
                            accentColor: e.target.value,
                          })
                        }
                      />

                      <Text fontSize={"small"}>Border Color</Text>

                      <input
                        type="color"
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "0px",
                          borderColor: "transparent",
                          outline: "0px",
                          outlineColor: "transparent",
                          background: "none",
                          padding: "0",
                        }}
                        value={reportStyles.fontColor}
                        onChange={(e) =>
                          setReportStyles({
                            ...reportStyles,
                            fontColor: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Flex
                      padding={"medium xs small xs"}
                      direction={"column"}
                      gap={"small"}
                    >
                      <Flex>
                        <View flex={1}>
                          <ImageUploader
                            dataBase64={
                              overwriteProspectLogo ?? prospectLogo ?? ""
                            }
                            onChangeBase64={(data) => {
                              setOverwriteProspectLogo(data);
                            }}
                            isLoading={prospectLogoLoading}
                          ></ImageUploader>
                        </View>
                        <View flex={1}>
                          <ImageUploader
                            dataBase64={reportStyles.yourLogo}
                            onChangeBase64={(data) => {
                              setReportStyles({
                                ...reportStyles,
                                yourLogo: data,
                              });
                            }}
                          ></ImageUploader>
                        </View>
                      </Flex>
                      <TextField
                        label="Please enter the URL for your logo image:"
                        fontSize={"smaller"}
                        style={{
                          fontSize: "var(--amplify-font-sizes-smaller)",
                        }}
                        placeholder="https://"
                        value={logoInput}
                        onChange={async (e) => {
                          setLogoInput(e.target.value);
                          imageToBase64Mutate({
                            url: e.target.value,
                            // maxWidth: 50,
                            // maxHeight: 50,
                          }).then((res) => {
                            setReportStyles({
                              ...reportStyles,
                              yourLogo: res,
                            });
                          });
                        }}
                      />
                      <Flex width={"100%"}>
                        <TextField
                          label="Contact Name"
                          fontSize={"smaller"}
                          style={{
                            fontSize: "var(--amplify-font-sizes-smaller)",
                          }}
                          placeholder="John Smith"
                          value={reportStyles.contactName}
                          onChange={async (e) => {
                            setReportStyles({
                              ...reportStyles,
                              contactName: e.target.value,
                            });
                          }}
                        />
                        <TextField
                          flex={1}
                          label="Contact Email"
                          type="email"
                          fontSize={"smaller"}
                          style={{
                            fontSize: "var(--amplify-font-sizes-smaller)",
                          }}
                          placeholder="report@builder.com"
                          value={reportStyles.contactEmail}
                          onChange={async (e) => {
                            setReportStyles({
                              ...reportStyles,
                              contactEmail: e.target.value,
                            });
                          }}
                        />
                      </Flex>
                      {/* <TextField
                            label="Phone #"
                            type="tel"
                            fontSize={"smaller"}
                            style={{
                              fontSize: "var(--amplify-font-sizes-smaller)",
                            }}
                            placeholder="4161234567"
                            value={reportStyles.phoneNumber}
                            onChange={async (e) => {
                              setReportStyles({
                                ...reportStyles,
                                phoneNumber: e.target.value,
                              });
                            }}
                          /> */}
                    </Flex>
                  </Flex>

                  <Flex
                    height={"300px"}
                    width={"100%"}
                    justifyContent={"center"}
                  >
                    <View
                      // width={"100%"}
                      boxShadow={"0 2px 10px -5px rgba(0, 0, 0, 0.4)"}
                      style={{ aspectRatio: "8/11" }}
                      borderRadius={"small"}
                    >
                      <Grid
                        width={"100%"}
                        height={"100%"}
                        templateColumns={"1fr 1fr"}
                        templateRows={"1fr 2fr 5fr"}
                        gap={"small"}
                      >
                        <View
                          columnSpan={2}
                          backgroundColor={reportStyles.primaryColor}
                          borderRadius={"small small 0 0"}
                        >
                          <Grid templateColumns={"1fr 1fr 1fr"} padding={"xs"}>
                            {reportStyles.yourLogo !== "" ? (
                              <Image
                                column={3}
                                alt={undefined}
                                src={reportStyles.yourLogo}
                                height={"20px"}
                              />
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </View>
                        <View
                          marginTop={"large"}
                          marginLeft={"small"}
                          borderRadius={"medium"}
                          columnStart={1}
                          columnSpan={1}
                          backgroundColor={reportStyles.accentColor}
                        ></View>
                        <View
                          marginLeft={"small"}
                          marginBottom={"small"}
                          borderRadius={"medium"}
                          columnStart={1}
                          columnSpan={1}
                          boxShadow={
                            "inset 0 0 0 3px " + reportStyles.fontColor
                          }
                          backgroundColor={"transparent"}
                        ></View>
                      </Grid>
                    </View>
                  </Flex>
                </Grid>
                <Flex
                  justifyContent={"center"}
                  wrap={"wrap"}
                  padding={"small xs 0 xs"}
                >
                  <Button
                    color="white"
                    data-attr="download-score-card"
                    size="small"
                    fontSize={"smaller"}
                    borderRadius={"medium"}
                    backgroundColor={"blue.90"}
                    gap={"xs"}
                    isLoading={isCyberReportDowloaderLoading}
                    onClick={() => {
                      savePDFScore();
                    }}
                  >
                    <FaDownload /> Score Card
                  </Button>
                  <Button
                    color="white"
                    data-attr="download-score-card"
                    size="small"
                    fontSize={"smaller"}
                    borderRadius={"medium"}
                    backgroundColor={"blue.90"}
                    gap={"xs"}
                    isLoading={isCyberReportDowloaderLoading}
                    onClick={() => {
                      savePDFScoreCardV2();
                    }}
                  >
                    <FaDownload />
                    NEW Score Card <FaStarOfLife color="red.60" />
                  </Button>
                  <Flex alignItems={"end"}>
                    <Button
                      variation="primary"
                      colorTheme="info"
                      data-attr="load-profile-pics"
                      size="small"
                      fontSize={"smaller"}
                      borderRadius={"medium"}
                      backgroundColor={"#144ee3"}
                      gap={"xs"}
                      isDisabled={
                        isCyberReportDowloaderLoading ||
                        (linkedInUrls
                          ? nProfilePics >= linkedInUrls.length
                          : false)
                      }
                      loadingText="Loading Profiles, this may 5 minutes..."
                      isLoading={isLoadingProfilePics}
                      onClick={() => {
                        if (linkedInUrls)
                          setNProfilePics((prev) =>
                            Math.min(prev + 2, linkedInUrls?.length)
                          );
                      }}
                    >
                      <FaLinkedin /> Find Profile Pictures
                    </Button>
                    <Text>
                      Searched {nProfilePics}/{linkedInUrls?.length} profiles
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </ScrollView>
          </Card>
        </Flex>
      </View>
    </>
  );
}
