import { View, Image, Text } from "@react-pdf/renderer";

import React from "react";
import lock from "../../assets/images/pdf/lock.png";
import ex from "../../assets/images/pdf/cs-red-x.png";
import check from "../../assets/images/pdf/cs-green-check.png";
import na from "../../assets/images/pdf/cs-not-available.png";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";

export interface IReportKeyTakeawaysProps {
  styles: any;
  userColor: string | undefined;
  cyberScore: CyberScoreCombined | undefined;
}

export default function ScoreCardKeyTakeaways(props: IReportKeyTakeawaysProps) {
  const { styles, userColor, cyberScore } = props;

  const processScoreSimple = (input: any) => {
    let results = {};
    let iconWAF;
    let iconESS;

    if (input["ESSScore"] === 1) {
      iconESS = check;
    } else if (input["ESSScore"] === -1) {
      iconESS = ex;
    } else {
      iconESS = na;
    }
    if (input["WAFScore"] === 1) {
      iconWAF = check;
    } else if (input["WAFScore"] === -1) {
      iconWAF = ex;
    } else {
      iconWAF = na;
    }
    results = { iconESS, iconWAF };
    return results;
  };
  const updatedStatus: any = processScoreSimple(cyberScore);

  return (
    <View
      style={[
        styles.keyTakeaways,
        { backgroundColor: userColor, borderColor: userColor },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: "0 0 0 10px",
        }}
      >
        <Image src={lock} style={{ width: "40px", height: "auto" }} />
        <Text
          style={{
            fontSize: "20px",
            textDecoration: "underline",
            textDecorationColor: "#FF343A",
            fontWeight: "medium",
          }}
        >
          Top Stats
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          padding: "5px 0 5px 0",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            margin: "0 5px 0 15px",
            color: "#FF343A",
            fontWeight: "bold",
            width: "10%",
            textAlign: "center",
          }}
        >
          {/* {calcResults.table.reduce((acc, item) => acc + item.count, 0)} */}
          {cyberScore?.BreachList.length}
        </Text>
        <Text
          style={{
            fontSize: "12px",
          }}
        >
          Email accounts for sale on the Dark Web
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          padding: "5px 0 5px 0",
          alignItems: "center",
        }}
      >
        <Image
          src={updatedStatus.iconESS}
          style={{
            width: "30px",
            height: "auto",
            objectFit: "contain",
            margin: "0 5px 0 15px",
          }}
        />
        <View style={{ flexDirection: "column" }}>
          <Text
            style={{
              width: "85%",
              fontSize: "12px",
            }}
          >
            Email protected from impersonations
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          padding: "5px 0 10px 0",
          alignItems: "center",
        }}
      >
        {" "}
        <Image
          src={updatedStatus.iconWAF}
          style={{
            width: "30px",
            height: "auto",
            objectFit: "contain",
            margin: "0 5px 0 15px",
          }}
        />
        <Text
          style={{
            width: "85%",
            fontSize: "12px",
          }}
        >
          Web application firewall protecting traffic
        </Text>
      </View>
    </View>
  );
}
