import { Grid, View } from "@aws-amplify/ui-react";
import React, { useState } from "react";

// import CyberReportDownloadButton from "../monitorcompany/CyberReportDownloadButton";

import TabsContainer from "./MonitorCompany.TabsContainer";
import Header from "./MonitorCompany.Header";
import Details from "./MonitorCompany.Details";
import Charts from "./MonitorCompany.Charts";

export default function MonitorCompany() {
  const [assetId, setAssetId] = useState<string | null>(null);
  const [nvtId, setNvtId] = useState<string | null>(null);



  return (
    <Grid
      paddingTop={"large"}
      paddingBottom={"large"}
      height={"100%"}
      gap={"small"}
      templateColumns={"1fr 2fr"}
      templateRows={"auto auto minmax(0, 1fr)"}
    >
      <View columnSpan={2}>
        <Header />
      </View>

      <Details />
      <Charts />
      <View columnSpan={2}>
        <TabsContainer
          assetId={assetId}
          setAssetId={setAssetId}
          nvtId={nvtId}
          setNvtId={setNvtId}
        ></TabsContainer>
      </View>
    </Grid>
  );
}
