import React from "react";
import gauge from "../../assets/images/pdf/gauge.png";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { View, Image, Text } from "@react-pdf/renderer";

type ReportScoreProps = {
  styles: any;
  cyberScore: CyberScoreCombined | undefined;
};

export default function ReportScore({ cyberScore }: ReportScoreProps) {
  return (
    <View
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Image
        src={gauge}
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
        }}
      />
      <Text
        style={{
          position: "relative",
          fontSize: "64px",
          color: "#404040",
          fontWeight: "extrabold",
          top: "45px",
        }}
      >
        {cyberScore?.Score}
        <Text
          style={{
            fontSize: "28px",
            verticalAlign: "super",
            top: "-30",
          }}
        >
          %
        </Text>
      </Text>
      <Text
        style={{
          position: "relative",
          fontWeight: "bold",
          top: "35px",
        }}
      >
        Cyber Score
      </Text>
    </View>
    // </View>
    // </View>
  );
}
