import { CompromisedAccountsTableData } from "..";

export function CompromisedAccountsSortFunction(
  sortColumn: keyof CompromisedAccountsTableData | undefined,
  a: CompromisedAccountsTableData,
  b: CompromisedAccountsTableData
) {
  if (!sortColumn) return 0;
  const A = a[sortColumn];
  const B = b[sortColumn];

  if (A === B) return 0;

  if (A === "") return -1;
  if (B === "") return 1;
  if (
    sortColumn === "email" ||
    sortColumn === "name" ||
    sortColumn === "position"
  ) {
    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) {
      return -1;
    }
    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) {
      return 1;
    }
  } else {
    if (A < B) {
      return -1;
    }
    if (A > B) {
      return 1;
    }
  }
  return 0;
}
