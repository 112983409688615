import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { Branding } from "../monitorcompany/ReportBuilderButton";

export const BreachTable = ({
  cyberScore,
  reportStyles,
}: {
  cyberScore: CyberScoreCombined;
  reportStyles: Branding;
}) => {
  const {
    Employees,
     BreachList
  } = cyberScore;

  // const BreachList = [
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "admin@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "nasy@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "juliak@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "stephenm@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(24)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "info@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(24)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "secretariat@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  // ];

  // console.log("Breach List", BreachList);

  const breachedEmployees = Employees.map((e) => ({
    ...e,
    Breaches: BreachList.filter((b) => b.Email === e.Email),
  }))
    .sort((a, b) => {
      const aScore = (a.FirstName ? 1 : 0) + (a.Position ? 1 : 0);
      const bScore = (b.FirstName ? 1 : 0) + (b.Position ? 1 : 0);

      return bScore - aScore;
    })
    .sort((a, b) => b.Breaches.length - a.Breaches.length);

  return (
    <View style={{ fontSize: "10px" }}>
      <View
        style={{
          flexDirection: "row",
          borderBottom: "2px",
          borderBottomColor: reportStyles.accentColor,
        }}
      >
        <Text style={{ fontWeight: "bold", width: "150px" }}>Top Accounts</Text>
        <Text style={{ fontWeight: "bold", width: "150px" }}>Name</Text>
        <Text style={{ fontWeight: "bold", width: "150px" }}>Position</Text>
        <Text
          style={{
            fontWeight: "bold",
            width: "70px",
            textAlign: "center",
          }}
        >
          Breaches
        </Text>
      </View>
      <View
        style={{
          paddingTop: "5px",
        }}
      >
        {breachedEmployees
          .map((e, i) => (
            <View key={i} style={{ flexDirection: "row", gap: "5px" }}>
              <Text
                style={{
                  width: "180px",
                  maxLines: 1,
                  textOverflow: "ellipsis",
                }}
              >
                {e.Email}
              </Text>
              <Text
                style={{
                  width: "180px",
                  maxLines: 1,
                  textOverflow: "ellipsis",
                }}
              >
                {e.FirstName} {e.LastName}
              </Text>
              <Text
                style={{
                  width: "180px",
                  maxLines: 1,
                  textOverflow: "ellipsis",
                }}
              >
                {e.Position}
              </Text>
              <Text
                style={{
                  width: "80px",
                  color: "#FF343A",
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {e.Breaches.length}
              </Text>
            </View>
          ))
          .slice(0, 10)}
      </View>
    </View>
  );
};
