import { useMemo } from "react";
import { EChartsType } from "echarts";
import { useECharts } from "../../hooks/useECharts";
import { View } from "@aws-amplify/ui-react";
import React from "react";
import useAssetTypeOptions from "../../hooks/useAssetTypeOptions";

type UseITLandscapeGraph = [
  React.FC,
  EChartsType | null,
  () => string | undefined
];

export const useITLandscapeChart = (): UseITLandscapeGraph => {
  const options = useAssetTypeOptions();

  const { ref, chartInstance, getImageData } = useECharts(options);

  const Chart = useMemo(
    () => () => <View ref={ref} width={"300px"} height={"150px"} />,
    []
  );

  return [Chart, chartInstance, getImageData];
};
