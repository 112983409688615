import { CompanyGroup, ListCompaniesOutput } from "../../types/companies";
import { getRequest } from "../restAPI";

export async function listCompanies(
  memberId: string,
  groupId: CompanyGroup,
  campaignId?: string
) {
  try {
    const companiesList = await getRequest(`/companies?`, {
      queryParams: campaignId
        ? {
            member_id: memberId,
            group_id: groupId,
            campaign_id: campaignId,
          }
        : {
            member_id: memberId,
            group_id: groupId,
          },
    }).then(async (response) => {
      return await response.body.json().then((json) => {
        const data = json as any as ListCompaniesOutput;

        return data;
      });
    });
    return companiesList as ListCompaniesOutput;
  } catch (error) {
    console.error(error);
    throw new Error("Error listing companies.");
  }
}
