import * as React from "react";
import {
  Flex,
  Button,
  Table,
  TableCell,
  TableRow,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { FaSave, FaTimes } from "react-icons/fa";
import TableTitle from "../styling/TableTitle";
import { Scanner, UpdateScannerRequest } from "../../types/scanners";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import { Modal } from "../Modal";
import ConfirmScannerUpdate from "./ConfirmScannerUpdate";

export type ScannerPortfolioProps = {
  onClose: () => void;
  onSave: (updateScannerRequest: UpdateScannerRequest) => void | Promise<void>;
  selectedScanner: Scanner | undefined;
};

export default function ScannerDetailsForm(props: ScannerPortfolioProps) {
  const { onSave, onClose, selectedScanner } = props;

  const { companies: businesses } = useCompaniesQuery(1);

  const createUpdateScannerRequest = (
    oldScanner: Scanner,
    newScanner: Scanner
  ): UpdateScannerRequest => {
    const request: UpdateScannerRequest = {
      Operation: "update",
      ScannerID: oldScanner.ScannerID,
    };

    // go over rest of attributes and if different add them to the request object
    if (oldScanner.Name !== newScanner.Name) {
      request.Name = newScanner.Name;
    }
    if (oldScanner.CUID !== newScanner.CUID) {
      request.CUID = newScanner.CUID;
    }

    // still need to handle email requests

    return request;
  };

  const [scanner, setScanner] = React.useState<Scanner | undefined>();

  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (selectedScanner) setScanner(selectedScanner);
  }, [selectedScanner]);

  const updated = React.useMemo(() => {
    if (selectedScanner === undefined) return false;
    if (scanner === undefined) return false;

    if (selectedScanner.Name !== scanner.Name) return true;
    if (selectedScanner.CUID !== scanner.CUID) return true;

    return false;
  }, [selectedScanner, scanner]);

  const updatingCompany = React.useMemo(() => {
    if (selectedScanner === undefined) return false;
    if (scanner === undefined) return false;
    if (selectedScanner.CUID !== scanner.CUID) return true;
    return false;
  }, [selectedScanner, scanner]);

  const doubleCheckModal = React.useRef<HTMLDialogElement>(null);

  const saveChanges = React.useCallback(async () => {
    setIsSaving(true);
    if (selectedScanner !== undefined && scanner !== undefined) {
      await onSave(createUpdateScannerRequest(selectedScanner, scanner));
    }
    setIsSaving(false);
  }, [onSave, selectedScanner, scanner]);

  return (
    <>
      <Modal ref={doubleCheckModal} size="medium">
        <ConfirmScannerUpdate
          sensorId={selectedScanner?.ScannerID ?? -1}
          companyId={scanner?.CUID ?? -1}
          onBack={() => doubleCheckModal.current?.close()}
          onSave={saveChanges}
          isSaving={isSaving}
        ></ConfirmScannerUpdate>
      </Modal>
      <Flex direction={"column"} width={"100%"}>
        <Flex justifyContent={"space-between"}>
          <TableTitle title={"Scanner Details"} />
          <Flex gap={"xs"}>
            <Button
              variation="primary"
              borderRadius={"medium"}
              isLoading={isSaving}
              isDisabled={!updated}
              onClick={async () => {
                if (updatingCompany) {
                  doubleCheckModal.current?.showModal();
                  return;
                }

                await saveChanges();
              }}
            >
              <FaSave />
            </Button>
            <Button
              color="white"
              backgroundColor={"red.60"}
              borderRadius={"medium"}
              onClick={() => {
                onClose();
              }}
            >
              <FaTimes />
            </Button>
          </Flex>
        </Flex>

        <Flex direction={"column"} gap="large">
          {/* <Grid
                templateColumns={{ base: "1fr", medium: "1fr 1fr" }}
                gap={"medium"}
              > */}
          <Table size="small">
            <TableRow>
              <TableCell as="th">Scanner Name</TableCell>
              <TableCell>
                <TextField
                  label="Scanner Name"
                  labelHidden
                  value={scanner?.Name ?? ""}
                  onChange={(event) => {
                    setScanner((prev) => {
                      if (!prev) return;
                      return {
                        ...prev,
                        Name: event.target.value,
                      };
                    });
                  }}
                />
              </TableCell>
              {/* <TableCell>{selectedScanner?.Name}</TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell as="th">Company</TableCell>
              <TableCell>
                <SelectField
                  label=""
                  descriptiveText=""
                  size="small"
                  variation="quiet"
                  value={scanner?.CUID?.toString()}
                  onChange={(event) => {
                    setScanner((prev) => {
                      if (!prev) return;
                      return {
                        ...prev,
                        CUID: parseInt(event.target.value),
                      };
                    });
                  }}
                >
                  {businesses?.map((business) => (
                    <option value={business.CUID.toString()}>
                      {business.CompanyName}
                    </option>
                  ))}
                </SelectField>
              </TableCell>
              {/* <TableCell>{company?.CompanyName}</TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell as="th">Public IP</TableCell>
              <TableCell>{selectedScanner?.PublicIP}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell as="th">GeoLocation City</TableCell>
              <TableCell>{selectedScanner?.GeolocationCity}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell as="th">Serial Number</TableCell>
              <TableCell>
                {selectedScanner?.Type === "Sensor"
                  ? selectedScanner?.SerialNumber
                  : selectedScanner?.Type === "Container"
                  ? selectedScanner?.HostID
                  : "-"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell as="th">MAC Address</TableCell>
              <TableCell>
                {selectedScanner?.Type === "Sensor"
                  ? selectedScanner?.MacAddress
                  : "-"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell as="th">Scanner Type</TableCell>
              <TableCell>{selectedScanner?.Type}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell as="th">Last Seen</TableCell>
              <TableCell>
                {timeStampToLocaleString(selectedScanner?.LastSeen)}
              </TableCell>
            </TableRow>
          </Table>
          {/* <Flex>Map of stuff goes here</Flex> */}
          {/* </Grid> */}

          {/* <Flex>Chart of stuff goes here</Flex> */}
        </Flex>
      </Flex>
    </>
  );
}
