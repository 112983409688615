import {
  ScrollView,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import * as React from "react";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import Row from "./RelatedAssetsTable.Row";

export type RelatedAssetsTableProps = {
  vulnId: string;
  selectedAssetId: string | null;
  setSelectedAssetId: React.Dispatch<React.SetStateAction<string | null>>;
};

export function RelatedAssetsTable({
  vulnId,
  selectedAssetId,
  setSelectedAssetId,
}: RelatedAssetsTableProps) {
  const { company, assets: allAssets } = useMonitorCompanyContext();

  const relatedAssets = React.useMemo(() => {
    return allAssets?.filter((asset) => {
      return asset.nvts.some((nvt) => nvt.nvt_oid === vulnId);
    });
  }, [allAssets, vulnId]);

  return (
    <>
      <ScrollView>
        <Table highlightOnHover={true} size="small">
          <TableHead>
            <TableRow>
              <TableCell fontSize={"xs"} as="th">
                Risks
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                IP Address
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                MAC Vendor
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                Brand
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                Type
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          {relatedAssets?.map((asset) => (
            <Row
              vulnId={vulnId}
              key={asset.DEVICE_ID}
              asset={asset}
              company={company}
              selectedAssetId={selectedAssetId}
              setSelectedAssetId={setSelectedAssetId}
            />
          ))}
        </Table>
      </ScrollView>
    </>
  );
}
