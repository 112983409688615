import { GetAssetInventorySummaryResponse } from "../../types/assetInventoryApi";
import { getRequest } from "../restAPI";

export async function getAssetInventorySummary(
  companyIds: number[]
): Promise<GetAssetInventorySummaryResponse> {
  try {
    const assetInventoryList = await getRequest(
      `/asset-inventory/summary/${companyIds.join(",")}`
    ).then(async (response) => {
      return await response.body.json().then((json) => {
        const assetInventoryList =
          json as any as GetAssetInventorySummaryResponse;

        return assetInventoryList;
      });
    });
    return assetInventoryList as GetAssetInventorySummaryResponse;
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching asset inventory summary.");
  }
}
