export const glossaryTerms = [
  {
    term: "Secure Email Gateway",
    meaning:
      "Filter that blocks malicious emails. Prevents hackers from tricking employees into harmful actions like divulging sensitive information.",
  },
  {
    term: "Website Headers",
    meaning:
      "Rules on your website that tell browsers how to safely handle your site's content. Protects your site and its visitors from attacks.",
  },
  {
    term: "Dark Web",
    meaning:
      "Hidden part of the internet often used for illegal activities. Important to monitor for stolen data or illegal activities related to the business.",
  },
  {
    term: "IoT (Internet of Things)",
    meaning:
      "Devices like cameras, sensors, and printers connected to the internet. Secure these to prevent hackers from taking control and accessing your network.",
  },
  {
    term: "Breached Account",
    meaning:
      "Online account that has been hacked into. Confidential data could be stolen and misused.",
  },
  {
    term: "Assets Vulnerabilities",
    meaning:
      "Weak spots in your computer systems and software. Identifying and fixing these prevents attacks and unauthorized access to business data.",
  },
  {
    term: "DMARC",
    meaning:
      "Prevents fraudsters from sending deceptive emails that appear to be from your business. Protect your brand reputation. Don't let customers receive fake emails.",
  },
  {
    term: "DKIM",
    meaning:
      "Security stamp that proves your emails haven’t been tampered with. Helps \n prevent email spoofing and maintains email integrity.",
  },
  {
    term: "SPF",
    meaning:
      "Verifies if emails sent from your domain are legitimate. Prevents spammers from sending emails that appear to come from your business.",
  },
  {
    term: "FTP Security Bypass",
    meaning:
      "Protecting the way files are transferred to prevent unauthorized access. Keeps sensitive files secure during transfer to ensure they aren't intercepted or stolen.",
  },
  {
    term: "Phishing",
    meaning:
      "Fake emails or websites designed to steal information. Educating employees about these can prevent data breaches and loss of sensitive information.",
  },
  {
    term: "Ransomware",
    meaning:
      "Malware that locks your files unless you pay a ransom. Critical business data could be encrypted and held hostage, demanding payment for access.",
  },
  {
    term: "Firewall",
    meaning:
      "Security system that controls incoming and outgoing network traffic and shields from unauthorized access. Without it, hackers could easily access and steal \n business information.",
  },
  {
    term: "VPN (Virtual Private Network)",
    meaning:
      "Private network that encrypts your internet connection and prevents your \n information from being intercepted. Safeguards data, especially when using public Wi-Fi.",
  },
  {
    term: "Two-Factor Authentication (2FA)",
    meaning:
      "Extra verification step when logging into accounts, adding an additional layer of security and protection. Accounts could be easily hacked with just a password.",
  },
  {
    term: "Malware",
    meaning:
      "Software designed to damage or gain unauthorized access to your computer. Could lead to data loss, theft, or system damage.",
  },
  {
    term: "Encryption",
    meaning:
      "Turning data into a code to prevent unauthorized access. Keeps sensitive data secure, even if intercepted. Unencrypted data could be read and misused if stolen.",
  },
  {
    term: "Intrusion Detection System (IDS)",
    meaning:
      "Monitors network for suspicious activity. Alerts you to potential cyber threats in real-time. Undetected, an attack could lead to data breaches or system damage.",
  },
  {
    term:"Exploitable Software \n Vulnerabilities", 
    meaning: "Weaknesses in software used by hackers to gain unauthorized access or cause harm. Up-to-date software can prevent attackers from exploiting these \n vulnerabilities.",
  },
  {
    term:"Aging Devices", 
    meaning: "Older devices may not support the latest security updates and are more \n susceptible to cyber attacks.",
  },
  {
    term:"Email Impersonation Security", 
    meaning: "Prevents unauthorized users from sending emails that appear to come from a legitimate source. Customers and employees could fall victim to phishing scams, leading to data breaches, brand damage, and financial loss.",
  }
]