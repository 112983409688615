import { Flex, Button, SelectField } from "@aws-amplify/ui-react";
import React, { Suspense } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { getOffsetTimestamp } from "../../util/getOffsetTimestamp";
import Filter from "./MonitorCompany.Filter";
import { useNavigate } from "react-router-dom";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import ScanProgress from "./MonitorCompany.Header.ScanProgress";

export default function Header() {
  const navigate = useNavigate();

  const { scanners, company, scannerId, isLoading, setTimeRangeDays } =
    useMonitorCompanyContext();

  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <Flex>
        <Button
          variation="link"
          alignItems={"center"}
          gap={"xs"}
          size="small"
          onClick={() => navigate(`/cyber-audit`)}
        >
          <FaArrowLeft />
          Cyber Audits
        </Button>
        <SelectField
          label="Scanner"
          labelHidden
          size="small"
          isDisabled={isLoading}
          value={scannerId}
          onChange={(e) => {
            const scanner = scanners?.find(
              (s) => s.ScannerID === parseInt(e.target.value)
            );

            const end_timestamp = scanner?.LastSeen?.split(".")[0];
            if (end_timestamp === undefined || end_timestamp === null) {
              toast.info(`This scanner has not scanned yet.`);
              e.preventDefault();
              return;
            }

            const start_timestamp = getOffsetTimestamp(end_timestamp, -24);

            navigate(
              `/monitor/${company?.CUID}/${scanner?.ScannerID}/?start_timestamp=${start_timestamp}&end_timestamp=${end_timestamp}`
            );
          }}
        >
          {scanners?.map((scanner, i) => (
            <option
              key={i}
              // disabled={scanner.Details.LastScan === null}
              value={scanner.ScannerID}
            >
              {scanner.Type === "Sensor"
                ? scanner.SerialNumber
                : scanner.Type === "Container"
                ? `${scanner.HostID}-${scanner.ContainerID}`
                : ""}
            </option>
          ))}
        </SelectField>
        <SelectField
          label="Time Range"
          labelHidden
          size="small"
          isDisabled={isLoading}
          defaultValue={"7"}
          // value={scannerId}
          onChange={(e) => {
            const days = parseInt(e.target.value);
            setTimeRangeDays(days);
          }}
        >
          <option value={1}>Latest Day</option>
          <option value={7}>Latest Week</option>
          <option value={30}>Latest Month</option>
          <option value={365}>Latest Year</option>
        </SelectField>

        <Filter />
      </Flex>

      <ScanProgress />

      <Flex gap={"xxs"}>
        {/* <TopCyberRisks /> */}
        <Suspense fallback={<Button isLoading />}>
          {/* <BrandingButton /> */}
          {/* <CyberReportDownloadButton company={company} /> */}
        </Suspense>
      </Flex>
    </Flex>
  );
}
