import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { Branding } from "../../components/monitorcompany/ReportBuilderButton";

type ReportContactInfoProps = {
  reportStyles: Branding;
};

export default function ReportContactInfo({
  reportStyles,
}: ReportContactInfoProps) {
  const truncatedName =
    reportStyles.contactName.length > 25
      ? reportStyles.contactName.substring(0, 25) + "..."
      : reportStyles.contactName;
  const truncatedEmail =
    reportStyles.contactEmail.length > 35
      ? reportStyles.contactEmail.substring(0, 35) + "..."
      : reportStyles.contactEmail;

  return (
    <View
      style={{
        flexDirection: "column",
        fontSize: "12px",
        color: "grey",
        textAlign: "right",
      }}
    >
      <Text
        style={{
          textAlign: "right",
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
          }}
        >
          Created by:{" "}
        </Text>
        <Text>{truncatedName}</Text>
      </Text>
      <Text
        style={{
          textAlign: "right",
        }}
      >
        {truncatedEmail}
      </Text>
    </View>
  );
}
