import "../../style/assetDetailsPopOutPanel.css";
import { SelectField } from "@aws-amplify/ui-react";
import React from "react";
import { VulnStatus } from "../../services/updateNvtStatus";
import { nvtStatusOptions } from "../../util/nvtStatusOptions";

type NvtStatus = {
  status?: "To Do" | "In Progress" | "Resolved" | "Accepted" | "Ignored" | null;
  onChange?: (
    status: "To Do" | "In Progress" | "Resolved" | "Accepted" | "Ignored"
  ) => void | Promise<void>;
};

export default function StatusSelectField({ status, onChange }: NvtStatus) {
  return (
    <SelectField
      label={"Status"}
      labelHidden
      width={"95px"}
      border={"0px"}
      inputStyles={{
        fontSize: "smaller",
        padding: "0px 3px",
        border: "0",
      }}
      size={"small"}
      value={status ?? "To Do"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onChange={(e) => {
        onChange?.(e.target.value as VulnStatus);
      }}
    >
      {/* Iterate of NvtStatus enum for options */}
      {nvtStatusOptions.map((status, i) => (
        <option
          key={i}
          value={status.label}
          style={{
            backgroundColor: status.backgroundColor,
            color: status.color,
            border: "0px",
          }}
        >
          {status.label}
        </option>
      ))}
    </SelectField>
  );
}
