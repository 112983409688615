import * as React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import xMark from "../../assets/images/pdf/cs-red-x.png";
import checkMark from "../../assets/images/pdf/cs-green-check.png";
import notAvail from "../../assets/images/pdf/cs-not-available.png";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";

export const ScoringCriteriaItems = ({
  cyberScore,
}: {
  cyberScore: CyberScoreCombined;
}) => {
  const getScoreImage = (score: -1 | 0 | 1) => {
    switch (score) {
      case -1:
        return xMark;
      case 0:
        return notAvail;
      case 1:
        return checkMark;
      default:
        return xMark;
    }
  };

  return (
    <View style={{ fontSize: "10px", gap: "10px" }}>
      <View
        style={{
          flexDirection: "row",
          gap: "10px",
          width: "100%",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={getScoreImage(cyberScore.ESSScore)}
              style={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </View>
          <View style={{ width: "190px" }}>
            <Text style={{ fontWeight: "semibold" }}>
              Email Impersonation Security
            </Text>
            <Text>
              {cyberScore.ESSScore === 1
                ? "Email secured with DMARC protecting against spoofing and impersonations."
                : cyberScore.ESSScore === -1
                ? "Missing valid DMARC spoofing protection to prevent email impersonations."
                : "No email service associated with this domain."}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={getScoreImage(cyberScore.ISHScore)}
              style={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </View>
          <View style={{ width: "190px" }}>
            <Text style={{ fontWeight: "semibold" }}>
              Website HTTP Security Settings
            </Text>
            <Text>
              {cyberScore.ISHScore === 1
                ? "Website securely configured to protect against malicious abuse."
                : cyberScore.ISHScore === -1
                ? `Missing ${
                    cyberScore.ISH.split(" ")[0]
                  } important settings to secure the site's visitor activity`
                : "No website associated with this domain."}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          gap: "10px",
          width: "100%",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={getScoreImage(cyberScore.BDVScore)}
              style={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </View>
          <View style={{ width: "190px" }}>
            <Text style={{ fontWeight: "semibold" }}>Dark Web Breaches</Text>
            <Text>
              {cyberScore.BDVScore === 1
                ? `No accounts for sale on the Dark Web out of ${cyberScore.EmailList.length} total email addresses.`
                : cyberScore.BDVScore === -1
                ? `${cyberScore.BreachList.length} accounts for sale on the Dark Web out of ${cyberScore.EmailList.length} total email addresses.`
                : "No email service associated with this domain."}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={getScoreImage(cyberScore.WIScore)}
              style={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </View>
          <View style={{ width: "190px" }}>
            <Text style={{ fontWeight: "semibold" }}>
              Website SSL Encryption
            </Text>
            <Text>
              {cyberScore.WIScore === 1
                ? `Website traffic is encrypted until ${
                    cyberScore.WI.split(" ")[2]
                  } by an SSL certificate.`
                : cyberScore.WIScore === -1
                ? `Website missing SSL certificate exposing visitor traffic to data theft.`
                : "No website associated with this domain."}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          gap: "10px",
          width: "100%",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={getScoreImage(cyberScore.SEGScore)}
              style={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </View>
          <View style={{ width: "190px" }}>
            <Text style={{ fontWeight: "semibold" }}>Secure Email Gateway</Text>
            <Text>
              {cyberScore.SEGScore === 1
                ? cyberScore.SEG
                : cyberScore.SEGScore === -1
                ? `No email service detected that provides spam/phishing protection.`
                : "No email service associated with this domain."}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={getScoreImage(cyberScore.WAFScore)}
              style={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </View>
          <View style={{ width: "190px" }}>
            <Text style={{ fontWeight: "semibold" }}>
              Web Application Firewall
            </Text>
            <Text>
              {cyberScore.WAFScore === 1
                ? cyberScore.WAF
                : cyberScore.WAFScore === -1
                ? `The site is not protected from malicious visitors.`
                : "No website associated with this domain."}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
