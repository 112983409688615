import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { invokeLambda } from "../aws/lambda/invokeFunction";

export type KeyTakeaways = {
  ["key-takeaway"]: string;
  threats:
    | {
        name: string;
        detail: string;
      }[]
    | undefined;
};

export async function genReconKeyTakeaways(cyberScore: CyberScoreCombined) {
  const response = await invokeLambda("TextGenApiHandler", {
    genType: "recon-key-takeaways",
    body: cyberScore,
  }).catch((err) => {
    console.error(err);
    throw err;
  });
  return JSON.parse(response.body) as KeyTakeaways;
}
