import React from 'react';
import logo from "../../../src/assets/images/logo-dark.png";
import {
    Authenticator,
    View,
    Image,
    Text,
    useAuthenticator,
    Button,
    CheckboxField,
    ScrollView,
    Grid,
    Flex,
    Link,
    Heading
} from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import TooltipHoverView from '../styling/TooltipHoverView';
import { FaInfoCircle } from 'react-icons/fa';



const AuthComponent = (props: any) => {
    const { route } = useAuthenticator(context => [context.route]);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (route === 'authenticated') {
            navigate('/getting-started');
        }
    }, [route, navigate]);


    /* ----- Components & Fields ----- */
    const components = {
        Header() {
            return (
                <>
                </>
            );
        },

        Footer() {
            return (
                <View textAlign="center" padding={"large"}>
                    <Text color={"neutral.80"}>
                        &copy; All Rights Reserved
                    </Text>
                </View>
            );
        },

        SignIn: {
            Header() {

                return (
                    <>
                    </>

                );
            },
            Footer() {
                const { toForgotPassword } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toForgotPassword}
                            size="small"
                            variation="link"
                        >
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },

        SignUp: {
            FormFields() {
                const { validationErrors } = useAuthenticator();

                return (
                    <>
                        <Authenticator.SignUp.FormFields />
                        <CheckboxField
                            // errorMessage={validationErrors.acknowledgement}
                            hasError={!!validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            label={
                                <Text>I agree with the <Link href='https://www.icebergcyber.com/terms' isExternal={true}>Terms and Conditions</Link> and <Link href='https://www.icebergcyber.com/privacy' isExternal={true}>Privacy Policy</Link></Text>
                            }
                        />
                    </>
                );
            },
            Header() {
                return (
                    <>
                    </>

                );
            },
            Footer() {
                const { toSignIn } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toSignIn}
                            size="small"
                            variation="link"
                        >
                            Back to Sign In
                        </Button>
                    </View>
                );
            },
        },

        SetupTotp: {
            Header() {

                return (
                    <>
                        <Heading>
                            Setup Multi-Factor Authentication{" "}<TooltipHoverView TooltipContent={

                                <Text width={"200px"} style={{padding:'6px'}} textAlign={"left"} color={"white"}>Recommended Auth Apps:
                                    <ul style={{padding:'10px'}}>
                                       <a color='var(--amplify-colors-neutral-90)' target='_blank' href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'> <li ><Text color="white">Google Authenticator</Text></li></a>


                                       <a color='var(--amplify-colors-neutral-90)' target='_blank' href='https://www.microsoft.com/en-ca/security/mobile-authenticator-app'> <li ><Text color="white">Microsoft Authenticator</Text></li></a>


                                       <a color='var(--amplify-colors-neutral-90)' target='_blank' href='https://authy.com/download/'> <li ><Text color="white">Authy</Text></li></a>

                              
                                    </ul>
                                </Text>

                            } color={'neutral.90'} children={<FaInfoCircle />} />
                        </Heading>
                        <Flex gap={"0"} direction={"column"}>
                            <Text fontSize={"small"}><b>Step 1:</b> Scan the QR Code OR enter the Setup Key into your Auth App</Text>
                            <Text fontSize={"small"}><b>Step 2:</b> Enter the Code from your Auth App into the field below</Text>

                        </Flex>
                    </>
                );
            },
            Footer() {
                return <>
                    {/* Optional footer */}
                </>;
            },
        },



        // ConfirmSignUp: {
        //     Header() {
        //         return (
        //             <>

        //             </>
        //         );
        //     },
        //     Footer() {
        //         return
        //         (
        //             <Text>
        //                 {/* Footer Information */}
        //             </Text>
        //         )
        //     },
        // },

        // ConfirmSignIn: {
        //     Header() {
        //         return (
        //             <>

        //             </>
        //         );
        //     },
        //     Footer() {
        //         return null
        //     },
        // },

        // ResetPassword: {
        //     Header() {
        //         return (
        //             <>
        //             </>
        //         );
        //     },
        //     Footer() {
        //         return null
        //     },
        // },

        // ConfirmResetPassword: {
        //     Header() {
        //         return (
        //             <>

        //             </>
        //         );
        //     },
        //     Footer() {
        //         return

        //         (
        //             <Text>
        //                 {/* Footer Information */}
        //             </Text>
        //         )
        //     },
        // },
    };

    const formFields = {
        signIn: {
            username: {
                placeholder: 'e.g. example@gmail.com',
                isRequired: true,
                label: 'Email:',
                order: 1,
            },

            password: {
                label: 'Password:',
                placeholder: '•••••••••••',
                isRequired: true,
                order: 2,
            },

        },
        signUp: {
            email: {
                placeholder: 'e.g. example@gmail.com',
                label: 'Email:',
                order: 1,
            },
            password: {
                label: 'Password:',
                placeholder: '•••••••••••',
                isRequired: true,
                order: 2,
            },
            confirm_password: {
                label: 'Confirm Password:',
                placeholder: '•••••••••••',
                order: 3,
            },
            given_name: {
                label: 'First Name:',
                placeholder: 'First Name',
                isRequired: true,
                order: 4,
            },
            family_name: {
                label: 'Last Name:',
                placeholder: 'Last Name',
                isRequired: true,
                order: 5,
            }
        },
        forceNewPassword: {
            password: {
                placeholder: '•••••••••••',
            },
        },
        resetPassword: {
            email: {
                placeholder: 'e.g. example@gmail.com',
            },
        },

        confirmResetPassword: {
            confirmation_code: {
                label: 'Confirmation Code:',
                placeholder: 'Enter your code',
                isRequired: false,
            },
            password: {
                label: 'New password:',
                placeholder: '•••••••••••',
            },

            confirm_password: {
                label: 'Confirm password:',
                placeholder: '•••••••••••',
            },
        },
        confirmSignIn: {
            confirmation_code: {
                label: 'Confirmation Code:',
                placeholder: 'Enter your code',
                isRequired: false,
            },
        },
    };
    /* ----- ------------------- ----- */

    const validateCustomSignUp = async (formData: any) => {
        if (!formData.acknowledgement) {
            return {
                acknowledgement: '',
            };
        }
        return null;
    };

    return (
        <>

            <ScrollView height={"100vh"}>
                <Grid
                    templateColumns={{ base: "1fr", large: "1fr 1fr" }}
                >

                    <View height={{ base: "0vh", large: "100vh" }}>
                        <View position={"relative"} style={{ clipPath: "polygon(100% 0,78% 100%,0 100%,0 0)", backgroundImage: "linear-gradient(to bottom right, var(--amplify-colors-blue-40), var(--amplify-colors-blue-100))" }} height={{ base: "0vh", large: "100vh" }}>

                        </View>
                    </View>

                    <ScrollView height={"100vh"}>
                        <Flex paddingTop={{ base: "medium", large: "10vh" }} height={"100%"} justifyContent={"center"} alignItems={"start"}>
                            <Flex>
                                <Flex direction={"column"}  >
                                    <View textAlign={"center"} padding={"large"} >
                                        <Image
                                            alt="Iceberg Cyber Logo"
                                            height={"80px"}
                                            src={logo}
                                        />
                                    </View>


                                    <Authenticator
                                        formFields={formFields}
                                        components={components}
                                        services={{ validateCustomSignUp }}
                                    >
                                        {props.children}
                                    </Authenticator>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ScrollView>
                </Grid>

            </ScrollView>

        </>
    );
}

export default AuthComponent;
