/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mafjriyld9.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "TheCyberScoreRestAPI",
            "endpoint": "https://dyklrjv40i.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:59b36ad0-ba37-4813-89ac-60233947785a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_VMYJGmFjJ",
    "aws_user_pools_web_client_id": "644sdgjbaku38ugpetq54uq2hf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "20",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Company-master",
            "region": "us-east-2"
        },
        {
            "tableName": "User-master",
            "region": "us-east-2"
        },
        {
            "tableName": "UserCompany-master",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
