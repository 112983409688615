import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Placeholder,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useState } from "react";
import InformationPanel from "./InformationPanel";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import { Asset } from "../../types/assetInventoryApi";
import { useActionPlanQuery } from "../../hooks/useActionPlanQuery";
import { useKeyTakeawaysQuery } from "../../hooks/useKeyTakeawaysQuery";
import BrandingButton from "./ReportBuilderButton";
import { PDFViewer } from "@react-pdf/renderer";
import useCyberReport from "../../hooks/useCyberReport";
import { useLinkedinProfilePics } from "../../hooks/useLinkedinProfilePics";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";
import { FaDownload } from "react-icons/fa6";
import useScoreCardPDF from "../../hooks/useScoreCardPDF";
import { useBrandingContext } from "../../hooks/useBrandingContext";
import { saveCyberReportToXlsx } from "../../services/export/saveCyberReportToXlsx";

type CyberSalesProps = {};

export default function CyberAutdit({}: CyberSalesProps) {
  const { assets, companyId, company, nvts } = useMonitorCompanyContext();

  const [prospectLogo, setProspectLogo] = useState<string>("");

  const { keyTakeaways, isLoading: ktLoading } = useKeyTakeawaysQuery(
    assets as Asset[],
    companyId
  );

  const { actionPlan, isLoading: apLoading } = useActionPlanQuery(
    assets as Asset[],
    companyId
  );

  const { cyberScore } = useCyberScoreCombinedQuery(company?.EmailDomain);

  const KeyTakeawaysPlaceHolder = () => (
    <Flex direction={"column"} gap={"xxs"}>
      <Placeholder width={"80%"} />
      <Flex direction={"column"} gap={"xxs"} padding={"xxs 0 0 large"}>
        <Placeholder paddingLeft={"xl"} width={"95%"} />
        <Placeholder paddingLeft={"xl"} width={"35%"} />
        <Placeholder paddingLeft={"xl"} />
        <Placeholder paddingLeft={"xl"} width={"20%"} />
      </Flex>
    </Flex>
  );
  const ActionPlanPlaceHolder = () => (
    <Flex direction={"column"} gap={"xxs"} paddingLeft={"large"}>
      <Placeholder paddingLeft={"xl"} />
      <Placeholder paddingLeft={"xl"} width={"32%"} />
      <Placeholder paddingLeft={"xl"} />
      <Placeholder paddingLeft={"xl"} width={"26%"} />
      <Placeholder paddingLeft={"xl"} />
      <Placeholder paddingLeft={"xl"} width={"18%"} />
    </Flex>
  );

  const { profilePics } = useLinkedinProfilePics(cyberScore);

  const { Document, download } = useCyberReport({
    prospectLogo,
    company: company,
    profilePics,
    nvts: nvts ?? [],
    assets: assets ?? [],
  });

  const { branding } = useBrandingContext();

  const { savePDFScoreCardV2 } = useScoreCardPDF(
    branding,
    company?.EmailDomain,
    prospectLogo,
    profilePics
  );

  return (
    <Card variation="elevated" padding={"xl"} borderRadius={"large"}>
      <Grid templateColumns={"3fr auto 2fr"} gap={"medium"}>
        <Flex direction={"column"}>
          <InformationPanel
            header={
              <Flex direction={"column"} gap={"0"}>
                <Heading level={5}>Key Takeaways</Heading>
                <Text fontSize={"small"}>What the big problems are</Text>
              </Flex>
            }
            body={
              !ktLoading && keyTakeaways ? (
                <Card
                  borderRadius={"large"}
                  backgroundColor={"neutral.10"}
                  fontSize={"small"}
                >
                  <Text fontWeight={"bold"}>
                    {keyTakeaways["key-takeaway"]}
                  </Text>
                  <ul>
                    {keyTakeaways?.threats?.map((threat, index) => (
                      <li key={index}>
                        <Text>
                          <b>{threat.name}:</b> {threat.detail}
                        </Text>
                      </li>
                    ))}
                  </ul>
                </Card>
              ) : (
                <Card borderRadius={"large"} backgroundColor={"neutral.10"}>
                  <KeyTakeawaysPlaceHolder />
                </Card>
              )
            }
          ></InformationPanel>
          {/* <Divider /> */}
          <InformationPanel
            header={
              <Flex direction={"column"} gap={"0"}>
                <Heading level={5}>Action Plan</Heading>
                <Text fontSize={"small"}>How you're going to fix them</Text>
              </Flex>
            }
            body={
              !apLoading && actionPlan ? (
                <Card
                  borderRadius={"large"}
                  backgroundColor={"neutral.10"}
                  fontSize={"small"}
                >
                  <ol>
                    {actionPlan?.actions?.map((action, index) => (
                      <li key={index}>
                        <Text>
                          <b>{action.title}:</b> {action.detail}
                        </Text>
                      </li>
                    ))}
                  </ol>
                </Card>
              ) : (
                <Card borderRadius={"large"} backgroundColor={"neutral.10"}>
                  <ActionPlanPlaceHolder />
                </Card>
              )
            }
          ></InformationPanel>
        </Flex>
        <Flex direction="column" gap="medium">
          <BrandingButton
            prospectLogo={prospectLogo}
            setProspectLogo={setProspectLogo}
          />
          <Button
            gap={"small"}
            data-attr="download-cyber-report"
            onClick={() => download()}
          >
            <FaDownload /> Cyber Report
          </Button>
          <Button
            gap={"small"}
            data-attr="download-score-card"
            onClick={() => savePDFScoreCardV2()}
          >
            <FaDownload /> Score Card
          </Button>
          <Button
            gap={"small"}
            data-attr="download-spreadsheet"
            onClick={() =>
              saveCyberReportToXlsx({
                assets,
                cyberScore,
                filename: `${new Date()
                  .toLocaleDateString("en-CA")
                  .split("/")
                  .join("-")} ${
                  branding.companyName ?? "Iceberg Cyber"
                } - Spreadsheet - ${company?.CompanyName}.xlsx`,
              })
            }
          >
            <FaDownload /> Spreadsheet
          </Button>
        </Flex>
        <View
          width={"100%"}
          style={{
            aspectRatio: "10.5/8",
          }}
        >
          {Document !== null ? (
            <PDFViewer
              showToolbar={false}
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {Document}
            </PDFViewer>
          ) : (
            <Placeholder width={"100%"} height={"100%"}></Placeholder>
          )}
        </View>
        {/* {(ktLoading || apLoading || !actionPlan || !keyTakeaways) && (
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Image
              height={"350px"}
              alt="Gif of animated target and users"
              src={loadingGif}
            ></Image>
          </Flex>
        )} */}
      </Grid>
    </Card>
  );
}
