import { useCallback, useMemo } from "react";
import { pdf } from "@react-pdf/renderer";
import React from "react";

import ScoreCard from "../services/exportreport/ScoreCard";
import { Branding } from "../components/monitorcompany/ReportBuilderButton";
import { useCyberScoreCombinedQuery } from "./useCyberScoreCombinedQuery";
import PDFScoreCardDocument from "../components/reports/score-card/PDFScoreCardDocument";
import { useReconKeyTakeawaysQuery } from "./useReconKeyTakeawaysQuery";
import { useReconActionPlanQuery } from "./useReconActionPlanQuery";
import { useBrandingContext } from "./useBrandingContext";

export default function useScoreCardPDF(
  reportStyles: Branding,
  domain: string | undefined | null,
  prospectLogo: string | undefined,
  profilePics: string[]
) {
  const { cyberScore, isLoading: isLoadingCyberScore } =
    useCyberScoreCombinedQuery(domain);

  const { keyTakeaways: reconKeyTakeaways, isLoading: reconKTLoading } =
    useReconKeyTakeawaysQuery(cyberScore, domain);

  const { actionPlan, isLoading: apisLoading } = useReconActionPlanQuery(
    cyberScore,
    domain
  );
  const { branding } = useBrandingContext();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const isLoading = useMemo(
    () => isLoadingCyberScore || reconKTLoading || apisLoading,

    [isLoadingCyberScore, isLoadingCyberScore, reconKTLoading, apisLoading]
  );

  const scoreDoc = useMemo(
    () => (
      <ScoreCard
        companyName={domain}
        domain={""}
        logo={prospectLogo}
        reportStyles={reportStyles}
        cyberScore={cyberScore}
      />
    ),
    [cyberScore, prospectLogo, reportStyles, domain]
  );

  const savePDFScore = useCallback(async () => {
    const asPdf = pdf(scoreDoc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} ${
      branding.companyName ?? "Iceberg Cyber"
    } - Score Card - ${domain}.pdf`;
    link.click();
  }, [cyberScore, prospectLogo, scoreDoc, domain, branding]);

  const savePDFScoreCardV2 = useCallback(async () => {
    if (
      !domain ||
      !cyberScore ||
      !actionPlan ||
      !reconKeyTakeaways ||
      !reportStyles
    ) {
      return;
    }
    setIsDownloading(true);

    const asPdf = pdf(
      <PDFScoreCardDocument
        company={domain ?? ""}
        logo={prospectLogo ?? ""}
        cyberScore={cyberScore}
        actionPlan={actionPlan}
        keyTakeaways={reconKeyTakeaways}
        branding={reportStyles}
        whiteLabelLogo={reportStyles.yourLogo}
        profilePics={profilePics}
      ></PDFScoreCardDocument>
      // <ScoreCard
      //   companyName={company?.CompanyName}
      //   domain={company?.EmailDomain}
      //   logo={logo}
      //   reportStyles={reportStyles}
      //   cyberScore={cyberScore}
      // />
    );
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} ${
      branding.companyName ?? "Iceberg Cyber"
    } - Score Card - ${domain}.pdf`;
    link.click();

    setIsDownloading(false);
  }, [
    domain,
    prospectLogo,
    reportStyles,
    cyberScore,
    actionPlan,
    reconKeyTakeaways,
    profilePics,
    branding,
  ]);

  return { savePDFScore, savePDFScoreCardV2, isLoading, isDownloading };
}
