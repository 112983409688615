import { Flex } from "@aws-amplify/ui-react";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export default function PaddedContainer({ children }: Props) {
  return (
    <Flex direction={"column"} gap="0" padding={"medium"} height={"100%"}>
      <Flex
        height={"52px"}
        padding={"small large 0 large"}
        justifyContent={"right"}
      >
        <Flex gap={"xxs"}></Flex>
      </Flex>
      <Flex width={"100%"} gap="large" direction={"column"} padding={"large"}>
        {children}
      </Flex>
    </Flex>
  );
}
