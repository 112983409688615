import React, { createContext, useContext } from "react";
import { useUserQuery } from "./useUser";
import { AuthUser, FetchUserAttributesOutput } from "aws-amplify/auth";
import { User } from "../types/User";
import useAuthEventListener from "../components/useAuthEventListener";

// Define the context
interface UserContextProps {
  authUser: AuthUser | null | undefined;
  user: User | undefined;
  userAttributes: FetchUserAttributesOutput | undefined;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

type UserContextProviderProps = {
  children: React.ReactNode;
};

// Define the provider
export function UserContextProvider({
  children,
}: UserContextProviderProps): JSX.Element {
  useAuthEventListener();

  const { authUser, user, userAttributes } = useUserQuery();

  return (
    <UserContext.Provider value={{ authUser, user, userAttributes }}>
      {children}
    </UserContext.Provider>
  );
}

// Define a hook for easy access to the context
export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext() must be used within a UserProvider");
  }
  return context;
}
