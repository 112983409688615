import { Grid, Loader } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { getAssetAge } from "../../util/getAssetAge";
import ImportantStatInfoCard from "../styling/ImportantStatInfoCard";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import TooltipHoverView from "../styling/TooltipHoverView";
import { HiInformationCircle } from "react-icons/hi";

type CompanyStatsCardProps = {
  // assets: Asset[];
  // nvts: NVT[];
};

export default function CompanyStatsCard(props: CompanyStatsCardProps) {
  const {} = props;

  const { assets, nvts } = useMonitorCompanyContext();

  const oldAssets = useMemo(
    () =>
      assets !== undefined
        ? assets.filter((asset) => {
            const ageYears = getAssetAge(asset);
            return ageYears > 4;
          })
        : undefined,
    [assets]
  );

  return (
    <Grid
      templateColumns={"1fr 1fr 1fr"}
      alignItems={"center"}
      width={"100%"}
      gap={"small"}
    >
      <ImportantStatInfoCard
        title={"Total Assets"}
        stat={assets ? assets?.length : <Loader />}
      />

      <ImportantStatInfoCard
        title={"Vulnerabilities"}
        stat={nvts ? nvts.length : <Loader />}
      />

      <ImportantStatInfoCard
        title={
          <>
            Aging Devices{" "}
            <TooltipHoverView
              TooltipContent={"The number of devices that are over 4 years old"}
              color={"neutral.90"}
              children={<HiInformationCircle />}
            />
          </>
        }
        stat={oldAssets ? oldAssets.length : <Loader />}
      />
    </Grid>
  );
}
