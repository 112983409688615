import {
  Button,
  Flex,
  Grid,
  Image,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadlineVerticalDivider from "../styling/HeadlineVerticalDivider";
import parser from "tld-extract";

export default function ScoreCardForm() {
  const navigate = useNavigate();

  const [input, setInput] = useState("");

  const handleChangeDomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let domain;
    try {
      const tld = parser(input);
      domain = tld.domain;
    } catch (err) {
      domain = input.split("/")[0].split("?")[0];
    }

    navigate(`/score-card/${domain}`);
  };

  return (
    <>
      <Grid
        height={"100%"}
        templateColumns={"1fr 1fr"}
        gap="large"
        padding={"large"}
        alignItems={"center"}
      >
        <Flex
          as="form"
          direction={"column"}
          justifyContent={"center"}
          onSubmit={onSubmit}
          data-attr="score-card-form"
          data-ph-capture-attribute-domain={input}
        >
          <HeadlineVerticalDivider
            heading={"Create a Score Card"}
            subheading={"Step 1"}
            borderColor={"#174DE1"}
          />

          <Text color={"neutral.80"} fontSize={"small"}>
            Just type in a prospect’s website and see their Cyber Score 💯
          </Text>

          <TextField
            size="small"
            label=""
            maxWidth={"400px"}
            placeholder="Enter a website"
            value={input || ""}
            onChange={handleChangeDomain}
          ></TextField>

          <Flex>
            <Button
              fontWeight={"medium"}
              variation="primary"
              backgroundColor={"#174DE1"}
              borderRadius={"medium"}
              type="submit"
              size="small"
            >
              Create the Score Card
            </Button>
          </Flex>
        </Flex>

        <Image
          flex={1}
          borderRadius="large"
          src="/images/score-card/score-card-blue-p1.png"
          alt=""
          objectFit={"contain"}
          boxShadow={"medium"}
        />
      </Grid>
    </>
  );
}
