import { View, Text, Image } from "@react-pdf/renderer";
import React from "react";

export interface IReportHeaderProps {
  heading: string;
  subheading?: string;
  logoUrl: string;
}

export default function ReportHeader1(props: IReportHeaderProps) {
  const { logoUrl, heading, subheading } = props;

  return (
    <View
      style={{
        width: "85%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "80px",
      }}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: "38px",
            color: "#FFF",
            // fontFamily: "Rubik",
            fontWeight: "bold",
            textDecoration: "underline",
            textDecorationColor: "#FF343A",
          }}
        >
          {heading}
        </Text>
        {subheading ? (
          <Text
            style={{
              fontSize: "28px",
              color: "#FFF",
              textDecorationColor: "#FF343A",
            }}
          >
            {subheading}
          </Text>
        ) : null}
      </View>
      {logoUrl ? (
        <Image
          src={logoUrl}
          style={{
            width: "200px",
            // height: "80px",
            objectFit: "contain",
            marginBottom: "5px",
            padding:"5px"
          }}
        ></Image>
      ) : null}
    </View>
  );
}
