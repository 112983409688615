import React from "react";
import CyberCenterContent from "./CyberCenterContent";
import {
  Table,
  TableCell,
  TableBody,
  Text,
  Link,
  Flex,
  TableRow,
  ScrollView,
} from "@aws-amplify/ui-react";
import { CyberScoreCombined } from "../types/CyberScoreCombined";

export default function EmailSpoofindProtectionInfo({
  cyberScoreData,
}: {
  cyberScoreData?: CyberScoreCombined | undefined;
}) {
  return (
    <CyberCenterContent
      whatIsItContent={
        <>  
          <Text>
            SPF, DKIM, and DMARC are email security methods. SPF verifies sender
            IP addresses, DKIM checks for message tampering, and DMARC
            coordinates SPF and DKIM and instructs email receivers on handling
            failures. Together, they prevent unauthorized sources from sending
            emails pretending to be from your domain. For any business,
            protecting email integrity is crucial. These methods ensure emails
            from your company are authentic and trustworthy, crucial for
            maintaining client confidence and avoiding fraud. To Score a pass,
            the DMARC record must use p=reject.
          </Text>
          {cyberScoreData ? (
            <Flex padding={"small xl xs xl"}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell fontSize={"small"} as="th">
                      SPF
                    </TableCell>
                    <TableCell
                      style={{
                        wordBreak: "break-all",
                      }}
                      fontSize={"smaller"}
                    >
                      {cyberScoreData?.SPF}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell fontSize={"small"} as="th">
                      DKIM
                    </TableCell>
                    <TableCell
                      maxWidth={"80px"}
                      fontSize={"smaller"}
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      <ScrollView maxHeight={"30px"}>
                        {cyberScoreData?.DKIM}
                      </ScrollView>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell fontSize={"small"} as="th">
                      DMARC
                    </TableCell>
                    <TableCell
                      style={{
                        wordBreak: "break-all",
                      }}
                      fontSize={"smaller"}
                    >
                      {cyberScoreData?.DMARC}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Flex>
          ) : null}
        </>
      }
      whyDoesItMatterContent={
        <Text>
          Email impersonation can lead to fraud, data breaches, and loss of
          client trust. For example, as an accounting firm, securing sensitive
          financial information is vital, and email impersonation protection
          helps safeguard this data. Without this protection hackers could send
          emails from your business, which could be used to trick customers or
          employees into revealing confidential information, transferring money,
          or infecting systems with malware.
        </Text>
      }
      howDoYouFixItContent={
        <Text>
          To set up SPF, DKIM, and DMARC for email protection, you need to add
          specific records to your domain's DNS settings. SPF records list
          authorized sending IPs, DKIM adds a digital signature to emails, and
          DMARC policies tell recipients how to handle emails failing these
          checks. Find the exact steps in our{" "}
          <Link
            href="https://www.icebergcyber.com/help-center-category/email-security"
            isExternal
            color={"teal.80"}
            fontWeight={"semibold"}
          >
            Help Center
          </Link>
          .
        </Text>
      }
    ></CyberCenterContent>
  );
}
