import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUserContext } from "../hooks/useUserContext";
import { useMemo } from "react";
import { updateCompanies } from "../services/companies/updateCompanies";
import { UpdateCompaniesInputBody } from "../types/companies";
import { toast } from "react-toastify";
import posthog from "posthog-js";

type CompaniesMutationVariables = {
  body: UpdateCompaniesInputBody;
};

export function useCompaniesMutation() {
  const { user } = useUserContext();

  const params = useMemo(
    () => ({
      member_id: user?.memberId ?? "",
    }),
    [user]
  );

  const queryClient = useQueryClient();

  const { mutateAsync: updateCompaniesMutate, ...mutation } = useMutation({
    mutationKey: ["companies"],
    mutationFn: async ({ body }: CompaniesMutationVariables) => {
      if (!params.member_id) {
        throw new Error("Member ID is not defined.");
      }
      const response = await updateCompanies(params, body);
      return response.Businesses;
    },
    onError: (err, { body }) => {
      console.error(err.message);
      posthog.capture("error", {
        function: "update-companis",
        args: {
          params,
          body,
        },
        message: err.message,
      });
      toast.error("Something went wrong. Please try again.");
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });

      const responses = data;
      if (!responses) {
        console.error("No response from server.");
        return;
      }

      if (responses.some((res) => res.Success === false)) {
        toast.error("Something went wrong. Please try again.");
      }

      // const successfulReqs = responses.filter((res) => res.Success === true);

      // const numCreated = successfulReqs.filter(
      //   (res) => res.Operation === "insert"
      // ).length;

      // const numUpdated = successfulReqs.filter(
      //   (res) => res.Operation === "update"
      // ).length;

      // if (numCreated > 0) {
      //   toast.success(
      //     `${numCreated} ${numCreated === 1 ? "Company" : "Companies"} Created.`
      //   );
      // }
      // if (numUpdated > 0) {
      //   toast.success(
      //     `${numUpdated} ${numUpdated === 1 ? "Company" : "Companies"} Updated.`
      //   );
      // }

      // const requests = context?.body.Businesses;
      // const responses = data;
      // if (responses && responses.length > 0) {
      //   const currentCompanies: Company[] =
      //     queryClient.getQueryData(["companies"]) ?? [];

      //   for (const i = 0; i < responses.length; i++) {
      //     const res = responses[i];
      //     const req = requests[i];
      //     if (res.success) {
      //       if (req.Operation === "insert") {
      //         const CUID = res.CUID;
      //         const { Operation, GroupID, ...company } = req;
      //         if (!company) return
      //         const {
      //           CompanyName: company.CompanyName ?? null,
      //           EmailDomain: company.EmailDomain ?? null,
      //           Website: company.Website ?? null,
      //           Address: company.Address ?? null,
      //           City: company.City ?? null,
      //           Region: company.Region ?? null,
      //           Country: company.Country ?? null,
      //           PostalCode: company.PostalCode ?? null,
      //           Industry: company.Industry ?? null,
      //           Employees: company.Employees ?? [],
      //           PublicIPs: company.PublicIPs ?? [],
      //         } = company;

      //         currentCompanies.push({ ...company, CUID });
      //       }
      //     }
      //   }

      //   queryClient.setQueryData(["companies"], (old: Company[]) => {
      //     for (const failedReqs of responses) {
      //       const index = old.findIndex(
      //         (company) => company.CUID === failedReqs.CUID
      //       );
      //       if (index !== -1) {
      //         old[index] = { ...old[index], ...failedReqs };
      //       }
      //     }
      //     return [...old];
      //   });
      //   queryClient.invalidateQueries({ queryKey: ["companies"] });
      // }
    },
    onSuccess: (_, { body: { Businesses } }) => {
      for (const company of Businesses) {
        console.log("company updated", company);
        posthog.capture("company updated", {
          ...company,
        });
      }
    },
  });

  return {
    updateCompaniesMutate,
    ...mutation,
  };
}
