import React from "react";

import {
  Flex,
  Grid,
  View,
  Text,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ScrollView,
  Tabs,
} from "@aws-amplify/ui-react";

import posthog from "posthog-js";
import InsightsCardReconLite from "../components/recon/InsightsCardReconLite";
import { CyberScoreCombined } from "../types/CyberScoreCombined";
// tried to add these to the interface folder/file but too many issues
export interface PersonalizedRiskItem {
  Email: string;
  BreachDate: string;
  BreachName: string;
}

export interface CyberScoreSimpleType {
  [key: string]: number;
}

export interface CyberScoreDetailedType {
  [key: string]: any[] | string | number;
}

export interface ThemeAttributes {
  colors: Record<string, string>;
  icons: Record<string, JSX.Element>;
  subtitles: Record<string, string>;
}

export enum Menu {
  EmailImpersonationProtection = "Email Impersonation Protection",
  DarkWebEmailBreaches = "Dark Web Email Breaches",
  SecureEmailGateway = "Secure Email Gateway",
  WebsiteSecuritySettings = "Website Security Settings",
  WordPressPluginSecurity = "WordPress Plugin Security",
  WebsiteEncryption = "Website Encryption",
  WebsiteFirewall = "Website Firewall",
}

type Props = {
  cyberScore: CyberScoreCombined | undefined;
};

export function ReconOutputs({ cyberScore }: Props) {
  return (
    <>
      <Flex direction={"column"} gap="0" padding={"large xxxs"}>
        <Grid gap="medium" templateColumns={"1fr"}>
          <Flex direction={"column"} gap="xs">
            <Flex direction={"column"} gap="xs">
              {/* <Accordion.Container
                data-attr="scoring-criteria-cyber-action-plan"
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Accordion.Item value="insight">
                  <Accordion.Trigger>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Flex gap={"xs"} alignItems={"inherit"}>
                        <Divider
                          alignSelf={"stretch"}
                          orientation="vertical"
                          size="large"
                          border={"5px solid #144EE3"}
                          opacity={1}
                          borderRadius={"medium"}
                        />
                        <Flex direction={"column"} gap={"0"}>
                          <Text
                            fontWeight={"bold"}
                            color={"color"}
                            fontSize={"smaller"}
                          >
                            {!ktLoading && keyTakeaways ? (
                              <>{keyTakeaways?.["key-takeaway"]}</>
                            ) : null}
                          </Text>
                          <Text fontSize={"xs"}>Cyber Action Plan</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Accordion.Icon />
                  </Accordion.Trigger>
                  <Accordion.Content>
                    {!apLoading && actionPlan ? (
                      <ul>
                        {actionPlan?.actions?.map((action, index) => (
                          <li key={index}>
                            <Text fontSize={"smaller"}>
                              <b>{action.title}:</b> {action.detail}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Container> */}

              <InsightsCardReconLite
                title={"Dark Web Email Breaches"}
                data-attr="scoring-criteria-dark-web-email-breaches"
                score={cyberScore?.BDVScore}
                subtitle={cyberScore?.BDV}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Flex direction="column">
                  <Flex direction="column" gap="xs">
                    <Flex gap={"xxs"}>
                      <Divider
                        width={"6px"}
                        margin={"3px 0 3px 0"}
                        borderRadius={"4px"}
                        opacity={"100"}
                        borderColor={"#42cefb"}
                        backgroundColor="#42cefb"
                        orientation="vertical"
                      />
                      <Text fontSize={"smaller"} fontWeight="bold">
                        What is it?
                      </Text>
                    </Flex>

                    <Text fontSize={"smaller"}>
                      Dark Web breaches expose emails and passwords, risking
                      hacking and fraud. Respond by resetting passwords, using
                      strong ones, enabling Mulit-Factor Authentication, and
                      education. Detailed instructions in our Help Center.
                    </Text>
                  </Flex>
                </Flex>

                <View padding={"xs 0 0 0"}>
                  <Tabs.Container defaultValue="darkwebreport">
                    <Tabs.List>
                      <Tabs.Item fontSize={"smaller"} value="darkwebreport">
                        Dark Web Report
                      </Tabs.Item>
                      <Tabs.Item fontSize={"smaller"} value="topdarkweb">
                        Compromised Accounts
                      </Tabs.Item>
                    </Tabs.List>
                    {/* <Tabs.Panel value="darkwebreport">
                      <DarkWebReportTableWithCS
                        maxHeight={"300px"}
                        cyberScore={cyberScore}
                        isLoading={false}
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="topdarkweb">
                      <CompromisedAccountsTableWithCS
                        cyberScore={cyberScore}
                        isLoading={false}
                        maxHeight={"300px"}
                      />
                    </Tabs.Panel> */}
                  </Tabs.Container>
                </View>
              </InsightsCardReconLite>

              <InsightsCardReconLite
                data-attr="scoring-criteria-email-impersonation-protection"
                title={"Email Impersonation Protection"}
                score={cyberScore?.ESSScore}
                subtitle={cyberScore?.ESS}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Flex direction="column">
                  <Flex direction="column" gap="xs">
                    <Flex gap={"xxs"}>
                      <Divider
                        width={"6px"}
                        margin={"3px 0 3px 0"}
                        borderRadius={"4px"}
                        opacity={"100"}
                        borderColor={"#42cefb"}
                        backgroundColor="#42cefb"
                        orientation="vertical"
                      />
                      <Text fontSize={"smaller"} fontWeight="bold">
                        What is it?
                      </Text>
                    </Flex>

                    <Text fontSize={"smaller"}>
                      SPF, DKIM, and DMARC make sure emails are really from who
                      they say they're from. This stops criminals from
                      pretending to be someone else in emails. Detailed
                      instructions in our Help Center to set up them correctly.
                    </Text>
                  </Flex>
                </Flex>

                <Flex padding={"small xl xs xl"}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell fontSize={"small"} as="th">
                          SPF
                        </TableCell>
                        <TableCell
                          style={{
                            wordBreak: "break-all",
                          }}
                          fontSize={"smaller"}
                        >
                          {cyberScore?.SPF}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell fontSize={"small"} as="th">
                          DKIM
                        </TableCell>
                        <TableCell
                          maxWidth={"80px"}
                          fontSize={"smaller"}
                          style={{
                            wordBreak: "break-all",
                          }}
                        >
                          <ScrollView maxHeight={"30px"}>
                            {cyberScore?.DKIM}
                          </ScrollView>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell fontSize={"small"} as="th">
                          DMARC
                        </TableCell>
                        <TableCell
                          style={{
                            wordBreak: "break-all",
                          }}
                          fontSize={"smaller"}
                        >
                          {cyberScore?.DMARC}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Flex>
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Secure Email Gateway"}
                data-attr="scoring-criteria-security-email-gateway"
                score={cyberScore?.SEGScore}
                subtitle={cyberScore?.SEG}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Flex direction="column">
                  <Flex direction="column" gap="xs">
                    <Flex gap={"xxs"}>
                      <Divider
                        width={"6px"}
                        margin={"3px 0 3px 0"}
                        borderRadius={"4px"}
                        opacity={"100"}
                        borderColor={"#42cefb"}
                        backgroundColor="#42cefb"
                        orientation="vertical"
                      />
                      <Text fontSize={"smaller"} fontWeight="bold">
                        What is it?
                      </Text>
                    </Flex>

                    <Text fontSize={"smaller"}>
                      Secure Email Gateways filter out harmful emails like spam
                      and phishing, safeguarding business data and client trust.
                      Since 90% of all attacks come through email, they are a
                      smart investment, reducing the risk of financial loss.
                    </Text>
                  </Flex>
                </Flex>
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Website Security Settings"}
                data-attr="scoring-criteria-web-security-settings"
                score={cyberScore?.ISHScore}
                subtitle={cyberScore?.ISH}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Flex direction="column">
                  <Flex direction="column" gap="xs">
                    <Flex gap={"xxs"}>
                      <Divider
                        width={"6px"}
                        margin={"3px 0 3px 0"}
                        borderRadius={"4px"}
                        opacity={"100"}
                        borderColor={"#42cefb"}
                        backgroundColor="#42cefb"
                        orientation="vertical"
                      />
                      <Text fontSize={"smaller"} fontWeight="bold">
                        What is it?
                      </Text>
                    </Flex>

                    <Text fontSize={"smaller"}>
                      Secure Email Gateways filter out harmful emails like spam
                      and phishing, safeguarding business data and client trust.
                      Purchasing one through your IT provider is a smart
                      investment, reducing the risk of breaches and financial
                      loss.
                    </Text>
                  </Flex>
                </Flex>
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Website Encryption"}
                data-attr="scoring-criteria-website-encryption"
                score={cyberScore?.WIScore}
                subtitle={cyberScore?.WI}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Flex direction="column">
                  <Flex direction="column" gap="xs">
                    <Flex gap={"xxs"}>
                      <Divider
                        width={"6px"}
                        margin={"3px 0 3px 0"}
                        borderRadius={"4px"}
                        opacity={"100"}
                        borderColor={"#42cefb"}
                        backgroundColor="#42cefb"
                        orientation="vertical"
                      />
                      <Text fontSize={"smaller"} fontWeight="bold">
                        What is it?
                      </Text>
                    </Flex>

                    <Text fontSize={"smaller"}>
                      An SSL certificate encrypts data between a user and your
                      website, keeping sensitive information secure. Without it,
                      data transmission is open to interception, risking
                      financial fraud and legal trouble. Learn more in our Help
                      Center.
                    </Text>
                  </Flex>
                </Flex>
              </InsightsCardReconLite>

              <InsightsCardReconLite
                title={"Website Firewall"}
                data-attr="scoring-criteria-website-firewall"
                score={cyberScore?.WAFScore}
                subtitle={cyberScore?.WAF}
                onClick={() => {
                  posthog.capture("Score Criteria Card Clicked");
                }}
              >
                <Flex direction="column">
                  <Flex direction="column" gap="xs">
                    <Flex gap={"xxs"}>
                      <Divider
                        width={"6px"}
                        margin={"3px 0 3px 0"}
                        borderRadius={"4px"}
                        opacity={"100"}
                        borderColor={"#42cefb"}
                        backgroundColor="#42cefb"
                        orientation="vertical"
                      />
                      <Text fontSize={"smaller"} fontWeight="bold">
                        What is it?
                      </Text>
                    </Flex>

                    <Text fontSize={"smaller"}>
                      A Web Application Firewall filters web traffic, blocking
                      hacking attempts to protect your website and client data.
                      Without it, your site is vulnerable to attacks, risking
                      fraud and reputational damage. Learn more in our Help
                      Center.
                    </Text>
                  </Flex>
                </Flex>
              </InsightsCardReconLite>
            </Flex>
          </Flex>
        </Grid>
      </Flex>
    </>
  );
}
