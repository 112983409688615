/**
 * Converts a priority number to a string.
 * @param priority The priority in number form.
 * @returns A string representation of the priority.
 */

export function priorityToString(priority: 0 | 1 | 2 | 3) {
  return priority === 0
    ? "Critical"
    : priority === 1
    ? "High"
    : priority === 2
    ? "Medium"
    : "Low";
}
