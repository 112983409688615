import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";

import GettingStartedGuide from "../components/getting-started/GettingStartedGuide";

export default function GettingStartedRoutes() {
  return (
    <>
      <DashboardLayout>
        <Routes>
          <Route index element={<GettingStartedGuide />} />
        </Routes>
      </DashboardLayout>
    </>
  );
}
