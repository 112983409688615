import { VulnTableData } from "..";

export function vulnSortFunction(
  sortColumn: keyof VulnTableData | undefined,
  a: VulnTableData,
  b: VulnTableData
) {
  if (!sortColumn) return 0;

  const A = a[sortColumn];
  const B = b[sortColumn];

  if (sortColumn === "priority") {
    return A < B ? 1 : -1;
  }

  if (typeof A === "string" && A.trim() === "") return 1;
  if (typeof B === "string" && B.trim() === "") return -1;
  return A > B ? 1 : -1;
}
