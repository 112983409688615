import { Divider, Flex, Link, Tabs, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import DarkWebReportTable from "../../../components/monitorcompany/DarkWebReportTable";
import CompromisedAccountsTable from "../../../components/monitorcompany/CompromisedAccountsTable";

type Props = {
  domain: string | undefined;
};

export function BDVDetails({ domain }: Props) {
  return (
    <>
      <Flex direction="column">
        <Flex direction="column" gap="xs">
          <Flex gap={"xxs"}>
            <Divider
              width={"6px"}
              margin={"3px 0 3px 0"}
              borderRadius={"4px"}
              opacity={"100"}
              borderColor={"#42cefb"}
              backgroundColor="#42cefb"
              orientation="vertical"
            />
            <Text fontSize={"smaller"} fontWeight="bold">
              What is it?
            </Text>
          </Flex>

          <Text fontSize={"smaller"}>
            Dark Web breaches expose emails and passwords, risking hacking and
            fraud. Respond by resetting passwords, using strong ones, enabling
            Mulit-Factor Authentication, and education. Detailed instructions in
            our{" "}
            <Link
              isExternal
              textDecoration={"underline"}
              fontWeight={"bold"}
              href="https://www.icebergcyber.com/help-center-category/website-security"
            >
              Help Center
            </Link>
            .
          </Text>
        </Flex>
      </Flex>

      <View padding={"xs 0 0 0"}>
        <Tabs.Container defaultValue="darkwebreport">
          <Tabs.List>
            <Tabs.Item fontSize={"smaller"} value="darkwebreport">
              Dark Web Report
            </Tabs.Item>
            <Tabs.Item fontSize={"smaller"} value="topdarkweb">
              Compromised Accounts
            </Tabs.Item>
          </Tabs.List>
          <Tabs.Panel value="darkwebreport">
            <DarkWebReportTable maxHeight={"300px"} domain={domain} />
          </Tabs.Panel>
          <Tabs.Panel value="topdarkweb">
            <CompromisedAccountsTable domain={domain} maxHeight={"300px"} />
          </Tabs.Panel>
        </Tabs.Container>
      </View>
    </>
  );
}
