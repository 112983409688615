import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import { Authenticator } from "@aws-amplify/ui-react";
import CenteredLoader from "../components/CenteredLoader";
import CyberAudit from "../pages/CyberAudit";

export default function CyberAuditRoutes() {
  return (
    <>
      <Authenticator>
        <DashboardLayout>
          <Suspense fallback={<CenteredLoader />}>
            <Routes>
              <Route index element={<CyberAudit />} />
            </Routes>
          </Suspense>
        </DashboardLayout>
      </Authenticator>
    </>
  );
}
