import {
  UpdateScannersOutput,
  UpdateScannersInputParams,
  UpdateScannersInputBody,
} from "../../types/scanners";
import { postRequest } from "../restAPI";

export async function updateScanners(
  params: UpdateScannersInputParams,
  body: UpdateScannersInputBody
) {
  try {
    const updateScannersResponse = await postRequest(`/scanners`, {
      queryParams: params,
      body: body,
    }).then(async (response) => {
      return await response.body.json().then((json) => {
        const data = json as any as UpdateScannersOutput;

        return data;
      });
    });
    return updateScannersResponse as UpdateScannersOutput;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating scanners.");
  }
}
