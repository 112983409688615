import { postRequest } from "./restAPI";

export async function imageToBase64(
  url: string,
  maxWidth?: number,
  maxHeight?: number
) {
  try {
    const result = await postRequest("/imageToBase64", {
      body: { url, maxWidth, maxHeight },
    });

    const base64 = await result.body.text();

    return base64;
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching image.");
  }
}
