import { Divider, Flex, Link, Text } from "@aws-amplify/ui-react";
import React from "react";
import { CyberScoreCombined } from "../../../types/CyberScoreCombined";

type Props = {
  cyberScore?: CyberScoreCombined | undefined;
};

export function WAFDetails({ cyberScore }: Props) {
  return (
    <Flex direction="column">
      <Flex direction="column" gap="xs">
        <Flex gap={"xxs"}>
          <Divider
            width={"6px"}
            margin={"3px 0 3px 0"}
            borderRadius={"4px"}
            opacity={"100"}
            borderColor={"#42cefb"}
            backgroundColor="#42cefb"
            orientation="vertical"
          />
          <Text fontSize={"smaller"} fontWeight="bold">
            What is it?
          </Text>
        </Flex>

        <Text fontSize={"smaller"}>
          A Web Application Firewall filters web traffic, blocking hacking
          attempts to protect your website and client data. Without it, your
          site is vulnerable to attacks, risking fraud and reputational damage.
          Learn more in our{" "}
          <Link
            isExternal
            textDecoration={"underline"}
            fontWeight={"bold"}
            href="https://www.icebergcyber.com/help-center-category/website-security"
          >
            Help Center
          </Link>
          .
        </Text>
      </Flex>
      {cyberScore ? (
        <Flex direction="column" gap="xs">
          <Flex gap={"xxs"}>
            <Divider
              width={"6px"}
              margin={"3px 0 3px 0"}
              borderRadius={"4px"}
              opacity={"100"}
              borderColor={"#42cefb"}
              backgroundColor="#42cefb"
              orientation="vertical"
            />
            <Text fontSize={"smaller"} fontWeight="bold">
              Your Results
            </Text>
          </Flex>

          <Text fontSize={"smaller"}>{cyberScore?.WAF}</Text>
        </Flex>
      ) : null}
    </Flex>
  );
}
