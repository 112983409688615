import React, { createContext, useContext, useState } from "react";

// Define the context type
type MonitorCompanyFilterContextType = {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
};

type Filter = {
  priority: {
    Critical: boolean;
    High: boolean;
    Medium: boolean;
    Low: boolean;
  };
  impact: {
    High: boolean;
    Medium: boolean;
    Low: boolean;
  };
  likelihood: {
    High: boolean;
    Medium: boolean;
    Low: boolean;
  };

  status: {
    ["To Do"]: boolean;
    ["In Progress"]: boolean;
    ["Resolved"]: boolean;
    ["Accepted"]: boolean;
    ["Ignored"]: boolean;
  };
  assets: {
    ["In Scope"]: boolean;
    ["Out of Scope"]: boolean;
    ["No Risks"]: boolean;
  };
};

// type PriorityValue = "High" | "Medium" | "Low" | string;
// type StatusValue = "To Do" | "In Progress" | "Resolved" | "Ignored" | string;
// type AssetsValue = "In Scope" | "Out of Scope" | string;

type MonitorCompanyFilterContextProviderProps = {
  children: React.ReactNode;
};
const MonitorCompanyFilterContext =
  createContext<MonitorCompanyFilterContextType>({
    filter: {
      // priority: ["Critcal", "High", "Medium", "Low"],
      priority: {
        Critical: true,
        High: true,
        Medium: true,
        Low: false,
      },
      impact: {
        High: true,
        Medium: true,
        Low: true,
      },
      likelihood: {
        High: true,
        Medium: true,
        Low: true,
      },
      status: {
        ["To Do"]: true,
        ["In Progress"]: true,
        ["Resolved"]: true,
        ["Accepted"]: true,
        ["Ignored"]: true,
      },
      assets: {
        ["In Scope"]: true,
        ["Out of Scope"]: true,
        ["No Risks"]: true,
      },
    },
  } as MonitorCompanyFilterContextType);

export function MonitorCompanyFilterContextProvider(
  props: MonitorCompanyFilterContextProviderProps
) {
  const { children } = props;

  const [filter, setFilter] = useState<Filter>({
    priority: {
      Critical: true,
      High: true,
      Medium: true,
      Low: true,
    },
    impact: {
      High: true,
      Medium: true,
      Low: true,
    },
    likelihood: {
      High: true,
      Medium: true,
      Low: true,
    },
    status: {
      ["To Do"]: true,
      ["In Progress"]: true,
      ["Resolved"]: true,
      ["Accepted"]: true,
      ["Ignored"]: true,
    },
    assets: {
      ["In Scope"]: true,
      ["Out of Scope"]: true,
      ["No Risks"]: true,
    },
  });

  // Add your context provider logic here
  return (
    <MonitorCompanyFilterContext.Provider
      value={{
        filter,
        setFilter,
      }}
    >
      {children}
    </MonitorCompanyFilterContext.Provider>
  );
}

// Define the hook to access the context
export const useMonitorCompanyFilterContext =
  (): MonitorCompanyFilterContextType => {
    const context = useContext(MonitorCompanyFilterContext);
    if (!context) {
      throw new Error(
        "useMonitorCompanyContext must be used within a MonitorCompanyFilterContextProvider"
      );
    }
    return context;
  };
