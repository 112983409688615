import {
  Button,
  CheckboxField,
  Divider,
  Fieldset,
  Flex,
  Heading,
} from "@aws-amplify/ui-react";
import React from "react";

import { FaFilter } from "react-icons/fa";
import { useMonitorCompanyFilterContext } from "../../hooks/useMonitorCompanyFilterContext";
import { Modal } from "../Modal";

type PriorityValue = "Critical" | "High" | "Medium" | "Low";

type StatusValue =
  | "To Do"
  | "In Progress"
  | "Resolved"
  | "Ignored"
  | "Accepted";
type AssetsValue = "In Scope" | "Out of Scope";

type Filter = {
  priority: PriorityValue[];
  status: StatusValue[];
  assets: AssetsValue[];
};

export default function Filter() {
  const { filter, setFilter } = useMonitorCompanyFilterContext();

  const dialogRef = React.useRef<HTMLDialogElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  // Use setFilter to update the state
  const handlePriorityFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      const value = e.target.value as "Critical" | "High" | "Medium" | "Low";

      newFilter.priority[value] = e.target.checked;
      return newFilter;
    });
  };

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      const value = e.target.value as
        | "To Do"
        | "In Progress"
        | "Resolved"
        | "Ignored"
        | "Accepted";

      newFilter.status[value] = e.target.checked;
      return newFilter;
    });
  };

  const handleAssetsFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      const value = e.target.value as "In Scope" | "Out of Scope";

      newFilter.assets[value] = e.target.checked;
      return newFilter;
    });
  };

  const handleImpactFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      const value = e.target.value as "High" | "Medium" | "Low";

      newFilter.impact[value] = e.target.checked;
      return newFilter;
    });
  };

  const handleLikelihoodFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      const value = e.target.value as "High" | "Medium" | "Low";

      newFilter.likelihood[value] = e.target.checked;
      return newFilter;
    });
  };

  const setAllFilterValues = (value: boolean) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      newFilter.priority = {
        Critical: value,
        High: value,
        Medium: value,
        Low: value,
      };
      newFilter.impact = {
        High: value,
        Medium: value,
        Low: value,
      };
      newFilter.likelihood = {
        High: value,
        Medium: value,
        Low: value,
      };
      newFilter.status = {
        ["To Do"]: value,
        ["In Progress"]: value,
        ["Resolved"]: value,
        ["Accepted"]: value,
        ["Ignored"]: value,
      };
      newFilter.assets = {
        ["In Scope"]: value,
        ["Out of Scope"]: value,
        ["No Risks"]: value,
      };
      return newFilter;
    });
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllFilterValues(e.target.checked);
  };

  const allChecked = React.useMemo(() => {
    return (
      Object.values(filter.priority).every((v) => v) &&
      Object.values(filter.status).every((v) => v) &&
      Object.values(filter.assets).every((v) => v)
    );
  }, [filter]);

  return (
    <>
      <Flex alignItems={"start"}>
        <Button
          ref={buttonRef}
          size="small"
          fontWeight={"normal"}
          borderRadius={"small"}
          backgroundColor={"white"}
          gap={"xs"}
          onClick={() => {
            if (dialogRef.current?.open) dialogRef.current?.close();
            else dialogRef.current?.showModal();
          }}
        >
          Filter <FaFilter />
        </Button>
      </Flex>
      <Modal ref={dialogRef} size="medium">
        <Flex direction={"column"}>
          <CheckboxField
            label="Select all"
            name="all"
            checked={allChecked}
            onChange={handleSelectAll}
          />
          <Divider />
          <Flex direction={"column"} gap={"xs"}>
            <Heading>Priority</Heading>
            <Fieldset legend="Priority" legendHidden direction={"row"}>
              {Object.entries(filter.priority).map(([key, value]) => (
                <CheckboxField
                  key={key}
                  label={key}
                  name={`priority-filter-${key}`}
                  value={key}
                  checked={value}
                  onChange={handlePriorityFilterChange}
                />
              ))}
            </Fieldset>
          </Flex>
          <Divider />
          <Flex direction={"column"} gap={"xs"}>
            <Heading>Impact</Heading>
            <Fieldset legend="Impact" legendHidden direction={"row"}>
              {Object.entries(filter.impact).map(([key, value]) => (
                <CheckboxField
                  key={key}
                  label={key}
                  name={`impact-filter-${key}`}
                  value={key}
                  checked={value}
                  onChange={handleImpactFilterChange}
                />
              ))}
            </Fieldset>
          </Flex>
          <Divider />
          <Flex direction={"column"} gap={"xs"}>
            <Heading>Likelihood</Heading>
            <Fieldset legend="Likelihood" legendHidden direction={"row"}>
              {Object.entries(filter.likelihood).map(([key, value]) => (
                <CheckboxField
                  key={key}
                  label={key}
                  name={`likelihood-filter-${key}`}
                  value={key}
                  checked={value}
                  onChange={handleLikelihoodFilterChange}
                />
              ))}
            </Fieldset>
          </Flex>
          <Divider />
          <Flex direction={"column"} gap={"xs"}>
            <Heading>Status</Heading>
            <Fieldset legend="Status" legendHidden direction={"row"}>
              {Object.entries(filter.status).map(([key, value]) => (
                <CheckboxField
                  key={key}
                  label={key}
                  name={`status-filter-${key}`}
                  value={key}
                  checked={value}
                  onChange={handleStatusFilterChange}
                />
              ))}
            </Fieldset>
          </Flex>
          <Divider />
          <Flex direction={"column"} gap={"xs"}>
            <Heading>Assets</Heading>
            <Fieldset legend="Assets" legendHidden direction={"row"}>
              {Object.entries(filter.assets).map(([key, value]) => (
                <CheckboxField
                  key={key}
                  label={key}
                  name={`assets-filter-${key}`}
                  value={key}
                  checked={value}
                  onChange={handleAssetsFilterChange}
                />
              ))}
            </Fieldset>
            <Button onClick={() => dialogRef.current?.close()}>Apply</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
