import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  View,
  Font,
} from "@react-pdf/renderer";

//icon srcs

import ScoreCardKeyTakeaways from "./ScoreCardKeyTakeaways";
import ReportCompanyDetails from "./ReportCompanyDetails";
import ReportDarkWebReport from "./ReportDarkWebReport";
import ScoringCriteria from "./ScoringCriteria";
import Glossary from "./ReportGlossary";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { glossaryTerms } from "./glossaryTerms";
import { Branding } from "../../components/monitorcompany/ReportBuilderButton";
import ReportHeader1 from "./ReportHeader1";
import ReportContactInfo from "./ReportContactInfo";
import ReportScore from "./ReportScore";

// Register font
Font.register({
  family: "Rubik",
  fonts: [
    { src: "/fonts/Rubik-Regular.ttf" },
    { src: "/fonts/Rubik-Medium.ttf", fontWeight: "medium" },
    { src: "/fonts/Rubik-SemiBold.ttf", fontWeight: "semibold" },
    { src: "/fonts/Rubik-Bold.ttf", fontWeight: "bold" },
    // { src: "/fonts/Rubik-SemiBold.ttf", fontWeight: "medium" },
    // { src: "/fonts/Rubik-SemiBold.ttf", fontWeight: "semibold" },
    // { src: "/fonts/Rubik-Bold.ttf", fontWeight: "bold" },
  ],
});

/* ============================================================= */
/* ************************ STYLESHEET ************************* */

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#000080",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    fontFamily: "Rubik",
  },
  baseCard: {
    width: "100%",
    height: "85%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topSection: {
    // height: "30vh",
    // backgroundColor: "red",
    flexDirection: "row",
    padding: "10px",
    gap: "20px",
  },
  middleSection: {
    height: "65vh",
    flexDirection: "row",
    padding: "10px",
    gap: "20px",
  },
  bottomSection: {
    height: "10px", //matches the margin for colLeft
    flexDirection: "row",
    padding: "10px",
    gap: "20px",
    margin: "0 10px 0 10px",
  },
  textHeading: {
    fontSize: "20px",
  },
  textSubheading: {
    fontSize: "16px",
  },
  textMain: {
    fontSize: "12px",
  },
  textSmall: {
    fontSize: "10px",
  },
  colLeft: {
    flex: 1.1,
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "10px",
  },
  colRight: {
    flex: 0.9,
    flexDirection: "column",
    marginRight: "20px",
  },
  companyDetails: {
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
    marginBottom: "5px",
    flexDirection: "row",
    color: "#000080",
    alignSelf: "center",
  },
  keyTakeaways: {
    position: "relative",
    alignSelf: "center",
    width: "100%",
    marginTop: "10px",
    flexDirection: "column",
    backgroundColor: "#000080",
    borderColor: "#000080",
    borderRadius: "15px",
    borderWidth: "1px",
    color: "white",
  },
  topRisks: {
    alignSelf: "center",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    // borderColor: "#000080",
    // borderRadius: "15px",
    // borderWidth: "3px",
  },
  riskItem: {
    position: "relative",
    alignSelf: "center",
    width: "100%",
    padding: "10px 0px 0px 10px",
  },
  score: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  darkWeb: {
    width: "100%",
  },
  darkWebSum: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
  },
  table: {
    display: "flex",
    width: "100%",
    paddingTop: "5px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "50%",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  tableColHeaderLeft: {
    width: "30%",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#000080",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#000080",
  },
  tableColHeaderRight: {
    width: "70%",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#000080",
  },
  tableCol: {
    width: "50%",
  },
  tableColLeft: {
    width: "30%",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#000080",
  },
  tableColRight: {
    width: "70%",
    textAlign: "left",
  },
  tableCellHeader: {
    margin: "auto",
    width: "100%",
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
  },
  tableCell: {
    margin: "auto",
    marginLeft: 5,
    marginTop: 5,
    fontSize: 10,
    justifyContent: "flex-start",
    textAlign: "left",
  },
  tableCellNum: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
    fontWeight: "bold",
    color: "#FF343A",
  },
});

type PDFScoreCard = {
  companyName: string | undefined | null;
  domain: string | undefined | null;
  logo: string | undefined;
  reportStyles: Branding;
  cyberScore: CyberScoreCombined | undefined;
};

/* ============================================================= */
/* ============================================================= */
export const ScoreCard = ({
  reportStyles,
  logo,
  companyName,
  domain,
  cyberScore,
}: PDFScoreCard) => {
  const chunkArray = (array: any, chunkSize: any) => {
    let index = 0;
    let arrayLength = array.length;
    let tempArray = [];

    for (index = 0; index < arrayLength; index += chunkSize) {
      let chunk = array.slice(index, index + chunkSize);
      tempArray.push(chunk);
    }

    return tempArray;
  };

  const glossaryChunks = chunkArray(glossaryTerms, 21);
  /* ============================================================= */
  /* ********************* RETURN COMPONENT ********************** */
  return (
    <Document>
      <Page
        size={"LETTER"}
        style={[styles.page, { backgroundColor: reportStyles.primaryColor }]}
      >
        <ReportHeader1
          heading="Cyber Report"
          subheading="Score Card"
          logoUrl={reportStyles.yourLogo}
        />

        <View style={styles.baseCard}>
          <View style={styles.topSection}>
            <View style={styles.colLeft}>
              <ReportCompanyDetails
                styles={styles}
                companyName={companyName}
                domain={domain}
                logo={logo}
              />
            </View>
            <View style={styles.colRight}>
              <ReportContactInfo reportStyles={reportStyles} />
            </View>
          </View>
          <View style={styles.topSection}>
            <View style={styles.colLeft}>
              <ScoreCardKeyTakeaways
                styles={styles}
                userColor={reportStyles.accentColor}
                cyberScore={cyberScore}
              />
            </View>
            <View style={styles.colRight}>
              <ReportScore styles={styles} cyberScore={cyberScore} />
            </View>
          </View>

          <View style={styles.middleSection}>
            <View style={styles.colLeft}>
              <ScoringCriteria
                styles={styles}
                userColor={reportStyles.fontColor}
                // cyberScoreDetails={cyberScoreDetails}
                scoreSimple={cyberScore}
              />
            </View>

            <View style={styles.colRight}>
              <ReportDarkWebReport
                styles={styles}
                cyberScore={cyberScore}
                source="ScoreCard"
              />
            </View>
          </View>
          <View style={styles.bottomSection} />
        </View>
      </Page>
      {glossaryChunks.map((terms) => (
        <Glossary reportStyles={reportStyles} terms={terms} />
      ))}
    </Document>
  );
};

export default ScoreCard;
