import React, { useState } from "react";

import {
  Flex,
  Grid,
  Loader,

  Text,
  Button,
  Card,
  Heading,
} from "@aws-amplify/ui-react";
import CompanyInformation from "../components/recon/CompanyInformation";
import CyberCenterCard from "../components/recon/CyberCenterCard";
import InsightsCard from "../components/recon/InsightsCard_new";
import { Navigate, useParams } from "react-router-dom";
import { useCyberScoreCombinedQuery } from "../hooks/useCyberScoreCombinedQuery";
import EnterDomainField from "../components/recon/EnterDomainField";
import { FaSailboat } from "react-icons/fa6";
import ReportBuilderButton from "../components/recon/Recon.ReportBuilderButton";
import { useReconKeyTakeawaysQuery } from "../hooks/useReconKeyTakeawaysQuery";
import InformationPanel from "../components/monitorcompany/InformationPanel";
import posthog from "posthog-js";
import { useReconActionPlanQuery } from "../hooks/useReconActionPlanQuery";

// tried to add these to the interface folder/file but too many issues
export interface PersonalizedRiskItem {
  Email: string;
  BreachDate: string;
  BreachName: string;
}

export interface CyberScoreSimpleType {
  [key: string]: number;
}

export interface CyberScoreDetailedType {
  [key: string]: any[] | string | number;
}

export interface ThemeAttributes {
  colors: Record<string, string>;
  icons: Record<string, JSX.Element>;
  subtitles: Record<string, string>;
}

export enum Menu {
  EmailImpersonationProtection = "Email Impersonation Protection",
  DarkWebEmailBreaches = "Dark Web Email Breaches",
  SecureEmailGateway = "Secure Email Gateway",
  WebsiteSecuritySettings = "Website Security Settings",
  WordPressPluginSecurity = "WordPress Plugin Security",
  WebsiteEncryption = "Website Encryption",
  WebsiteFirewall = "Website Firewall",
}
   
export default function ReconCompany() {
  /* *********************** useRef/UseState Hooks ************************* */

  const [menu, setMenu] = useState<Menu>(Menu.EmailImpersonationProtection);

  // state for opening dialog popout

  // state for "active" card selected
  // const [activeCategory, setActiveCategory] = useState<string>("");

  /* *********************** API Queries ************************* */

  const { domain } = useParams();

  const { cyberScore, isLoading, isError } = useCyberScoreCombinedQuery(domain);

  const { keyTakeaways, isLoading: ktLoading } = useReconKeyTakeawaysQuery(
    cyberScore,
    domain
  );
  const { actionPlan, isLoading: apLoading } = useReconActionPlanQuery(
    cyberScore,
    domain
  );

  if (isError) {
    return (
      <>
        <Navigate to="/recon" />
      </>
    );
  }

  if (isLoading) {
    return (
      <Flex
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex
          shrink={1}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"medium"}
        >
          <Text>
            We're calculating {domain}'s Cyber Score. Sit tight, this can take
            60 seconds.
          </Text>
          <Loader size="large" variation="linear" filledColor="#144ee3" />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Flex direction={"column"} gap="0" padding={"medium"}>
        <Flex
          height={"52px"}
          padding={"small large 0 large"}
          justifyContent={"right"}
        >
          {isLoading ? (
            ""
          ) : (
            <>
              <Button
                borderRadius={"medium"}
                data-attr="start-cyber-audit"
                size="small"
                variation="primary"
                gap={"xs"}
              >
                <Flex alignItems={"center"} gap={"xs"}>
                  <Text padding={"xxs 0 0 0 "} color={"red.60"}>
                    <FaSailboat />
                  </Text>
                  <Text color={"white"}> Start Cyber Audit</Text>
                </Flex>
              </Button>

              <ReportBuilderButton domain={domain} />
            </>
          )}
          {/* <View
            as="dialog"
            open={cyberScoreCardOpen}
            border={"0"}
            position={"absolute"}
            style={{ zIndex: "99" }}
            padding={"0"}
          >
            <CyberScoreCard
              colors={"neutral.80"}
              // colors={themeAttributes.colors}
              score={cyberScore?.Score}
              domain={domain}
              setCyberScoreCard={setCyberScoreCard}
            />
          </View> */}
          <Flex gap={"xxs"}></Flex>
        </Flex>
        <Grid
          gap="medium"
          templateColumns={{ base: "1fr", large: "1fr 2fr" }}
          padding={"large"}
        >
          <Flex  direction={"column"} gap="xs">
            <EnterDomainField />
            <CompanyInformation domain={domain} cyberScore={cyberScore} />

            <Flex direction={"column"} gap="xs">
              <InsightsCard
                data-attr="scoring-criteria-email-impersonation-protection"
                title={"Email Impersonation Protection"}
                score={cyberScore?.ESSScore}
                subtitle={cyberScore?.ESS}
                menu={Menu.EmailImpersonationProtection}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
              <InsightsCard
                title={"Dark Web Email Breaches"}
                data-attr="scoring-criteria-dark-web-email-breaches"
                score={cyberScore?.BDVScore}
                subtitle={cyberScore?.BDV}
                menu={Menu.DarkWebEmailBreaches}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
              <InsightsCard
                title={"Secure Email Gateway"}
                data-attr="scoring-criteria-security-email-gateway"
                score={cyberScore?.SEGScore}
                subtitle={cyberScore?.SEG}
                menu={Menu.SecureEmailGateway}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
              <InsightsCard
                title={"Website Security Settings"}
                data-attr="scoring-criteria-web-security-settings"
                score={cyberScore?.ISHScore}
                subtitle={cyberScore?.ISH}
                menu={Menu.WebsiteSecuritySettings}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
              <InsightsCard
                title={"WordPress Plugin Security"}
                data-attr="scoring-criteria-wordpress-plugin-security"
                score={cyberScore?.PSScore}
                subtitle={cyberScore?.PS}
                menu={Menu.WordPressPluginSecurity}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
              <InsightsCard
                title={"Website Encryption"}
                data-attr="scoring-criteria-website-encryption"
                score={cyberScore?.WIScore}
                subtitle={cyberScore?.WI}
                menu={Menu.WebsiteEncryption}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
              <InsightsCard
                title={"Website Firewall"}
                data-attr="scoring-criteria-website-firewall"
                score={cyberScore?.WAFScore}
                subtitle={cyberScore?.WAF}
                menu={Menu.WebsiteFirewall}
                currentMenu={menu}
                onClick={(menu) => {
                  posthog.capture("Score Criteria Card Clicked", { menu });
                  setMenu(menu);
                }}
              />
            </Flex>
          </Flex>

          <Flex direction={"column"} gap={"xs"}>
            <Card variation="elevated" borderRadius={"large"}>
              <InformationPanel
                header={
                  <Flex direction={"column"} gap={"0"}>
                    <Heading level={6}>
                      {keyTakeaways?.["key-takeaway"]}
                    </Heading>
                    <Text fontSize={"small"}>Key Takeaway</Text>
                  </Flex>
                }
                body={
                  <Card
                    borderRadius={"large"}
                    backgroundColor={"neutral.10"}
                    fontSize={"small"}
                    padding={"xs"}
                  >
                    {!ktLoading && keyTakeaways ? (
                      <ul>
                        {keyTakeaways?.threats?.map((threat, index) => (
                          <li key={index}>
                            <Text>
                              <b>{threat.name}:</b> {threat.detail}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </Card>
                }
              ></InformationPanel>{" "}
            </Card>
            <Card variation="elevated" borderRadius={"large"}>
              <InformationPanel
                header={<Heading level={6}>Cyber Action Plan</Heading>}
                body={
                  <Card
                    borderRadius={"large"}
                    backgroundColor={"neutral.10"}
                    fontSize={"small"}
                    padding={"xs"}
                  >
                    {!apLoading && actionPlan ? (
                      <ul>
                        {actionPlan?.actions?.map((action, index) => (
                          <li key={index}>
                            <Text>
                              <b>{action.title}:</b> {action.detail}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </Card>
                }
              ></InformationPanel>{" "}
            </Card>
            <CyberCenterCard menu={menu} cyberScoreData={cyberScore} />
          </Flex>
        </Grid>
      </Flex>
    </>
  );
}
