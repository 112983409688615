import { useEffect, useState } from "react";

function getSavedValue<T>(key: string) {
  const itemString = localStorage.getItem(key);
  if (!itemString) return;
  try {
    const savedValue = JSON.parse(itemString);
    if (savedValue) return savedValue as T;
  } catch (e) {
    console.error(
      "Error parsing saved value for key: ",
      key,
      " value: ",
      itemString,
      " returning undefined."
    );
  }
  return undefined;
}

export default function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // if savedValue exists set as initial
  // else use given initalValue as initial
  const [value, setValue] = useState(() => {
    // called once at start
    const savedVal = getSavedValue<T>(key);
    return savedVal ?? initialValue;
  });

  // set to localStorage whenenver setValue() called
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
}
