async function fileToBase64(file: File): Promise<string> {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const base64Data: string = await new Promise((resolve, reject) => {
      reader.onload = (ev) => {
        resolve(ev.target?.result as string);
      };
      reader.onerror = (error) => reject(error);
    });
    // console.log(base64Data);
    return base64Data;
  } catch (error) {
    console.error("Error converting file to base64:", error);
    return "";
  }
}

export default fileToBase64;
