import {
  Button,
  Link,
  Card,
  Flex,
  ScrollView,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Text,
  View,
  TextField,
  Placeholder,
} from "@aws-amplify/ui-react";
import React from "react";
import TableTitle from "../styling/TableTitle";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { useCampaignsQuery } from "../../hooks/useCampaignsQuery";
import { Modal } from "../Modal";

export default function ScoreGroupsTable() {
  const navigate = useNavigate();

  const { groups, createGroupMutation, isLoading } = useCampaignsQuery();

  const { mutateAsync: createGroup, isPending } = createGroupMutation;

  const createGroupModal = React.useRef<HTMLDialogElement>(null);

  // const { updateCompaniesMutate } = useCompaniesMutation();

  const [name, setName] = React.useState("");

  return (
    <>
      <Modal size="small" ref={createGroupModal} hasCloseButton>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            await createGroup(name);
            setName("");
            createGroupModal.current?.close();
          }}
        >
          <TextField
            label={<b>Name</b>}
            name="name"
            placeholder="Enter group name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            outerEndComponent={
              <Button
                variation="primary"
                isLoading={isPending}
                loadingText="Creating..."
                type="submit"
              >
                Create
              </Button>
            }
          />
        </form>
      </Modal>
      <Card boxShadow={"medium"} borderRadius={"large"}>
        <Flex justifyContent={"space-between"} paddingBottom={"small"}>
          <TableTitle title={"Groups"} />

          <Button
            variation="primary"
            gap={"xs"}
            backgroundColor={"blue.60"}
            borderRadius={"large"}
            fontSize={"small"}
            whiteSpace={"nowrap"}
            onClick={() => createGroupModal.current?.showModal()}
          >
            <FaPlus />
          </Button>
        </Flex>

        <ScrollView maxHeight={"400px"}>
          <View>
            <Table highlightOnHover={true} size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={"20%"} fontSize={"smaller"} as="th">
                    Name
                  </TableCell>
                  <TableCell
                    width={"15%"}
                    fontSize={"smaller"}
                    textAlign={"center"}
                    as="th"
                  >
                    Companies
                  </TableCell>
                  <TableCell
                    width={"15%"}
                    fontSize={"smaller"}
                    textAlign={"center"}
                    as="th"
                  >
                    Breached Accounts
                  </TableCell>
                  <TableCell
                    textAlign={"center"}
                    width={"15%"}
                    fontSize={"smaller"}
                    as="th"
                  >
                    Risks
                  </TableCell>
                  <TableCell
                    textAlign={"center"}
                    width={"15%"}
                    fontSize={"smaller"}
                    as="th"
                  >
                    Avg. Cyber Score
                  </TableCell>
                  {/* <TableCell
                  textAlign={"center"}
                  width={"15%"}
                  fontSize={"smaller"}
                  as="th"
                  >
                  Campaign Status
                  </TableCell> */}
                </TableRow>
              </TableHead>

              {isLoading
                ? Array(20).fill(0).map(() => (
                    <TableRow>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                      <TableCell>
                        <Placeholder />
                      </TableCell>
                    </TableRow>
                  ))
                : groups?.map(
                    ({
                      Name,
                      ProspectCount,
                      BreachedAccounts,
                      AvgScore,
                      CampaignID,
                    }) => (
                      <TableRow>
                        <TableCell
                          fontSize={"smaller"}
                          onClick={() =>
                            navigate(`/score-management/groups/${CampaignID}`)
                          }
                        >
                          <Link>{Name}</Link>
                        </TableCell>
                        <TableCell fontSize={"smaller"} textAlign={"center"}>
                          <Flex alignItems={"center"} justifyContent={"center"}>
                            <Text>{ProspectCount}</Text>
                          </Flex>
                        </TableCell>
                        <TableCell fontSize={"smaller"} textAlign={"center"}>
                          {BreachedAccounts}
                        </TableCell>
                        <TableCell fontSize={"smaller"} textAlign={"center"}>
                          -
                        </TableCell>
                        <TableCell fontSize={"smaller"} textAlign={"center"}>
                          <Flex
                            justifyContent={"center"}
                            gap={"xxs"}
                            alignItems={"end"}
                          >
                            <Text color={"yellow.60"} fontSize={"smaller"}>
                              <FaCircle />
                            </Text>
                            <Text>{AvgScore}%</Text>
                          </Flex>
                        </TableCell>
                        {/* <TableCell fontSize={"smaller"}>
                    <SelectField label="" descriptiveText="" size="small">
                    <option value="notStarted">Non Started</option>
                    <option value="inProgress">In Progress</option>
                    <option value="completed">Completed</option>
                    </SelectField>
                    </TableCell> */}
                      </TableRow>
                    )
                  )}
            </Table>
          </View>
        </ScrollView>
      </Card>
    </>
  );
}
