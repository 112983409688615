import {
  UpdateCompaniesOutput,
  UpdateCompaniesInputParams,
  UpdateCompaniesInputBody,
} from "../../types/companies";
import { postRequest } from "../restAPI";

export async function updateCompanies(
  params: UpdateCompaniesInputParams,
  body: UpdateCompaniesInputBody
) {
  try {
    const updateCompaniesResponse = await postRequest(`/companies`, {
      queryParams: params,
      body: body,
    }).then(async (response) => {
      return await response.body.json().then((json) => {
        const data = json as any as UpdateCompaniesOutput;

        return data;
      });
    });
    return updateCompaniesResponse as UpdateCompaniesOutput;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating companies.");
  }
}
