import React from "react";

import { Card, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import EnterDomainField from "../components/recon/EnterDomainField";

export default function Recon() {
  return (
    <>
      <Flex
        width={"100%"}
        height={"100%"}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"xxxl"}
      >

        <Card borderRadius="large" boxShadow="large" maxWidth={"700px"}>

          <Flex direction={{ base: "column", medium: "row" }} >

            <Image alignSelf={"center"} height={"230px"} width={"250px"} borderRadius={"large"} alt="Cyber Score Card" src="https://assets-global.website-files.com/658c7dbe6f7007cb9233ad26/6599decbfccf5c14ab7e3a3e_Iceberg%20Cyber%20Score%20Card%20Insert-p-500.png" />

            <Flex direction={"column"} justifyContent={"center"} >


              <Text fontSize={{ base: "medium", medium: "large" }} fontWeight={"bold"}>Use Cyber Scores to Prospect</Text>

              <Text fontSize={{ base: "xs", medium: "small" }}>
                Differentiate your outreach with personalized Cyber Scores. Enter a Prospect's website URL and in 60s you'll get their Dark Web Report and Website security risks.
              </Text>


            </Flex>
          </Flex>

        </Card>

        <View width={"100%"} maxWidth={"700px"}>
          <EnterDomainField />


        </View>
        {/* <Flex height={"52px"} padding={"small large 0 large"} justifyContent={"right"}>
                    <Flex gap={"xxs"}>
                    </Flex>
                </Flex>

                <Grid
                    gap="large"
                    templateColumns={{ base: "1fr", large: '1fr fr' }}
                    padding={"large"}>

                    <Flex direction={"column"} gap="large">
                        <EnterDomainField />
                        <HackerInsightsTable />
                    </Flex>

                    <ReconMap />
                    <ReconCompaniesTable />
                    <RisksScoreCategoryChart />
                </Grid> */}
      </Flex>
    </>
  );
}
