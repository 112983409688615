import React, { createContext, useContext, useEffect } from "react";
import useAuthEventListener from "../components/useAuthEventListener";
import { Branding } from "../components/monitorcompany/ReportBuilderButton";
import useLocalStorage from "./useLocalStorage";
import { useUserContext } from "./useUserContext";

// Define the context
interface BrandingContextProps {
  branding: Branding;
  setBranding: React.Dispatch<React.SetStateAction<Branding>>;
}

const BrandingContext = createContext<BrandingContextProps | undefined>(
  undefined
);

type BrandingContextProviderProps = {
  children: React.ReactNode;
};

// Define the provider
export function BrandingContextProvider({
  children,
}: BrandingContextProviderProps): JSX.Element {
  useAuthEventListener();

  const { userAttributes } = useUserContext();

  const [branding, setBranding] = useLocalStorage<Branding>("branding", {
    primaryColor: "#000080",
    accentColor: "#000080",
    fontColor: "#fefefe",
    yourLogo: "",
    contactName: userAttributes?.name || "",
    contactEmail: userAttributes?.email || "",
    phoneNumber: "",
    clientLogo: "",
    slogan: "Secure your livelihood",
    companyName: "",
    scoreAlias: "Cyber Score",
  });

  useEffect(() => {
    const fullName =
      (userAttributes?.given_name ?? "") +
        " " +
        (userAttributes?.family_name ?? "") ?? "";

    if (userAttributes) {
      setBranding((prev) => ({
        ...prev,
        contactName: prev.contactName === "" ? fullName : prev.contactName,
        contactEmail:
          prev.contactEmail === ""
            ? userAttributes.email ?? ""
            : prev.contactEmail,
      }));
    }
  }, [userAttributes]);

  return (
    <BrandingContext.Provider value={{ branding, setBranding }}>
      {children}
    </BrandingContext.Provider>
  );
}

// Define a hook for easy access to the context
export function useBrandingContext() {
  const context = useContext(BrandingContext);
  if (!context) {
    throw new Error(
      "useBrandingContext() must be used within a BrandingContext"
    );
  }
  return context;
}
