import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Branding } from "../monitorcompany/ReportBuilderButton";

export const KeyTakeaway = ({
  text,
  reportStyles,
}: {
  text: string;
  reportStyles: Branding;
}) => (
  <View
    style={{
      flexDirection: "row",
      alignItems: "center",
      gap: "6px",
    }}
  >
    <View
      style={{
        height: "100%",
        width: "6px",
        backgroundColor: reportStyles.accentColor,
        borderRadius: "20px",
      }}
    ></View>
    <View style={{ gap: "5px" }}>
      <Text style={{ width: "500px", fontWeight:"bold" }}>{text}</Text>
      <Text style={{ fontSize: "10px" }}>Key Takeaway</Text>
    </View>
  </View>
);
