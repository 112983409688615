import { Accordion, Flex, Text } from "@aws-amplify/ui-react";
import React, { useMemo, } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FaCircleMinus } from "react-icons/fa6";

export default function InsightsCardReconLite({
  title,
  score,
  subtitle,
  children
}: {
  title: any;
  score: -1 | 0 | 1 | undefined;
  subtitle: string | undefined;
  onClick: () => void;
  children?: React.ReactNode
}) {


  const status = useMemo(
    () => (score === 1 ? "pass" : score === 0 ? "unknown" : "fail"),
    [score]
  );

  const color = useMemo(
    () =>
      status === "pass"
        ? "green.60"
        : status === "unknown"
          ? "neutral.80"
          : "red.60",
    [status]
  );

  const Icon = () =>
    status === "pass" ? (
      <FaCheckCircle />
    ) : status === "unknown" ? (
      <FaCircleMinus />
    ) : (
      <FaTimesCircle />
    );

  return (
    <>

      <Accordion.Container>
        <Accordion.Item value="insight">
          <Accordion.Trigger>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text fontSize={"larger"} color={color}>
                <Icon />
              </Text>
              <Flex direction={"column"} gap={"0"}>
                <Text fontWeight={"bold"} color={color} fontSize={"smaller"}>
                  {title}
                </Text>
                <Text fontSize={"xs"}>{subtitle}</Text>
              </Flex>
            </Flex>
            <Accordion.Icon />
          </Accordion.Trigger>
          <Accordion.Content padding={"medium"}>
            {children}
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Container>

    </>
  );
}
