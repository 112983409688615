import "../../style/assetDetailsPopOutPanel.css";

import { TableCell, TableRow, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import { Asset } from "../../types/assetInventoryApi";
import { Company } from "../../types/companies";
import StatusSelectField from "./StatusSelectField";
import { useUpdateVulnStatusMutation } from "../../hooks/useUpdateVulnStatusMutation";

interface RowProps {
  vulnId: string;
  asset: Asset;
  key: React.Key | null | undefined;
  company: Company | undefined;
  selectedAssetId: string | null;
  setSelectedAssetId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const Row = (props: RowProps) => {
  const { asset, key, selectedAssetId, vulnId, company, setSelectedAssetId } =
    props;

  const selected = React.useMemo(
    () => selectedAssetId === asset.DEVICE_ID,
    [selectedAssetId, asset.DEVICE_ID]
  );

  const { updateNvtStatus } = useUpdateVulnStatusMutation(
    company?.CUID as number,
    asset.DEVICE_ID,
    vulnId
  );

  const nvt = React.useMemo(() => {
    return asset.nvts.find((nvt) => nvt.nvt_oid === vulnId);
  }, [asset.nvts, vulnId]);

  return (
    <TableRow
      key={key}
      backgroundColor={selected ? "#EAF4FF" : undefined}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedAssetId((prev) =>
          prev === asset.DEVICE_ID ? null : asset.DEVICE_ID
        );
      }}
    >
      <TableCell colSpan={1}>
        <Text>{asset.nvts.length ?? "-"}</Text>
      </TableCell>
      <TableCell fontSize={"xs"} colSpan={1}>
        <Text>{asset.IPs[0].IP ?? "-"}</Text>
      </TableCell>
      <TableCell fontSize={"xs"} colSpan={1}>
        <Text>{asset.MAC_VENDOR ?? "-"}</Text>
      </TableCell>
      <TableCell fontSize={"xs"} colSpan={1}>
        <Text>{asset.recog_brand ?? "-"}</Text>
      </TableCell>
      <TableCell fontSize={"xs"} colSpan={1}>
        <Text>{asset.recog_type ?? "-"}</Text>
      </TableCell>

      <TableCell colSpan={1}>
        <StatusSelectField
          status={nvt?.status}
          onChange={async (status) => {
            await updateNvtStatus(status);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default Row;
