import {
  Card,
  Flex,
  Image,
  Link,
  Placeholder,
  Text,
} from "@aws-amplify/ui-react";
import React from "react";

import { DomainProps } from "./interfaces/Interfaces";
import { useFavicon } from "../../hooks/useFavicon";
import CyberScoreGraphs from "./CyberScoreGraphs";

// do we need to use the useFavicon and this type (below) for this page?
// kind of makes sense to make it simple and only worry about the URL/type = DomainProps
// export type Company = {
//     companyId: string;
//     companyName: string;
//     scannerId: string;
//     domain: string;
// }

export default function CompanyInformation({
  domain,
  cyberScore,
}: DomainProps) {
  const { url, isLoading } = useFavicon(domain);

  return (
    <Card width={"100%"} boxShadow={"medium"} borderRadius={"large"}>
      <Flex justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          <Card boxShadow={"medium"} borderRadius={"medium"} padding={"xxxs"}>
            <Flex alignItems={"center"}>
              {isLoading ? (
                <Placeholder height={60} width={60} />
              ) : (
                <Image
                  alt="company logo"
                  src={url}
                  objectFit="contain"
                  objectPosition="50% 50%"
                  height="60px"
                  width="60px"
                />
              )}
            </Flex>
          </Card>

          {cyberScore?.CompanyName ? (
            <Flex direction={"column"} gap={"0"}>
              <Text fontWeight={"bold"} color={"blue.90"}>
                {cyberScore?.CompanyName}
              </Text>
              <Link
                href={"https://" + domain}
                isExternal
                color={"blue.90"}
                fontSize={"small"}
              >
                {domain}
              </Link>
              <Text color={"neutral.80"} fontSize={"xs"}>
                Latest Report:{" "}
                {new Date().toLocaleDateString("en-CA").split("/")}
              </Text>
            </Flex>
          ) : (
            <Flex direction={"column"} gap={"0"}>
              <Link
                href={"https://" + domain}
                isExternal
                fontWeight={"bold"}
                color={"blue.90"}
              >
                {domain}
              </Link>
              <Text color={"neutral.80"} fontSize={"xs"}>
                Latest Report:{" "}
                {new Date().toLocaleDateString("en-CA").split("/")}
              </Text>
            </Flex>
          )}
        </Flex>
        <CyberScoreGraphs cyberScore={cyberScore} />
      </Flex>

      {/* <Grid templateColumns={"1fr 1fr"} gap={"medium"} padding={"small"}>
        <Flex
        direction={"column"}
          gap={"xxxs"}
          style={{ borderRight: "solid var(--amplify-colors-neutral-60) 1px" }}
          >
          <Text fontWeight={"bold"} fontSize={"xs"}>
            Location
          </Text>
          <Text fontWeight={"bold"} fontSize={"xs"}>
            Industry
          </Text>
          <Text fontWeight={"bold"} fontSize={"xs"}>
            Size
          </Text>
          <Text fontWeight={"bold"} fontSize={"xs"}>
            Est. Revenue
          </Text>
          <Text fontWeight={"bold"} fontSize={"xs"}>
            Social
          </Text>
        </Flex>

        <Flex direction={"column"} gap={"xxxs"}>
          <Text fontSize={"xs"}>-</Text>
          <Text fontSize={"xs"}>-</Text>
          <Text fontSize={"xs"}>-</Text>
          <Text fontSize={"xs"}>&lt;-</Text>
          <Text fontSize={"xs"}>
            <FaLinkedin />
          </Text>
        </Flex>
      </Grid> */}
    </Card>
  );
}
