import { useQuery } from "@tanstack/react-query";

import { Asset } from "../types/assetInventoryApi";
import { genActionPlan } from "../services/gpt-generation/genActionPlan";

export function useActionPlanQuery(
  assets: Asset[] | undefined,
  companyId: number | undefined
) {
  const { data: actionPlan, ...query } = useQuery({
    queryKey: ["action-plan", companyId],
    queryFn: async () => await genActionPlan(assets as Asset[]),
    enabled: !!assets && !!companyId,
  });

  return {
    actionPlan,
    ...query,
  };
}
