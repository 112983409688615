import { Button, Flex, Grid, Image } from '@aws-amplify/ui-react'
import React from 'react'
import HeadlineVerticalDivider from '../styling/HeadlineVerticalDivider'

export default function Step2Modal() {
    return (
        <>
            <Grid
                height={"100%"}
                templateColumns={"1fr 1fr"}
                gap="large"
                padding={"large"}
                alignItems={"center"}
            >
                <Flex
                    as="form"
                    gap={"xl"}
                    direction={"column"}
                    justifyContent={"center"}
                    data-attr="score-card-form"
                >

                    <Flex direction={"column"} gap={"small"}>
                        <HeadlineVerticalDivider heading={"Assign a Sensor"} subheading={"Step 1"} borderColor={"#174DE1"} />
                     //Dropdown of Sensors OR Buy a Sensor Button
                    </Flex>



                    <Flex direction={"column"}>


                        <HeadlineVerticalDivider heading={"Plug in the Sensor, Turn it on"} subheading={"Step 2"} borderColor={"#174DE1"} />


                    </Flex>


                    <Flex direction={"column"}>


                        <HeadlineVerticalDivider heading={"Download the Cyber Report (After 24 Hours)"} subheading={"Step 3"} borderColor={"#174DE1"} />


                    </Flex>






                    <Flex>
                        <Button
                            fontWeight={"medium"}
                            variation="primary"
                            backgroundColor={"#174DE1"}
                            borderRadius={"medium"}
                            size="small"
                        >
                            Download Report
                        </Button>
                    </Flex>
                </Flex>

                <Image
                    flex={1}
                    borderRadius="large"
                    src="/images/score-card/cyber-report-blue-p1.png"
                    alt=""
                    objectFit={"contain"}
                    boxShadow={"medium"}
                />
            </Grid>
        </>
    )
}
