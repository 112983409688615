import React, { createContext, useContext } from "react";
import { useMonitorCompany } from "./useMonitorCompany";

type MonitorCompanyContextType = {} & ReturnType<typeof useMonitorCompany>;

type MonitorCompanyContextProviderProps = {
  children: React.ReactNode;
};
const MonitorCompanyContext = createContext<MonitorCompanyContextType>(
  {} as MonitorCompanyContextType
);

export function MonitorCompanyContextProvider(
  props: MonitorCompanyContextProviderProps
) {
  const { children } = props;

  const monitorCompanyState = useMonitorCompany();

  return (
    <MonitorCompanyContext.Provider
      value={{
        ...monitorCompanyState,
      }}
    >
      {children}
    </MonitorCompanyContext.Provider>
  );
}

// Define the hook to access the context
export const useMonitorCompanyContext = (): MonitorCompanyContextType => {
  const context = useContext(MonitorCompanyContext);
  if (!context) {
    throw new Error(
      "useMonitorCompanyContext must be used within a MonitorCompanyContextProvider"
    );
  }
  return context;
};
