import {
  Button,
  Loader,
  Placeholder,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useAssetInventorySummaryQuery } from "../../hooks/useAssetInventorySummaryQuery";
import { toast } from "react-toastify";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import { FaGear } from "react-icons/fa6";
import CompanyDetailsForm from "../portfolio/CompanyDetailsForm";
import { Modal } from "../Modal";
import { useCompaniesMutation } from "../../hooks/useCompaniesMutation";
import { Company } from "../../types/companies";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { useScannersQuery } from "../../hooks/useScannersQuery";

type CompaniesTableProps = {};

export default function CompaniesTable(props: CompaniesTableProps) {
  const {} = props;

  const navigate = useNavigate();

  const { companies, isLoading: isLoadingCompanies } = useCompaniesQuery(1);

  const { scanners } = useScannersQuery();

  const { assetInventorySummary, isLoading: isLoadingAssetInv } =
    useAssetInventorySummaryQuery(companies?.map((company) => company.CUID));

  const updateCompanyModal = React.useRef<HTMLDialogElement>(null);

  const { updateCompaniesMutate } = useCompaniesMutation();
  const [selectedCompany, setSelectedCompany] = React.useState<
    Company | undefined
  >();
  // const scanners = useMemo(() => {
  //   return assetInventorySummary;
  // }, [assetInventorySummary]);

  const tableData = useMemo(() => {
    return companies
      ?.map((company) => {
        const scannerSummaries = assetInventorySummary?.filter(
          (ai) => ai.cuid === company.CUID
        );

        const scanner = scanners?.find((s) => s.CUID === company.CUID);

        let mostRecentLastScan = null;
        let mostRecentStartScan = null;
        let sumAssets = null;
        let sumVulns = null;
        let hasScanner = false;

        if (scannerSummaries && scannerSummaries.length !== 0) {
          hasScanner = true;
          mostRecentLastScan = scannerSummaries.reduce((acc, curr) => {
            return new Date(curr.end_timestamp) > new Date(acc)
              ? curr.end_timestamp
              : acc;
          }, scannerSummaries[0].end_timestamp);

          mostRecentStartScan = scannerSummaries.reduce((acc, curr) => {
            return new Date(curr.start_timestamp) > new Date(acc)
              ? curr.start_timestamp
              : acc;
          }, scannerSummaries[0].start_timestamp);

          sumAssets = scannerSummaries.reduce((acc, curr) => {
            return curr.dev_found + acc;
          }, 0);
          sumVulns = scannerSummaries.reduce((acc, curr) => {
            return curr.vuln_found + acc;
          }, 0);
        }

        return {
          scanner,
          company,
          companyId: company.CUID,
          scannerSummaries,
          companyName: company.CompanyName,
          sumAssets,
          sumVulns,
          lastScan: mostRecentLastScan,
          startTimestamp: mostRecentStartScan,
          endTimestamp: mostRecentLastScan,
          scannerStatus: null,
          hasScanner,
        };
      })
      .sort((a, b) => {
        if (a.lastScan === null) return 1;
        if (b.lastScan === null) return -1;
        return new Date(b.lastScan).getTime() - new Date(a.lastScan).getTime();
      });
  }, [companies, assetInventorySummary]);

  const isLoading = React.useMemo(
    () => isLoadingCompanies || isLoadingAssetInv,
    [isLoadingCompanies, isLoadingAssetInv]
  );

  return (
    <>
      <Modal ref={updateCompanyModal}>
        <CompanyDetailsForm
          groupId={1}
          Operation="update"
          selectedCompany={selectedCompany}
          onSave={async (updateCompanyRequest) => {
            await updateCompaniesMutate({
              body: { Businesses: [updateCompanyRequest] },
            });
          }}
          onClose={() => updateCompanyModal.current?.close()}
        />
      </Modal>
      {/* <Card boxShadow={"medium"} borderRadius={"large"}> */}
      {/* <Flex justifyContent={"space-between"} paddingBottom={"small"}>
        <TableTitle title={"Companies"} />
        <Flex>
          <SearchField
            label="Search"
            placeholder=""
            size="small"
            variation="quiet"
            hasSearchButton={false}
            hasSearchIcon={true}
          />
        </Flex>
      </Flex> */}

      <Table highlightOnHover={true} size="small">
        <TableHead>
          <TableRow>
            <TableCell width={"40%"} fontSize={"smaller"} as="th">
              Company
            </TableCell>
            <TableCell
              width={"15%"}
              textAlign={"center"}
              fontSize={"smaller"}
              as="th"
            >
              Total Risks
            </TableCell>
            <TableCell
              width={"15%"}
              textAlign={"center"}
              fontSize={"smaller"}
              as="th"
            >
              Local Assets
            </TableCell>
            <TableCell
              width={"15%"}
              textAlign={"center"}
              fontSize={"smaller"}
              as="th"
            >
              Last Scan
            </TableCell>
            <TableCell
              width={"15%"}
              textAlign={"center"}
              fontSize={"smaller"}
              as="th"
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>

        {isLoading
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
              <TableRow key={i}>
                <TableCell>
                  <Placeholder />
                </TableCell>
                <TableCell>
                  <Placeholder />
                </TableCell>
                <TableCell>
                  <Placeholder />
                </TableCell>
                <TableCell>
                  <Placeholder />
                </TableCell>
                <TableCell>
                  <Placeholder />
                </TableCell>
              </TableRow>
            ))
          : tableData?.map((data, i) => (
              <TableRow
                key={i}
                onClick={
                  data.hasScanner
                    ? () => {
                        if (
                          data.scannerSummaries === undefined ||
                          data.scannerSummaries.length === 0
                        ) {
                          toast.error("No scan data found for this company.");
                          return;
                        }
                        const oneWeekAgoTime =
                          new Date(
                            data.scannerSummaries[0].start_timestamp.replace(
                              " ",
                              "T"
                            ) + "Z"
                          ).getTime() -
                          7 * 24 * 60 * 60 * 1000;

                        // end timestamp is always right now (Can be left out of query)
                        // start timestamp should be lateest report date MINUS some time period (7 days)
                        // if not latest report date, then use right now's date

                        const oneWeekAgoTimestamp = new Date(oneWeekAgoTime)
                          .toISOString()
                          .replace("Z", "")
                          .replace("T", " ");

                        console.log(oneWeekAgoTimestamp);

                        navigate(
                          `/monitor/${data.companyId}/${data.scannerSummaries[0].scanner_id}?start_timestamp=${oneWeekAgoTimestamp}`
                        );
                      }
                    : () => {
                        toast.info("No scanner found for this company.");
                      }
                }
              >
                <TableCell fontSize={"smaller"}>
                  <Button
                    gap={"medium"}
                    padding={"xxs"}
                    variation="link"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCompany(data.company);
                      updateCompanyModal.current?.showModal();
                    }}
                  >
                    <FaGear fontSize={"small"} />
                  </Button>{" "}
                  {data.companyName}
                </TableCell>
                <TableCell textAlign={"center"} fontSize={"smaller"}>
                  {isLoading ? (
                    <Loader />
                  ) : data.sumVulns !== undefined ? (
                    data.sumVulns
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell textAlign={"center"} fontSize={"smaller"}>
                  {isLoading ? (
                    <Loader />
                  ) : data.sumAssets !== undefined ? (
                    data.sumAssets
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell textAlign={"center"} fontSize={"smaller"}>
                  {isLoading ? (
                    <Loader />
                  ) : data.lastScan ? (
                    timeStampToLocaleString(data.lastScan).split(",")[0]
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell
                  textAlign={"center"}
                  fontSize={"smaller"}
                  padding="0"
                >
                  {data.scanner?.ScanPercent ? (
                    <Button
                      isFullWidth
                      whiteSpace={"nowrap"}
                      size="small"
                      gap={"xxxs"}
                      fontWeight={"medium"}
                      fontSize={"1em"}
                      borderRadius={"medium"}
                      width={"100px"}
                    >
                      {data.scanner?.ScanPercent}%
                      <Loader
                        emptyColor="var(--amplify-colors-teal-30)"
                        filledColor="#174DE1"
                        percentage={data.scanner?.ScanPercent}
                        isDeterminate
                        isPercentageTextHidden
                      />
                    </Button>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
      </Table>
      {/* </Card> */}
    </>
  );
}
