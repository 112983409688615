import { Card, Flex } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import VulnerabilitiesTable from "./VulnerabilitiesTable";
import DeviceDetails from "./DeviceDetails";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";

type VulnerabilitiesPanelProps = {
  assetId: string | null;
  setAssetId: React.Dispatch<React.SetStateAction<string | null>>;
  nvtId: string | null;
  setNvtId: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function VulnerabilitiesPanel({
  nvtId,
  setNvtId,
  assetId,
  setAssetId,
}: VulnerabilitiesPanelProps) {
  const { assets, nvts } = useMonitorCompanyContext();

  const selectedNvt = useMemo(() => {
    if (!nvtId) return null;
    return nvts?.find((nvt) => nvt.nvt_oid === nvtId);
  }, [nvtId, nvts]);

  const selectedAsset = useMemo(() => {
    if (!assets || assetId === null) return null;
    return assets.find((a) => a.DEVICE_ID === assetId);
  }, [assetId, assets]);

  return (
    <Flex gap={"large"} height={"100%"}>
      <Card variation="elevated" borderRadius={"large"} flex={1}>
        <VulnerabilitiesTable
          setNvtId={setNvtId}
          selectedNvtId={nvtId}
          selectedAssetId={selectedAsset?.DEVICE_ID ?? null}
          setSelectedAssetId={setAssetId}
        ></VulnerabilitiesTable>
      </Card>

      <Flex direction={"column"} flex={1}>
        <DeviceDetails asset={selectedAsset} selectedNvt={selectedNvt} />
      </Flex>
    </Flex>
  );
}
