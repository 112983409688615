import { get, post, put, del } from "aws-amplify/api";

export type RestApiOptions = {
  headers?: Record<string, string>;
  body?: any;
  queryParams?: any;
};

export async function getRequest(path: string, options?: RestApiOptions) {
  return await get({ apiName: "TheCyberScoreRestAPI", path, options }).response;
}
export function postRequest(path: string, options?: RestApiOptions) {
  return post({ apiName: "TheCyberScoreRestAPI", path, options }).response;
}
export function putRequest(path: string, options?: RestApiOptions) {
  return put({ apiName: "TheCyberScoreRestAPI", path, options }).response;
}
export function delRequest(path: string, options?: RestApiOptions) {
  return del({ apiName: "TheCyberScoreRestAPI", path, options }).response;
}
