import React, { ReactNode } from "react";
import { ResponsiveStyle, StyleToken, Text } from "@aws-amplify/ui-react";
import { ColorKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import { Property } from "csstype";

type TableTitleProps = {
  title: ReactNode;
  color?: ResponsiveStyle<ColorKeys<StyleToken<Property.Color>>> | undefined;
};

export default function TableTitle({ title, color }: TableTitleProps) {
  return (
    <Text fontWeight={"bold"} fontSize={"small"} color={color}>
      {title}
    </Text>
  );
}
