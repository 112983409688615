import {
  Button,
  Flex,
  Heading,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import React from "react";

import HeadlineVerticalDivider from "../styling/HeadlineVerticalDivider";
import { useMutation } from "@tanstack/react-query";
import { getCyberScoreCombined } from "../../services/cyberScore/getCyberScoreCombined";
import BrandingOptions from "./BrandingOptions";
import { useBrandingContext } from "../../hooks/useBrandingContext";
import { pdf } from "@react-pdf/renderer";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { Branding } from "../monitorcompany/ReportBuilderButton";
import PDFScoreCardDocument from "../reports/score-card/PDFScoreCardDocument";
import { getLogo } from "../../services/images/getLogo";
import { imageToBase64 } from "../../services/imageToBase64";
import {
  ActionPlan,
  genReconActionPlan,
} from "../../services/gpt-generation/genReconActionPlan";
import {
  genReconKeyTakeaways,
  KeyTakeaways,
} from "../../services/gpt-generation/genReconKeyTakeaways";

import { toast } from "react-toastify";

export default function ScoreCardBatcher() {
  const { branding } = useBrandingContext();

  const [text, setText] = React.useState("");
  const [processIndex, setProcessIndex] = React.useState(0);
  // const [currentProfile, setCurrentProfile] = React.useState<
  // string | undefined
  // >(undefined);
  const domains = text
    .split(",")
    .map((d) => d.trim())
    .filter((d) => d.length > 0);

  // const { mutateAsync: getPfp } = useMutation({
  //   mutationKey: ["get-profile-pic", currentProfile],

  //   mutationFn: async ({ url }: { url: string }) => getLinkedInProfilePic(url),

  //   // retry: false,
  //   retry: (failureCount, error) => {
  //     if (error.name !== "429") return false;
  //     return failureCount < 3;
  //   },

  //   retryDelay: (failureCount) =>
  //     Math.min(failureCount > 1 ? 2 ** failureCount * 10000 : 10000, 60 * 1000),
  // });

  const { mutateAsync: processBatch, isPending } = useMutation({
    mutationKey: ["process-score-card-batch", ...domains],
    mutationFn: async () => {

      for (let i = 0; i < domains.length; i++) {
        try {
          const domain = domains[i];
          setProcessIndex(i + 1);
          const cyberScore = await getCyberScoreCombined(domain);

          // const profiles = cyberScore.Employees.map((e) => e.SourcePage)
          //   .filter((p) => p !== null)
          //   .filter((p, index, self) => self.indexOf(p) === index)
          //   .slice(0, 5) as string[];

          // const profilePicPromises = profiles.map((p) => getPfp({ url: p }));

          // const profilePics: LinkedInProfilePic[] = [];
          // for (let i = 0; i < profiles.length && profilePics.length < 2; i++) {
          //   const profile = profiles[i];
          //   //   index: i,
          //   //   total: profiles.length,
          //   // });
          //   // setCurrentProfile(profile);
          //   let nRetries = 0;
          //   const maxRetries = 1;
          //   while (nRetries <= maxRetries) {
          //     try {
          //       const profilePic = await getLinkedInPersonPFP(profile);
          //       if (!profilePic.isDefault) profilePics.push(profilePic);
          //       break;
          //     } catch (e: any) {
          //       console.error(`Error fetching profile pic for ${profile}`);
          //       if (e.name === "429") {
          //         nRetries++;
          //         await new Promise((resolve) => setTimeout(resolve, 65000));
          //       } else break;
          //     }
          //   }
          // }
          // const profilePicData = profilePics.map((p) => p.data);

          const emp1Base64 = cyberScore.Employee1
            ? await imageToBase64(cyberScore.Employee1)
            : undefined;
          const emp2Base64 = cyberScore.Employee2
            ? await imageToBase64(cyberScore.Employee2)
            : undefined;

          const profilePicData = [emp1Base64, emp2Base64].filter(
            (url) => url !== undefined
          ) as string[];


          // console.log(`${domain} Profile Pics`, profilePicData);
          let prospectLogoUrl = "";
          try {
            prospectLogoUrl = await getLogo(domain);
          } catch {
            toast.error(`Error fetching favicon for ${domain}`);
            prospectLogoUrl =
              "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";
          }
          const faviconBase64 = prospectLogoUrl
            ? await imageToBase64(prospectLogoUrl)
            : "";
          // console.log(`${domain} Favicon`, faviconBase64);
          const actionPlan = await genReconActionPlan(cyberScore);
          // console.log(`${domain} Action Plan`, actionPlan);
          const keyTakeaways = await genReconKeyTakeaways(cyberScore);
          // console.log(`${domain} Key Takeaways`, keyTakeaways);
          downloadPdf(
            domain,
            faviconBase64,
            branding,
            cyberScore,
            actionPlan,
            keyTakeaways,
            profilePicData
          );
        } catch (e) {
          toast.error(`Error processing domain: ${domains[i]}`);
        }
      }
    },
  });

  const downloadPdf = async (
    domain: string,
    prospectLogo: string,
    branding: Branding,
    cyberScore: CyberScoreCombined,
    actionPlan: ActionPlan,
    keyTakeaways: KeyTakeaways,
    profilePics: string[]
  ) => {
    const asPdf = pdf(
      <PDFScoreCardDocument
        company={domain}
        logo={prospectLogo}
        cyberScore={cyberScore}
        actionPlan={actionPlan}
        keyTakeaways={keyTakeaways}
        branding={branding}
        whiteLabelLogo={branding.yourLogo}
        profilePics={profilePics}
      ></PDFScoreCardDocument>
    );
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} ${
      branding.companyName ?? "Iceberg Cyber"
    } - Score Card - ${domain}.pdf`;
    link.click();
  };

  return (
    <Flex
      gap={"xxl"}
      direction={"column"}
      padding={"68px 0 0 40px"}
      maxWidth={"600px"}
    >
      <Heading fontSize={"2.5em"} lineHeight={"0.75em"}>
        SCORE CARD BATCHES
      </Heading>
      <Text fontSize={"small"}>
        For all those Cyber Powered users that need to make a batch of Score
        Cards 🧺
      </Text>

      <Flex gap={"small"} direction={"column"}>
        <Text fontWeight={"bold"} fontSize={"larger"} paddingBottom={"small"}>
          Create a batch of Score Cards
        </Text>

        <Flex direction="column">
          <HeadlineVerticalDivider
            heading={"Edit Branding"}
            subheading={"Step 1"}
            borderColor={"#174DE1"}
          />
          <BrandingOptions />
        </Flex>
      </Flex>
      <Flex gap={"small"} direction={"column"}>
        <TextAreaField
          label={
            <HeadlineVerticalDivider
              heading={"Enter a comma seperated list of domains"}
              subheading={"Step 2"}
              borderColor={"#174DE1"}
            />
          }
          placeholder="google.com, yahoo.com, bing.com, ..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Button
          variation="primary"
          isLoading={isPending}
          isDisabled={domains.length === 0}
          loadingText={`Processing... ( ${processIndex}/${domains.length})`}
          onClick={async () => await processBatch()}
        >
          Process the batch
        </Button>
      </Flex>
    </Flex>
  );
}
