import { useQuery } from "@tanstack/react-query";

export function useFaviconGoogle(domain: string | undefined | null) {
  // const rootDomain = useRootDomain(company);

  const fallbackUrl =
    "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";

  const { ...query } = useQuery({
    queryKey: ["favicon-google", domain],
    queryFn: async () => {
      if (!domain) {
        return fallbackUrl;
      } else {
        let faviconUrl = `https://www.google.com/s2/favicons?domain=${domain}&sz=${256}`;

        // Creates an image to mock the behavior of the browser
        const img = new Image();
        img.src = faviconUrl;

        // if the image errors, it will use the fallback
        img.onerror = () => {
          faviconUrl = fallbackUrl;
        };

        // waits some seconds to see if the image loads
        await new Promise((resolve) => setTimeout(resolve, 5000));

        // after some time return whatever worked
        return faviconUrl;
      }

      // const fallbackUrl =
      //   "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";
      // if (!company?.Domain) return fallbackUrl;

      // try {
      //   const response = await axios.get(faviconUrl);
      //   if (response.status === 200) {
      //     return faviconUrl;
      //   } else {
      //     return fallbackUrl;
      //   }
      // } catch (err) {
      //   console.error("Favicon error", err);
      //   return fallbackUrl;
      // }
    },

    // enabled: !!company?.Domain,
  });

  return { url: query.data, ...query };
}
