import React from "react";

import { Button, Flex, Grid, View } from "@aws-amplify/ui-react";
import { FaSave } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
import PortfolioOverviewCard from "../components/portfolio/PortfolioOverviewCard";
import CompanyPortfolio from "../components/portfolio/CompanyPortfolio";
import ScannerPortfolio from "../components/portfolio/ScannerPortfolio";
import { useScannersQuery } from "../hooks/useScannersQuery";
import { useCompaniesQuery } from "../hooks/useCompaniesQuery";

export default function Portfolio() {
  const { refetch: refetchScanners } = useScannersQuery();
  const { refetch: refetchCompanies } = useCompaniesQuery(1);

  const refetch = () => {
    refetchScanners();
    refetchCompanies();
  };

  return (
    <>
      <Flex direction={"column"} gap="0" padding={"medium"}>
        <Flex
          height={"52px"}
          padding={"small large 0 large"}
          justifyContent={"right"}
        >
          <Flex gap={"xxs"}>
            <Button
              size="small"
              borderRadius={"medium"}
              variation="link"
              onClick={() => refetch()}
            >
              <FaArrowRotateRight />
            </Button>

            <Button
              size="small"
              borderRadius={"medium"}
              backgroundColor={"blue.60"}
              variation="primary"
            >
              <FaSave />
            </Button>
          </Flex>
        </Flex>

        <Grid
          minHeight={"85vh"}
          gap="large"
          templateColumns={{ base: "1fr", large: "1fr 2fr" }}
          templateRows={{ base: "auto auto auto", large: "auto auto" }}
          padding={"large"}
        >
          <View rowStart={"1"} rowEnd={"-1"}>
            <PortfolioOverviewCard />
          </View>
          <CompanyPortfolio />
          <ScannerPortfolio />
        </Grid>

        {/* Web Dev Simplified */}
        {/* https://blog.webdevsimplified.com/2023-04/html-dialog/ */}

        {/* <ScannerDetailsModal
          selectedScanner={selectedScanner}
          setSelectedScanner={setSelectedScanner}
        /> */}

        {/* <CompanyDetailsModal
          selectedCompany={selectedBusiness}
          setSelectedCompany={setSelectedBusiness}
        /> */}
      </Flex>
    </>
  );
}
