import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUserContext } from "../hooks/useUserContext";
import { useMemo } from "react";
import { updateScanners } from "../services/scanners/updateScanners";
import { Scanner, UpdateScannersInputBody } from "../types/scanners";
import { toast } from "react-toastify";
import posthog from "posthog-js";

type ScannersMutationVariables = {
  body: UpdateScannersInputBody;
};

export function useScannersMutation() {
  const { user } = useUserContext();

  const queryClient = useQueryClient();

  const params = useMemo(
    () => ({
      member_id: user?.memberId ?? "",
    }),
    [user]
  );

  const { mutateAsync: updateScannersMutate, ...mutation } = useMutation({
    mutationKey: ["scanner", "list"],
    mutationFn: async ({ body }: ScannersMutationVariables) => {
      if (!params.member_id) {
        throw new Error("Member ID is not defined.");
      }
      const response = await updateScanners(params, body);
      return response.Scanners;
    },
    onMutate: async (input) => {
      await queryClient.cancelQueries({ queryKey: ["scanner", "list"] });
      const previousScanners = queryClient.getQueryData(["scanner", "list"]);

      // Optimistically update to the new value
      queryClient.setQueryData(["scanner", "list"], (old: Scanner[]) => {
        const updRequest = input.body.Scanners;
        for (const update of updRequest) {
          const index = old.findIndex(
            (scanner) => scanner.ScannerID === update.ScannerID
          );
          if (index !== -1) {
            old[index] = { ...old[index], ...update };
          }
        }
        return [...old];
      });
      return { previousScanners };
    },
    onError: (err, { body }, context) => {
      console.error(err.message);
      toast.error("Something went wrong. Please try again.");
      queryClient.setQueryData(["scanner", "list"], context?.previousScanners);
      posthog.capture("error", {
        function: "update-scanner",
        args: {
          params,
          body,
        },
        message: err.message,
      });
    },
    onSettled: (data) => {
      const failedRequests = data?.filter(
        (response) => response.Success === false
      );
      if (failedRequests && failedRequests.length > 0) {
        toast.error("Something went wrong. Please try again.");
        queryClient.setQueryData(["scanner", "list"], (old: Scanner[]) => {
          for (const failedReqs of failedRequests) {
            const index = old.findIndex(
              (scanner) => scanner.ScannerID === failedReqs.ScannerID
            );
            if (index !== -1) {
              old[index] = { ...old[index], ...failedReqs };
            }
          }
          return [...old];
        });
        queryClient.invalidateQueries({ queryKey: ["scanner", "list"] });
      }
    },
  });

  return {
    updateScannersMutate,
    ...mutation,
  };
}
