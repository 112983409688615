import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import MonitorCompany from "../pages/MonitorCompany";
import { Authenticator } from "@aws-amplify/ui-react";
import CenteredLoader from "../components/CenteredLoader";
import Monitor from "../pages/Monitor";

export default function MonitorsRoutes() {
  return (
    <>
      <Authenticator>
        <DashboardLayout>
          <Suspense fallback={<CenteredLoader />}>
            <Routes>
              <Route index element={<Monitor />} />
              {/* <Route index element={<MonitorCompany />} /> */}
              <Route path="/:companyId/:scannerId" element={<MonitorCompany />} />
            </Routes>
          </Suspense>
        </DashboardLayout>
      </Authenticator>
    </>
  );
}
