import { UpdateCampaignsInputBody } from "../../types/campaigns";
import { icebergPost } from "../iceberg-api";

type Args = {
  memberId: string;
  campaignId: string;
  currentProspects: number[];
  newProspect: number;
};

export async function insertCompany({
  memberId,
  campaignId,
  currentProspects,
  newProspect,
}: Args) {
  const inputBody: UpdateCampaignsInputBody = {
    Campaigns: [
      {
        Operation: "update",
        CampaignID: campaignId,
        Prospects: [...currentProspects, newProspect],
      },
    ],
  };

  await icebergPost("campaign_management", {
    queryParams: { member_id: memberId },
    body: inputBody,
  });
}
