import { NVT } from "../types/assetInventoryApi";

export function getVulnPriority(nvt: NVT) {
  switch (nvt.priority) {
    case 0:
      return "Critical";
    case 1:
      return "High";
    case 2:
      return "Medium";
    case 3:
      return "Low";
    default:
      return "Low";
  }
}
