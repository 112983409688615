import { useQuery } from "@tanstack/react-query";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import posthog from "posthog-js";
import { getUser } from "../services/users";
import { toast } from "react-toastify";

export function useUserQuery() {
  const { data: authUser } = useQuery({
    queryKey: ["user", "auth"],
    queryFn: async () => {
      try {
        return await getCurrentUser();
      } catch (e) {
        return null;
      }
    },
  });
  const { data: userAttributes } = useQuery({
    queryKey: ["user", "attributes"],
    queryFn: async () => {
      const attributes = await fetchUserAttributes();
      return attributes;
    },
    enabled: !!authUser,
  });

  const { data: user, ...query } = useQuery({
    queryKey: ["user", "data"],
    queryFn: async () => {
      const user = await getUser();
      const attributes = await fetchUserAttributes();
      posthog.identify(attributes.email, {
        email: attributes.email,
        name: `${attributes.given_name} ${attributes.family_name}`,
        memberId: user.memberId,
        userId: user.userId,
      });
      return user;
    },
    retry: false,
    enabled: !!authUser,
    throwOnError: () => {
      toast.info(
        "We are getting your account setup. Check out the features in the mean time.",
        {
          autoClose: false,
          closeOnClick: false,
          toastId: "userQueryError",
        }
      );
      return false;
    },
  });

  return {
    authUser,
    user,
    userAttributes,
    ...query,
  };
}
