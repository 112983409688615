import { View, Image, Text } from "@react-pdf/renderer";
import * as React from "react";
export interface IReportCompanyDetailsProps {
  styles: any;
  // company?: Business;
  companyName: string | undefined | null;
  domain: string | undefined | null;
  logo: string | undefined;
}

export default function ReportCompanyDetails(
  props: IReportCompanyDetailsProps
) {
  const { styles, companyName, domain, logo } = props;


  return (
    <View style={styles.companyDetails}>
      <View style={{ paddingRight: "5px" }}>
        {logo ? (
          <Image
            src={logo}
            style={{ width: "50px", height: "50px", objectFit: "contain" }}
          />
        ) : null}
      </View>
      <View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "black",
          }}
        >
          {companyName}
        </Text>
        <Text
          style={{
            fontSize: "12px",
            color: "black",
          }}
        >
          {domain}
        </Text>
        <Text
          style={{
            fontSize: "12px",
            color: "black",
          }}
        >
          {new Date().toLocaleDateString("en-ca")}
        </Text>
      </View>
    </View>
  );
}
