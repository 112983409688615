import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useUserContext } from "./useUserContext";
import { useMemo } from "react";
import { icebergGet, icebergPost } from "../services/iceberg-api";
import {
  ListCampaignsOutput,
  UpdateCampaignsInputBody,
} from "../types/campaigns";

export function useCampaignsQuery() {
  const { user } = useUserContext();

  const memberId = useMemo(() => user?.memberId ?? undefined, [user]);

  const { data: groups, ...query } = useQuery({
    queryKey: ["score-group", "list", memberId],
    queryFn: async () => {
      const campaigns = await icebergGet("campaign_management", {
        queryParams: { member_id: memberId },
      }).then(
        async (res) =>
          ((await res.body.json()) as any as ListCampaignsOutput).Campaigns
      );
      return campaigns;
    },
    enabled: !!memberId,
  });

  const queryClient = useQueryClient();

  useQueries({
    queries:
      groups?.map((group) => ({
        queryKey: ["score-group", "detail", group.CampaignID],
        queryFn: () => group,
      })) ?? [],
  });

  const createGroupMutation = useMutation({
    mutationKey: ["create-score-group"],
    mutationFn: async (name: string) => {
      const body: UpdateCampaignsInputBody = {
        Campaigns: [
          {
            Operation: "insert",
            Name: name,
          },
        ],
      };

      await icebergPost("campaign_management", {
        queryParams: { member_id: memberId },
        body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["score-group"] });
    },
  });

  return {
    groups,
    createGroupMutation,
    ...query,
  };
}
