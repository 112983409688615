import { Button, Flex, Grid, Image, Input, Label, TextField, View } from '@aws-amplify/ui-react'
import React from 'react'
import HeadlineVerticalDivider from '../styling/HeadlineVerticalDivider'

export default function Step1Modal() {
    return (
        <>
            <Grid
                height={"100%"}
                templateColumns={"1fr 1fr"}
                gap="large"
                padding={"large"}
                alignItems={"center"}

            >
                <Flex
                    as="form"
                    gap={"xl"}
                    direction={"column"}
                    justifyContent={"center"}
                    data-attr="score-card-form"
                >

                    <Flex direction={"column"} gap={"small"}>
                        <HeadlineVerticalDivider heading={"Create a Score Card"} subheading={"Step 1"} borderColor={"#174DE1"} />

                        <TextField
                            size="small"
                            label=""
                            maxWidth={"400px"}
                            placeholder="Enter a website"
                        ></TextField>
                    </Flex>



                    <Flex direction={"column"}>


                        <HeadlineVerticalDivider heading={"Setup your branding"} subheading={"Step 2"} borderColor={"#174DE1"} />


                        <Flex justifyContent={"center"} height={"100px"}>
                            <Grid
                                templateColumns={"auto auto"}
                                gap={"small"}
                                justifyContent={"start"}
                            >
                                <Label fontSize={"small"} htmlFor="primary-color">Primary</Label>
                                <Input
                                    id="primary-color"
                                    type="color"
                                    width={"40px"}
                                    padding={"xxxs"}

                                ></Input>

                                <Label fontSize={"small"} htmlFor="accent-color">Accent</Label>
                                <Input
                                    id="accent-color"
                                    type="color"
                                    width={"40px"}
                                    padding={"xxxs"}

                                ></Input>

                                <Label fontSize={"small"} htmlFor="font-color">Font</Label>
                                <Input
                                    id="font-color"
                                    fontSize={"small"}
                                    type="color"
                                    width={"40px"}
                                    padding={"xxxs"}

                                ></Input>
                            </Grid>

                            <View flex={1}>
                              //Your Logo Image Uploader
                            </View>
                            <View flex={1}>
                            //Prospect Logo Image Uploader
                            </View>
                        </Flex>
                        <Flex flex={2}>
                            <TextField
                                label="Your Contact Name"
                                labelHidden
                                size="small"
                                flex={1}
                                placeholder="Your Contact Name"
                            ></TextField>
                            <TextField
                                label="Your Contact Email"
                                labelHidden
                                flex={1}
                                size="small"
                                placeholder="Your Contact Email"
                                type="email"
                            ></TextField>
                        </Flex>

                    </Flex>


                    <Flex>
                        <Button
                            fontWeight={"medium"}
                            variation="primary"
                            backgroundColor={"#174DE1"}
                            borderRadius={"medium"}
                            size="small"
                        >
                            Add Prospect
                        </Button>
                    </Flex>
                </Flex>

                <Image
                    flex={1}
                    borderRadius="large"
                    src="/images/score-card/score-card-blue-p1.png"
                    alt=""
                    objectFit={"contain"}
                    boxShadow={"medium"}
                />
            </Grid>
        </>
    )
}
