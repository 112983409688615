import React, { Suspense } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Authenicate from "./pages/Home";
import MonitorsRoutes from "./routes/MonitorRoutes";
import PortfolioRoutes from "./routes/PortfolioRoutes";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuthComponent from "./components/authentication/AuthComponent";
import { Authenticator } from "@aws-amplify/ui-react";
import CenteredLoader from "./components/CenteredLoader";
import ScoreManagementRoutes from "./routes/ScoreManagementRoutes";
import { ToastContainer, toast } from "react-toastify";
import Settings from "./pages/Settings";
import PageNotFound from "./pages/PageNotFound";
import { UserContextProvider } from "./hooks/useUserContext";
import GettingStartedRoutes from "./routes/GettingStartedRoutes";
import ScoreCardRoutes from "./routes/ScoreCardRoutes";
import CyberAuditRoutes from "./routes/CyberAuditRoutes";
import { BrandingContextProvider } from "./hooks/useBrandingContext";
import ReconRoutes from "./routes/ReconRoutes";
import posthog from "posthog-js";

Amplify.configure(awsExports);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      throwOnError: (error, key) => {
        toast.error(error.message, { toastId: key.queryHash[0] });
        posthog.capture("error", {
          queryKey: key,
          message: error.message,
        });
        return false;
      },
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <Suspense fallback={<CenteredLoader />}>
            <UserContextProvider>
              <BrandingContextProvider>
                <Router>
                  <Routes>
                    {/* <Route index element={<Authenicate />} /> */}
                    <Route index element={<AuthComponent />} />
                    {/* <Route path="/login" element={<Authenicate />} /> */}
                    <Route path="monitor/*" element={<MonitorsRoutes />} />
                    <Route path="portfolio/*" element={<PortfolioRoutes />} />
                    <Route
                      path="score-management/*"
                      element={<ScoreManagementRoutes />}
                    />
                    <Route path="recon/*" element={<ReconRoutes />} />
                    <Route path="settings" element={<Settings />} />
                    {/* <Route path="test/*" element={<TestRoute />} /> */}
                    <Route
                      path="cyber-audit/*"
                      element={<CyberAuditRoutes />}
                    />
                    <Route
                      path="getting-started/*"
                      element={<GettingStartedRoutes />}
                    />
                    <Route path="score-card/*" element={<ScoreCardRoutes />} />

                    {/* <Route path="test2/*" element={<TestRoute2 />} /> */}
                    <Route path="*" element={<PageNotFound />} />
                    {/* <Route path="/dashboard/*" element={<DashboardLayout />}/>
          <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
                  </Routes>
                </Router>
              </BrandingContextProvider>
            </UserContextProvider>
          </Suspense>
          <ToastContainer position="top-center" />
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        </Authenticator.Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
