import { Card, Flex, Text } from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FaCircleMinus } from "react-icons/fa6";
import { Menu } from "../../pages/ReconCompany";

export default function InsightsCard({
  title,
  score,
  subtitle,
  currentMenu,
  menu,
  onClick,
}: {
  title: string;
  score: -1 | 0 | 1 | undefined;
  subtitle: string | undefined;
  currentMenu: Menu;
  menu: Menu;
  onClick: (menu: Menu) => void;
}) {
  /* *********************** useState/Variables ************************* */

  const active = useMemo(() => currentMenu === menu, [currentMenu, menu]);

  // hover State
  const [hover, setHover] = useState(false);

  // css changes based off this handled within cardStyle and outsideStyle (active state managed within the parent component)
  const cardStyle = {
    backgroundColor: active ? "#f0f0f0" : hover ? "#f0f0f0" : "white",
    border: active
      ? "1px solid rgba(128, 128, 128, 0.2)"
      : "1px solid whitesmoke",
    boxShadow: active ? "5px 5px 10px rgba(0, 0, 98, .2)" : "",
    borderRadius: active ? "20%" : "",
  };

  const status = useMemo(
    () => (score === 1 ? "pass" : score === 0 ? "unknown" : "fail"),
    [score]
  );

  const color = useMemo(
    () =>
      status === "pass"
        ? "green.60"
        : status === "unknown"
          ? "neutral.80"
          : "red.60",
    [status]
  );

  const Icon = () =>
    status === "pass" ? (
      <FaCheckCircle />
    ) : status === "unknown" ? (
      <FaCircleMinus />
    ) : (
      <FaTimesCircle />
    );

  return (
    <>
      <Card
        onClick={() => onClick(menu)}
        boxShadow={"medium"}
        borderRadius={"large"}
        padding={"xs"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ ...cardStyle }}
      >
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex direction={"column"} gap={"0"}>
            <Text fontWeight={"bold"} color={color} fontSize={"smaller"}>
              {title}
            </Text>
            <Text fontSize={"xs"}>{subtitle}</Text>
          </Flex>

          <Text fontSize={"larger"} color={color}>
            <Icon />
          </Text>
        </Flex>
      </Card>
    </>
  );
}
