import { AssetTableData } from "..";

export function assetSortFunction(
  sortColumn: keyof AssetTableData | undefined,
  a: AssetTableData,
  b: AssetTableData
) {
  if (!sortColumn) return 0;

  const A = a[sortColumn];
  const B = b[sortColumn];

  if (typeof A === "string" && A.trim() === "") return 1;
  if (typeof B === "string" && B.trim() === "") return -1;
  return A > B ? 1 : -1;
}
