import { useQuery } from "@tanstack/react-query";

export function useFavicon(domain: string | undefined | null) {
  // const rootDomain = useRootDomain(company);

  const fallbackUrl =
    "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";

  const query = useQuery({
    queryKey: ["favicon", domain],
    queryFn: async () => {
      if (!domain) {
        return fallbackUrl;
      } else {
        let faviconUrl = `https://favicon.twenty.com/${domain}`;

        // Creates an image to mock the behavior of the browser
        const img = new Image();
        img.src = faviconUrl;

        // if the image errors, it will use the fallback
        img.onerror = () => {
          faviconUrl = fallbackUrl;
        };

        // img.onload = async () => {
        //   faviconUrl = await loadImage(faviconUrl);
        // };

        // waits some seconds to see if the image loads
        await new Promise((resolve) => setTimeout(resolve, 5000));

        // console.log(faviconUrl);
        // after some time return whatever worked
        return faviconUrl;
      }

      // const fallbackUrl =
      //   "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";
      // if (!company?.Domain) return fallbackUrl;

      // try {
      //   const response = await axios.get(faviconUrl);
      //   if (response.status === 200) {
      //     return faviconUrl;
      //   } else {
      //     return fallbackUrl;
      //   }
      // } catch (err) {
      //   console.error("Favicon error", err);
      //   return fallbackUrl;
      // }
    },

    // enabled: !!company?.Domain,
  });

  return { url: query.data, ...query };
}

// function loadImage(url: string): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.onload = function () {
//       const reader = new FileReader();
//       reader.onloadend = function () {
//         if (typeof reader.result === "string") {
//           resolve(reader.result);
//         } else {
//           reject(new Error("Unable to read image data"));
//         }
//       };
//       reader.onerror = function () {
//         reject(new Error("Failed to load image"));
//       };
//       reader.readAsDataURL(xhr.response);
//     };
//     xhr.onerror = function () {
//       reject(new Error("Failed to load image"));
//     };
//     xhr.open("GET", url);
//     xhr.responseType = "blob";
//     xhr.send();
//   });
// }
