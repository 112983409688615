import {
  Button,
  Card,
  Flex,
  Image,
  Link,
  Placeholder,
  Text,
} from "@aws-amplify/ui-react";
import React from "react";
import { useFavicon } from "../../hooks/useFavicon";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";

import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { Company } from "../../types/companies";
import { Modal } from "../Modal";
import CompanyDetailsForm from "../portfolio/CompanyDetailsForm";
import { useCompaniesMutation } from "../../hooks/useCompaniesMutation";
import { FaGear } from "react-icons/fa6";

type CompanyCardProps = {};

export default function CompanyCard(props: CompanyCardProps) {
  const {} = props;

  const { company } = useMonitorCompanyContext();

  const { url } = useFavicon(company?.EmailDomain);

  const updateCompanyModal = React.useRef<HTMLDialogElement>(null);

  const { updateCompaniesMutate } = useCompaniesMutation();

  const Content = ({ company }: { company?: Company }) => (
    <>
      <Modal ref={updateCompanyModal}>
        <CompanyDetailsForm
          groupId={1}
          Operation="update"
          selectedCompany={company}
          onSave={async (updateCompanyRequest) => {
            await updateCompaniesMutate({
              body: { Businesses: [updateCompanyRequest] },
            });
          }}
          onClose={() => updateCompanyModal.current?.close()}
        />
      </Modal>
      <Flex>
        <Flex alignItems={"center"}>
          {!company ? (
            <Placeholder width={"65px"} height={"65px"} />
          ) : (
            <Image
              alt="company logo"
              src={url}
              // onError={(e) => {
              //   // (
              //   //   e as React.SyntheticEvent<HTMLImageElement, Event>
              //   // ).currentTarget.src =
              //   //   "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png";
              // }}
              objectFit="initial"
              objectPosition="50% 50%"
              height="65px"
              width="65px"
            />
          )}
        </Flex>

        <Flex direction={"column"} gap={"2px"}>
          <Text fontWeight={"bold"} color={"blue.90"}>
            {!company ? (
              <Placeholder height={"24px"} width={"125px"} />
            ) : (
              company?.CompanyName
            )}
          </Text>
          <Link
            href={"https://" + company?.EmailDomain}
            isExternal
            fontSize={"smaller"}
            color={"blue.90"}
          >
            {!company ? (
              <Placeholder height={"20px"} width={"100px"} />
            ) : (
              company?.EmailDomain
            )}
          </Link>
          <Text fontSize={"xs"} color={"neutral.80"}>
            {/* Latest Report: 2023-11-30 8:39pm EST */}
            {!company ? (
              <Placeholder height={"18px"} width={"175px"} />
            ) : (
              "Latest Report: " +
              (company?.LastScan
                ? timeStampToLocaleString(company?.LastScan)
                : "-")
            )}
          </Text>
        </Flex>
        <Flex flex={1} justifyContent={"end"} alignItems={"start"}>
          <Button
            size="small"
            variation="menu"
            onClick={() => {
              updateCompanyModal.current?.showModal();
            }}
          >
            <FaGear />
          </Button>
        </Flex>
      </Flex>
    </>
  );

  return (
    <Card width={"100%"} boxShadow={"medium"} borderRadius={"large"}>
      <Content company={company} />
    </Card>
  );
}
