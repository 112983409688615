import React, { createContext } from "react";

import { useITLandscapeChart } from "./ITLandscapeBarChart.hooks";
import { useAssetAgeChart } from "./AssetAgeChart.hooks";
import { EChartsType } from "echarts";
import useAssetTypeOptions from "../../hooks/useAssetTypeOptions";
import EChartsReact from "echarts-for-react";
import { View } from "@aws-amplify/ui-react";
import useAssetAgeOptions from "../../hooks/useAssetAgeOptions";

interface MonitorCompanyChartsContextState {
  ITLandscapeChart: React.FC;
  ITLandscapeChartInstance: EChartsType | null;
  AssetAgeChart: React.FC;
  assetAgeChartInstance: EChartsType | null;
  getITLandscapeChartImageData: () => string | undefined;
  getAssetAgeChartImageData: () => string | undefined;
}

// Create the context
const MonitorCompanyChartsContext = createContext<
  MonitorCompanyChartsContextState | undefined
>(undefined);

export function useMonitorCompanyChartsContext() {
  const context = React.useContext(MonitorCompanyChartsContext);
  if (context === undefined) {
    throw new Error(
      "useMonitorCompanyChartsContext must be used within a MonitorCompanyChartsContextProvider"
    );
  }
  return context;
}

// Create the provider component
export const MonitorCompanyChartsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [
    ITLandscapeChart,
    ITLandscapeChartInstance,
    // getITLandscapeChartImageData,
  ] = useITLandscapeChart();

  const [AssetAgeChart, assetAgeChartInstance] = useAssetAgeChart();

  const assetTypeOptions = useAssetTypeOptions();
  const assetAgeOptions = useAssetAgeOptions();

  const assetTypeChartRef = React.useRef<EChartsReact>(null);
  const assetAgeChartRef = React.useRef<EChartsReact>(null);

  // Return the provider component
  return (
    <>
      <View position={"fixed"} left={"100%"}>
        <EChartsReact
          ref={assetTypeChartRef}
          option={{ ...assetTypeOptions, animation: false }}
          style={{ height: "125px", width: "300px" }}
        />
      </View>
      <View position={"fixed"} left={"100%"}>
        <EChartsReact
          ref={assetAgeChartRef}
          option={{ ...assetAgeOptions, animation: false }}
          style={{ height: "125px", width: "300px" }}
        />
      </View>
      <MonitorCompanyChartsContext.Provider
        value={{
          ITLandscapeChart,
          ITLandscapeChartInstance,
          AssetAgeChart,
          assetAgeChartInstance,
          // getITLandscapeChartImageData,
          getITLandscapeChartImageData: () => {
            assetTypeChartRef.current
              ?.getEchartsInstance()
              .setOption({ ...assetTypeOptions, animation: false });
            return assetTypeChartRef.current
              ?.getEchartsInstance()
              .getDataURL({ pixelRatio: 4 });
          },
          getAssetAgeChartImageData: () => {
            assetAgeChartRef?.current
              ?.getEchartsInstance()
              .setOption({ ...assetAgeOptions, animation: false });
            return assetAgeChartRef.current
              ?.getEchartsInstance()
              .getDataURL({ pixelRatio: 4 });
          },
        }}
      >
        {children}
      </MonitorCompanyChartsContext.Provider>
    </>
  );
};
