import { useQuery } from "@tanstack/react-query";
import { getCyberScoreCombined } from "../services/cyberScore/getCyberScoreCombined";
import { CyberScoreCombined } from "../types/CyberScoreCombined";

export function useCyberScoreCombinedQuery(domain: string | undefined | null) {
  const { data: cyberScore, ...query } = useQuery<CyberScoreCombined>({
    queryKey: ["cyber-score", "combined", domain],
    queryFn: async () => await getCyberScoreCombined(domain ?? ""),
    retry: false,
    enabled: !!domain,
  });

  return {
    cyberScore,
    ...query,
  };
}
