type NVTStatusOption = {
  label: "To Do" | "Ignored" | "In Progress" | "Resolved" | "Accepted";
  backgroundColor: string;
  color: string;
}[];

export const nvtStatusOptions: NVTStatusOption = [
  {
    label: "To Do",
    backgroundColor: "white",
    color: "black",
  },
  {
    label: "Ignored",
    backgroundColor: "#42cefb",
    color: "black",
  },
  {
    label: "In Progress",
    backgroundColor: "#1b6eff",
    color: "white",
  },
  {
    label: "Resolved",
    backgroundColor: "var(--amplify-colors-green-20)",
    color: "var(--amplify-colors-green-90)",
  },
  {
    label: "Accepted",
    backgroundColor: "var(--amplify-colors-green-10)",
    color: "var(--amplify-colors-green-80)",
  },
];
