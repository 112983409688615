import { Asset } from "../../types/assetInventoryApi";
import { invokeLambda } from "../aws/lambda/invokeFunction";

export type ActionPlan = {
  actions:
    | {
        title: string;
        detail: string;
      }[]
    | undefined;
};

export async function genActionPlan(assets: Asset[]) {
  const response = await invokeLambda("TextGenApiHandler", {
    genType: "action-plan",
    body: assets,
  }).catch((err) => {
    console.error(err);
    throw err;
  });
  return JSON.parse(response.body) as ActionPlan;
}
