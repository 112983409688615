import React from "react";
import CyberCenterContent from "./CyberCenterContent";
import { Link, Text } from "@aws-amplify/ui-react";

export default function WebsiteFirewall() {
  return (
    <CyberCenterContent
      whatIsItContent={
        <Text>
          A Web Application Firewall (WAF) is a security tool that monitors and
          filters traffic to and from your web applications, blocking harmful
          requests like hacking attempts. A WAF is important as it protects your
          website and web applications from attacks, ensuring client data and
          business operations remain secure.
        </Text>
      }
      whyDoesItMatterContent={
        <Text>
          If you don't have a Web Application Firewall (WAF), your business
          website could become an easy target for cyberattacks like SQL
          injections, cross-site scripting, and other exploits. The worst
          outcomes include a major data breach where sensitive client
          information, like financial records, is stolen or compromised. This
          can lead to financial fraud, legal liabilities for your business, a
          loss of client trust, and significant reputational damage. The impact
          of such an event can be devastating, especially for a small business
          where client confidence is key.
        </Text>
      }
      howDoYouFixItContent={
        <Text>
          To get a WAF, choose a provider offering WAF services suitable for
          your business size and needs. Then, integrate it with your website
          following the provider's instructions, which may involve changing your
          domain's DNS settings. For small businesses, selecting a Web
          Application Firewall (WAF) solution that is both effective and
          cost-efficient is important. Some recommended options include:{" "}
          <ul>
            <li>
              <Link
                href="https://www.cloudflare.com/en-ca/application-services/products/waf/"
                isExternal
                color={"teal.80"}
                fontWeight={"semibold"}
              >
                Cloudflare
              </Link>
              : Known for its ease of use and good performance. It offers a free
              plan which is suitable for small websites.{" "}
            </li>
            <li>
              <Link href="https://sucuri.net/website-firewall/" isExternal
                color={"teal.80"}
                fontWeight={"semibold"}>
                Sucuri
              </Link>
              : Offers a website security platform including a WAF. It is
              user-friendly and specifically caters to small and medium-sized
              businesses.{" "}
            </li>
            <li>
              <Link href="https://www.wordfence.com/" isExternal
                color={"teal.80"}
                fontWeight={"semibold"}>
                Wordfence
              </Link>
              : Ideal for WordPress websites, providing a robust firewall and
              malware scanner designed for the WordPress environment.
            </li>
          </ul>
          Find the exact steps in our{" "}
          <Link
            href="https://www.icebergcyber.com/help-center-category/website-security"
            isExternal
            color={"teal.80"}
            fontWeight={"semibold"}
          >
            Help Center
          </Link>
          .
        </Text>
      }
    />
  );
}
