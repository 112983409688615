import { useQuery } from "@tanstack/react-query";
import { CompanyGroup } from "../types/companies";
import { useUserContext } from "./useUserContext";
import { useMemo } from "react";
import { listReconOutputs } from "../services/recon-outputs/listReconOutputs";

export function useReconOutputsQuery({
  groupId,
  campaignId,
}: {
  groupId: CompanyGroup;
  campaignId?: string;
}) {
  const { user } = useUserContext();

  const memberId = useMemo(() => user?.memberId ?? undefined, [user]);

  const { data: recons, ...query } = useQuery({
    queryKey: ["recon-outputs", groupId, campaignId],
    queryFn: async () => {
      const response = await listReconOutputs({
        memberId: memberId as string,
        groupId,
        campaignId,
      });
      return response;
    },
    enabled: !!memberId && !!campaignId,
    refetchOnWindowFocus: true,
    refetchInterval: 30 * 1000,
  });

  return {
    recons,
    ...query,
  };
}
