import React from "react";
import { Card, Flex, Grid, Heading, View } from "@aws-amplify/ui-react";
import ScoreGroupsTable from "../components/portfolio/ScoreGroupsTable";
import PaddedContainer from "../layouts/PaddedContainer";
import InformationPanel from "../components/monitorcompany/InformationPanel";



export default function ScoreGroupListView() {


    
  return (
    <PaddedContainer>
      <Grid
        gap={"xl large"}
        templateColumns={{ base: "1fr", large: "1fr 1fr" }}
      >
        <Flex
          direction="column"
          height={"100%"}
          justifyContent={"space-between"}
        >
          <InformationPanel
            header={
              <Heading level={3} fontWeight={"bold"}>
                Score Management
              </Heading>
            }
          >
            We are going to write some sort of intro description paragraph here
            to describe that Score Management allows the user to save Score
            Cards for companies and group together these save Score Cards. This
            way you can stay up to date on all the publicly available cyber risk
            data of companies you are interested in. Maybe we call them Packs of
            Score Cards?
          </InformationPanel>
        </Flex>
        <Card borderRadius={"large"} boxShadow={"medium"}></Card>
        <View columnSpan={{ base: 1, large: 2 }}>
          <ScoreGroupsTable />
        </View>
      </Grid>
    </PaddedContainer>
  );
}
