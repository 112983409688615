import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import CompanyCard from "./CompanyCard";
import CompanyStatsCard from "./CompanyStatsCard";

export default function Details() {
  return (
    <Flex direction={"column"} gap={"large"}>
      <CompanyCard />
      <CompanyStatsCard />
    </Flex>
  );
}
