import React from "react";
import CyberCenterContent from "./CyberCenterContent";
import { Text, Tabs, View } from "@aws-amplify/ui-react";
import DarkWebReportTable from "../components/monitorcompany/DarkWebReportTable";
import { useParams } from "react-router-dom";
import CompromisedAccountsTable from "../components/monitorcompany/CompromisedAccountsTable";

type DarkWebEmailBreachesContentProps = {};

export default function DarkWebEmailBreachesContent({}: DarkWebEmailBreachesContentProps) {
  const { domain } = useParams();

  const [activeTab, setActiveTab] = React.useState("darkwebreport");

  return (
    <CyberCenterContent
      whatIsItContent={
        <>
          <Text>
            Dark Web data breaches expose your emails and passwords, making them
            accessible to hackers. This can lead to unauthorized access to your
            systems, data theft, or impersonation. As a small business data
            security is critical. Breaches can compromise client data, financial
            information, and your business's credibility.
          </Text>

          <View padding={"xs 0 0 0"}>
            <Tabs.Container defaultValue="darkwebreport">
              <Tabs.List
                value={activeTab}
                onValueChange={(tab) => setActiveTab(tab)}
              >
                <Tabs.Item fontSize={"smaller"} value="darkwebreport">
                  Dark Web Report
                </Tabs.Item>
                <Tabs.Item fontSize={"smaller"} value="topdarkweb">
                  Compromised Accounts
                </Tabs.Item>
              </Tabs.List>
              <Tabs.Panel value="darkwebreport">
                <DarkWebReportTable maxHeight={"300px"} domain={domain} />
              </Tabs.Panel>
              <Tabs.Panel value="topdarkweb">
                <CompromisedAccountsTable domain={domain} maxHeight={"300px"} />
              </Tabs.Panel>
            </Tabs.Container>
          </View>

          {/* <Tabs
            value={activeTab}
            onValueChange={(tab) => setActiveTab(tab)}
            // inputStyles={{ fontSize: "var(--amplify-font-sizes-xs)" }}
            style={{ fontSize: "12px" }}
            items={[
              {
                label: "Dark Web Report",
                value: "darkwebreport",
                content: <DarkWebReportTable domain={domain} />,
              },
              {
                label: "Top Dark Web",
                value: "topdarkweb",
                content: <TopDarkWebTable domain={domain} />,
              },
            ]}
          >
          </Tabs> */}
        </>
      }
      whyDoesItMatterContent={
        <Text>
          Compromised work emails pose a risk of unauthorized transactions, data
          leaks, and communication breaches, which can severely impact your
          business's integrity and client trust. Attackers can simply log into
          your important accounts! Some negative outcomes of this include
          financial loss due to fraud, legal penalties for data breaches, loss
          of client trust, and significant damage to your business reputation.
        </Text>
      }
      howDoYouFixItContent={
        <Text>
          You can't stop data breaches but you can respond quickly to protect
          yourself. Immediately reset the passwords of affected accounts.
          Implement strong, unique passwords and consider using a password
          manager. Enable Two-Factor Authentication (2FA) and Multi-Factor
          Authentication (MFA) for extra security. Regularly monitor for
          suspicious activities and educate your employees about cybersecurity
          practices.
        </Text>
      }
    />
  );
}
