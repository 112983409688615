import {
  Flex,
  Grid,
  Input,
  Label,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import React from "react";
import ImageUploader from "../monitorcompany/ImageUploader";
import { useBrandingContext } from "../../hooks/useBrandingContext";

type Props = {
  prospectLogo?: string;
  setProspectLogo?: React.Dispatch<React.SetStateAction<string>>;
};

export default function BrandingOptions({
  prospectLogo,
  setProspectLogo,
}: Props) {
  const { branding, setBranding } = useBrandingContext();

  const {
    primaryColor,
    accentColor,
    fontColor,
    yourLogo: logo,
    contactName,
    contactEmail,
    slogan,
    companyName,
    scoreAlias,
  } = branding;

  return (
    <>
      <Flex direction={"column"}>
        <Flex justifyContent={"center"} height={"100px"}>
          <Grid
            templateColumns={"auto auto"}
            gap={"small"}
            justifyContent={"start"}
          >
            <Label fontSize={"small"} htmlFor="primary-color">
              Primary
            </Label>
            <Input
              id="primary-color"
              type="color"
              width={"40px"}
              padding={"xxxs"}
              value={primaryColor}
              onChange={(e) => {
                setBranding((prev) => ({
                  ...prev,
                  primaryColor: e.target.value,
                }));
              }}
            ></Input>

            <Label fontSize={"small"} htmlFor="accent-color">
              Accent
            </Label>
            <Input
              id="accent-color"
              type="color"
              width={"40px"}
              padding={"xxxs"}
              value={accentColor}
              onChange={(e) => {
                setBranding((prev) => ({
                  ...prev,
                  accentColor: e.target.value,
                }));
              }}
            ></Input>

            <Label fontSize={"small"} htmlFor="font-color">
              Font
            </Label>
            <Input
              id="font-color"
              fontSize={"small"}
              type="color"
              width={"40px"}
              padding={"xxxs"}
              value={fontColor}
              onChange={(e) => {
                setBranding((prev) => ({
                  ...prev,
                  fontColor: e.target.value,
                }));
              }}
            ></Input>
          </Grid>

          <View flex={1}>
            <ImageUploader
              text={
                <Text fontSize={"small"} color={"neutral.80"}>
                  Your Logo
                </Text>
              }
              dataBase64={logo}
              onChangeBase64={(data) => {
                setBranding((prev) => ({
                  ...prev,
                  yourLogo: data,
                }));
              }}
            />
          </View>
          <View flex={1}>
            <ImageUploader
              text={
                <Text fontSize={"small"} color={"neutral.80"}>
                  Prospect's Logo
                </Text>
              }
              dataBase64={prospectLogo}
              onChangeBase64={(data) => {
                setProspectLogo?.(data);
              }}
            />
          </View>
        </Flex>
        <Flex flex={2}>
          <TextField
            label="Your Contact Name"
            labelHidden
            size="small"
            flex={1}
            placeholder="Your Contact Name"
            value={contactName}
            onChange={(e) => {
              setBranding((prev) => ({
                ...prev,
                contactName: e.target.value,
              }));
            }}
          ></TextField>
          <TextField
            label="Your Contact Email"
            labelHidden
            flex={1}
            size="small"
            placeholder="Your Contact Email"
            type="email"
            value={contactEmail}
            onChange={(e) => {
              setBranding((prev) => ({
                ...prev,
                contactEmail: e.target.value,
              }));
            }}
          ></TextField>
        </Flex>
        <Flex>
          <TextField
            label="Your Company Name"
            labelHidden
            flex={1}
            size="small"
            placeholder="Your Company Name"
            type="text"
            value={companyName}
            onChange={(e) => {
              setBranding((prev) => ({
                ...prev,
                companyName: e.target.value,
              }));
            }}
          ></TextField>
          <TextField
            label="Your Score Name"
            labelHidden
            flex={1}
            size="small"
            placeholder="Your Score Name"
            type="text"
            value={scoreAlias}
            onChange={(e) => {
              setBranding((prev) => ({
                ...prev,
                scoreAlias: e.target.value,
              }));
            }}
          ></TextField>
        </Flex>
        <TextField
          label="Your Slogan"
          labelHidden
          flex={1}
          size="small"
          placeholder="Your Slogan"
          type="text"
          value={slogan}
          onChange={(e) => {
            setBranding((prev) => ({
              ...prev,
              slogan: e.target.value,
            }));
          }}
        ></TextField>
      </Flex>
    </>
  );
}
