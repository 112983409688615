import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Branding } from "../monitorcompany/ReportBuilderButton";

export const Title = ({
  title,
  reportStyles,
}: {
  title: string;
  reportStyles: Branding;
}) => (
  <View
    style={{
      borderBottom: "5px",
      borderBottomColor: reportStyles.primaryColor,
    }}
  >
    <Text style={{ fontSize: "48px", fontWeight: "bold" }}>{title}</Text>
  </View>
);
