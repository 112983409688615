/**
 * Converts a MAC date to an age in years.
 *
 * @param {string} macDate - The MAC date to convert.
 * @returns {number} The age in years.
 */
export function macDateToAge(macDate: string) {
  return Math.round(
    (new Date().getTime() - new Date(macDate).getTime()) /
      1000 /
      60 /
      60 /
      24 /
      365
  );
}
