import React, { useCallback, useEffect, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import EChartsReact from "echarts-for-react";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import { getVulnPriority } from "../../util/getVulnPriority";

type TopRiskAssetData = {
  priority: string;
  nRisks: number;
};

const titleOffsets = [
  ["70%", "-90%"],
  ["70%", "-70%"],
  ["70%", "-50%"],
  ["70%", "-30%"],
];

const colors = ["#DC2B2B", "#144EE3", "#1B6EFF", "#42CEFB"];

const TopRisksandPrioritiesCharts = () => {
  const { nvts } = useMonitorCompanyContext();

  const priorityGroupedNvts = useMemo(
    () => ({
      Critical: [],
      High: [],
      Medium: [],
      Low: [],
      ...Object.groupBy(nvts ?? [], (nvt) => getVulnPriority(nvt)),
    }),
    [nvts]
  );

  const topAssetRisksData: TopRiskAssetData[] = useMemo(
    () =>
      Object.entries(priorityGroupedNvts).map(([priority, nvts]) => {
        return {
          priority,
          nRisks: nvts.length,
        };
      }),

    [priorityGroupedNvts]
  );

  const assetsData = useMemo(() => {
    return topAssetRisksData.map((data, index) => {
      return {
        value: data.nRisks,
        name: data.priority,
        title: {
          offsetCenter: titleOffsets[index],
          fontSize: 10,
        },
        itemStyle: {
          color: colors[index],
        },
      };
    });
  }, [topAssetRisksData]);

  const option = {
    tooltip: {
      trigger: "item",
    },

    series: [
      {
        max:
          Math.max(...topAssetRisksData.map((data) => data.nRisks)) === 0
            ? 1
            : Math.max(...topAssetRisksData.map((data) => data.nRisks)),
        name: "Assets",
        type: "gauge",
        radius: "100%",
        center: ["50%", "50%"],
        startAngle: 90,
        endAngle: 0,
        clockwise: false,
        pointer: {
          show: false,
        },

        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
        },

        axisLine: {
          lineStyle: {
            width: 60,
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },

        detail: {
          show: false,
        },

        data: assetsData,
      },
    ],
  };

  const ref = React.useRef<EChartsReact>(null);

  const chartInstance = useMemo(
    () => ref.current?.getEchartsInstance(),
    [ref.current]
  );

  const resizeCallback = useCallback(() => {
    chartInstance?.resize();
  }, [chartInstance]);

  useEffect(() => {
    resizeCallback();

    window.addEventListener("resize", resizeCallback);

    return () => {
      window.removeEventListener("resize", resizeCallback);
    };
  }, [resizeCallback]);

  return (
    <ReactECharts
      ref={ref}
      option={option}
      style={{ width: "150px", height: "150px" }}
    />
  );
};
export default TopRisksandPrioritiesCharts;
