import { Badge, Card, Flex, Placeholder, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { FaRegClock } from "react-icons/fa";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";

export default function ScanProgress() {
  //  will not need to wait for ai details range once liam pushed new schema
  const { currentScanner, scannersLoading, assetsLoading } =
    useMonitorCompanyContext();

  const { hours, minutes } = useMemo(() => {
    if (
      !currentScanner ||
      currentScanner.ScanTimeRemaining === undefined ||
      currentScanner.ScanTimeRemaining === null ||
      !currentScanner.IsOn
    ) {
      return { hours: "-", minutes: "-" };
    }

    const durationMinutes = currentScanner.ScanTimeRemaining;

    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    return { hours, minutes };
  }, [currentScanner]);

  if (scannersLoading || assetsLoading)
    return (
      <Card
        variation="outlined"
        padding={"xxxs"}
        paddingLeft={"medium"}
        paddingRight={"medium"}
        borderRadius={"small"}
        width={"325px"}
      >
        <Flex direction={"column"} gap={"xxxs"}>
          <Placeholder width={"100%"} />
          <Placeholder width={"75%"} />
        </Flex>
      </Card>
    );

  return (
    <Card
      variation="outlined"
      padding={"xxxs"}
      paddingLeft={"medium"}
      paddingRight={"medium"}
      borderRadius={"small"}
    >
      <Flex direction={"column"} gap={"0"}>
        <Flex justifyContent={"space-between"}>
          <Flex alignItems={"center"} gap={"xs"} justifyContent={"start"}>
            <Badge
              className={currentScanner?.IsOn ? "blink" : ""}
              size="small"
              variation={currentScanner?.IsOn ? "success" : undefined}
              border={
                currentScanner?.IsOn ? "1px solid green" : "1px solid grey"
              }
              padding={"0"}
              width={"12px"}
              height={"12px"}
            ></Badge>
            <Text fontSize={"small"}>Scan Progress</Text>
          </Flex>
          <Flex>
            <Badge
              size="small"
              padding={"0"}
              paddingLeft={"xxs"}
              paddingRight={"xxs"}
              height={"21px"}
            >
              <Flex
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"xxs"}
              >
                {`${currentScanner?.ScanDevices ?? "-"}/${
                  currentScanner?.TotalDevices ?? "-"
                }`}
              </Flex>
            </Badge>
            <Badge
              size="small"
              padding={"0"}
              paddingLeft={"xxs"}
              paddingRight={"xxs"}
              height={"21px"}
            >
              <Flex
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"xxs"}
              >
                <FaRegClock />{" "}
                {hours + ":" + minutes.toString().padStart(2, "0") + " left"}
              </Flex>
            </Badge>
          </Flex>
        </Flex>
        <Text fontSize={"xs"} color={"neutral.80"}>
          Last Seen:
          {" " +
            (currentScanner?.LastSeen
              ? timeStampToLocaleString(currentScanner?.LastSeen)
              : "-")}
        </Text>
      </Flex>
    </Card>
  );
}
