import * as React from "react";
import { Flex, Card, Text } from "@aws-amplify/ui-react";
import { useCompaniesQuery } from "../../hooks/useCompaniesQuery";
import { useScannersQuery } from "../../hooks/useScannersQuery";

export interface IPortfolioOverviewCardProps {}

export default function PortfolioOverviewCard(
  props: IPortfolioOverviewCardProps
) {
  const {} = props;

  // const { businesses, scanners } = useScannerManagementQuery();

  const { companies: businesses } = useCompaniesQuery(1);
  const { scanners } = useScannersQuery();

  return (
    <Card boxShadow={"medium"} borderRadius={"large"} height={"100%"}>
      <Flex justifyContent={"center"}>
        <Card
          backgroundColor={"transparent"}
          style={{
            borderRight: "solid var(--amplify-colors-neutral-60) 1px",
          }}
          borderRadius={"0"}
        >
          <Flex alignItems={"center"}>
            <Text fontWeight={"semibold"} color={"red.60"} fontSize={"xl"}>
              {businesses?.length}
            </Text>
            <Text textAlign={"center"} fontSize={"xs"}>
              Companies Monitored
            </Text>
          </Flex>
        </Card>
        <Card backgroundColor={"transparent"} borderRadius={"0"}>
          <Flex alignItems={"center"}>
            <Text fontWeight={"semibold"} color={"red.60"} fontSize={"xl"}>
              {scanners?.length}
            </Text>
            <Text textAlign={"center"} fontSize={"xs"}>
              Scanners Registered
            </Text>
          </Flex>
        </Card>
      </Flex>

      {/* <Flex padding={"large"} direction={"column"}>
        <Text>Chart goes here</Text>

        <Text>
          Map goes here: Use map -
          https://docs.amplify.aws/javascript/build-a-backend/more-features/geo/maps/
        </Text>
      </Flex> */}
    </Card>
  );
}
