import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { invokeLambda } from "../aws/lambda/invokeFunction";

export type ActionPlan = {
  actions:
    | {
        title: string;
        detail: string;
      }[]
    | undefined;
};

export async function genReconActionPlan(cyberScore: CyberScoreCombined) {
  const response = await invokeLambda("TextGenApiHandler", {
    genType: "recon-action-plan",
    body: cyberScore,
  }).catch((err) => {
    console.error(err);
    throw err;
  });
  try {
    return JSON.parse(response.body) as ActionPlan;
  } catch (err) {
    console.error("Failed to parse: ", err);
    throw new Error("Failed get Recon Action Plan");
  }
}
