import { User } from "../../types/User";
import { getRequest } from "../restAPI";
import { getUserId } from "../getUserId";

export async function getUser(userId?: string) {
  if (!userId) {
    userId = await getUserId();
  }

  return await getRequest(`/users/${userId}`).then(async (response) => {
    return await response.body.json().then((json) => {
      const user: User | undefined = json as User;
      if (!user) {
        throw new Error(`User ${userId} not found`);
      }
      return user;
    });
  });
}
