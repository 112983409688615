import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  View,
  Text,
} from "@aws-amplify/ui-react";
import * as React from "react";
import NvtTableRow from "./AssetDetailsPopOutPanel.NvtTableRow";
import { useMonitorCompanyContext } from "../../hooks/useMonitorCompanyContext";
import { NVT } from "../../types/assetInventoryApi";
import { getVulnLikelihood } from "../../util/getVulnLikelihood";
import { getVulnImpact } from "../../util/getVulnImpact";

export type RelatedVulensTableProps = {
  assetId: string | null;
  nvts: NVT[];
  nvt: NVT | null | undefined;
  setNVTId: React.Dispatch<React.SetStateAction<string | null>>;
};

export function RelatedVulnsTable({
  assetId,
  nvts,
  nvt: selectedNvt,
  setNVTId: setNVT,
}: RelatedVulensTableProps) {
  const { company } = useMonitorCompanyContext();

  // const [selected, setSelected] = React.useState<NVT | null>(null);

  const [increment, setIncrement] = React.useState(10);

  const incrementChunks = React.useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 250));
    setIncrement((prev) => prev + 50);
  }, []);

  React.useEffect(() => {
    if (increment < nvts.length) incrementChunks();
  }, [increment]);

  const sortedNvts = React.useMemo(() => {
    const likelihoodSorted = nvts?.sort((a, b) => {
      const likelihoodA =
        getVulnLikelihood(a) === "High"
          ? 1
          : getVulnLikelihood(a) === "Medium"
          ? 2
          : 3;
      const likelihoodB =
        getVulnLikelihood(b) === "High"
          ? 1
          : getVulnLikelihood(b) === "Medium"
          ? 2
          : 3;

      if (likelihoodA < likelihoodB) {
        return -1;
      }
      if (likelihoodA > likelihoodB) {
        return 1;
      }
      return 0;
    });

    const impactSorted = likelihoodSorted?.sort((a, b) => {
      const impactA =
        getVulnImpact(a) === "High" ? 1 : getVulnImpact(a) === "Medium" ? 2 : 3;
      const impactB =
        getVulnImpact(b) === "High" ? 1 : getVulnImpact(b) === "Medium" ? 2 : 3;

      if (impactA < impactB) {
        return -1;
      }
      if (impactA > impactB) {
        return 1;
      }
      return 0;
    });

    const prioritySorted = impactSorted?.sort((a, b) => {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    });

    return prioritySorted;
  }, [nvts]);

  if (sortedNvts.length === 0)
    return <Text fontSize={"xs"}>No vulnerabilities found</Text>;

  return (
    <>
      <View>
        <Table highlightOnHover={true} size="small" backgroundColor={"white"}>
          <TableHead>
            <TableRow>
              <TableCell fontSize={"xs"} as="th" textAlign={"center"}>
                Priority
              </TableCell>
              <TableCell fontSize={"xs"} as="th" textAlign={"start"}>
                Family
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                Vulnerability Name
              </TableCell>
              <TableCell fontSize={"xs"} as="th">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          {sortedNvts.slice(0, increment).map((nvt) => (
            <NvtTableRow
              key={nvt.nvt_oid}
              nvt={nvt}
              assetId={assetId as string}
              company={company}
              selectedNvt={selectedNvt}
              setSelectedNvtId={(value) => {
                setNVT(value);
              }}
            />
          ))}
        </Table>
        {/* </ScrollView> */}
        {/* </Card> */}
      </View>
    </>
  );
}
