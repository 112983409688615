import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import CompaniesTable from "../components/monitor/CompaniesTable";

// import { useUserQuery } from "../hooks/useUser";

export default function Monitor() {

  return (
    <>
      <Flex direction={"column"} gap="0" padding={"large"}>
        <Flex
          minHeight={"40px"}
          padding={"small large 0 0"}
          justifyContent={"right"}
        >
        </Flex>

        <Flex direction="column" gap="large">
          <CompaniesTable />
        </Flex>
      </Flex>
    </>
  );
}
