import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { isDomain } from "../../util/isDomain";
import { invokeLambda } from "../aws/lambda/invokeFunction";

export async function getCyberScoreCombined(domain: string) {
  if (isDomain(domain) === false) {
    throw new Error(`${domain} is not a valid domain.`);
  }

  const cyberScore = await invokeLambda("GetCyberScoreDetails", {
    body: JSON.stringify({ domain, type: "combined" }),
  })
    .then((result) => {
      const cyberScore = result.body as CyberScoreCombined;
      if (cyberScore === undefined) {
        throw new Error(`Cyber Score for ${domain} is undefined.`);
      }
      return cyberScore;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(
        `Could not get ${domain}'s Cyber Score, try again with another domain.`
      );
    });
  return cyberScore;
}
