import {
  Badge,
  Flex,
  Heading,
  Text,
  Placeholder,
  ScrollView,
  Table,
  TableCell,
  TableHead,
  TableRow,
  View,
  Button,
} from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";
import { Modal } from "../Modal";
import InformationPanel from "./InformationPanel";
import {
  DarkWebSortFunction,
  type DarkWebTableData,
} from "../../features/cyber-score";

type DarkWebReportTableProps = {
  domain: string | undefined;
  maxHeight: string;
};

export default function DarkWebReportTable(props: DarkWebReportTableProps) {
  const { domain, maxHeight } = props;

  const { cyberScore, isLoading } = useCyberScoreCombinedQuery(domain);

  const [selectedItem, setSelectedItem] = React.useState<
    DarkWebTableData | undefined
  >(undefined);

  const [sortColumn, setSortColumn] = useState<
    keyof DarkWebTableData | undefined
  >("date");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const tableData = useMemo<DarkWebTableData[]>(() => {
    const sorted =
      cyberScore?.BreachList.map((breach) => ({
        email: breach.Email,
        breachedAccount: breach.BreachName,
        date: breach.BreachDate,
        description: breach.Description,
      })).sort((a, b) => DarkWebSortFunction(sortColumn, a, b)) ?? [];

    // const dateSortedItems = items.sort((a, b) => {
    //   return new Date(b.date).getTime() - new Date(a.date).getTime();
    // });
    return sortOrder === "asc" ? sorted : sorted.reverse();
  }, [cyberScore, sortColumn, sortOrder]);

  const modalRef = React.useRef<HTMLDialogElement>(null);

  return (
    <>
      <Modal ref={modalRef} size="medium">
        <InformationPanel
          onClose={() => modalRef.current?.close()}
          header={
            <View>
              <Heading level={4}>{selectedItem?.breachedAccount}</Heading>
              <Badge size="small">{selectedItem?.date}</Badge>
            </View>
          }
          body={
            <View
              dangerouslySetInnerHTML={{
                __html: selectedItem?.description ?? "",
              }}
            />
          }
        />
      </Modal>
      <ScrollView maxHeight={maxHeight}>
        <Table highlightOnHover={true} size="small">
          <TableHead>
            <TableRow>
              <TableCell as="th" width={"100px"}>
                <Button
                  paddingLeft={"0"}
                  boxShadow={"none"}
                  backgroundColor={"transparent"}
                  padding={"xxs"}
                  justifyContent={"start"}
                  variation="link"
                  whiteSpace={"nowrap"}
                  fontWeight={"bold"}
                  fontSize={"small"}
                  textAlign={"center"}
                  onClick={() => {
                    if (sortColumn !== "date") {
                      setSortColumn("date");
                      setSortOrder("desc");
                    } else {
                      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    }
                  }}
                >
                  Date{" "}
                  {sortColumn === "date"
                    ? sortOrder === "asc"
                      ? "▲"
                      : "▼"
                    : ""}
                </Button>
              </TableCell>
              <TableCell as="th">
                <Button
                  paddingLeft={"0"}
                  boxShadow={"none"}
                  backgroundColor={"transparent"}
                  padding={"xxs"}
                  justifyContent={"start"}
                  variation="link"
                  whiteSpace={"nowrap"}
                  fontWeight={"bold"}
                  fontSize={"small"}
                  textAlign={"center"}
                  onClick={() => {
                    if (sortColumn !== "email") {
                      setSortColumn("email");
                      setSortOrder("asc");
                    } else {
                      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    }
                  }}
                >
                  Email{" "}
                  {sortColumn === "email"
                    ? sortOrder === "asc"
                      ? "▲"
                      : "▼"
                    : ""}
                </Button>
              </TableCell>
              <TableCell as="th">
                <Button
                  paddingLeft={"0"}
                  boxShadow={"none"}
                  backgroundColor={"transparent"}
                  padding={"xxs"}
                  justifyContent={"start"}
                  variation="link"
                  whiteSpace={"nowrap"}
                  fontWeight={"bold"}
                  fontSize={"small"}
                  textAlign={"center"}
                  onClick={() => {
                    if (sortColumn !== "breachedAccount") {
                      setSortColumn("breachedAccount");
                      setSortOrder("asc");
                    } else {
                      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    }
                  }}
                >
                  Breached Account{" "}
                  {sortColumn === "breachedAccount"
                    ? sortOrder === "asc"
                      ? "▲"
                      : "▼"
                    : ""}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>

          {isLoading ? (
            [0, 1, 2, 3, 4, 5].map((i) => (
              <TableRow key={i}>
                <TableCell>
                  <Placeholder />
                </TableCell>
                <TableCell>
                  <Placeholder />
                </TableCell>
                <TableCell>
                  <Placeholder />
                </TableCell>
              </TableRow>
            ))
          ) : tableData.length === 0 ? (
            <TableRow>
              <TableCell fontSize={"smaller"}>-</TableCell>
              <TableCell fontSize={"smaller"}>-</TableCell>
              <TableCell fontSize={"smaller"}>-</TableCell>
            </TableRow>
          ) : (
            tableData.map((item, i) => (
              <TableRow
                key={i}
                onClick={() => {
                  setSelectedItem(item);
                  modalRef.current?.showModal();
                }}
                style={{ cursor: "pointer" }}
              >
                <TableCell fontSize={"smaller"}>{item.date}</TableCell>
                <TableCell fontSize={"smaller"}>{item.email}</TableCell>

                <TableCell fontSize={"smaller"} id={i.toString()}>
                  <Flex alignItems={"center"} gap={"small"}>
                    <Text maxWidth={"200px"}>{item.breachedAccount}</Text>
                  </Flex>
                </TableCell>
              </TableRow>
            ))
          )}
        </Table>
      </ScrollView>
    </>
  );
}
