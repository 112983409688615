import { Accordion, Button, Flex, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

export interface IErrorFallbackProps {
  error?: Error;
}

export default function ErrorFallback(props: IErrorFallbackProps) {
  const { error } = props;
  return (
    <Flex
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      padding={"large"}
    >
      <FaExclamationTriangle size={64} />
      <Text>Something went wrong.</Text>
      <Button onClick={() => window.location.reload()}>Reload</Button>
      <Accordion
        fontSize={"small"}
        width={"100%"}
        maxWidth={"800px"}
        items={[
          {
            trigger: <Text textAlign={"center"}>More Info</Text>,
            value: "error",
            content: <Text color={"red.10"}>{error?.message}</Text>,
          },
        ]}
      />
    </Flex>
  );
}
