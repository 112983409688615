import { Card, Flex, Image, Text } from "@aws-amplify/ui-react";
import React from "react";

export default function GettingStartedStepCard({
  title,
  subtitle,
  action,
  image,
}: {
  title: string;
  subtitle: string;
  action: any;
  image: string;
}) {
  return (
    <Card borderRadius={"large"} boxShadow={"medium"}>
      <Flex
        justifyContent={"center"}
        textAlign={"center"}
        direction={"column"}
        gap={"medium"}
      >
        <Flex justifyContent={"center"} padding={"large"}>
          <Image
            borderRadius={"large"}
            // boxShadow={"large"}
            alt=""
            src={image}
            height={"250px"}
            objectFit={"contain"}
            style={{
              WebkitFilter: "drop-shadow(0px 0px 5px rgba(0,0,0,0.3))",
              filter: "drop-shadow(0px 0px 5px rgba(0,0,0,0.3))",
            }}
          />
        </Flex>
        <Text fontSize={"large"}>{title}</Text>

        <Text color={"neutral.80"}>{subtitle}</Text>

        <Text color={"blue.60"}>{action}</Text>
      </Flex>
    </Card>
  );
}
