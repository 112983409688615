import React from "react";
import { Company } from "../types/companies";
import { ReconOutput } from "../types/recon-outputs";
import {
  Flex,
  Heading,
  View,
  Text,
  Button,
  Card,
  Loader,
} from "@aws-amplify/ui-react";
import InformationPanel from "../components/monitorcompany/InformationPanel";
import { FaCheck, FaMinus, FaX } from "react-icons/fa6";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { forceScan } from "../services/recon-outputs/forceScan";
import { useUserContext } from "../hooks/useUserContext";
import { useParams } from "react-router-dom";
import { ReconOutputs } from "./ReconOutputView.Outputs";
import useScoreCardDownload from "../hooks/useScoreCardDownload";

type Props = {
  company: Company | undefined;
  recon: ReconOutput | undefined;
};

export default function ReconOutputView({ company, recon }: Props) {
  const { id } = useParams();

  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const {} = useScoreCardDownload({
    cyberScore: recon?.Outputs ? recon.CyberScore : undefined,
    domain: company?.EmailDomain,
    prospectLogo:
      "http://localhost:3000/static/media/logo.bedd423efd272fc27ed7.png",
    profilePics: [],
  });

  const { mutateAsync: scan, isPending: pendingScan } = useMutation({
    mutationKey: ["scan-new-companies", company?.CUID],
    mutationFn: async () =>
      forceScan({
        member_id: user?.memberId ?? "",
        group_id: 2,
        campaign_id: id,
        cuid: company?.CUID,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["recon-outputs", 2, id] });
    },
  });

  if (!company || !recon) return <View>Select a company</View>;
  const { Status, Outputs } = recon;
  return (
    <Flex direction={"column"}>
      <InformationPanel
        header={
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Flex direction={"column"} gap={"0"}>
              <Heading level={4}>{company.CompanyName}</Heading>
              <Text>{company.EmailDomain}</Text>
            </Flex>
            <Card
              variation="elevated"
              padding={
                Status === "Not scanned" ||
                Status === "Failure" ||
                Status === "Complete"
                  ? "xxxs"
                  : "xxxs medium xxxs xxxs"
              }
              borderRadius={"100px"}
            >
              <Flex alignItems={"center"}>
                <Flex
                  borderRadius={"100px"}
                  backgroundColor={
                    Status === "Not scanned"
                      ? "neutral.60"
                      : Status === "Failure"
                      ? "red.80"
                      : Status === "In progress"
                      ? "brand.primary.80"
                      : "green.80"
                  }
                  height={"35px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{ aspectRatio: "1/1" }}
                >
                  {Status === "Not scanned" ? (
                    <FaMinus color="white" size={"70%"} />
                  ) : Status === "Failure" ? (
                    <FaX color="white" size={"60%"} />
                  ) : Status === "In progress" ? (
                    <Loader />
                  ) : (
                    <FaCheck color="white" size={"60%"} />
                  )}
                </Flex>
                <Text>
                  {Status === "Not scanned"
                    ? "Not Scanned"
                    : Status === "Failure"
                    ? "Scan Failed"
                    : Status === "In progress"
                    ? "Scan In Progress"
                    : null}
                </Text>
                {Status !== "In progress" ? (
                  <Button
                    size="small"
                    variation="primary"
                    borderRadius={"100px"}
                    isLoading={pendingScan}
                    onClick={() => scan()}
                  >
                    {Status === "Not scanned"
                      ? "Scan"
                      : Status === "Failure"
                      ? "Try Again"
                      : "New Scan"}
                  </Button>
                ) : null}
              </Flex>
            </Card>
          </Flex>
        }
      >
        {Outputs ? null : null}
      </InformationPanel>
      {recon.Outputs ? <ReconOutputs cyberScore={recon.CyberScore} /> : null}
    </Flex>
  );
}
