import {
  Button,
  Link,
  Placeholder,
  ScrollView,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import { useCyberScoreCombinedQuery } from "../../hooks/useCyberScoreCombinedQuery";
import { FaLinkedin } from "react-icons/fa6";
import { toast } from "react-toastify";
import { FaClipboard } from "react-icons/fa";
import { CompromisedAccountsTableData } from "../../features/cyber-score";
import { CompromisedAccountsSortFunction } from "../../features/cyber-score/utils/CompromisedAccountsSortFunction";

type CompromisedAccountsTableProps = {
  domain: string | undefined;
  maxHeight: string;
};

export default function CompromisedAccountsTable(
  props: CompromisedAccountsTableProps
) {
  const { domain, maxHeight } = props;

  const { cyberScore, isLoading } = useCyberScoreCombinedQuery(domain);

  const [sortColumn, setSortColumn] = useState<
    keyof CompromisedAccountsTableData | undefined
  >("nBreaches");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const tableItems = useMemo<CompromisedAccountsTableData[]>(() => {
    const sorted =
      cyberScore?.Employees.map((employee) => {
        const email = employee.Email ?? "";

        // count occurences of email in BreachList
        const nBreaches = cyberScore.BreachList.filter(
          (breach) => breach.Email === email
        ).length;

        const { FirstName, LastName, Position } = employee;

        const f = FirstName !== null ? FirstName : "";

        const l = LastName !== null ? LastName : "";

        const name = `${f} ${l}`.trim() || "-";
        const position = Position ?? "-";

        return {
          email,
          nBreaches: nBreaches,
          name: name,
          position: position,
          linkedInUrl: employee.SourcePage ?? "",
        };
      }).sort((a, b) => CompromisedAccountsSortFunction(sortColumn, a, b)) ??
      [];

    return sortOrder === "asc" ? sorted : sorted.reverse();
  }, [cyberScore, sortColumn, sortOrder]);

  return (
    <ScrollView maxHeight={maxHeight}>
      <Table highlightOnHover={true} size="small">
        <TableHead>
          <TableRow>
            <TableCell fontSize={"smaller"} as="th" width={"190px"}>
              <Button
                paddingLeft={"0"}
                boxShadow={"none"}
                backgroundColor={"transparent"}
                padding={"xxs"}
                justifyContent={"start"}
                variation="link"
                whiteSpace={"nowrap"}
                fontWeight={"bold"}
                fontSize={"small"}
                textAlign={"center"}
                onClick={() => {
                  if (sortColumn !== "email") {
                    setSortColumn("email");
                    setSortOrder("asc");
                  } else {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }
                }}
              >
                Email{" "}
                <Text opacity={sortColumn === "email" ? "100" : "0"}>
                  {sortColumn === "email"
                    ? sortOrder === "asc"
                      ? "▲"
                      : "▼"
                    : ""}
                </Text>
              </Button>
            </TableCell>
            <TableCell fontSize={"smaller"} as="th" width={"190px"}>
              <Button
                paddingLeft={"0"}
                boxShadow={"none"}
                backgroundColor={"transparent"}
                padding={"xxs"}
                justifyContent={"start"}
                variation="link"
                whiteSpace={"nowrap"}
                fontWeight={"bold"}
                fontSize={"small"}
                textAlign={"center"}
                onClick={() => {
                  if (sortColumn !== "name") {
                    setSortColumn("name");
                    setSortOrder("asc");
                  } else {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }
                }}
              >
                Name{" "}
                <Text opacity={sortColumn === "name" ? "100" : "0"}>
                  {sortOrder === "asc" ? "▲" : "▼"}
                </Text>
              </Button>
            </TableCell>
            <TableCell fontSize={"smaller"} as="th" width={"190px"}>
              <Button
                paddingLeft={"0"}
                boxShadow={"none"}
                backgroundColor={"transparent"}
                padding={"xxs"}
                justifyContent={"start"}
                variation="link"
                whiteSpace={"nowrap"}
                fontWeight={"bold"}
                fontSize={"small"}
                textAlign={"center"}
                onClick={() => {
                  if (sortColumn !== "position") {
                    setSortColumn("position");
                    setSortOrder("asc");
                  } else {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }
                }}
              >
                Position{" "}
                <Text opacity={sortColumn === "position" ? "100" : "0"}>
                  {sortOrder === "asc" ? "▲" : "▼"}
                </Text>
              </Button>
            </TableCell>
            <TableCell
              fontSize={"smaller"}
              as="th"
              textAlign={"center"}
              width={"120px"}
            >
              <Button
                paddingLeft={"0"}
                boxShadow={"none"}
                backgroundColor={"transparent"}
                padding={"xxs"}
                justifyContent={"start"}
                variation="link"
                whiteSpace={"nowrap"}
                fontWeight={"bold"}
                fontSize={"small"}
                textAlign={"center"}
                onClick={() => {
                  if (sortColumn !== "nBreaches") {
                    setSortColumn("nBreaches");
                    setSortOrder("desc");
                  } else {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }
                }}
              >
                Breaches{" "}
                <Text opacity={sortColumn === "nBreaches" ? "100" : "0"}>
                  {sortOrder === "asc" ? "▲" : "▼"}
                </Text>
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          [0, 1, 2, 3, 4, 5].map((i) => (
            <TableRow key={i}>
              <TableCell>
                <Placeholder />
              </TableCell>
              <TableCell>
                <Placeholder />
              </TableCell>
              <TableCell>
                <Placeholder />
              </TableCell>
              <TableCell>
                <Placeholder />
              </TableCell>
            </TableRow>
          ))
        ) : tableItems.length === 0 ? (
          <TableRow>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        ) : (
          tableItems.map((item, i) => (
            <TableRow key={i}>
              <TableCell
                fontSize={"smaller"}
                textAlign={"start"}
                whiteSpace={"nowrap"}
                onClick={() => {
                  navigator.clipboard.writeText(item.email);
                  toast.success(
                    <>
                      <b>{item.email}</b> copied to clipboard 📋
                    </>
                  );
                }}
              >
                <Text isTruncated width={"125px"}>
                  {" "}
                  {item.email}
                </Text>

                <View as={"span"} className="copy" padding={"0 0 0 xs"}>
                  <FaClipboard className="copy-hover" />
                </View>
              </TableCell>
              <TableCell
                fontSize={"smaller"}
                whiteSpace={"nowrap"}
                textAlign={"start"}
              >
                {item.linkedInUrl ? (
                  <Link href={item.linkedInUrl} isExternal>
                    <FaLinkedin />{" "}
                  </Link>
                ) : null}
                {item.name}
              </TableCell>
              <TableCell
                whiteSpace={"nowrap"}
                fontSize={"smaller"}
                textAlign={"start"}
              >
                {item.position}
              </TableCell>
              <TableCell fontSize={"smaller"} textAlign={"center"}>
                {item.nBreaches}
              </TableCell>
            </TableRow>
          ))
        )}
      </Table>
    </ScrollView>
  );
}
