import { useCallback, useMemo } from "react";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import { useQuery } from "@tanstack/react-query";

import { imageToBase64QueryOptions } from "../util/imageToBase64QueryOptions";
import ScoreCard from "../services/exportreport/ScoreCard";
import { Branding } from "../components/monitorcompany/ReportBuilderButton";
import { useCyberScoreCombinedQuery } from "./useCyberScoreCombinedQuery";
import PDFScoreCardDocument from "../components/reports/score-card/PDFScoreCardDocument";
import { useReconKeyTakeawaysQuery } from "./useReconKeyTakeawaysQuery";
import { useReconActionPlanQuery } from "./useReconActionPlanQuery";
import { useFaviconGoogle } from "./useFaviconGoogle";

export default function useCyberReportScoreDownloader(
  reportStyles: Branding,
  domain: string | undefined | null,
  profilePics: string[]
) {
  const { cyberScore, isLoading: isLoadingCyberScore } =
    useCyberScoreCombinedQuery(domain);

  const { url } = useFaviconGoogle(domain);

  const { data: logo } = useQuery(
    imageToBase64QueryOptions(
      url,
      "https://assets-global.website-files.com/658c7dbe6f7007cb9233ad26/658c7dbe6f7007cb9233af00_icon-1-perks-dataplus-template.svg"
    )
  );

  const { keyTakeaways: reconKeyTakeaways, isLoading: reconKTLoading } =
    useReconKeyTakeawaysQuery(cyberScore, domain);

  const { actionPlan, isLoading: apisLoading } = useReconActionPlanQuery(
    cyberScore,
    domain
  );

  const isLoading = useMemo(
    () =>
      isLoadingCyberScore ||
      isLoadingCyberScore ||
      reconKTLoading ||
      apisLoading,

    [isLoadingCyberScore, isLoadingCyberScore, reconKTLoading, apisLoading]
  );

  const scoreDoc = useMemo(
    () => (
      <ScoreCard
        companyName={domain}
        domain={""}
        logo={logo}
        reportStyles={reportStyles}
        cyberScore={cyberScore}
      />
    ),
    [cyberScore, logo, reportStyles, domain]
  );

  const savePDFScore = useCallback(async () => {
    const asPdf = pdf(scoreDoc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} Cyber Report - Score Card - ${domain}.pdf`;
    link.click();
  }, [cyberScore, logo, scoreDoc, domain]);

  const savePDFScoreCardV2 = useCallback(async () => {
    if (
      !domain ||
      !logo ||
      !cyberScore ||
      !actionPlan ||
      !reconKeyTakeaways ||
      !reportStyles
    ) {
      return;
    }

    const asPdf = pdf(
      <PDFScoreCardDocument
        company={domain ?? ""}
        logo={logo}
        cyberScore={cyberScore}
        actionPlan={actionPlan}
        keyTakeaways={reconKeyTakeaways}
        branding={reportStyles}
        whiteLabelLogo={reportStyles.yourLogo}
        profilePics={profilePics}
      ></PDFScoreCardDocument>
      // <ScoreCard
      //   companyName={company?.CompanyName}
      //   domain={company?.EmailDomain}
      //   logo={logo}
      //   reportStyles={reportStyles}
      //   cyberScore={cyberScore}
      // />
    );
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} Cyber Report - Score Card - ${domain}.pdf`;
    link.click();
  }, [
    domain,
    logo,
    reportStyles,
    cyberScore,
    actionPlan,
    reconKeyTakeaways,
    profilePics,
  ]);

  return { savePDFScore, savePDFScoreCardV2, isLoading };
}
