import { Asset } from "../../types/assetInventoryApi";
import { invokeLambda } from "../aws/lambda/invokeFunction";

export async function genVulnRemediation(asset: Asset, nvtId: string) {
  const response = await invokeLambda("GenVulnRemediation", {
    inputJson: asset,
    nvt: nvtId,
  }).catch((err) => {
    console.error(err);
    throw err;
  });
  return response.body.response as string;
}
