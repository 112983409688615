import { fetchAuthSession } from "aws-amplify/auth";

export async function getCredentials() {
  try {
    const credentials = (await fetchAuthSession()).credentials;
    return credentials;
  } catch (err) {
    throw new Error("Error getting credentials: " + err);
  }
}
