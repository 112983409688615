// import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Asset } from "../../types/assetInventoryApi";
import { getAssetLastScanned } from "../../util/getAssetLastScanned";
import { getAssetCurrentIPRecord } from "../../util/getAssetCurrentIPRecord";
import { CyberScoreCombined } from "../../types/CyberScoreCombined";
import { getVulnImpact } from "../../util/getVulnImpact";
import { getVulnLikelihood } from "../../util/getVulnLikelihood";
import { getVulnPriority } from "../../util/getVulnPriority";
import { getAssetAge } from "../../util/getAssetAge";
import { timeStampToLocaleString } from "../../util/timeStampToLocaleString";

// imported placeholder data to represent the data that would be passed from the Playbook
// import { assets } from './placeholderData';

type Row = { [key: string]: string | number | boolean | null };

type BreachedEmailRow = {
  Email: string;
  "Breach Name": string;
  "Breach Date": string;
  "Breach Description": string;
};

const processCyberReport = (assets: Asset[]) => {
  // if (!assets) return null;

  const vulnRows: Row[] = [];
  const assetRows: Row[] = [];

  assets.forEach((asset) => {
    // Calculate device age
    // let deviceAge: number | "N/A" = "N/A";
    // if (asset.MAC_DATE) {
    //   const macDate = new Date(
    //     asset.MAC_DATE.replace(" ", "T") + "Z"
    //   ).getMilliseconds();
    //   const currentDate = new Date().getMilliseconds();
    //   const ageInMilliseconds = currentDate - macDate;
    //   const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365);
    //   deviceAge = Math.round(ageInYears * 10) / 10; // Round to 1 decimal place
    // }

    // Process data for Asset Inventory table
    assetRows.push({
      Risks: Object.keys(asset.nvts).length,
      "Scan Date": getAssetLastScanned(asset),
      "Asset Id": asset.DEVICE_ID,
      IP: getAssetCurrentIPRecord(asset)?.IP ?? "-",
      Subnet: getAssetCurrentIPRecord(asset)?.SUBNET ?? "-",
      MAC: asset.MAC,
      "MAC Vendor": asset.MAC_VENDOR,
      "Device Age (years)": getAssetAge(asset).toFixed(0),
      Hostname: asset.HOSTNAME,
      Type: asset.recog_type,
      Category: asset.recog_type_group,
      Brand: asset.recog_brand,
      Model: asset.recog_model,
      Serial: asset.recog_serial,
      OS: asset.recog_os_name,
      "OS Version": asset.recog_os_version,
      "OS Build": asset.recog_os_build,
      "Last Updated":
        asset.nvts.length > 0
          ? timeStampToLocaleString(
              asset.nvts.sort((a, b) => {
                if (
                  new Date(a.nvt_published ?? 0).getDate() >
                  new Date(b.nvt_published ?? 0).getDate()
                ) {
                  return 1;
                } else if (
                  new Date(a.nvt_published ?? 0).getDate() <
                  new Date(b.nvt_published ?? 0).getDate()
                ) {
                  return -1;
                }
                return 0;
              })[0].nvt_published,
              {
                timeZoneName: undefined,
                dateStyle: "short",
              }
            )
          : "",
      "First Seen (Local)": timeStampToLocaleString(asset.IPs[0].FIRST_SEEN, {
        dateStyle: "short",
        timeStyle: "short",
        hour12: false,
        timeZoneName: undefined,
      }).replace(",", ""),
      "Last Seen (Local)": timeStampToLocaleString(asset.IPs[0].LAST_SEEN, {
        dateStyle: "short",
        timeStyle: "short",
        hour12: false,
        timeZoneName: undefined,
      }).replace(",", ""),
    });

    // Process data for Vulnerabilities table
    asset.nvts.forEach((nvt) => {
      vulnRows.push({
        Priority: getVulnPriority(nvt),
        Impact: getVulnImpact(nvt),
        Likelihood: getVulnLikelihood(nvt),
        Status: nvt.status || "To Do",
        "Asset Id": asset.DEVICE_ID,
        "Asset IP": getAssetCurrentIPRecord(asset)?.IP ?? "-",
        "Vulnerability Name": nvt.name,
        Family: nvt.family,
        "CVSS Score": nvt.cvss_score,
        "EPSS Score": nvt.epss_score + "%",
        KEV: nvt.CVEs.length ? nvt.CVEs.some((cve) => cve.KEV) : null,
        Summary: nvt.summary,
        Insight: nvt.insight,
        Solution: nvt.solution,
        "Solution Type": nvt.solution_type,
        Affected: nvt.affected,
        CPE: nvt.CPE,
        Ports: nvt.ports.join(", "),
        CVEs: nvt.CVEs.map((cve) => cve.cve_id).join(", "),
        References: nvt.references.join(", "),
        "Publish Date": timeStampToLocaleString(nvt.nvt_published),
        "First Seen": timeStampToLocaleString(nvt.nvt_first_seen),
        "Last Seen": timeStampToLocaleString(nvt.nvt_last_seen),
      });
    });
  });

  return { vulnRows, assetRows };
};
const processCyberScoreDetails = (cyberScoreDetails?: CyberScoreCombined) => {
  const breachedEmailRows: BreachedEmailRow[] = [];

  if (cyberScoreDetails === undefined) {
    return { breachedEmailRows };
  }

  const emails = cyberScoreDetails.EmailList;
  const breaches = cyberScoreDetails.BreachList;

  emails.forEach((email) => {
    const emailBreaches = breaches.filter((breach) => breach.Email === email);
    if (emailBreaches.length === 0) {
      breachedEmailRows.push({
        Email: email,
        "Breach Name": "-",
        "Breach Date": "-",
        "Breach Description": "-",
      });
    } else {
      emailBreaches.forEach((breach) => {
        breachedEmailRows.push({
          Email: email,
          "Breach Name": breach.BreachName,
          "Breach Date": breach.BreachDate,
          "Breach Description": breach.Description,
        });
      });
    }
  });

  return { breachedEmailRows };
};

export const saveCyberReportToXlsx = ({
  assets,
  cyberScore,
  filename,
}: {
  assets?: Asset[];
  cyberScore: CyberScoreCombined | undefined;
  filename: string;
}) => {
  if (assets === undefined) {
    toast.error("Assets are undefined");
    return;
  }

  const { vulnRows, assetRows } = processCyberReport(assets);

  const { breachedEmailRows } = processCyberScoreDetails(cyberScore);

  // Convert data to worksheets
  const vulnWorksheet = XLSX.utils.json_to_sheet(vulnRows);
  const assetWorksheet = XLSX.utils.json_to_sheet(assetRows);
  const breachedEmailWorksheet = XLSX.utils.json_to_sheet(breachedEmailRows);

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Append worksheets to the workbook with different names
  XLSX.utils.book_append_sheet(
    workbook,
    vulnWorksheet,
    "Vulnerabilities Table"
  );
  XLSX.utils.book_append_sheet(workbook, assetWorksheet, "Asset Inventory");
  XLSX.utils.book_append_sheet(
    workbook,
    breachedEmailWorksheet,
    "Dark Web Report"
  );

  // Write workbook to a file
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  const buf = new ArrayBuffer(wbout.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;
  saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
};
