import React from "react";
import { Routes, Route } from 'react-router-dom';
import DashboardLayout from "../layouts/DashboardLayout";
import HackerView from "../pages/Recon";
import HackerViewCompany from "../pages/ReconCompany";


export default function ReconRoutes() {

    return (
        <>
            <DashboardLayout>
                <Routes>
                    <Route index element={<HackerView/>} />
                    <Route path=":domain" element={<HackerViewCompany/>} />
                </Routes>
            </DashboardLayout>
        </>
    );
}
