import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import React from "react";
import GettingStartedStepCard from "./GettingStartedStepCard";
import { useNavigate } from "react-router-dom";

export default function GettingStartedGuide() {
  const navigate = useNavigate();

  return (
    <Flex
      height={"100%"}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"0"}
      onClick={() => navigate("/score-card")}
    >
      {/* <Flex
        minHeight={"40px"}
        padding={"small large 0 0"}
        justifyContent={"right"}
      ></Flex> */}

      <Flex direction="column" gap="large">
        <Flex width={{ base: "90%", medium: "60%" }} alignSelf={"center"}>
          <Text textAlign="center" fontSize={"3rem"}>
            Captivate your leads with{" "}
            <span style={{ color: "#174DE1" }}> Cyber Powered </span>{" "}
            prospecting
          </Text>
        </Flex>
      </Flex>

      <Grid
        templateColumns={"1fr 1fr 1fr"}
        gap={"medium"}
        padding={"xxl large"}
      >
        <GettingStartedStepCard
          title={"1. Create a Score Card"}
          subtitle={
            "Just type in a prospect’s website and see their Cyber Score 💯"
          }
          action={"~1 minute"}
          image={"/images/score-card/score-card-blue-p1.png"}
        />

        <GettingStartedStepCard
          title={"2. Make it your own"}
          subtitle={
            "Add your MSP’s logo, colors, and contact info so they know it’s you"
          }
          action={"~5 seconds"}
          image={"/images/score-card/score-card-green-p1-cropped.png"}
        />

        <GettingStartedStepCard
          title={"3. Email it to a prospect"}
          subtitle={"Send them a personalized email with their Score Card."}
          action={
            <Button
              variation="primary"
              backgroundColor={"#174DE1"}
              borderRadius={"large"}
            >
              Let's get started 👉🚨
            </Button>
          }
          image={"/images/score-card/score-card-green-p1-swoosh.png"}
        />
      </Grid>
    </Flex>
  );
}
